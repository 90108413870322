import React from 'react';
import Strings from 'Strings.js';
import axios from 'axios/axios.js';
import Pagination from 'components/Pagination/Pagination';



class UnallocatedTasksList extends React.Component{
    
    constructor(props){
        super(props);
        this.flag = 1;
    }

    state = {
        list: [],
        draggedIds:[],
        draggedItems:[],
        totPages: 1,
        currentPage: 1,
    }

    callFromServer = (currentPage) => {
        if(this.flag)
        {
            this.flag = 0;

            if(!currentPage)
                currentPage = this.state.currentPage;
            
            axios.post('Tasks/tasksList.php?unallocated=1&forAType='+this.props.type+'&currentPage='+currentPage+'&siid='+this.props.siid, {}).then(response => {
                if(response.data.r === 1)
                {
                    this.setState({
                        ...this.state,
                        list: response.data.d.tasksList,
                        totPages: response.data.totPages,
                        currentPage: response.data.currentPage,
                    });
                }
                this.flag = 1;
            }).catch(error => {
                this.flag = 1;
            });
        }
    }

    componentDidMount(){
        if(this.state.list.length === 0){
            this.callFromServer(this.state.currentPage);
        }
    }

    pageClicked = (ele) => {
        console.log(ele);
        this.callFromServer(ele);
    }

    dragStart = (ev) => {
        //console.log("Drag is working");
        //console.log(this.state.draggedItems);
        ev.dataTransfer.setData("textid", JSON.stringify(this.state.draggedItems));
    }

    selectTask = (e, item) => {
        e.preventDefault();
        if (e.ctrlKey) {
            var tempstate = [...this.state.draggedIds];
            var dragitems = [...this.state.draggedItems];
            if(tempstate.includes(item.id))
            {
                var index = tempstate.indexOf(item.id);
                tempstate.splice(index, 1);
                dragitems.splice(index, 1);
            }
            else{
                tempstate.push(item.id);
                dragitems.push(item);
            }

            this.setState({draggedIds: tempstate, draggedItems: dragitems});
        }
        else{
            this.setState({draggedIds: [item.id], draggedItems: [item]});
        }
        
    }
   

    render(){
        const tasklist = this.state.list.map((item, index) => {
            var dura = Math.floor(item.duration % 60);

            var tatinmins = Math.floor((parseInt(item.tat) - (Date.now() / 1000)) / 60);

            var minu = Math.abs(Math.floor(tatinmins % 60));
            var tlhours = Math.floor(tatinmins / 60);
            var durhous = Math.floor(item.duration / 60);
            return (
                <tr onClick={(e) => {this.selectTask(e, item)}} style={this.state.draggedIds.includes(item.id)?{background:'yellow'}:{}} key={index} id={'task:'+item.id} draggable="true" onDragStart={this.dragStart} >
                    <td style={{padding:'0 10px'}}>{item.name}</td>
                    {/*<td style={{padding:'0 10px'}}>{item.tat}</td>*/}
                    <td style={{padding:'0 10px',textAlign:'right'}}>{tlhours}{':'}{minu > 9?minu:'0'+minu}{':00'}</td>
                    <td style={{padding:'0 10px',textAlign:'right'}}>{durhous > 9?durhous:'0'+durhous}{':'}{dura > 9?dura:'0'+dura}</td>
                </tr>
            );
        });
        return(
            <div>
                <Pagination totPages={this.state.totPages} currentPage={this.state.currentPage} pageClicked={(ele) => this.pageClicked(ele)}>
                    <table>
                        <thead>
                            <tr style={{background:'#eee'}}>
                                <th style={{padding:'0 10px'}}>{Strings.text.label.taskName}</th>
                                {/*<th style={{padding:'0 10px'}}>{Strings.text.label.tat}</th>*/}
                                <th style={{padding:'0 10px'}}>{Strings.text.label.timeLeft}</th>
                                <th style={{padding:'0 10px'}}>{Strings.text.label.taskLength}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tasklist}
                        </tbody>
                    </table>
                </Pagination>
            </div>
        )
    }
}



export default UnallocatedTasksList; 