import React, { Component } from 'react';
import Strings from 'Strings.js';
import Classes from './NotificationBar.module.css';
import { connect } from 'react-redux';	
import Toast from 'react-bootstrap/Toast'

class NotificationBar extends Component{

    render(){
        
        return(
            <div>
                {this.props.children}
                <div className={Classes.Notification}>
                    {
                        Object.keys(this.props.errorGroup).map(item => {

                            return(<Toast style={{margin:'10px'}} key={item} onClose={() => this.props.removeError(item)} show={true} delay={5000} autohide>
                                {
                                    this.props.errorGroup[item]['type'] === 1?(
                                        <React.Fragment>
                                            <Toast.Header style={{background:'green',color:'white'}}>
                                                <strong className="mr-auto">Success!</strong>
                                            </Toast.Header>
                                            <Toast.Body>{'Operation Completed Successfully'}</Toast.Body>
                                        </React.Fragment>
                                    ):(
                                        <React.Fragment>
                                            <Toast.Header style={{background:'orangered',color:'white'}}>
                                                <strong className="mr-auto">Error!</strong>
                                            </Toast.Header>
                                            <Toast.Body>{this.props.errorGroup[item]['errorCode'] in Strings.error?Strings.error[this.props.errorGroup[item]['errorCode']]:this.props.errorGroup[item]['errorCode']}</Toast.Body>
                                        </React.Fragment>
                                    )
                                }
                                
                                
                            </Toast>)





                            //setTimeout(() => this.props.removeError(item), 8000);
                            //return (<div key={item} onClick={(item) => this.props.removeError(item)}>{this.props.errorGroup[item]}</div>)
                        })
                    }
                </div>
            </div>
        )
    }

    
}

const mapStateToProps = state => {
	return {
        errorGroup: state.errorReducer.errorGroup,
    }
}

const mapDispatchToProps = dispatch => {
	return {
		removeError: (ind) => dispatch({type: 'REMOVE_ERROR', payload:{index: ind}}),
	}
}


export default connect(mapStateToProps, mapDispatchToProps)(NotificationBar);