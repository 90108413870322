const initialState = {
    dashboardTabs : localStorage.getItem('tab') === null?{}:JSON.parse(localStorage.getItem('tab'))
}

const reducer = (state = initialState, action) => {
    if(action.type === 'CHANGE_DASHBOARD_TABS'){
        return {
            ...state,
            dashboardTabs: action.payload.tabs
        }
    }

    return state;
}
export default reducer;
