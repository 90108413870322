import React from 'react';
import List from 'modules/List/List';
import Strings from 'Strings.js';

class Single extends React.Component{

    render(){
        return(
            <React.Fragment>
                <td>{this.props.permissionId}</td>
                <td>{this.props.permissionName}</td>
            </React.Fragment>
        );
    }
}

class _List extends React.Component{

    render(){
        return(
            <List 
                listPermission={60}
                onListClick={(item) => this.props.onListClick(item)}
                list={this.props.list}
                headings={{serial:'id',
                            permissionName:'permissionName',
                        }}
                singleItem={(item) => (<Single permissionName={item.permissionName} permissionId={item.id} />)}
                listContainerCSS={this.props.listContainerCSS}
            />
        );
    }
}

export {Single as PermissionSingleList, _List as PermissionListList};

/*
    for FileSingle -
        permissionName

    for FileList -
        onListClick, list, 
*/