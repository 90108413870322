import React from 'react';
import CreateFile from 'modules/Options/Files/Create';
import {FileListList} from 'modules/Options/Files/Single';
import Classes from './Files.module.css';
import axios from 'axios/axios.js';

class DictatorsList extends React.Component{

    state = {
        isClicked: 0,
        list: [],
        matter: null
    }

    componentDidMount(){
        if(this.state.list.length === 0){
            axios.post('Files/filesList.php', {}).then(response => {
                if(response.data.r === 1)
                {
                    console.log(response.data.d.filesList);
                    this.setState({
                        ...this.state,
                        list: response.data.d.filesList
                    });
                }
            }).catch(error => {
                
            });
        }
    }

    onListClick = (item) => {
        this.setState({
            isClicked: 0,
            matter: null
        }, () => {
            this.setState({
                isClicked: 1,
                matter: null
            });
        });
    }

    render(){
        return(
            <div className={Classes.container}>
                {/*<div className={Classes.SingleEntry}>
                    <CreateFile />
                </div>*/}
                <div style={{marginRight:'20px',boxSizing:'border-box'}}>
                    <FileListList listContainerCSS={Classes.listContainer}
                        onListClick={(data) => this.onListClick(data)}
                        list={this.state.list} />
                    </div>

                    <div>
                        {this.state.isClicked === 1?this.state.matter:null}
                    </div>
            </div>
        )
    }
}

export default DictatorsList; 