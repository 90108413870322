import React, { Component } from 'react';


class CheckPermission extends Component{
    render(){
        
        var perm, isPer = null;
        if((isPer = localStorage.getItem("per"))) 
            perm = JSON.parse(isPer);
        
        const propPerm = Array.isArray(this.props.perm)?this.props.perm:[this.props.perm];

       // console.log("Perm Permissions", perm.permissions);
        return(
            <React.Fragment>
                {propPerm.indexOf('ALL') > -1 || perm.permissions === 'ALL' || Object.values(perm.permissions).filter(x => propPerm.includes(parseInt(x))).length > 0?(this.props.children):(this.props.hideComponent === true?null:(
                    <div>DO NOT HAVE PERMISSION TO ACCESS THIS</div>
                ))}
            </React.Fragment>
        ) 
    }

    
}


export default CheckPermission;