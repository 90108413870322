import React from 'react';
import CheckPermission from 'components/CheckPermission/CheckPermission';
import Classes from './List.module.css';
import {NavLink} from 'react-router-dom';
import Strings from 'Strings.js';
import Table from 'react-bootstrap/Table'

class List extends React.Component{

    constructor(props){
        super(props);
        this.refForList = {};
    }

    state = {
        active : [0],
        items : [],
    }

    setActive = (index) => {
        if (this.props.list.length > this.state.active[0] + index && this.state.active[0] + index >= 0){
            this.setState({active: [this.state.active[0] + index]}, () => {
                this.refForList[this.state.active[0]].scrollIntoView({block: "center", inline: "nearest"})
            });
        }
    }

    openActive = (type) => {
        var item = this.props.list[this.state.active[0]];
        if(!(this.props.nonclick && this.props.nonclick.is == true && item[this.props.nonclick.cond]==this.props.nonclick.lockon)){
            this.props.onListClick(item, type);
        }
    }

    onListClick = (event, item, index) => {
        event.preventDefault();
        if(!(this.props.nonclick && this.props.nonclick.is == true && item[this.props.nonclick.cond]==this.props.nonclick.lockon))
        {
            let act = [];
            let it = [];
            if (event.ctrlKey) {
                act = [...this.state.active];
                it = [...this.state.items];
                act.push(index);
                it.push(item);
                
            }
            else{
                act = [index];
                it = [item];
            }
            
            this.setState({active: act, items: it}, () => {
                //if('onMultipleListClick' in this.props)
                //    this.props.onMultipleListClick(this.state.items);
            });
            
            this.props.onListClick(item);
        }
    }


    render(){
        let headings = [];
        for(var v in this.props.headings){
            headings.push(<th key={v}>{Strings.text.label[v]}</th>);
        }
        
        /*
        let list = [];
        for(var k in this.props.list){
            var temp = [];
            for(var v in this.props.headings){
                var xdr = this.props.list[k][this.props.headings[v]];
                temp.push(<td>{Array.isArray(xdr)?Object.values(xdr).join(', '):xdr}</td>)
            }
            list.push(<tr key={this.props.list[k].id} onClick={(event) => this.onListClick(event, this.props.list[k])}>{temp}</tr>)
        }
        */
        
        const list = this.props.list.map((item, index) => {
            var dfr = null;
            if(!(this.props.nonclick && this.props.nonclick.is == true && item[this.props.nonclick.cond]==this.props.nonclick.lockon))
            {
                dfr = this.props.onListClick?(
                            <tr key={item.id} ref={ref => this.refForList[index] = ref} className={[this.state.active.includes(index)?this.props.activeClass:'', this.props.listIdentifierForAutoClick+item.id].join(' ')} onClick={(event) => this.onListClick(event, item, index)}>
                                {this.props.singleItem(item)}
                            </tr>
                        ):(
                            <tr key={item.id} ref={ref => this.refForList[index] = ref}>
                                {this.props.singleItem(item)}
                            </tr>
                        );
            }
            else{
                dfr = <tr key={item.id} ref={ref => this.refForList[index] = ref} className={[this.state.active.includes(index)?this.props.activeClass:'',this.props.nonclick.lockclass].join(' ')}>
                        {this.props.singleItem(item)}
                    </tr>
            }
            return (
                dfr
            );
        });
        

        return(
            <CheckPermission perm={this.props.listPermission}>
                <div className={this.props.listContainerCSS}>
                    <Table bordered>
                        <thead>
                            <tr>
                                {headings}
                            </tr>
                        </thead>
                        <tbody className={Classes.listTableClass}>
                        {/**/}
                            {list}
                        {/*</div>*/}
                        </tbody>
                    </Table>
                </div>
            </CheckPermission>
        );
    }
}

export default List;


/*
    listPermission = permission for list
    onListClick = function to handle list click
    list = list of item properties with id
    singleItem = function to return single list item
    listContainerCSS = List container css class
*/