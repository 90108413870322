import React, { Component } from 'react';
import {Link, Redirect} from 'react-router-dom';
import Form from 'components/Form/Form';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Strings from 'Strings.js';

class ForgetPassword extends Component{
    state = {redirect: false, email: null}

    successSubmit = (data) => {
        this.setState({redirect: true, email: data.email});
    }

    failSubmit = (data) => {
        console.log("Login Failed : " + data.e);
    }

    render(){
        const fields = [{
            type: 'email',
            value: '',
            name: 'email',
            placeholder: Strings.text.placeholder.email,
            options: {
                        required: true,
                    },
            label: Strings.text.label.emailForgetPass,
        }];


        return(
            <div style={{marginTop:'30px'}}>
            {this.state.redirect === true?<Redirect to={"/change-password?email="+this.state.email} />:(
                <Container>
                    <Row className="justify-content-center">
                        <Col xs={6}>
                            <h1>{Strings.text.heading.main}</h1>
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col xs={6}>
                            <h4>{Strings.text.heading.forgetPass}</h4>
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col xs={6}>
                            <Form   url={'forgetPass.php'}
                            buttonValue={Strings.text.button.sendCode}
                            buttonType='success'
                            successSubmit={(data) => this.successSubmit(data)}
                            failSubmit={(data) => this.failSubmit(data)}
                            fields={fields} />
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col xs={6}>
                            <Link to={{
                                pathname: '/login',
                            }}>{Strings.text.link.backToLogin}</Link>  
                        </Col>
                    </Row>
                </Container>
            )}
            </div>
        )
    }
}

export default ForgetPassword;