import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import {Provider} from 'react-redux';

import store from './store';

import axios from 'axios/axios';
import 'bootstrap/dist/css/bootstrap.min.css';



axios.interceptors.request.use(request => {
  console.log(request);
  return request;		//	always return request otherwise it will be blocked.
}, error => {
  store.dispatch({type: 'SHOW_ERROR', payload:{errorCode: 'INTERNET_CONNECTION', type:0}});
  return Promise.reject(error);
});

axios.interceptors.response.use(response => {
  console.log(response);
  if(response.data.r === 0){
    store.dispatch({type: 'SHOW_ERROR', payload:{errorCode: response.data.e, type:0}});
  }
  else{
    //store.dispatch({type: 'SHOW_ERROR', payload:{errorCode: response.data.d, type:1}});
  }
  return response;		//	always return request otherwise it will be blocked.
}, error => {
  if(error.response.status === 422 && error.response.data.r === 0){
    store.dispatch({type: 'SHOW_ERROR', payload:{errorCode: error.response.data.e, type:0}});
    return error.response;
  } else {
    store.dispatch({type: 'SHOW_ERROR', payload:{errorCode: 'INTERNET_CONNECTION', type:0}});
    return Promise.reject(error);
  }
  
});




ReactDOM.render(<Provider store={store}><App /></Provider>, document.getElementById('root'));
//ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
