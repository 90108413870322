import React from 'react';
import EditFile from 'modules/Form/Edit/Edit';
import Strings from 'Strings.js';

class Edit extends React.Component{

    state = {
        update: 1,
        approveUser: this.props.isApproved
    }

    onSuccess = (data) => {
        console.log(data);
        this.setState({...this.state, update: 0}, () => {
            this.setState({
                update: 1,
                approveUser: parseInt(data['vendor_approved'])
            });
        });
        this.props.refresh();
    }

    onFailure = (data) => {

    }

    render(){
        const editFile = <EditFile
                editPermission = {19}
                ButtonCSS = {''}
                NameCSS = {''}
                editButtonValue = {this.state.approveUser == 0 ? Strings.text.button.approveVendorButton : Strings.text.button.unApproveVendorButton}
                editContainerCSS = {''}
                url = {'Vendors/approveVendor.php'}
                buttonClicked={1}
                enableButton='true'
                buttonValue = {this.state.approveUser == 0 ? Strings.text.button.approveVendorButton : Strings.text.button.unApproveVendorButton}
                onSuccess = {(data) => this.onSuccess(data)}
                onFailure = {(data) => this.onFailure(data)}
                fields = {[
                    {
                        type: 'hidden',
                        value: this.props.vid,
                        name: 'vid',
                        options: {
                                    required: true,
                                },
                    },
                    {
                        type: 'hidden',
                        value: this.state.approveUser==1?0:1,
                        name: 'approved',
                        options: {
                                    required: true,
                                },
                    },
                ]}
            />
        return(
            this.state.update === 1?editFile:null
        );
    }
}

export default Edit;

/*
    vid, approveUser
*/