import React from 'react';
import {NavLink, Route, Switch} from 'react-router-dom';
import Classes from './Home.module.css';
import {connect} from 'react-redux';
import Strings from 'Strings.js';
import CheckPermission from 'components/CheckPermission/CheckPermission';

import AllocateTasksWrapper from 'pages/AllocateTasksWrapper/AllocateTasksWrapper';
import TaskTemplateList from 'pages/TaskTemplateList/TaskTemplateList';
import DictatorTemplateList from 'pages/DictatorTemplateList/DictatorTemplateList';
import TasksList from 'pages/TasksList/TasksList';
import UsersList from 'pages/UsersList/UsersList';
import VendorsList from 'pages/VendorsList/VendorsList';
import InstitutesList from 'pages/InstitutesList/InstitutesList';
import DictatorsList from 'pages/DictatorsList/DictatorsList';
import CreateNewUsers from 'pages/CreateNewUsers/CreateNewUsers';
import CreateDynamicForms from 'pages/CreateDynamicForms/CreateDynamicForms';
import TestingClientForm from 'pages/TestingClientForm/CreateNewUsers';
import CreateIndustrySubcategory from 'pages/IndustrySubcategory/CreateIndustrySubcategory';
import CreateDictatorSpecialization from 'pages/CreateDictatorSpecialization/CreateDictatorSpecialization';
//import DownloadFiles from 'pages/DownloadFiles/DownloadFiles';
import UploadFiles from 'pages/UploadFiles/UploadFiles';
import UsersMonitor from 'pages/UsersMonitor/UsersMonitor';
//import CreateTemplate from 'pages/CreateTemplate/CreateTemplate';
//import DeleteEntities from 'pages/DeleteEntities/DeleteEntities';
import PermissionList from 'pages/PermissionList/PermissionList'; 
import CreateTaskTemplate from 'pages/CreateTaskTemplate/CreateTaskTemplate';
import PermissionTemplateList from 'pages/PermissionTemplateList/PermissionTemplateList';
import CreateIndexingTemplate from 'pages/CreateIndexingTemplate/CreateIndexingTemplate';
import Files from 'pages/Files/Files';
import UploadEfaxes from 'pages/UploadEfaxes/UploadEfaxes';
import DashboardPage from 'pages/DashboardPage/DashboardPage';

import Navbar from 'react-bootstrap/Navbar'

class Home extends React.Component{
    state = {
        isLogin: true,
    }; 

    render(){

        const menuStrings = Strings.text.menu;

        const menuList = [
            {'m':menuStrings.dashboard, u:'/dashboard/home/dashboard', r:DashboardPage, p:['ALL']},
            {'m':menuStrings.allocateTasks, u:'/dashboard/home/allocate-tasks', r:AllocateTasksWrapper, p:[80,86,87,88]},
            {'m':menuStrings.listOfTasks, u:'/dashboard/home/task-list', r:TasksList, p:[23,77]},
            {'m':menuStrings.listOfUsers, u:'/dashboard/home/users-list', r:UsersList, p:[5]},
            {'m':menuStrings.listOfVend, u:'/dashboard/home/vendors-list', r:VendorsList, p:[45]},
            {'m':menuStrings.listOfInst, u:'/dashboard/home/institutes-list', r:InstitutesList, p:[47]},
            {'m':menuStrings.listOfDict, u:'/dashboard/home/dictators-list', r:DictatorsList, p:[43]},
            {'m':menuStrings.files, u:'/dashboard/home/files', r:Files, p:[59]},
            {'m':menuStrings.listOfPerm, u:'/dashboard/home/permission-list', r:PermissionList, p:[60]},
            {'m':menuStrings.listOfPermTemp, u:'/dashboard/home/permission-Template-list', r:PermissionTemplateList, p:[11,61]},
            {'m':menuStrings.createUsers, u:'/dashboard/home/create-users', r:CreateNewUsers, p:[1,9,17,15]},
            {'m':menuStrings.createIndustrySubcategory, u:'/dashboard/home/create-industry-subcategory', r:CreateIndustrySubcategory, p:[50]},
            {'m':menuStrings.createDictatorSpecialization, u:'/dashboard/home/create-dictator-specialization', r:CreateDictatorSpecialization, p:[50]},
            
            //{'m':menuStrings.downloadF, u:'/dashboard/home/download-files', r:DownloadFiles},
            {'m':menuStrings.uploadF, u:'/dashboard/home/upload-files', r:UploadFiles, p:[29]},
            {'m':menuStrings.uploadEFaxes, u:'/dashboard/home/upload-efaxes', r:UploadEfaxes, p:[82]},
            {'m':menuStrings.usersMonit, u:'/dashboard/home/users-monitor', r:UsersMonitor, p:[26]},
            //{'m':menuStrings.createTemp, u:'/dashboard/home/create-template', r:CreateTemplate},
            {'m':menuStrings.CreateIndexingTemplate, u:'/dashboard/home/create-indexing-template', r:CreateIndexingTemplate, p:[81]},
            {'m':menuStrings.CreateDynamicForms, u:'/dashboard/home/create-dynamic-forms', r:CreateDynamicForms, p:[83]},
            {'m':'Client Form', u:'/dashboard/home/testing-client-form', r:TestingClientForm, p:[82]},
            {'m':menuStrings.createTaskTemp, u:'/dashboard/home/create-task-template', r:CreateTaskTemplate, p:[23]},
            {'m':menuStrings.taskTemplateList, u:'/dashboard/home/task-template-list', r:TaskTemplateList, p:[78]},
            {'m':menuStrings.dictatorTemplateList, u:'/dashboard/home/dictator-template-list', r:DictatorTemplateList, p:[176]},
            //{'m':menuStrings.delete, u:'/dashboard/home/delete', r:DeleteEntities},  
        ];

        const menus = menuList.map((items, index) => {
            return (
                <CheckPermission key={index} perm={items.p} hideComponent={true}>
                    <NavLink key={index} to={items.u} className={Classes.MenuItem} activeClassName={Classes.active}>
                        {items.m}
                    </NavLink>
                </CheckPermission>
            );
        });

        const routes = menuList.map((items, index) => {
            return (
                    <Route key={index} path={items.u} component={items.r} />
            );
        });

        return(
            <React.Fragment>
                <div className={Classes.container}>
                    <div className={Classes.leftBar} id={'sidebar'}>{menus}</div>
                    <div className={Classes.rightBar} id={'workspace'}><Switch>{routes}</Switch></div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
	return {
      dashboardTabs: state.tabsReducer.dashboardTabs,
  }
}

export default connect(mapStateToProps, null)(Home);