import React from 'react';
import {NavLink, Redirect} from 'react-router-dom';
import Classes from './Dashboard.module.css';
import {connect} from 'react-redux';
import Strings from 'Strings.js';
import Form from 'components/Form/Form';
import CheckLogin from 'components/CheckLogin/CheckLogin';
import Navbar from 'react-bootstrap/Navbar'
import Button from 'react-bootstrap/Button'
import Nav from 'react-bootstrap/Nav'
import runningPage from 'global';


class Dashboard extends React.Component{
    constructor(props){
        super(props);
        this.tog = 0;
    }

    state = {
        dashboardTabs: this.props.dashboardTabs,
        isLogin: true,
    };

    logout = () => {
        localStorage.removeItem("awx");
        //this.setState({isLogin: false});
        window.location.href = '/login';
    }

    toggleSidebar = () => {
        var filterbar = document.getElementById("filterBar");
        if(this.tog){
            this.tog = 0;
            document.getElementById("sidebar").style.overflowY = "hidden";
            document.getElementById("sidebar").style.width = "0";
            if(filterbar)
                filterbar.style.left = 0;
            document.getElementById("workspace").style.marginLeft= "0";
        }
        else{
            this.tog = 1;
            document.getElementById("sidebar").style.width = "250px";
            document.getElementById("workspace").style.marginLeft= "250px";
            if(filterbar)
                filterbar.style.left = "250px";
            document.getElementById("sidebar").style.overflowY = "auto";
        }
    }

    toggleWorkbenchHome = (e) => {
        if(e.altKey){
            e.preventDefault();
        }
        if(runningPage.page === 'home'){
            if(e.altKey){
                if (e.keyCode == 'Q'.charCodeAt(0)) {
                    
                    const homelink = document.querySelectorAll("a[href='/dashboard/home/dashboard']")[0];
                    if(homelink.classList.contains("active")){
                        document.querySelectorAll("a[href='/dashboard/workbench/task-list']")[0].click();
                    } else {
                        homelink.click();
                    }
                } else if (e.keyCode == 'A'.charCodeAt(0)) {
                    //e.preventDefault();
                    const lnk = document.querySelectorAll("a[href='/dashboard/workbench/task-list']")[0]
                    if(lnk) lnk.click();
                } else if (e.keyCode == 'T'.charCodeAt(0)) {
                    //e.preventDefault();
                    const lnk = document.querySelectorAll("a[href='/dashboard/workbench/pool']")[0]
                    if(lnk) lnk.click();
                } else if (e.keyCode == 'C'.charCodeAt(0)) {
                    //e.preventDefault();
                    const lnk = document.querySelectorAll("a[href='/dashboard/workbench/task-compare']")[0]
                    if(lnk) lnk.click();
                } else if (e.keyCode == 'L'.charCodeAt(0)) {
                    this.logout();
                }
            }
        }
    };

    componentDidMount(){
        this.toggleSidebar();
        runningPage.page = 'home';
        document.addEventListener('keydown', this.toggleWorkbenchHome);
    }

    componentWillUnmount(){
        document.removeEventListener('keydown', this.toggleWorkbenchHome);
    }

    render(){
        var perm, isPer = null;
        if((isPer = localStorage.getItem("per"))) 
            perm = JSON.parse(isPer);
        

        return(
            <React.Fragment>
                {this.state.isLogin === false?<Redirect to={'/login'} />:null}
                <CheckLogin>

                <Navbar bg="dark" variant="dark" expand="lg" fixed="top" style={{height: '80px'}}>
                    <Navbar.Text onClick={this.toggleSidebar} style={{marginRight:'20px','cursor':'pointer'}}>
                        &#9776;
                    </Navbar.Text>
                    <Navbar.Brand>
                        {perm.name}<br />
                        <h6>{perm.account_type_name}</h6>
                    </Navbar.Brand>
                    
                    <Nav className="mr-auto">
                        <NavLink className={'nav-link'} to={'/dashboard/home/dashboard'}>
                            {Strings.text.tabs.home}
                            <span style={{
                                fontSize: '10px',
                                display: 'block',
                            }}>( Alt+Q )</span>
                        </NavLink>
                        <NavLink className={'nav-link'} to={'/dashboard/workbench/task-list'}>
                            {'WorkBench'}
                            <span style={{
                                fontSize: '10px',
                                display: 'block',
                            }}>( Alt+Q )</span>
                        </NavLink>
                    </Nav>
                    
                    <Navbar.Text>
                        <Button style={{
                            paddingTop: '5px', 
                            paddingBottom: '5px'
                        }} type={'button'} onClick={this.logout} variant={'success'}>
                            {Strings.text.button.logout}
                            <span style={{
                                fontSize: '10px',
                                display: 'block',
                            }}>( Alt+L )</span>
                        </Button>
                        
                    </Navbar.Text>
                </Navbar>
                
                <div className={Classes.workspace}>
                    {this.props.children}
                </div>

                </CheckLogin>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
	return {
      dashboardTabs: state.tabsReducer.dashboardTabs,
  }
}

export default connect(mapStateToProps, null)(Dashboard);
