import React from 'react';
import CreateSpecialization from 'modules/Dictators/Specialization/Create';
import axios from 'axios/axios';
import Classes from './CreateDictatorSpecialization.module.css';

class CreateDictatorSpecialization extends React.Component{
    state = {
        refreshList: 1,
        list: {},
    }

    refreshList = () => {
        this.setState({
            refreshList: 0,
        }, () => {
            this.callFromServer();
        });
    }

    callFromServer = () => {
        axios.get('Dictators/getSpecializationWithSubcategory.php').then(response => {
            if(response.data.r === 1)
            {
                this.setState({
                    list: response.data.d.dictator_specialization,
                    refreshList: 1,
                });
            }
        }).catch(error => { });
     }

    componentDidMount(){
        if(Object.keys(this.state.list).length === 0){
            this.callFromServer();
        }
    }

    render(){
        var specializations = [];
        Object.keys(this.state.list).forEach(ele => {
            var subcategory = <td>{this.state.list[ele][0].subcatname}</td>;
            var listItems = [];
            this.state.list[ele].forEach(el => {
                listItems.push(<span style={{display:'block'}}>{el.specname}</span>)
            })
            var specs = <td>{listItems}</td>
            specializations.push(<tr>{subcategory}{specs}</tr>)
        })

        return(
            <div className={Classes.container}>
                {this.state.refreshList == 1?(
                    <React.Fragment>
                        <div className={Classes.innerContainer}>
                            <CreateSpecialization refresh={this.refreshList} />
                        </div>
                        <div className={Classes.innerContainer}>
                            <h4>Dictator Specializations List</h4>
                            <table border={'collapse'}>
                                <thead>
                                    <tr>
                                        <th>Industry Subcategory</th>
                                        <th>Dictator Specializations</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {specializations}
                                </tbody>
                            </table>
                        </div>
                    </React.Fragment>
                ):null}
            </div>
        )
    }
}

export default CreateDictatorSpecialization;