import React from 'react';
import List from 'modules/List/List';
import Strings from 'Strings.js';

class Single extends React.Component{

    render(){
        return(
            <React.Fragment>
                <td>{this.props.name}</td>
                <td>{this.props.specialization}</td>
                <td>{this.props.institute}</td>
                <td>{this.props.totTemplatesCount}</td>
                <td>{this.props.processedTaskCount}</td>
                <td>{this.props.pendingTaskCount}</td>
                <td>{this.props.isApproved}</td>
                <td>{this.props.isDeleted}</td>
            </React.Fragment>
        );
    }
}

class _List extends React.Component{

    render(){
        return(
            <List 
                listPermission={43}
                onListClick={(item) => this.props.onListClick(item)}
                list={this.props.list}
                activeClass={this.props.activeClass}
                listIdentifierForAutoClick={'dictators'}
                headings={{dictatorName:'name',specialization:'specialization',instituteName:'institute',totalTemplates:'totTemplatesCount',
                totalTaskProcessed:'processedTaskCount',totalTaskPending:'pendingTaskCount',dictatorApproved:'isApproved',
                dictatorDeleted:'isDeleted'}}
                singleItem={(item) => (<Single name={item.name} 
                    specialization={item.specialization} institute={item.institute} totTemplatesCount={item.totTemplatesCount} 
                    processedTaskCount={item.processedTaskCount} pendingTaskCount={item.pendingTaskCount} 
                    isApproved={item.isApproved} isDeleted={item.isDeleted} />)}
                listContainerCSS={this.props.listContainerCSS}
            />
        );
    }
}

export {Single as DictatorListSingle, _List as DictatorListList};

/*
    for FileSingle -
        name, specialization, institute, totTemplatesCount, processedTaskCount, pendingTaskCount, isApproved, isDeleted

    for FileList -
        onListClick, list, 
*/