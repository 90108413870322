import React from 'react';
import SelectAppropriateForm from 'modules/DynamicForm/SelectAppropriateForm/SelectAppropriateForm';
import GetDynamicForm from 'modules/DynamicForm/GetDynamicForm/GetDynamicForm';

import Strings from 'Strings.js';


class Create extends React.Component{
    state = {
        refresh: 0,
        formType: this.props.formType,
        dependency: {},
    }

    loadForm = (data, formTy) => {
        console.log("Load FOrm Data", data);
        this.setState({refresh:0}, () => this.setState({
            refresh: 1,
            dependency: Object.fromEntries(data),
        }));
    }
    
    render(){
        return(
            <div style={{padding:'20px'}}>
                <SelectAppropriateForm formType={this.state.formType} loadForm={(data, formTy)=>this.loadForm(data, formTy)} />
                {
                    this.state.refresh==1?(
                        <GetDynamicForm 
                            formType={this.state.formType} 
                            dependency={this.state.dependency}
                            createContainerCSS={''}
                            buttonValue={this.props.buttonValue}
                        />
                    ):null 
                }
            
            </div>
        );
    }
}

export default Create;


/*
    this.props
            buttonValue: name of button
            formType
*/