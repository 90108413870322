import { EditorState, RichUtils, KeyBindingUtil, Modifier } from 'draft-js';

const {hasCommandModifier} = KeyBindingUtil;

export default () => {
    return {
        customStyleMap: {
            'SUPERSCRIPT': {
                verticalAlign:'super', 
                fontSize:'smaller'
            }
        },
        keyBindingFn: e => {
            if (e.keyCode === 50 && e.altKey) {
                //2
                return "superscript";
            }
        },
        handleKeyCommand: (command, editorState, eventTimeStamp, { setEditorState }) => {
            if (command === "superscript") {
                const nextContentState = ['SUBSCRIPT','SUPERSCRIPT'].reduce((contentState, color) => {
                    return Modifier.removeInlineStyle(contentState, editorState.getSelection(), color)
                }, editorState.getCurrentContent());
        
                let nextEditorState = EditorState.push(
                    editorState,
                    nextContentState,
                    'change-inline-style'
                );
                setEditorState(RichUtils.toggleInlineStyle(nextEditorState, "SUPERSCRIPT"));
                return true;
            }
        }
    };
  };