const initialState = {
    errorGroup: {},
    errorIndex : 0,
}

const reducer = (state = initialState, action) => {
    if(action.type === 'SHOW_ERROR'){
        const newErrorGroup = {...state.errorGroup};
        const errorIndex = state.errorIndex + 1;
        console.log("Inside show error");
	
        newErrorGroup[errorIndex] = {errorCode: action.payload.errorCode, type: action.payload.type};
        return {
            errorGroup: newErrorGroup,
            errorIndex: errorIndex,
        }
    }
    else if(action.type === 'REMOVE_ERROR'){
        const newErrorGroup = {...state.errorGroup};
        delete newErrorGroup[action.payload.index];
        return {
            ...state,
            errorGroup: newErrorGroup
        }
    }
    return state;
}
export default reducer;
