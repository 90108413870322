import React from 'react';
import {DictatorListList} from 'modules/Dictators/Single';
import DictatorProfile from 'modules/Dictators/DictatorProfile';
import Pagination from 'components/Pagination/Pagination';
import Classes from './DictatorsList.module.css';
import axios from 'axios/axios.js';

class DictatorsList extends React.Component{

    constructor(props){
        super(props);
        this.flag = 1;
    }

    state = {
        isClicked: 0,
        list: [],
        curItem: null,
        matter: null,
        totPages: 1,
        currentPage: 1,
        refreshList: 1,
    }

    callFromServer = (currentPage) => {
        if(this.flag)
        {
            this.flag = 0;
            axios.post('Dictators/dictatorsList.php?currentPage='+currentPage, {}).then(response => {
                if(response.data.r === 1)
                {
                    console.log(response.data.d.dictatorsList);
                    this.setState({
                        ...this.state,
                        totPages: response.data.totPages,
                        currentPage: response.data.currentPage,
                        list: response.data.d.dictatorsList,
                        refreshList: 1,
                    }, ()=>{
                        if(this.state.curItem){
                            var allEle = document.getElementsByClassName('dictators'+this.state.curItem.id);
                            if(allEle){
                                allEle[0].click();
                            }
                        }
                    });
                }
                this.flag = 1;
            }).catch(error => {
                this.flag = 1;
            });
        }
    }

    componentDidMount(){
        if(this.state.list.length === 0){
            this.callFromServer(this.state.currentPage);
        }
    }

    onListClick = (item) => {
        this.setState({
            isClicked: 0,
            matter: null
        }, () => {
            this.setState({
                isClicked: 1,
                curItem: item,
                matter: <DictatorProfile
                            listContainerCSS={Classes.SingleEntry}
                            id={item.id}
                            did={item.id}
                            iid={item.iid}
                            industry_id={item.industry_id}
                            specialization_id={item.specialization_id}
                            name={item.name} 
                            directPost={item.directPost}
                            asr={item.asr}
                            nickname={item.nickname}
                            specialization={item.specialization} 
                            institute={item.institute}
                            totTemplatesCount={item.totTemplatesCount}
                            processedTaskCount={item.processedTaskCount}
                            pendingTaskCount={item.pendingTaskCount}
                            isApproved={item.isApproved} 
                            isDeleted={item.isDeleted}
                            approveUser={item.approveUser}
                            gender={item.gender}
                            phone={item.phone}
                            tat={item.tat}
                            access={item.access}
                            address={item.address}
                            email={item.email}
                            vendor_id={item.vendor_id}
                            accountType={item.accountType}
                            grade_all_1={item.grade_all_1}
                            grade_all_2={item.grade_all_2}
                            refresh={this.refreshList}
                        />
            });
        });
    }

    pageClicked = (ele) => {
        console.log(ele);
        this.callFromServer(ele);
    }

    refreshList = () => {
        this.setState({
            refreshList: 0,
            matter: null,
        }, () => {
            this.callFromServer(this.state.currentPage);
        });
    }

    render(){
        return(
            <div className={Classes.container}>
                {this.state.refreshList == 1?(
                    <React.Fragment>
                        <div style={{marginRight:'20px',boxSizing:'border-box'}}>
                            <Pagination totPages={this.state.totPages} currentPage={this.state.currentPage} pageClicked={(ele) => this.pageClicked(ele)}>
                                <DictatorListList listContainerCSS={Classes.listContainer}
                                    onListClick={(data) => this.onListClick(data)}
                                    showCheck={true}
                                    activeClass={Classes.active}
                                    list={this.state.list} />
                            </Pagination>
                        </div>

                        <div>
                            {this.state.isClicked === 1?this.state.matter:null}
                        </div>
                    </React.Fragment>
                ):null}
            </div>
        )
    }
}

export default DictatorsList; 