import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import Input from 'components/Input/Input.js';
import Strings from 'Strings.js';
import Button from 'components/Button/Button.js';
import FormTable from 'components/FormTable/FormTable';
import {EditorState, convertToRaw, convertFromRaw, CompositeDecorator, Modifier, Draft, Immutable, Entity, SelectionState, RichUtils} from 'draft-js';
import {getSelectionEntity, getEntityRange, getSelectedBlock} from 'draftjs-utils';
import {cloneDeep} from 'lodash';
import {stateToHTML} from 'draft-js-export-html';

import axios from 'axios/axios.js';
import Form from 'react-bootstrap/Form' 
import Classes from './Form.module.css'



class Formnew extends Component{
    
    constructor(props){
        super(props);
        this.ajaxCalls = {};

        this.dictionary = null;
        this.startF = 1;
        this.ReRender = 1;

        this.saveIntervalVar = null;
        this.editorIndex = 0;

        this.editorChange = 0;
        this.editorStateInit = [];

        this.commentsData = {};
        this.commentStarting = 0;
        this.tableStarting = 0;

        this.submitFormButtonRef = React.createRef();
        
        this.onChangePointer = [];
        this.originalFields = cloneDeep(this.props.fields); 
        this.originalButton = {
            disable: this.props.enableButton?false:true,
            loader: false,
            value: this.props.buttonValue,
            type: this.props.buttonType,
        };

        //console.log(props);


          const MALE_STRINGS = /\bhis\b|\bhim\b|\bhe\b/gi;
          const FEMALE_STRINGS =/\bshe\b|\bher\b/gi;
          const FORMAT_STRINGS = / /gi;
          const TAB_STRINGS = /\t/gi;
    
        const spaceFormatStrategy = (contentBlock, callback, contentState) => {
            //if(this.startF)
                findWithString(FORMAT_STRINGS, contentBlock, callback);
        }
          
          const genderMaleStrategy = (contentBlock, callback, contentState) => {
              findWithString(MALE_STRINGS, contentBlock, callback);
          }
          
          const genderFemaleStrategy = (contentBlock, callback, contentState) => {
              findWithString(FEMALE_STRINGS, contentBlock, callback);
          }

          const tabStrategy = (contentBlock, callback, contentState) => {
            findWithString(TAB_STRINGS, contentBlock, callback);
            }

            const commentStrategy = (contentBlock, callback, contentState) => {
                contentBlock.findEntityRanges(
                    (character) => {
                        const entityKey = character.getEntity();
                        return (
                            entityKey !== null &&
                            Entity.get(entityKey).getType() === 'COMMENT'
                        );
                    },
                    callback
                );
            }

            const tableStrategy = (contentBlock, callback, contentState) => {
                contentBlock.findEntityRanges(
                    (character) => {
                        const entityKey = character.getEntity();
                        return (
                            entityKey !== null &&
                            Entity.get(entityKey).getType() === 'TABLE'
                        );
                    },
                    callback
                );
            }

            const badOCRStrategy = (contentBlock, callback, contentState) => {
                contentBlock.findEntityRanges(
                    (character) => {
                        const entityKey = character.getEntity();
                        return (
                            entityKey !== null &&
                            Entity.get(entityKey).getType() === 'BADOCR'
                        );
                    },
                    callback
                );
            }

            const modOCRStrategy = (contentBlock, callback, contentState) => {
                contentBlock.findEntityRanges(
                    (character) => {
                        const entityKey = character.getEntity();
                        return (
                            entityKey !== null &&
                            Entity.get(entityKey).getType() === 'MODERATEOCR'
                        );
                    },
                    callback
                );
            }

          /*
          const dictionaryStrategy = (contentBlock, callback, contentState) => {
            if(this.dictionary) { 
                const text = contentBlock.getText();  
                var words = text.split(' ');
                //console.log(words);
                var index = 0;
                for(var i in words){
                    if(words[i].length > 0){
                        var vth = '';
                        if(!(vth = recursiveFunction(this.dictionary[words[i][0]], words[i].toLowerCase(), 0, this.dictionary[words[i][0]].length-1))){
                            callback(index, index + words[i].length);
                            
                        }

                        //console.log(vth, index, index + words[i].length);

                        index = index + words[i].length + 1;
                    }
                    else
                        index = index + 1;
                } 
            }
          }

          let recursiveFunction = (arr, x, start, end) => { 
       
                // Base Condtion 
                if (start > end) return false; 
            
                // Find the middle index 
                let mid=Math.floor((start + end)/2); 
            
                // Compare mid with given key x 
                if (arr[mid].toLowerCase()==x) return true; 
                    
                // If element at mid is greater than x, 
                // search in the left half of mid 
                if(arr[mid] > x)  
                    return recursiveFunction(arr, x, start, mid-1); 
                else
                    return recursiveFunction(arr, x, mid+1, end); 
            } 
            */

          const findWithString = (regex, contentBlock, callback) => {
              const text = contentBlock.getText();
              let matchArr,
                  start;
              while ((matchArr = regex.exec(text)) !== null) {
                  start = matchArr.index;
                  callback(start, start + matchArr[0].length);
              }
          }

          const findUneditableContent = (contentBlock, callback, contentState) => {
            //console.log("Called findUneditableContent");
            contentBlock.findEntityRanges(
              (character) => {
                const entityKey = character.getEntity();
                return (
                  entityKey !== null &&
                  contentState.getEntity(entityKey).getType() === 'UNEDITABLE_CONTENT'
                );
              },
              callback
            );
          }
    
        const Uneditable = (props) => {
            //console.log("Called Uneditable");
            return (
              <span className={'uneditable'} data-offset-key={props.offsetKey} style={{border:'1px solid #888'}}>
                {props.children}
              </span>
            );
          };
          
          const GenderMaleSpan = (props) => {
              return (
                  <span className={'male-highlight'} style={styles.male} data-offset-key={props.offsetKey}>
                      {props.children}
                  </span>
              );
          };

          const GenderFemaleSpan = (props) => {
            return (
                <span className={'female-highlight'} style={styles.female} data-offset-key={props.offsetKey}>
                    {props.children}
                </span>
            );
        };

        const DictionarySpan = (props) => {
            return (
                <span className={'dictionary'} style={styles.dictionary} data-offset-key={props.offsetKey}>
                    {props.children}
                </span>
            );
        };

        const spaceFormatSpan = (props) => {
            return (
                <span className={'blankSpace dotback'} data-offset-key={props.offsetKey}>
                    {props.children}
                </span>
            );
        };

        const tabFormatSpan = (props) => {
            return (
                <span className={'tabclass'} data-offset-key={props.offsetKey} style={{width:'48px',display:'inline-block'}}>
                    {props.children}
                </span>
            ); 
        };

        const commentSpan = (props) => {
            return (
              <span style={{border:'3px dashed red'}}>
                {props.children}
              </span>
            );
        };

        const tableSpan = (props) => {
            return (
              <span className={'editorTableSymbol'}>
                <span style={{opacity:'0'}}>
                    {props.children}
                </span>
              </span>
            );
        };

        const badOCRSpan = (props) => {
            return (
              <span style={{borderBottom:'3px solid red'}}>
                {props.children}
              </span>
            );
        };

        const modOCRSpan = (props) => {
            return (
              <span style={{borderBottom:'3px solid yellow'}}>
                {props.children}
              </span>
            );
        };

          

        this.compositeDecorator = new CompositeDecorator([
            {
                strategy: commentStrategy,
                component: commentSpan,
            },
            {
                strategy: tableStrategy,
                component: tableSpan,
            },
            {
                strategy: badOCRStrategy,
                component: badOCRSpan,
            },
            {
                strategy: modOCRStrategy,
                component: modOCRSpan,
            },
            {
              strategy: genderMaleStrategy,
              component: GenderMaleSpan,
            },
            {
                strategy: genderFemaleStrategy,
                component: GenderFemaleSpan,
            },
            {
                strategy: spaceFormatStrategy,
                component: spaceFormatSpan,
            },
            
          ]);

          this.compositeDecoratorWithoutFormat = new CompositeDecorator([
            {
              strategy: genderMaleStrategy,
              component: GenderMaleSpan,
            },
            {
                strategy: genderFemaleStrategy,
                component: GenderFemaleSpan,
            }
          ]);

        
        this.propFields = cloneDeep(this.props.fields);
        this.props.fields.forEach((element, index) => {
            if(element.type === 'editor')
            {
                if(element.value.length > 0)
                {
                    this.propFields[index]['editorState'] = EditorState.createWithContent(convertFromRaw(JSON.parse(element.value)), this.compositeDecorator);
                    this.originalFields[index]['editorState'] = EditorState.createWithContent(convertFromRaw(JSON.parse(element.value)), this.compositeDecorator);
                }
                else{
                    this.propFields[index]['editorState'] = EditorState.createEmpty(this.compositeDecorator);
                    this.originalFields[index]['editorState'] = EditorState.createEmpty(this.compositeDecorator);
                }
                this.editorStateInit[index] = 0; 
            }
        });

        this.state = {
            fields: this.propFields,
            button: {
                disable: this.props.enableButton?false:true,
                loader: false,
                value: this.props.buttonValue,
                type: this.props.buttonType,
            },
        };


        this.myBlockStyleFn = (contentBlock) => {
            const type = contentBlock.getType();
            if (type === 'blockquote') {
                return 'blockquote';
            }
        }
    
        this.styleMap = {
            'STRIKETHROUGH': {
                textDecoration: 'line-through',
            },
            'HIGHLIGHTER': {
                background: 'yellow',
            },
            'SUPERSCRIPT': {
                verticalAlign:'super', 
                fontSize:'smaller'
            },
            'SUBSCRIPT': {
                verticalAlign:'sub', 
                fontSize:'smaller'
            },
            'FONTFAMILY-Times New Roman': {
                fontFamily: 'Times New Roman'
            },
            'FONTFAMILY-Arial': {
                fontFamily: 'Arial'
            },
            'FONTFAMILY-Calibri': {
                fontFamily: 'Calibri'
            },
            'FONTFAMILY-Verdana': {
                fontFamily: 'Verdana'
            },
            'FONTFAMILY-Tahoma': {
                fontFamily: 'Tahoma'
            },
            'FONTFAMILY-Sans-Serif': {
                fontFamily: 'Sans-Serif'
            },
            'FONTFAMILY-HELVETICA': {
                fontFamily: 'Helvetica'
            },
            'SHIFTF3-1': {
                textTransform: 'uppercase'
            },
            'SHIFTF3-2': {
                textTransform: 'lowercase'
            },
            'SHIFTF3-3': {
                textTransform: 'capitalize' 
            }
        }
    
        
        
    }

    commentStartingFunc = (type) => {
        if(type[0]==1)
            this.commentStarting = type[1];
        else if(type[0]==2)
            this.tableStarting = type[1];
    }

    deleteComment = (e, num, fieldIndex, cb) => {
        if(e)
            e.preventDefault();
        var newState = [...this.state.fields];
        var newStateField = {...this.state.fields[fieldIndex]};
        const contentState = newStateField.editorState.getCurrentContent();
        var ent = getSelectionEntity(newStateField.editorState);
        var comment_ = [];
        var selection = '';
        if(ent && contentState.getEntity(ent).getType() == 'COMMENT')
        {
            var startend = getEntityRange(newStateField.editorState, ent);
            const {comment} = contentState.getEntity(ent).getData();
            comment_ = [...comment];

            var commentObj = null;

            
            if(comment_.length == 1 || num==-1)
                commentObj = null;
            else{
                comment_.splice(num, 1);
                commentObj = {comment: comment_};
            }
            
            selection = newStateField.editorState.getSelection().merge({
                focusOffset: startend.end,
                anchorOffset: startend.start,
            });

            const contentStateWithEntity = contentState.createEntity(
                'COMMENT',
                'MUTABLE',
                commentObj
            );
            const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
            const newEditorState = EditorState.set(newStateField.editorState, { currentContent: contentStateWithEntity });
            newStateField.editorState = RichUtils.toggleLink(
                                            newEditorState,
                                            selection,
                                            commentObj==null?null:entityKey
                                        );
            newState[fieldIndex] = newStateField;
            this.setState({
                fields: newState,
            }, ()=>{
                document.getElementById('commentTextarea').focus();
                cb()
            });
        }
        
    }

    commentPostFunc = (fieldIndex, data, cb) => {
        var newState = [...this.state.fields];
        var newStateField = {...this.state.fields[fieldIndex]};
        const contentState = newStateField.editorState.getCurrentContent();
        var ent = getSelectionEntity(newStateField.editorState);
        var comment_ = [];
        var selection = '';
        if(ent && contentState.getEntity(ent).getType() == 'COMMENT')
        {
            var startend = getEntityRange(newStateField.editorState, ent);
            const {comment} = contentState.getEntity(ent).getData();
            comment_ = [...comment];
            comment_.push({user:JSON.parse(localStorage.getItem('per'))['name'], comment: data});
            //var selectionState = SelectionState.createEmpty(getSelectedBlock(newStateField.editorState).getKey());
            selection = newStateField.editorState.getSelection().merge({
                focusOffset: startend.end,
                anchorOffset: startend.start,
            });
        }
        else{
            selection = newStateField.editorState.getSelection();
            comment_ = [{user:JSON.parse(localStorage.getItem('per'))['name'], comment: data}];
            this.commentStarting = 0;
        }
        
        const contentStateWithEntity = contentState.createEntity(
            'COMMENT',
            'MUTABLE',
            {comment: comment_}
        );
        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
        const newEditorState = EditorState.set(newStateField.editorState, { currentContent: contentStateWithEntity });
        newStateField.editorState = RichUtils.toggleLink(
                                        newEditorState,
                                        selection,
                                        entityKey
                                    );
        newState[fieldIndex] = newStateField;
        this.setState({
            fields: newState,
        }, ()=>{
             var ctta = document.getElementById('commentTextarea')
             ctta.value = '';
             ctta.focus();
            cb();
        });
        
    }

    tablePostFunc = (fieldIndex, data) => {
        var newState = [...this.state.fields];
        var newStateField = {...this.state.fields[fieldIndex]};
        const contentState = newStateField.editorState.getCurrentContent();
        var ent = getSelectionEntity(newStateField.editorState);
        var selection = '';
        if(ent && contentState.getEntity(ent).getType() == 'TABLE')
        {
            var startend = getEntityRange(newStateField.editorState, ent);
            selection = newStateField.editorState.getSelection().merge({
                focusOffset: startend.end,
                anchorOffset: startend.start,
            });
        }
        else{
            selection = newStateField.editorState.getSelection();
            this.tableStarting = 0;
        }
        
        const contentStateWithEntity = contentState.createEntity(
            'TABLE',
            'MUTABLE',
            {table: data}
        );
        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
        const newEditorState = EditorState.set(newStateField.editorState, { currentContent: contentStateWithEntity });
        newStateField.editorState = RichUtils.toggleLink(
                                        newEditorState,
                                        selection,
                                        entityKey
                                    );
        newState[fieldIndex] = newStateField;
        this.setState({
            fields: newState,
        }, ()=>{
            this.editorRef.setEditorFocus();
        });
        
    }
    
/*
    selectDictionary = (event) => {
        switch(event.target.value){
            case 'No Dictionary':
                this.dictionary = null;
                break;
            case 'EnglishUS':
                this.dictionary = EnglishUS;
                break;
            case 'EnglishUK':
                this.dictionary = EnglishUK;
                break;
            case 'EnglishAU':
                this.dictionary = EnglishAU;
                break;
            default:
                this.dictionary = null;
                break; 
        }
    }
    */

    setStartF = (val, editorState) => {
        this.startF = val;
        if(val == 1)
            return EditorState.set(editorState, {decorator: this.compositeDecorator});
        else
            return EditorState.set(editorState, {decorator: this.compositeDecoratorWithoutFormat});
        
    }

    

    showHtml = (editorState) => {
        
        let options = {
            inlineStyles: {
              // Override default element (`strong`).
              HIGHLIGHT: {style: {background: 'yellow'}},
              SUPERSCRIPT: {style: {verticalAlign: 'super',fontSize: 'smaller'}},
              SUBSCRIPT: {style: {
                    verticalAlign:'sub', 
                    fontSize:'smaller'
                }},
                'FONTFAMILY-DEFAULT': {style: {
                    fontFamily: 'inherit'
                }},
                'FONTFAMILY-TIMES NEW ROMAN': {style: {fontFamily: 'Times New Roman'}},
                'FONTFAMILY-ARIAL': {style: {fontFamily: 'Arial'}},
                'FONTFAMILY-ARIAL ROUNDED MT BOLD': {style: {fontFamily: 'Arial Rounded MT'}},
                'FONTFAMILY-ARIAL UNICODE MS': {style: {fontFamily: 'Arial Unicode MS'}},
                'FONTFAMILY-BAHNSCHRIFT': {style: {fontFamily: 'Bahnschrift'}},
                'FONTFAMILY-BAHNSCHRIFT SEMIBOLD': {style: {fontFamily: 'Bahnschrift Semibold'}},
                'FONTFAMILY-BOOK ANTIQUA': {style: {fontFamily: 'Book Antiqua'}},
                'FONTFAMILY-BOOKMAN OLD STYLE': {style: {fontFamily: 'Bookman Old Style'}},
                'FONTFAMILY-CALIBRI': {style: {fontFamily: 'Calibri'}},
                'FONTFAMILY-CALIBRI LIGHT': {style: {fontFamily: 'Calibri Light'}},
                'FONTFAMILY-CAMBRIA': {style: {fontFamily: 'Cambria'}},
                'FONTFAMILY-CENTURY GOTHIC': {style: {fontFamily: 'Century Gothic'}},
                'FONTFAMILY-COURIER NEW': {style: {fontFamily: 'Courier New'}},
                'FONTFAMILY-DEJAVU SERIF': {style: {fontFamily: 'DejaVu Serif'}},
                'FONTFAMILY-FRANKLIN GOTHIC MEDIUM': {style: {fontFamily: 'Franklin Gothic Medium'}},
                'FONTFAMILY-FREESTYLE SCRIPT': {style: {fontFamily: 'Freestyle Script'}},
                'FONTFAMILY-GARAMOND': {style: {fontFamily: 'Garamond'}},
                'FONTFAMILY-IMPACT': {style: {fontFamily: 'Impact'}},
                'FONTFAMILY-HELVETICA': {style: {fontFamily: 'Helvetica'}},
                'FONTFAMILY-MICROSOFT SANS SERIF': {style: {fontFamily: 'Microsoft Sans Serif'}},
                'FONTFAMILY-PALATINO LINOTYPE': {style: {fontFamily: 'Palatino Linotype'}},
                'FONTFAMILY-SANS-SERIF': {style: {fontFamily: 'Sans-Serif'}},
                'FONTFAMILY-TAHOMA': {style: {fontFamily: 'Tahoma'}},
                'FONTFAMILY-TREBUCHET MS': {style: {fontFamily: 'Trebuchet MS'}},
                'FONTFAMILY-VERDANA': {style: {fontFamily: 'Verdana'}},
                'FONTSIZE-DEFAULT': {style: {
                    fontSize: 'inherit'
                }},
                'FONTSIZE-8': {style: {
                    fontSize: (8*4/3)+'px'
                }},
                'FONTSIZE-9': {style: {
                    fontSize: (9*4/3)+'px'
                }},
                'FONTSIZE-10': {style: {
                    fontSize: (10*4/3)+'px'
                }},
                'FONTSIZE-11': {style: {
                    fontSize: (11*4/3)+'px'
                }},
                'FONTSIZE-12': {style: {
                    fontSize: (12*4/3)+'px'
                }},
                'FONTSIZE-13': {style: {
                    fontSize: (13*4/3)+'px'
                }},
                'FONTSIZE-14': {style: {
                    fontSize: (14*4/3)+'px'
                }},
                'FONTSIZE-15': {style: {
                    fontSize: (15*4/3)+'px'
                }},
                'FONTSIZE-16': {style: {
                    fontSize: (16*4/3)+'px'
                }},
                'FONTSIZE-17': {style: {
                    fontSize: (17*4/3)+'px'
                }},
                'FONTSIZE-18': {style: {
                    fontSize: (18*4/3)+'px'
                }},
                'FONTSIZE-19': {style: {
                    fontSize: (19*4/3)+'px'
                }},
                'FONTSIZE-20': {style: {
                    fontSize: (20*4/3)+'px'
                }},
                'FONTSIZE-21': {style: {
                    fontSize: (21*4/3)+'px'
                }},
                'FONTSIZE-22': {style: {
                    fontSize: (22*4/3)+'px'
                }},
                'FONTSIZE-23': {style: {
                    fontSize: (23*4/3)+'px'
                }},
                'FONTSIZE-24': {style: {
                    fontSize: (24*4/3)+'px'
                }},
                'FONTSIZE-25': {style: {
                    fontSize: (25*4/3)+'px'
                }},
                'FONTSIZE-26': {style: {
                    fontSize: (26*4/3)+'px'
                }},
                'FONTSIZE-27': {style: {
                    fontSize: (27*4/3)+'px'
                }},
                'FONTSIZE-28': {style: {
                    fontSize: (28*4/3)+'px'
                }},
                'FONTSIZE-29': {style: {
                    fontSize: (29*4/3)+'px'
                }},
                'FONTSIZE-30': {style: {
                    fontSize: (30*4/3)+'px'
                }},
                'FONTCOLOR-DEFAULT': {style: {color: 'inherit'}},
                'FONTCOLOR-#FFFFFF': {style: {color: '#FFF'}},
                'FONTCOLOR-#000000': {style: {color: '#000'}},
                'FONTCOLOR-#FF0000': {style: {color: '#F00'}},
                'FONTCOLOR-#00FF00': {style: {color: '#0F0'}},
                'FONTCOLOR-#0000FF': {style: {color: '#00F'}},
                'FONTCOLOR-#FFFF00': {style: {color: '#FF0'}},
                'FONTCOLOR-#FF00FF': {style: {color: '#F0F'}},
                'FONTCOLOR-#00FFFF': {style: {color: '#0FF'}},
                'FONTCOLOR-#AA0000': {style: {color: '#A00'}},
                'FONTCOLOR-#00AA00': {style: {color: '#0A0'}},
                'FONTCOLOR-#0000AA': {style: {color: '#00A'}},
                'LOWERCASE': {style: {
                    textTransform: 'lowercase',
                }},
                'UPPERCASE': {style: {
                    textTransform: 'uppercase',
                }},
                'CAPITALIZE': {style: {
                    textTransform: 'capitalize',
                }}
            },
            blockRenderers: {
                atomic: (block) => {
                    let data = editorState.getCurrentContent().getEntity(block.getEntityAt(0)).getData();
                    return '<div><a href="'+data.src+'" target="_blank"><img src="'+data.src+'" width="100%" /></a></div>';
                },
            },
            blockStyleFn: (block) => {
                var data = block.getData();
                var style = {lineHeight:1};
                if(data.has('data'))
                {
                    var newData = data.get('data');
                    if('lineHeight' in newData) {
                        style['lineHeight'] = newData['lineHeight'];  
                    }
                    if('textAlign' in newData){
                        style['textAlign'] = newData['textAlign'];  
                    }
                    if('indentation' in newData){
                        style['indentation'] = newData['indentation'];  
                    }
                    if('hanging' in newData){
                        style['hanging'] = newData['hanging'];  
                    }
                    if('pageNum' in newData){
                        style['pageNum'] = newData['pageNum'];  
                    }

                    return {
                        style: style
                    }
                }
            },
            entityStyleFn: (entity) => {
                const entityType = entity.get('type').toLowerCase();
                if (entityType === 'image') {
                    ////console.log(entity);
                  const data = entity.getData();
                  return {
                    element: 'img',
                    attributes: {
                      src: data.src,
                    },
                    style: {
                      width: '100%'
                    },
                  };
                }
              },
            
          };
        return stateToHTML(editorState.getCurrentContent(), options);
    }



    handleSubmit = (event) => {
        if(event)
            event.preventDefault();

        ////console.log("handle Submit clicked");

        if('checkBeforeSubmit' in this.props){
            if(!this.props.checkBeforeSubmit())
                return false;
        } 
            
        if(this.state.button.disable === false)
        {
            this.setState({
                ...this.state,
                button: {
                    ...this.state.button,
                    loader: true
                }
            }, () => {
                
                if(this.props.url){
                    axios.post(this.props.url, this.getValuesFromFields()).then(response => {
                        if(response.data.r == 1)
                        {
                            if(this.props.autoSave){
                                //clearInterval(this.saveIntervalVar);
                                localStorage.setItem('snapshot:'+this.props.autoSave, JSON.stringify(convertToRaw(this.state.fields[this.editorIndex].editorState.getCurrentContent())));
                            }
                            this.props.successSubmit(response.data.d);
                            if(!this.props.keepFormPopulated)
                                this.blankForm();
                            else{
                                this.setState({
                                    button: {
                                        ...this.state.button,
                                        loader: false,
                                        disable: true,
                                    }
                                });
                            }
                        }
                        else{
                            this.props.failSubmit(response.data);
                            this.setState({
                                ...this.state,
                                button: {
                                    ...this.state.button,
                                    loader: false
                                }
                            });
                        } 
                    }).catch(error => {
                        console.log(error);
                        this.setState({
                            ...this.state,
                            button: {
                                ...this.state.button,
                                loader: false
                            }
                        });
                    });
                }
                else if(this.props.onSubmit){
                    //console.log("I can reach here");
                    this.props.onSubmit(this.state, this.getValuesFromFields());
                    this.setState({
                        ...this.state,
                        button: {
                            ...this.state.button,
                            loader: false
                        }
                    });
                }
            });
            
            
        }
        else
            return false;
            
    }

    getValuesFromFields = () => {
        const dataArray = new FormData();
        var dAT = []; 
        const fields = this.state.fields;
        fields.forEach(element => {
            switch(element.type){
                case 'hidden':
                case 'text':
                case 'password':    
                case 'number':
                case 'alphabets':
                case 'email':
                case 'textarea':
                case 'radio':
                case 'select':
                case 'date': 
                case 'datetime-local':
                    if(element.name.indexOf('array_') != -1)
                    {
                        if(element.type == 'select'){
                            if(element.value != null && element.value.length > 0)
                                dataArray.append(element.name+'[]', element.value);
                            else{
                                dataArray.append(element.name+'[]', element.options.options[0].name);
                            }
                        }
                        else if(element.type == 'date'){
                            var dtval = element.value.split('-');
                            if(element.value != null)
                                dataArray.append(element.name+'[]', dtval[2]+'/'+dtval[1]+'/'+dtval[0]);
                        }
                        else if(element.type == 'datetime-local'){
                            if(element.value != null)
                                dataArray.append(element.name+'[]', new Date(element.value).getTime());
                        }
                        else if(element.value != null)
                            dataArray.append(element.name+'[]', element.value);
                    }
                    else if(element.type == 'select'){
                        if(element.value != null && element.value.length > 0)
                            dataArray.append(element.name, element.value);
                        else{
                            dataArray.append(element.name, element.options.options[0].name);
                        }
                    }  
                    else{
                        if(element.type == 'date'){
                            var dtval = element.value.split('-');
                            if(element.value != null)
                                dataArray.append(element.name, dtval[2]+'/'+dtval[1]+'/'+dtval[0]);
                        }
                        else if(element.type == 'datetime-local'){
                            if(element.value != null)
                                dataArray.append(element.name, new Date(element.value).getTime());
                        }
                        else if(element.value != null)
                            dataArray.append(element.name, element.value);
                    } 
                        
                    break;
                case 'file':
                    //console.log("stored File val "+JSON.stringify(element.value));
                    Object.values(element.value).forEach(el=>{
                        dataArray.append(element.name+'[]', el);
                    });
                    
                    break;
                case 'editor': 
                    //console.log("Current Content", element.editorState.getCurrentContent());
                    var currentContent = element.editorState.getCurrentContent();
                    var rawContentState = convertToRaw(currentContent);
                    var blockMap = currentContent.getBlockMap();
                    blockMap.forEach(ele => {
                        if(ele.getText() == ''){
                            var neweditorstate = EditorState.acceptSelection(
                                element.editorState,
                                new SelectionState({
                                    anchorKey: ele.getKey(),
                                    anchorOffset: 0,
                                    focusKey: ele.getKey(),
                                    focusOffset: 0,
                                })
                            );

                            //console.log("current Inline Style At", neweditorstate.getCurrentInlineStyle());

                            neweditorstate.getCurrentInlineStyle().forEach(el => {
                                rawContentState.blocks.map((item,ind) => {
                                    if(item.key == ele.getKey()){
                                        rawContentState.blocks[ind]["inlineStyleRanges"].push({offset:0,length:0,style:el});
                                    }
                                });
                            });
                        }
                    });







                    dataArray.append(element.name, JSON.stringify(rawContentState));
                    dataArray.append(element.name+'_html', this.showHtml(element.editorState));
                    break;
                case 'checkbox':
                    //var dataArraytemp = [];
                    //dataArraytemp[element.name] = [];
                    //dataArraytemp[element.name+'_false'] = [];
                    element.options.options.forEach(ele => {
                        if(ele.selected === true)
                            dataArray.append(element.name+'[]', ele.name);
                        else
                            dataArray.append(element.name+'_false'+'[]', ele.name);
                    });
                    //dataArray.append(element.name, dataArraytemp[element.name]);
                    //dataArray.append(element.name+'_false', dataArraytemp[element.name+'_false']);
                    break;
                case 'checkboxlist':
                    element.options.options.forEach(ele => {
                        if('children' in ele){
                            ele.children.forEach(child => {
                                if(child.selected === true)
                                    dataArray.append(element.name+'_child['+ele.name+'][]', child.name);
                                else
                                    dataArray.append(element.name+'_child_false['+ele.name+'][]', child.name);
                            });
                        }
                        else {
                            if(ele.selected === true)
                                dataArray.append(element.name+'[]', ele.name);
                            else
                                dataArray.append(element.name+'_false[]', ele.name);
                        }
                    });
                    break;
                default:
                    break;                       
            }
        });

        return dataArray;
    }

    blankForm = () => {
        this.setState({
            fields: this.originalFields,
            button: this.originalButton
        });
    }

    filterFields = (targetValue, fieldProps) => {
        if(fieldProps.type === 'number')
            return targetValue.replace(/[^0-9]/gi, '');
        if(fieldProps.type === 'alphabets')
            return targetValue.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>{}[\]\\/0-9]/gi, '');
        
        return targetValue;    
    }

    validateFields = (targetValue, fieldProps) => {

        if(fieldProps.type == 'checkbox'){
            for(var k=0; k<fieldProps.options.options.length; k++){
                if(fieldProps.options.options[k].selected == true){
                    return null;
                }
            }
        }

        if(fieldProps.type == 'checkboxlist'){
            for(var k=0; k<fieldProps.options.options.length; k++){
                if('children' in fieldProps.options.options[k]){
                    for(var t=0; t<fieldProps.options.options[k].children.length; t++){
                        if(fieldProps.options.options[k].children[t] == true){
                            return null;
                        }
                    }
                }
                else{
                    if(fieldProps.options.options[k].selected == true){
                        return null;
                    }
                }
            }
        }

        if(fieldProps.type == 'select'){
            return null;
        }


        if(targetValue == null)
        {
            if(fieldProps.options.required == false)
                return null;
            else{
                return Strings.error.required;
            }
        }


        targetValue = ""+targetValue;

        if(fieldProps.options.required == false && targetValue.trim().length == 0){
            return null;
        }

        

        if(fieldProps.options.required == true && targetValue.trim().length == 0){
            return Strings.error.required;
        }

        if('validation' in fieldProps.options && fieldProps.options.validation.length > 0){
            fieldProps.options.validation.forEach(element => {
                if(!targetValue.match(element.regex)){
                    return element.error;
                }
            });
        }

        if(fieldProps.type === 'password' && 'strongPass' in fieldProps.options){
            var errorString = [];
            var lowerChar = new RegExp("^(?=.*[a-z])");
            var upperChar = new RegExp("^(?=.*[A-Z])");
            var numericChar = new RegExp("^(?=.*[0-9])");
            var specialChar = new RegExp("^(?=.*[!@#\$%\^&\*])");
            var minLeng = new RegExp("^(?=.{8,})");

            if(!lowerChar.test(targetValue))
                errorString.push(<span style={{display:'block'}}>{Strings.error.strongPass[0]}</span>);
            if(!upperChar.test(targetValue))
                errorString.push(<span style={{display:'block'}}>{Strings.error.strongPass[1]}</span>); 
            if(!numericChar.test(targetValue))
                errorString.push(<span style={{display:'block'}}>{Strings.error.strongPass[2]}</span>);
            if(!specialChar.test(targetValue))
                errorString.push(<span style={{display:'block'}}>{Strings.error.strongPass[3]}</span>);
            if(!minLeng.test(targetValue))
                errorString.push(<span style={{display:'block'}}>{Strings.error.strongPass[4]}</span>);   

            if(errorString.length > 0){
                return errorString.map(ele => ele);
            }
        }

        if(fieldProps.type === 'email')
        {
            var locdom = targetValue.split('@');
            if(locdom.length == 2){
                var havedots = locdom[0].split('..');
                var havedots1 = locdom[1].split('..');
                if(havedots.length == 1 && havedots1.length == 1){
                    if(havedots[0].charAt(0)!='.' && havedots[0].charAt(havedots[0].length-1)!='.'){
                        var chararr =  ['0','1','2','3','4','5','6','7','8','9','a','b','c','d','e',
                                        'f','g','h','i','j','k','l','m','n','o','p','q','r','s','t',
                                        'u','v','w','x','y','z','A','B','C','D','E','F','G','H','I',
                                        'J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X',
                                        'Y','Z','-','_','.'];
                        var errorHappen = 0;
                        for(var i=0; i<havedots[0].length-1; i++){
                            if(!chararr.includes(havedots[0].charAt(i))){
                                errorHappen = 1;
                                break;
                            }
                        }

                        if(!errorHappen){
                            if(havedots1[0].charAt(0)!='.' && havedots1[0].charAt(havedots1[0].length-1)!='.'){
                                var atLeastOneDot = havedots1[0].split('.');
                                if(atLeastOneDot.length > 1)
                                {
                                    for(var k=0; k<havedots1[0].length-1; k++){
                                        if(!chararr.includes(havedots1[0].charAt(k))){
                                            errorHappen = 1;
                                            break;
                                        }
                                    }
                                    if(!errorHappen){
                                        return null;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            return Strings.error.invalidEmail;

        }

        

        if('min' in fieldProps.options && fieldProps.options.min > 0 && targetValue < fieldProps.options.min)
            return Strings.error.minValue + fieldProps.options.min;
        
        if('max' in fieldProps.options && fieldProps.options.max > 0 && targetValue > fieldProps.options.max)
            return Strings.error.maxValue + fieldProps.options.max;

        if('minLength' in fieldProps.options && fieldProps.options.minLength > 0 && targetValue.length < fieldProps.options.minLength)
            return Strings.error.minLengthValue[0] + fieldProps.options.minLength + Strings.error.minLengthValue[1];
        
        if('maxLength' in fieldProps.options && fieldProps.options.maxLength > 0 && targetValue.length > fieldProps.options.maxLength)
            return Strings.error.maxLengthValue[0] + fieldProps.options.maxLength + Strings.error.maxLengthValue[1];    

        return null;    
    }

    onChangeInput = (event, fieldProps, index, callback) => {
        if(this.ReRender){
            let newState = {...this.state.fields[index]};
            
            let newFieldState = [...this.state.fields];

            

            if(newState.type === 'checkbox'){
                if(event.target.value === 'selectAllCheckBox'){
                    newState.options.selectAll.selected = !newState.options.selectAll.selected;
                    newState.options.options.forEach(element => {
                        element.selected = newState.options.selectAll.selected;
                    });
                }
            }

            if(newState.type === 'checkboxlist'){
                if(event.target.value === 'selectAllCheckBox'){
                    newState.options.selectAll.selected = !newState.options.selectAll.selected;
                    newState.options.options.forEach(element => {
                        element.selected = newState.options.selectAll.selected;
                        if('children' in element){
                            element.children.forEach(child => {
                                child.selected = newState.options.selectAll.selected;
                            });
                        }
                    });
                } else if (event.target.value.indexOf('selectAllCheckBox-') > -1){
                    newState.options.options.forEach(element => {
                        if(element.name == event.target.value.substring(18))
                        {
                            element.selected = !element.selected;
                            if('children' in element){
                                element.children.forEach(child => {
                                    child.selected = element.selected;
                                });
                            }
                        }
                    });
                }
            }

            if(newState.type === 'file'){
                console.log("Uploaded Files: "+event.target.files.length);
                newState.errorText = this.validateFields(event.target.files.length > 0?'1':'', fieldProps);
                newState.value = event.target.files;
            }
            else if(newState.type !== 'editor' && newState.type !== 'checkbox' && newState.type !== 'checkboxlist'){
                newState.errorText = this.validateFields(event.target.value, fieldProps);
                newState.value = this.filterFields(event.target.value, fieldProps);
            }
            
            if(newState.type === 'editor'){
                //console.log("Editor On Change Called", convertToRaw(event.getCurrentContent()));
                var ctstate = event.getCurrentContent();
                var edi = ctstate.getPlainText('\u0001');
                /*
                var ent = getSelectionEntity(event);
                var commentContainer = document.getElementById('commentContainer');
                var tableContainer = document.getElementById('tableContainer');
                const moreInfo = document.getElementById('editorMoreInfo')
                
                if(commentContainer && !this.commentStarting){
                    
                    if(ent && ctstate.getEntity(ent).getType()=='COMMENT'){
                        if(moreInfo)
                            moreInfo.style.display = 'none';
                        var {comment} = ctstate.getEntity(ent).getData();
                        
                        var commentBox = document.getElementById('commentBox');
                        commentContainer.style.display = 'flex';
                        var allComments = comment.map((elem,inde) => {
                            return (<div style={{background:'#FFF',marginBottom:'5px',padding:'10px'}}><b>{elem.user}</b>: <span style={{float:'right',color:'blue',textDecoration:'underline',cursor:'pointer'}} onClick={(e)=>this.deleteComment(e, inde, index)}>Delete</span><div>{elem.comment}</div></div>);
                        });
                        ReactDOM.render(<div><div style={{overflow:'hidden'}}><span style={{float:'right',color:'blue',textDecoration:'underline',cursor:'pointer'}} onClick={(e)=>this.deleteComment(e, -1, index)}>Delete All Comments</span></div>{allComments}</div>, commentBox);
                    }
                    else{
                        commentContainer.style.display = 'none';
                    }
                    this.commentStarting = 0;
                }
                if(tableContainer && !this.tableStarting){
                    
                    

                    if(ent && ctstate.getEntity(ent).getType()=='TABLE'){
                        if(moreInfo)
                            moreInfo.style.display = 'none';
                        var {table} = ctstate.getEntity(ent).getData();
                        
                        tableContainer.style.display = 'block';
                        var tableBox = document.getElementById('tableBox');
                        ReactDOM.unmountComponentAtNode(tableBox)
                        ReactDOM.render(<FormTable postTable={(cbdata)=>this.tablePostFunc(index, cbdata)} table={table} />, tableBox);
                            
                    
                    }
                    else{
                        tableContainer.style.display = 'none';
                    }
                    this.tableStarting = 0;
                }

                if(moreInfo && !(ent && (ctstate.getEntity(ent).getType()=='TABLE' || ctstate.getEntity(ent).getType()=='COMMENT')) && !this.commentStarting && !this.tableStarting){
                    moreInfo.style.display = null;
                }
                */

                newState.editorState = event;
                //console.log("New Editor State", newState.editorState);
                newState.errorText = this.validateFields(edi, fieldProps);
                newState.value = this.filterFields(edi, fieldProps);
            }

            if(newState.type === 'radio'){
                newState.options.options.forEach(element => {
                    element.selected = false;
                    if(element.name === newState.value)
                        element.selected = true;
                });
            }
            else if(newState.type === 'checkbox' || newState.type === 'checkboxlist'){
                if(event.target.value.indexOf('selectAllCheckBox') == -1){
                    newState.options.options.forEach(element => {
                        if('children' in element){
                            element.children.forEach(child => {
                                if(child.name == event.target.value)
                                    child.selected = !child.selected;
                            });
                        }
                        else if(element.name == event.target.value)
                            element.selected = !element.selected;
                    });
                }
            }
            else if(newState.type === 'select'){
                newState.options.options.forEach(element => {
                    element.selected = false;
                    if(element.name === newState.value)
                        element.selected = true;
                });
            }

            

            if(newState.type === 'checkbox' || newState.type === 'checkboxlist'){
                let valuesi = [];
                        
                newState.options.options.forEach((element) => {
                    if(element.value.indexOf('selectAllCheckBox') == -1){
                        if(element.selected === true){
                            valuesi.push(""+element.name);
                        }
                    }
                    if('children' in element){
                        element.children.forEach(child => {
                            if(child.selected === true){
                                valuesi.push(""+child.name);
                            }
                        });
                    }
                });

                newState.value = valuesi.join("[,]");
                newState.errorText = this.validateFields(newState.value, fieldProps);
            }
            
            newFieldState[index] = newState;

            if('dependentChildren' in newState.options){
                newFieldState = this.setDynamicFormOptions(newFieldState, newState, newState.value);
            }

            if('id' in newState){
                var whiletrue = true;
                var checkEle = newState;
                while(whiletrue){
                    this.onChangePointer.some(element => {
                        if(element.pointer === checkEle.id){
                            newFieldState[element.index]['value'] = "";
                            [newFieldState, checkEle] = this.setSelectValues(newFieldState, element.index, checkEle.value in checkEle.options.pointers?checkEle.options.pointers[checkEle.value]:{d:[{name:"",value:"",selected:false}]}, checkEle.id);
                            if('id' in newFieldState[element.index]){
                                checkEle = newFieldState[element.index];
                                return true;
                            }
                            else
                                whiletrue = false;
                        }
                    });
                }
            }



            
            let buttonDisable = false;

            newFieldState.forEach((element, ind) => {
                /*if(ind === index)
                {
                    if(newState.errorText !== undefined && newState.errorText !== null && newState.errorText.length > 0){
                        buttonDisable = true;
                        return;
                    }
                }
                else */
                if(this.validateFields(element.value, element) !== null){
                    buttonDisable = true;
                    return;
                }
            });

            if(newState.type === 'editor'){
                if(this.editorStateInit[index] == 0)
                {
                    this.editorStateInit[index] = 1;
                    return;
                }
            }
            
            //console.log("Set State Called Here");
            this.setState(prevState => ({
                fields: newFieldState,
                button: {
                    ...prevState.button,
                    disable: buttonDisable
                } 
            }), () => {
                if(this.props.afterEveryInput)
                    this.getValBut(null, 1, index, this.props.afterEveryInput);
                callback();
            });
        }
        
    }

    setDynamicFormOptions = (wholeState, workingEle, workval) => {
        if('dependentChildren' in workingEle.options)
        {
            workingEle.options.dependentChildren.forEach(element=>{
                wholeState.forEach((ell,indd)=>{
                    if(ell.name == element){
                        var selOpts = {d:[]};
                        var requOpts = wholeState[indd]['options']['pointers'];
                        
                        var newWorkVal = null;

                        Object.keys(requOpts[workval]).forEach(iyu => {
                            selOpts.d.push({name:iyu,value:requOpts[workval][iyu],selected:!newWorkVal?true:false});
                            if(!newWorkVal){
                                newWorkVal = iyu;
                                wholeState[indd]['value'] = '';
                            }
                            
                        });
                        [wholeState] = this.setSelectValues(wholeState, indd, selOpts, 0);
                        this.setDynamicFormOptions(wholeState, wholeState[indd], newWorkVal);
                    }
                })
            });
        }

        return wholeState;
    }

    setSelectValues = (prev, index, data, id) => {
        const newFieldState = [...prev]; 
        const neededField = {...newFieldState[index]};

        if(neededField.type === 'editor'){
            neededField.editorState = EditorState.createWithContent(convertFromRaw(JSON.parse(data.d)), this.compositeDecorator);
            neededField.value = neededField.editorState.getCurrentContent().getPlainText('\u0001');
        }
        else{
            let valuesi = [];
            data = 'd' in data?data:{d:[{name:"",value:"",selected:false}]};
            if(data){
                if(neededField.type === 'hidden'){
                    if(id){
                        for(var i=newFieldState.length-1; i>=0; i--){
                            if('refer' in newFieldState[i] && newFieldState[i]['refer']===id){
                                newFieldState.splice(i, 1);
                            }
                        }
                        
                        var cind = 1;
                        const toaddarr = data.d.map((items, ind) => {
                            if('taskProperty' in neededField){
                                this.addPropertySec([], [{keyv:items.name,valv:items.value,type:items.type}]).forEach((uyt,inu) => {
                                    newFieldState.splice(index+cind,0,uyt);
                                    cind++;
                                });
                                
                                
                            }
                            else{
                                newFieldState.splice(index+1+ind,0,{
                                    type: 'text',
                                    value: items.value,
                                    name: items.name,
                                    refer: id,
                                    placeholder: '',
                                    options: {
                                                required: false,
                                            },
                                    label: items.label,
                                });
                            }
                            return 1;
                        });
                        

                        
                    }
                }
                else{
                    neededField.options.options = data.d;
                }

                if('p' in data){
                    neededField.options.pointers = data.p;
                }
                
                
                data.d.forEach((element, index) => {
                    if(neededField.type === 'select' || neededField.type === 'hidden'){
                        if(neededField.value != "")
                        {
                            if(neededField.value == ""+element.name)
                            {
                                neededField.options.options[index]['selected'] = true;
                                return;
                            }
                        }
                        else{
                            if(index === 0)
                                neededField.value = ""+element.name;

                            if(element.selected === true){
                                neededField.value = ""+element.name;
                                return;
                            }
                        }
                    }
                    else if(neededField.type === 'checkbox' || neededField.type === 'checkboxlist'){
                        if('selectAll' in neededField.options){
                            if(neededField.options.selectAll.set === true){
                                if(neededField.options.selectAll.selected === true){
                                    element.selected = true;
                                    if('children' in element){
                                        element.children.forEach(child => {
                                            child.selected = true;
                                        });
                                    }
                                }
                            }
                        }
                        if(element.value.indexOf('selectAllCheckBox') == -1){
                            if(element.selected === true){
                                valuesi.push(""+element.name);
                            }
                        }
                        if('children' in element){
                            element.children.forEach(child => {
                                if(child.selected === true){
                                    valuesi.push(""+child.name);
                                }
                            });
                        }
                    }
                });
            }

            if(neededField.type === 'checkbox' || neededField.type === 'checkboxlist'){
                neededField.value = valuesi.join("[,]");
            }
        }

        if('showLoader' in neededField.options)
            neededField.options.showLoader[0] = false;
        newFieldState[index] = neededField;

        return [newFieldState, neededField];
    }

    afterFetchFromServer = (prevState, index, data) => {
        let [newFieldState, neededField] = this.setSelectValues(prevState.fields, index, data, 0);

        this.originalFields[index] = neededField;

        
        if('id' in neededField){
            this.onChangePointer.forEach(element => {
                if(element.pointer === neededField.id){
                    [newFieldState, neededField] = this.setSelectValues(newFieldState, element.index, neededField.value in neededField.options.pointers?neededField.options.pointers[neededField.value]:{d:[{name:"",value:"",selected:false}]}, neededField.id);

                    this.originalFields[element.index] = neededField;
                }
            });
        }

        //console.log("original fields After server fetching", this.originalFields);

        return {
            fields: newFieldState,
            button: this.state.button,
        }
    }

    

    fetchFromServer = (index, url, done) => {
        if(done === 0)
        {
            this.ajaxCalls[index]['done'] = 1;
            var this2 = this;
            if(this.state.fields[index].type == 'editor' && this.props.autoSave && localStorage.getItem('snapshot:'+this.props.autoSave)){
                this.setState(prevState => {
                    this2.editorIndex = index;
                    return this2.afterFetchFromServer(prevState, index, {d: localStorage.getItem('snapshot:'+this.props.autoSave)})
                }, () => {
                    this2.saveIntervalVar = setInterval(() => {
                        console.log("Saving snapshot");
                        localStorage.setItem('snapshot:'+this2.props.autoSave, JSON.stringify(convertToRaw(this2.state.fields[index].editorState.getCurrentContent())));
                    }, 10000);
                });
            }
            else{
                axios.get(url).then(response => {
                    const data = response.data;
                    if(data.r === 1){
                        this.setState(prevState => {
                            return this2.afterFetchFromServer(prevState, index, data)
                        }, () => {
                            if(this2.props.autoSave && this2.state.fields[index].type == 'editor'){
                                this2.editorIndex = index;
                                    
                                this2.saveIntervalVar = setInterval(() => {
                                    console.log("Saving snapshot");
                                    localStorage.setItem('snapshot:'+this2.props.autoSave, JSON.stringify(convertToRaw(this2.state.fields[index].editorState.getCurrentContent())));
                                }, 10000);
                            }
                        });
                    }
                }).catch(error => {
                    //console.log(error);
                });
            }
        }
    }

    componentDidMount(){
        for(var key in this.ajaxCalls){
            //console.log('component did mount called');
            const {ind, url, done} = this.ajaxCalls[key];
            this.fetchFromServer(ind, url, done);
        };

        if(this.props.autoSubmit){
            this.handleSubmit(null);
        }


    }

    componentWillUnmount(){
        clearInterval(this.saveIntervalVar);
        console.log("Saving snapshot OFF");
    }

    componentDidUpdate(){
        if(this.props.buttonTrigger){
            this.props.buttonTrigger(this.state.button.disable);
        }
    }

    addProperty = (e) => {
        e.preventDefault();
        this.setState({fields: this.addPropertySec([...this.state.fields], [{keyv:'',valv:'',type:'text'}])});
    }

    addPropertySec = (newFields, propertyVal, newFields2=[]) => {       
        propertyVal.forEach(ele => {
            newFields.push({
                type: 'text',
                value: ele.keyv,
                name: 'array_key',
                placeholder: '',
                options: {
                            required: true,
                            table: {rowStart: true},
                         },
            });
            newFields.push({
                type: 'select',
                value: ele.type,
                name: 'array_format',
                options: {
                            required: true,
                            table: {},
                            options: [
                                {value: 'Text', name: 'text', selected: ele.type=='text'?true:false},
                                {value: 'Date', name: 'date', selected: ele.type=='date'?true:false},
                            ],
                         },
            });
            newFields.push({
                type: 'text',
                value: ele.valv,
                name: 'array_value',
                placeholder: '',
                options: {
                            required: true,
                            table: {rowEnd: true},
                            addFieldsBut: {plus:true, minus:true, func:(data) => this.addDeleteFields(data)}
                         },
            });
        });
        
        return [...newFields, ...newFields2];
        
    }

    addDeleteFields = (data) => {
        if(data.addminus == 1){
            this.setState({fields: this.addPropertySec([...this.state.fields.slice(0, data.index+1)], [{keyv:'',valv:'',type:'text'}], [...this.state.fields.slice(data.index+1)])});
        }
        else if(data.addminus == -1){
            data.fields.splice(data.index-2,3);
            this.setState({fields: data.fields});
        }
    }

    deleteProperty = (e) => {
        e.preventDefault();
        let newFields = [...this.state.fields];
        let toRemoveIndexes = [];
        for(var v in newFields){
            if(newFields[v]['name'] == 'array_key')
                toRemoveIndexes[0] = v;
            if(newFields[v]['name'] == 'array_format')
                toRemoveIndexes[1] = v;
            if(newFields[v]['name'] == 'array_value')
                toRemoveIndexes[2] = v;
        }
        ////console.log(toRemoveIndexes);
        if(toRemoveIndexes.length == 3){
            while(toRemoveIndexes.length) {
                newFields.splice(toRemoveIndexes.pop(), 1);
            }
        }
        ////console.log(newFields);
        this.setState({fields: newFields});
    }

    getValBut = (e, addminus, index, cb) => {
        if(e)
            e.preventDefault();
        cb({val: this.getValuesFromFields(), fields:this.state.fields, index:index, addminus:addminus}); 
    }

    getFormState = () => {
        return {val: this.getValuesFromFields(), fields:this.state.fields};
    }

    submitFormButtonClick = () => {
        this.submitFormButtonRef.current.click();
    }

    render(){
        console.log("fields", this.state.fields);
        
        let rowContainer = [];
        let onlyTable = [];
        const formItems = this.state.fields.map((val, index) => {
            if('showLoader' in val.options && val.options.showLoader[2] !== undefined && !(index in this.ajaxCalls)){
                this.ajaxCalls[index] = {ind: index, url: val.options.showLoader[2], done: 0};

                //console.log(this.ajaxCalls);
            }
            if('onChange' in val.options){
                this.onChangePointer.push({index: index, pointer: val.options.onChange});
            }
            let cft = <div className={val.class} key={index}><Input
                            inputIndex={index}
                            type={val.type}
                            value={val.value}
                            placeholder={val.placeholder}
                            ref={val.type == 'editor' ? node => this.editorRef = node : ''}
                            onChange={(event, callback = () => {}) => this.onChangeInput(event, val, index, callback)}
                            changeReRender={(val) => {this.ReRender=val;}}
                            onChangeEditor={(editorState, callback = () => {}) => this.onChangeInput(editorState, val, index, callback)}
                            selectDictionary={(event) => {}}
                            options={val.options}
                            handleSubmit={this.handleSubmit}
                            passToInput={this.props.passToInput}
                            editorState={val.editorState}
                            deleteComment={this.deleteComment}
                            commentPostFunc={(dyre, cb) => this.commentPostFunc(index, dyre, cb)}
                            tablePostFunc={(dyre) => this.tablePostFunc(index, dyre)}
                            commentStarting = {(type) => this.commentStartingFunc(type)}
                            myBlockStyleFn = {this.myBlockStyleFn}
                            styleMap = {this.styleMap}
                            label={val.label}
                            setStartF={(val, editorState) => this.setStartF(val, editorState)}
                            errorText={val.errorText} /></div>
            
            let toret = [];                
            if(this.props.isTable){
                if('table' in val.options)
                {
                    if('rowStart' in val.options.table)
                        rowContainer = [];

                    rowContainer.push(<td>{cft}</td>);  
                    
                    if('addFieldsBut' in val.options){
                        rowContainer.push(<td><div style={{width:'60px',marginBottom:'1rem'}}>
                            {'plus' in val.options.addFieldsBut && val.options.addFieldsBut.plus?<button onClick={(e)=>this.getValBut(e, 1, index, val.options.addFieldsBut.func)}>+</button>:null}
                            {'minus' in val.options.addFieldsBut && val.options.addFieldsBut.minus?<button onClick={(e)=>this.getValBut(e, -1, index, val.options.addFieldsBut.func)}>-</button>:null}
                        </div></td>);
                    }

                    if('rowEnd' in val.options.table)
                        onlyTable.push(<tr>{rowContainer}</tr>);
                    return null;
                }
            }
            return (cft); 
        });
        let finalFormItems = null;
        
        if(this.props.isTable){
            let thd = [];
            thd = this.props.isTable.map(item => <th>{item}</th>);
            finalFormItems = <table><thead><tr>{thd}</tr></thead><tbody>{onlyTable}</tbody></table>;
        }
        
        let compFinFormItems = <React.Fragment>
                                    {this.props.addProperty?(
                                        <div>
                                            <button onClick={this.addProperty}>{Strings.text.button.addProperty}</button>
                                            <button onClick={this.deleteProperty}>{Strings.text.button.deleteProperty}</button>
                                        </div>
                                    ):null}
                                    {finalFormItems}
                                    {this.props.tableExtraInfo}
                                </React.Fragment>

        return (
            <div className={'allowFormOverflow' in this.props ? '' : 'formTop'}>
                <Form method="post" ref={(node) => {this.mainFormRef = node}} onSubmit={this.handleSubmit}>
                    <div className={finalFormItems == null?'formItemsWrapper':'floatLeft formItemsWrapper'}>
                        {formItems}
                        {this.props.inlineTable?compFinFormItems:null}
                        <button style={{display:'none'}} onClick={this.handleSubmit} className={'autoFormSubmit'} />
                        <Button buttonShortcut={this.props.buttonShortcut} innerRef={this.submitFormButtonRef} {...this.state.button} />
                        {this.props.getValuesButton?(
                            <button className={'searchButton'} onClick={(e) => this.getValBut(e, 0, 0, this.props.getValuesButton.func)} type={'button'} style={this.props.getValuesButton.style}>{this.props.getValuesButton.name}</button>
                        ):null}
                    </div>
                    {this.props.inlineTable?null:(
                        <div style={{float:'right',marginLeft:'30px'}}>
                            {compFinFormItems}
                        </div>
                    )}
                    
                </Form>
            </div>
        );
    }
}

const styles = {
    male : {
        background: 'pink'
    },
    female: {
        background: 'orange'
    },
    dictionary:{
        textDecoration: 'underline',
        textDecorationStyle: 'wavy',
        textDecorationColor: 'red'
    }
}

export default Formnew;


/*
    this.props.url = url where form data is to be posted;
    this.props.onSubmit = func used when submit not calling url.
    this.props.successSubmit = func
    this.props.failSubmit = func
    this.props.buttonValue = text on button;
    this.props.buttonType = success | danger | normal
    this.props.fields = [
        {
            type: email | text | password | checkbox | number | radio | select | textarea | alphabets,
            value: value | null,
            name: ,
            placeholder: placeholder | null,
            options: {
                        required: true | false,
                        showLoader: [true | false, 'black', 'url for fetching data'],
                        group : group name (for radio),
                        min: minimum value,
                        max: maximum value,
                        minLength: minimum length,
                        maxLength: maximum length,
                        validation: [
                            {regex: , error:},
                        ],
                        options: [
                                    {value: , name: , selected: true | false},
                                 ]
                     },
            label: ,
        },
    ]

*/