import React from 'react';
import axios from 'axios/axios.js';
import CreateFile from 'modules/Form/Create/Create';

class SelectTypeOfForm extends React.Component{

    state = {
        formtypes: [],
        defaultValue: this.props.formType?this.props.formType:null,
        extraFields: [],
    }

    onFormTypeChange = (e) => {
        e.preventDefault();
        this.loadAppropriateForm(e.target.value);
    }

    loadAppropriateForm = (ft) => {
        if(ft != 0){
            var formtype = ft;
            axios.get('DynamicForms/makeFormType.php?formType='+ft+'&includeAll=1').then(response => {
                if(response.data.r == 1)
                {
                    this.setState({defaultValue: 0, extraFields:[]},()=>{
                        this.setState({
                            defaultValue: formtype,
                            extraFields: response.data.d
                        })
                    });
                } 
            }).catch(error => {
            
            });
        }
        else{
            this.setState({defaultValue: 0, extraFields:[]});
        }
    }

    loadForm = () => {
        this.createFileRef.fieldRef.formRef.getValBut(null, 1, 0, (data)=>{
            console.log("Load Form State Val", data);
            var reqf = data.val;
            this.props.loadForm(reqf, this.state.defaultValue);
        });
    }


    componentDidMount(){
        if(this.state.formtypes.length==0 && this.state.defaultValue == null){
            axios.get('DynamicForms/getFormTypes.php').then(response => {
                if(response.data.r == 1)
                {
                    this.setState({formtypes:[]},()=>{
                        this.setState({
                            defaultValue: 0,
                            formtypes: response.data.d
                        })
                    });
                } 
            }).catch(error => {
            
            });
        }
        else if(this.state.extraFields.length==0 && this.state.defaultValue != null){
            this.loadAppropriateForm(this.state.defaultValue);
        }
    }

    render(){
        var selopts = this.state.formtypes.map(item => {
            return <option value={item.name}>{item.value}</option>;
        });
        return (
            <div>
                {this.state.formtypes.length > 0?(
                    <div>
                        <label style={{marginRight:'20px'}}>Select Type of Form</label>
                        <select value={this.state.defaultValue} onChange={this.onFormTypeChange}>
                            {selopts}
                        </select>
                    </div>
                ):null}
                {this.state.extraFields.length > 0?(
                    <CreateFile
                        createPermission = {'ALL'}
                        createContainerCSS = {''}
                        onSubmit={this.loadForm}
                        ref={(node) => { this.createFileRef = node; }}
                        buttonValue = {'Load Appropriate Form'}
                        fields = {this.state.extraFields}
                    />
                ):null}
            </div>
        );
    }
}

export default SelectTypeOfForm


/*
    this.props
            formType: null | formType
            loadForm: func having parameters of formType and colA-E
*/