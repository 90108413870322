import React from 'react';
import EditFile from 'modules/Form/Edit/Edit';
import Strings from 'Strings.js';

class Edit extends React.Component{

    onSuccess = (data) => {
        this.props.refresh();
    }

    onFailure = (data) => {

    }

    render(){
        return(
            <EditFile
                editPermission = {10}
                ButtonCSS = {''}
                NameCSS = {''}
                editButtonValue = {Strings.text.button.editVendorButton}
                editContainerCSS = {''}
                url = {'Vendors/editVendor.php'}
                buttonValue = {Strings.text.button.editVendorButton}
                onSuccess = {(data) => this.onSuccess(data)}
                onFailure = {(data) => this.onFailure(data)}
                fields = {[
                    {
                        type: 'hidden',
                        value: this.props.vid,
                        name: 'vid',
                        options: {
                                    required: true,
                                },
                    },{
                        type: 'text',
                        value: this.props.companyName,
                        name: 'company_name',
                        placeholder: Strings.text.placeholder.companyName,
                        options: {
                                    required: true,
                                    minLength: 5,
                                 },
                        label: Strings.text.label.companyName,
                    },{
                        type: 'email',
                        value: this.props.email,
                        name: 'email',
                        placeholder: Strings.text.placeholder.email,
                        options: {
                                    required: true,
                                },
                        label: Strings.text.label.email,
                    },{
                        type: 'alphabets',
                        value: this.props.contactPerson,
                        name: 'contact_person',
                        placeholder: Strings.text.placeholder.contactPerson,
                        options: {
                                    required: true,
                                },
                        label: Strings.text.label.contactPerson,
                    },{
                        type: 'text',
                        value: this.props.address,
                        name:'address',
                        placeholder: Strings.text.placeholder.address,
                        options: {
                                    required: true,
                                 },
                        label: Strings.text.label.address,
                    },{
                        type: 'number',
                        value: this.props.phone,
                        name:'phone',
                        placeholder: Strings.text.placeholder.phone,
                        options: {
                                    required: true,
                                    minLength: 10,
                                    maxLength: 10,
                                 },
                        label: Strings.text.label.phone,
                    },{
                        type: 'select',
                        value: '',
                        name: 'iid',
                        options: {
                                    required: true,
                                    showLoader: [true, 'black', 'Institute/getInstituteList.php'],
                                    options: [
                                        {value: '', name: '', selected: true},
                                    ]
                                },
                        label: Strings.text.label.selectInstitute,
                    },{
                        type: 'checkbox',
                        value: '',
                        name: 'siids',
                        options: {
                                    required: true, 
                                    showLoader: [true, 'black', 'Industry/serviceIndustryList.php?vid='+this.props.vid],
                                    group: 'assignPermission',
                                    newLine: true,
                                    options: [
                                        {value: '', name: '', selected: false},
                                    ]
                                },
                        label: Strings.text.label.selectServiceIndustry,
                    }
                ]}
            />
        );
    }
}

export default Edit;


/*
    vid
    companyName
    contactPerson
    phone
    address
    email
    
*/