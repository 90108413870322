import React from 'react';
import PdfViewer from 'modules/PdfViewer/PdfViewer';
import Form from 'components/Form/Form';
import Strings from 'Strings.js';
import Classes from './TranslationPartsList.module.css';
import axios from 'axios/axios.js';
import CreateFile from 'modules/Form/Create/Create';
import CreateSnapshot from 'modules/Tasks/Snapshot_OCR/Create';
import AssignTemplateWithoutDemo from 'modules/Tasks/Template/AssignOrEdit/TaskWithoutDemo';
import {cloneDeep} from 'lodash';
import store from 'store.js';
import runningPage from 'global';

class TranslationPartsList extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            isOpen: 0,
            refreshEditor: 1,
        }

        

        this.snapshotSaved = 0;
        this.refreshForward = 0;
        

        this.directPost = 0;
        this.blankCheck = 0;
        this.forward = 0;
        this.backward = 0;
        this.grading = [];
    }

    

    getPartsList = () => {
        //this.snapshotSaved = 0;
        //axios.post('Tasks/getSnapshot.php?tid='+this.props.tid+'&part=1', {}).then(response => {
        //    if(response.data.r === 1)
        //    {
                document.getElementsByTagName('body')[0].style.overflow = 'hidden';
                
                
                this.setState({
                    refreshEditor: 0,
                }, ()=>{
                    this.setState({
                        refreshEditor: 1,
                        isOpen: 1,
                    }, () => {
                        var byt = document.getElementsByClassName('toRefreshForward_indexing')[0].getElementsByClassName('formSubmitButton');
                        for(var i=0; i<byt.length; i++){
                            byt[i].setAttribute('disabled','disabled');
                        }
                        setTimeout(()=>{
                            var optionsHeightAdj = document.getElementsByClassName('optionContainerHeightFix')[0];
                            optionsHeightAdj.style.height = document.getElementsByClassName('optionContainer')[0].clientHeight + 'px';
                        }, 2000)
                    })
                });
    //        }

    //    }).catch(error => {
            
    //    });
    }

    refreshEditor = () => {
        this.setState({
            refreshEditor: 0,
        }, ()=>{
            this.setState({
                refreshEditor: 1,
            })
        });
    }

    openWorkbench = () => {
        axios.post('Tasks/checkIfTaskIsStillAssigned_digitization.php?tid='+this.props.tid, {}).then(response => {
            if(response.data.r === 1)
            {
                this.directPost = response.data.d.directPost;
                this.blankCheck = response.data.d.blankCheck;
                this.forward = response.data.d.forward;
                this.backward = response.data.d.backward;
                this.grading = response.data.d.grading;
                this.getPartsList();
            //    this.assignDemo();
            }
            else if(response.data.e == 'TASK_ASSIGNED_SOMEONE_ELSE'){
                this.closeDemo();
                this.props.refreshList();
            }

        }).catch(error => {
            
        });
    }

    snapshotSavedFunc = () => {
        console.log("Snapshot Saved Called");
        this.snapshotSaved = 1;
        //this.refreshEditor();
    }

    buttonTrigger = (isDisable) => {
        
        this.refreshForward = isDisable && this.snapshotSaved;
        console.log("Button Trigger Called. Save is not "+this.refreshForward);
        var byt = document.getElementsByClassName('toRefreshForward_indexing')[0].getElementsByClassName('formSubmitButton');
        for(var i=0; i<byt.length; i++){
            if(this.refreshForward == 1)
                byt[i].removeAttribute('disabled');
            else
                byt[i].setAttribute('disabled','disabled');
        }
    }


    successTaskComplete = (data) => {
        this.closeDemo();
        
        this.props.refreshList();
    }

    failTaskComplete = (data) => {

    }

    partsListEvent = (e) => {
        if(runningPage.page === 'taskopen'){
            if(e.altKey && e.ctrlKey){
                if(e.keyCode === 84){
                    var tableContainer = document.getElementById('tableContainer');
                    if(tableContainer.style.display === 'block'){
                        tableContainer.getElementsByClassName('public-DraftEditor-content')[0].focus();
                    }
                }
            }
            else if(e.altKey){
                if (e.keyCode == 192){
                    this.closeDemo();
                } else if (e.keyCode === 81){
                    if(!this.demoOrEditor){
                        this.refs['demoBoxRef'].createFileRef.fieldRef.formRef.mainFormRef.getElementsByTagName('select')[0].focus();
                        this.demoOrEditor = 1;
                    }
                    else{
                        this.snapshotRef.createFileRef.fieldRef.formRef.editorRef.setEditorFocus()
                        this.demoOrEditor = 0;
                    }
                } else if (e.keyCode === 83){
                    // alt + s
                    if(document.getElementById('hideEditorBox').style.display == 'none'){
                        this.refs['demoBoxRef'].createFileRef.fieldRef.formRef.mainFormRef.getElementsByClassName('searchButton')[0].click();
                    } else {
                        this.demoButtonTrigger(1);
                    }
                    
                } else if (e.keyCode === 78){
                    // alt + n
                    console.log(this.createPartRef.partCreate.fieldRef.formRef.mainFormRef.getElementsByClassName('autoFormSubmit')[0])
                    this.createPartRef.partCreate.fieldRef.formRef.mainFormRef.getElementsByClassName('autoFormSubmit')[0].click()
                } 
            } else {
                if (e.keyCode === 33 || e.keyCode === 34){
                    e.preventDefault();
                    
                }
            }
        }
    }

    componentDidMount(){
        if(this.state.isOpen === 0){
            this.openWorkbench();
        }
        runningPage.page = 'taskopen';
        document.addEventListener('keydown', this.partsListEvent);
    }

    componentWillUnmount(){
        runningPage.page = 'home';
        document.removeEventListener('keydown', this.partsListEvent);
    }

    assignDemo = (e) => {
        if(e)
            e.preventDefault();
        document.getElementsByTagName('body')[0].style.overflow = 'hidden';
        this.setState({isOpen: 1});
    }

    closeDemo = () => {
        document.getElementsByTagName('body')[0].style.overflow = 'auto';
        this.setState({isOpen: 0});
        runningPage.page = 'home';
        document.removeEventListener('keydown', this.partsListEvent);
    }

    download = (data) => {
        var fd = new FormData();
        fd.append('tid', this.props.tid);
        fd.append('part', 1);
        axios.post('downloadPrevDoc.php', fd, {responseType: 'arraybuffer'}).then(response => {
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(new Blob([response.data], {type: 'application/octet-stream'}));
            link.download = data.file;

            document.body.appendChild(link);

            link.click();
            setTimeout(function() {
                window.URL.revokeObjectURL(link);
            }, 200);
        }).catch(error => {})
    }
    
    checkBeforeSubmit = () => {
        if((this.props.to_be_graded == 1 && this.props.escalation_level_id == 3) || this.props.escalation_level_id == 5){
            if(this.grading.length == 0){
                store.dispatch({type: 'SHOW_ERROR', payload:{errorCode: 'GRADING_NOT_FILLED', type:0}});
                return false;
            }
        }
        return true;
    }

    
    render(){
        return(
            <div>
                {this.state.isOpen?(
                    <div style={{position:'fixed', width:'100vw', height:'100vh', top:0, left:0, zIndex:'5555', background:'#fff', boxSizing:'border-box'}}>
                        <div className={Classes.topRight}>
                            <div style={{display:'inline-block', verticalAlign:'middle', marginLeft:'10px'}}>
                                <Form   
                                    onSubmit={this.closeDemo}
                                    buttonValue={Strings.text.button.closeButton}
                                    buttonType='success'
                                    enableButton={true}
                                    fields={[]} />
                            </div>
                        </div>
                        <div className={Classes.leftPane}>
                            <PdfViewer tid={this.props.tid} />
                        </div>
                        <div className={Classes.rightPane}>
                            <div className={Classes.workarea}>
                                <AssignTemplateWithoutDemo refreshList={() => this.getPartsList()} tid={this.props.tid} partNum={1} buttonTrigger={(isDisable) => {}} />
                                <CreateSnapshot 
                                    snapshotSaved={() => {this.snapshotSavedFunc()}} 
                                    buttonTrigger={(isDisable) => {this.buttonTrigger(isDisable)}} 
                                    containerCss={Classes.OCRForm} 
                                    tabSize={1.27} 
                                    tid={this.props.tid} 
                                    partNum={1} 
                                    grading={this.grading}
                                    to_be_graded={this.props.to_be_graded}
                                    gradingChangeFunc={(data) => this.grading = data}
                                />
                            </div>
                            <div className={'toRefreshForward_indexing'}>
                                <React.Fragment>
                                    {this.directPost?(
                                        <div style={{display:'inline-block',marginRight:'10px'}}>
                                            <Form 
                                            url={'Tasks/showWordFileDemo_digitization.php'}
                                            buttonValue={Strings.text.button.preview}
                                            buttonType='info'
                                            enableButton={true}
                                            successSubmit={(data) => this.download(data)}
                                            failSubmit={(data) => this.failTaskComplete(data)}
                                            fields={[
                                                {
                                                    type: 'hidden',
                                                    value: this.props.tid,
                                                    name: 'tid',
                                                    options: {
                                                                required: true,
                                                                noFormGroup: true,
                                                            },
                                                },
                                                {
                                                    type: 'hidden',
                                                    value: 1,
                                                    name: 'part',
                                                    options: {
                                                                required: true,
                                                                noFormGroup: true,
                                                            },
                                                },
                                            ]} /> 
                                        </div>
                                    ):null}
                                    {this.directPost?(
                                        <div style={{display:'inline-block',marginRight:'10px'}}>
                                            <Form 
                                            url={'Tasks/markTaskComplete_digitization.php'}
                                            buttonValue={Strings.text.button.markCompletePost}
                                            buttonType='primary'
                                            enableButton={true}
                                            successSubmit={(data) => this.successTaskComplete(data)}
                                            failSubmit={(data) => this.failTaskComplete(data)}
                                            checkBeforeSubmit={this.checkBeforeSubmit}
                                            fields={[
                                                {
                                                    type: 'hidden',
                                                    value: this.props.tid,
                                                    name: 'tid',
                                                    options: {
                                                                required: true,
                                                                noFormGroup: true,
                                                            },
                                                },
                                                {
                                                    type: 'hidden',
                                                    value: 1,
                                                    name: 'is_complete',
                                                    options: {
                                                                required: true,
                                                                noFormGroup: true,
                                                            },
                                                },
                                            ]} /> 
                                        
                                        </div>
                                        
                                    ):null}
                                    
                                    {this.backward?(
                                        <div style={{display:'inline-block',marginRight:'10px'}}>
                                            <div id="backPopUp" className={Classes.backPopUp}>
                                                Are you sure, you want to send this file back to last user?
                                                <div style={{marginTop: '10px',overflow:'hidden'}}>
                                                    <div style={{float:'left'}}>
                                                        <Form 
                                                            url={'Tasks/escalateTaskBackward.php'}
                                                            buttonValue={'YES'}
                                                            buttonType='warning'
                                                            enableButton={true}
                                                            successSubmit={(data) => this.successTaskComplete(data)}
                                                            failSubmit={(data) => this.failTaskComplete(data)}
                                                            fields={[{
                                                                type: 'hidden',
                                                                value: this.props.tid,
                                                                name: 'tid',
                                                                options: {
                                                                            required: true,
                                                                            noFormGroup: true,
                                                                        },
                                                            }]} />
                                                    </div>

                                                    <div style={{float:'right'}}>
                                                        <Form 
                                                            onSubmit={(data)=>{document.getElementById('backPopUp').style.display='none'}}
                                                            buttonValue={'NO'}
                                                            buttonType='warning'
                                                            enableButton={true}
                                                            fields={[]} />
                                                    </div>
                                                </div>
                                            </div>
                                            <Form 
                                                onSubmit={(data)=>{document.getElementById('backPopUp').style.display='block'}}
                                                buttonValue={Strings.text.button.backward}
                                                buttonType='primary'
                                                enableButton={true}
                                                successSubmit={(data) => {}}
                                                failSubmit={(data) => {}}
                                                fields={[]} /> 
                                        
                                        </div> 
                                    ):null}
                                    {this.forward?(
                                        <div style={{display:'inline-block',marginRight:'10px'}}>
                                            <Form 
                                            url={'Tasks/escalateTask.php'}
                                            buttonValue={Strings.text.button.forward}
                                            buttonType='primary'
                                            enableButton={true}
                                            successSubmit={(data) => this.successTaskComplete(data)}
                                            failSubmit={(data) => this.failTaskComplete(data)}
                                            checkBeforeSubmit={this.checkBeforeSubmit}
                                            fields={[
                                                {
                                                    type: 'hidden',
                                                    value: this.props.tid,
                                                    name: 'tid',
                                                    options: {
                                                                required: true,
                                                                noFormGroup: true,
                                                            },
                                                }
                                            ]} /> 
                                        
                                        </div> 
                                    ):null}
                                    {this.blankCheck?(
                                        <div style={{display:'inline-block',marginRight:'10px'}}>
                                            <Form 
                                            url={'Tasks/postToBlankCheck.php'}
                                            buttonValue={Strings.text.button.blankCheck}
                                            buttonType='primary'
                                            enableButton={true}
                                            successSubmit={(data) => this.successTaskComplete(data)}
                                            failSubmit={(data) => this.failTaskComplete(data)}
                                            checkBeforeSubmit={this.checkBeforeSubmit}
                                            fields={[
                                                {
                                                    type: 'hidden',
                                                    value: this.props.tid,
                                                    name: 'tid',
                                                    options: {
                                                                required: true,
                                                                noFormGroup: true,
                                                            },
                                                }
                                            ]} /> 
                                        
                                        </div> 
                                        ):null}
                                </React.Fragment>
                            </div>
                        </div>
                    </div> 
                ):null}
            </div>
        )
    }
}



export default TranslationPartsList; 