import React from 'react';
import PdfViewer from 'modules/PdfViewer/PdfViewer';
import Form from 'components/Form/Form';
import Strings from 'Strings.js';
import Classes from './IndexingPartsList.module.css';
import axios from 'axios/axios.js';
import CreateFile from 'modules/Form/Create/Create';
import {cloneDeep} from 'lodash';

class IndexingPartsList extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            isOpen: 0,
            refreshEditor: 1,
            fields: [{
                type: 'hidden',
                value: this.props.tid,
                name: 'tid',
                placeholder: '',
                options: {
                            required: true,
                         },
            }]
        }

        

        this.snapshotSaved = 0;
        this.refreshForward = 0;
        

        this.directPost = 0;
        this.blankCheck = 0;
        this.forward = 0;
        this.backward = 0;
        
    }

    _addDeleteFields = (data) => {
        console.log("index", data.index)
        if(data.addminus == 1){
            data.fields.splice(data.index+1,0,{
                type: 'text',
                value: 'key' in data?data.key:'',
                name: 'array_key',
                placeholder: '',
                options: {
                            required: true,
                            table: {rowStart: true},
                         },
            },{
                type: 'select',
                value: 'type' in data?data.type:'text',
                name: 'array_type',
                options: {
                            required: true,
                            table: {},
                            options: [
                                {value: 'Text', name: 'text', selected: true},
                                {value: 'Date', name: 'date', selected: false},
                                {value: 'Number', name: 'number', selected: false},
                            ],
                         },
            },{
                type: 'text',
                value: 'value' in data?data.value:'',
                name: 'array_value',
                placeholder: '',
                options: {
                            required: true,
                            table: {rowEnd: true},
                            addFieldsBut: {plus:true, minus:data.index==0?false:true, func:(data) => this.addDeleteFields(data)}
                         }, 
            });
        }
        else if(data.addminus == -1){
            data.fields.splice(data.index-2,3);
        } 

        return data;
    }
    
    addDeleteFields = (data) => {
        var data1 = this._addDeleteFields(data)
        this.setState({fields: []}, () => {this.setState({fields: data1.fields})});
    }

    

    getPartsList = () => {
        this.snapshotSaved = 0;
        axios.post('Tasks/indexList.php?tid='+this.props.tid, {}).then(response => {
            if(response.data.r === 1)
            {
                document.getElementsByTagName('body')[0].style.overflow = 'hidden';
                var indexFormData = cloneDeep(this.state.fields)
                
                if(response.data.d.length > 0){
                    response.data.d.forEach((ele,ind) => {
                        var whole = this._addDeleteFields({addminus:1, index:ind*3, key: ele.key, type: ele.type, value: ele.value, fields:indexFormData})
                        indexFormData = whole.fields
                    })
                }
                else{
                    var whole = this._addDeleteFields({addminus:1, index:0, fields:indexFormData})
                    indexFormData = whole.fields
                }
                
                this.setState({
                    refreshEditor: 0,
                    fields: [],
                }, ()=>{
                    this.setState({
                        refreshEditor: 1,
                        isOpen: 1,
                        fields: indexFormData,
                    }, () => {
                        var byt = document.getElementsByClassName('toRefreshForward_indexing')[0].getElementsByClassName('formSubmitButton');
                        for(var i=0; i<byt.length; i++){
                            byt[i].setAttribute('disabled','disabled');
                        }
                    })
                });
            }

        }).catch(error => {
            
        });
    }

    refreshEditor = () => {
        this.setState({
            refreshEditor: 0,
        }, ()=>{
            this.setState({
                refreshEditor: 1,
            })
        });
    }

    openWorkbench = () => {
        axios.post('Tasks/checkIfTaskIsStillAssigned_digitization.php?tid='+this.props.tid, {}).then(response => {
            if(response.data.r === 1)
            {
                this.directPost = response.data.d.directPost;
                this.blankCheck = response.data.d.blankCheck;
                this.forward = response.data.d.forward;
                this.backward = response.data.d.backward;
                
                this.getPartsList();
            //    this.assignDemo();
            }
            else if(response.data.e == 'TASK_ASSIGNED_SOMEONE_ELSE'){
                this.closeDemo();
                this.props.refreshList();
            }

        }).catch(error => {
            
        });
    }

    snapshotSavedFunc = () => {
        console.log("Snapshot Saved Called");
        this.snapshotSaved = 1;
        //this.refreshEditor();
    }

    buttonTrigger = (isDisable) => {
        
        this.refreshForward = isDisable && this.snapshotSaved;
        console.log("Button Trigger Called. Save is not "+this.refreshForward);
        var byt = document.getElementsByClassName('toRefreshForward_indexing')[0].getElementsByClassName('formSubmitButton');
        for(var i=0; i<byt.length; i++){
            if(this.refreshForward == 1)
                byt[i].removeAttribute('disabled');
            else
                byt[i].setAttribute('disabled','disabled');
        }
    }


    successTaskComplete = (data) => {
        this.closeDemo();
        
        this.props.refreshList();
    }

    failTaskComplete = (data) => {

    }

    componentDidMount(){
        if(this.state.isOpen === 0){
            this.openWorkbench();
        }
    }

    assignDemo = (e) => {
        if(e)
            e.preventDefault();
        document.getElementsByTagName('body')[0].style.overflow = 'hidden';
        this.setState({isOpen: 1});
    }

    closeDemo = (e) => {
        if(e)
            e.preventDefault();
        document.getElementsByTagName('body')[0].style.overflow = 'auto';
        this.setState({isOpen: 0});
    }


    

    
    render(){
        return(
            <div>
                {this.state.isOpen?(
                    <div style={{position:'fixed', width:'100vw', height:'100vh', top:0, left:0, zIndex:'5555', background:'#fff', boxSizing:'border-box'}}>
                        <div className={Classes.topRight}>
                            <div style={{display:'inline-block', verticalAlign:'middle', marginLeft:'10px'}}>
                                <Form   
                                    onSubmit={this.closeDemo}
                                    buttonValue={Strings.text.button.closeButton}
                                    buttonType='success'
                                    enableButton={true}
                                    fields={[]} />
                            </div>
                        </div>
                        <div className={Classes.leftPane}>
                            <PdfViewer tid={this.props.tid} />
                        </div>
                        <div className={Classes.rightPane}>
                            <div className={Classes.workarea}>
                                {this.state.fields.length > 0?
                                    <CreateFile
                                        createPermission = {[110,111,112,113,114,115,122,123,124,125,126,127]}
                                        createContainerCSS = {Classes.indexingForm}
                                        url = {'Tasks/saveIndex.php'} 
                                        onSuccess = {(data) => this.snapshotSavedFunc()}
                                        onFailure = {(data) => {}}
                                        keepFormPopulated = {true}
                                        buttonValue = {Strings.text.button.addIndexes}
                                        buttonTrigger={(isDisable) => {this.buttonTrigger(isDisable)}}
                                        isTable = {[Strings.text.label.indexKey, Strings.text.label.indexType, Strings.text.label.indexValue]}
                                        inlineTable = {true}
                                        fields = {this.state.fields}
                                    />:null}
                            </div>
                            <div className={'toRefreshForward_indexing'}>
                                <React.Fragment>
                                    {this.directPost?(
                                        <div style={{display:'inline-block',marginRight:'10px'}}>
                                            <Form 
                                            url={'Tasks/markTaskComplete_indexing.php'}
                                            buttonValue={Strings.text.button.markCompletePost}
                                            buttonType='primary'
                                            enableButton={true}
                                            successSubmit={(data) => this.successTaskComplete(data)}
                                            failSubmit={(data) => this.failTaskComplete(data)}
                                            fields={[
                                                {
                                                    type: 'hidden',
                                                    value: this.props.tid,
                                                    name: 'tid',
                                                    options: {
                                                                required: true,
                                                                noFormGroup: true,
                                                            },
                                                },
                                                {
                                                    type: 'hidden',
                                                    value: 1,
                                                    name: 'is_complete',
                                                    options: {
                                                                required: true,
                                                                noFormGroup: true,
                                                            },
                                                },
                                            ]} /> 
                                        
                                        </div>
                                        
                                    ):null}
                                    
                                    {this.backward?(
                                        <div style={{display:'inline-block',marginRight:'10px'}}>
                                            <div id="backPopUp" className={Classes.backPopUp}>
                                                Are you sure, you want to send this file back to last user?
                                                <div style={{marginTop: '10px',overflow:'hidden'}}>
                                                    <div style={{float:'left'}}>
                                                        <Form 
                                                            url={'Tasks/escalateTaskBackward.php'}
                                                            buttonValue={'YES'}
                                                            buttonType='warning'
                                                            enableButton={true}
                                                            successSubmit={(data) => this.successTaskComplete(data)}
                                                            failSubmit={(data) => this.failTaskComplete(data)}
                                                            fields={[{
                                                                type: 'hidden',
                                                                value: this.props.tid,
                                                                name: 'tid',
                                                                options: {
                                                                            required: true,
                                                                            noFormGroup: true,
                                                                        },
                                                            }]} />
                                                    </div>

                                                    <div style={{float:'right'}}>
                                                        <Form 
                                                            onSubmit={(data)=>{document.getElementById('backPopUp').style.display='none'}}
                                                            buttonValue={'NO'}
                                                            buttonType='warning'
                                                            enableButton={true}
                                                            fields={[]} />
                                                    </div>
                                                </div>
                                            </div>
                                            <Form 
                                                onSubmit={(data)=>{document.getElementById('backPopUp').style.display='block'}}
                                                buttonValue={Strings.text.button.backward}
                                                buttonType='primary'
                                                enableButton={true}
                                                successSubmit={(data) => {}}
                                                failSubmit={(data) => {}}
                                                fields={[]} /> 
                                        
                                        </div> 
                                    ):null}
                                    {this.forward?(
                                        <div style={{display:'inline-block',marginRight:'10px'}}>
                                            <Form 
                                            url={'Tasks/escalateTask.php'}
                                            buttonValue={Strings.text.button.forward}
                                            buttonType='primary'
                                            enableButton={true}
                                            successSubmit={(data) => this.successTaskComplete(data)}
                                            failSubmit={(data) => this.failTaskComplete(data)}
                                            fields={[
                                                {
                                                    type: 'hidden',
                                                    value: this.props.tid,
                                                    name: 'tid',
                                                    options: {
                                                                required: true,
                                                                noFormGroup: true,
                                                            },
                                                }
                                            ]} /> 
                                        
                                        </div> 
                                    ):null}
                                    {/*this.blankCheck?(
                                        <div style={{display:'inline-block',marginRight:'10px'}}>
                                            <Form 
                                            url={'Tasks/postToBlankCheck.php'}
                                            buttonValue={Strings.text.button.blankCheck}
                                            buttonType='primary'
                                            enableButton={true}
                                            successSubmit={(data) => this.successTaskComplete(data)}
                                            failSubmit={(data) => this.failTaskComplete(data)}
                                            fields={[
                                                {
                                                    type: 'hidden',
                                                    value: this.props.tid,
                                                    name: 'tid',
                                                    options: {
                                                                required: true,
                                                                noFormGroup: true,
                                                            },
                                                }
                                            ]} /> 
                                        
                                        </div> 
                                        ):null*/}
                                </React.Fragment>
                            </div>
                        </div>
                    </div> 
                ):null}
            </div>
        )
    }
}



export default IndexingPartsList; 