import React from 'react';
import CreateFile from 'modules/Form/Create/Create';
import Strings from 'Strings.js';

class Create extends React.Component{

    onSuccess = (data) => {

    }

    onFailure = (data) => {

    }

    render(){
        return(
            <React.Fragment>
            <h4>{Strings.text.heading.AddVendor}</h4>
            <CreateFile
                createPermission = {9}
                createContainerCSS = {''}
                url = {'Vendors/addVendorAccount.php'}
                onSuccess = {(data) => this.onSuccess(data)}
                onFailure = {(data) => this.onFailure(data)}
                buttonValue = {Strings.text.button.addVendorButton}
                fields = {[{
                    type: 'text',
                    value: '',
                    name: 'company_name',
                    placeholder: Strings.text.placeholder.companyName,
                    options: {
                                required: true,
                                minLength: 5,
                             },
                    label: Strings.text.label.companyName,
                },{
                    type: 'email',
                    value: '',
                    name: 'email',
                    placeholder: Strings.text.placeholder.email,
                    options: {
                                required: true,
                            },
                    label: Strings.text.label.email,
                },{
                    type: 'alphabets',
                    value: '',
                    name: 'contact_person',
                    placeholder: Strings.text.placeholder.contactPerson,
                    options: {
                                required: true,
                            },
                    label: Strings.text.label.contactPerson,
                },{
                    type: 'text',
                    value: '',
                    name:'address',
                    placeholder: Strings.text.placeholder.address,
                    options: {
                                required: true,
                             },
                    label: Strings.text.label.address,
                },{
                    type: 'number',
                    value: '',
                    name:'phone',
                    placeholder: Strings.text.placeholder.phone,
                    options: {
                                required: true,
                                minLength: 10,
                                maxLength: 10,
                             },
                    label: Strings.text.label.phone,
                },{
                    type: 'select',
                    value: '',
                    name: 'iid',
                    options: {
                                required: true,
                                showLoader: [true, 'black', 'Institute/getInstituteList.php'],
                                options: [
                                    {value: '', name: '', selected: true},
                                ]
                            },
                    label: Strings.text.label.selectInstitute,
                },{
                    type: 'checkbox',
                    value: '',
                    name: 'siids',
                    options: {
                                required: true, 
                                showLoader: [true, 'black', 'Industry/serviceIndustryList.php'],
                                group: 'assignPermission',
                                newLine: true,
                                options: [
                                    {value: '', name: '', selected: false},
                                ]
                            },
                    label: Strings.text.label.selectServiceIndustry,
                }]}
            />
            </React.Fragment>
        );
    }
}

export default Create;