import React from 'react';
import CreateFile from 'modules/Form/Create/Create';

import Strings from 'Strings.js';
import axios from 'axios/axios.js';

class Create extends React.Component{
    state = {
        fields:[]
    };

    onSuccess = (data) => {
    
    }

    onFailure = (data) => {

    }

    getOptionsForElement = (arr, ele) => {
        var neededEle = ele.dependencyOn;
        var tree = [ele.column, neededEle];
        while(arr[neededEle]['dependencyOn'] && arr[neededEle]['dependencyOn'].length > 0){
            neededEle = arr[neededEle]['dependencyOn'];
            tree.push(neededEle.column);
        }

        var defaultOpt = 0;

        if(arr[neededEle]['default'] && arr[neededEle]['default'].length > 0){
            defaultOpt = arr[neededEle]['default'];
        }
        
        for(var i=tree.length-2; i>0; i--){
            var optparse = arr[tree[i]].options;
            defaultOpt = Object.keys(optparse[defaultOpt])[0];
        }

        return ele.options[defaultOpt];
    }

    componentDidMount(){
        if(this.state.fields.length == 0){
            var fd = new FormData();
            fd.append('formType', this.props.formType);
            Object.keys(this.props.dependency).forEach(ele => {
                fd.append(ele, this.props.dependency[ele]);
            });
            
            axios.post('DynamicForms/getForm.php', fd).then(response => {
                if(response.data.r == 1)
                {
                    var fieldsarr = [{
                                        type: 'hidden',
                                        value: response.data.uniqid,
                                        name: "unique_id",
                                        options: {required:true},
                                    }];
                    Object.values(response.data.d).forEach(element => {
                        console.log(element);
                       var options = {required: element.required};
                       if(element.options && Object.keys(element.options).length > 0){

                            if(element.type=='radio' || element.type=='checkbox'){
                                options.group = element.column;
                            }

                            var depchil = Object.values(element.dependentChildren);
                            if(depchil.length>0)
                                options['dependentChildren'] = depchil;

                            
                            var optopt = [];
                            var optparse = null;       

                            if(element.dependencyOn && element.dependencyOn.length > 0){
                                options['pointers'] = element.options;
                                optparse = this.getOptionsForElement(response.data.d, element);
                            }
                            else{
                                
                                optparse = element.options;
                            }
                            Object.keys(optparse).forEach(el=>{
                                optopt.push({value: optparse[el], name: ''+el, selected: el==element.default?true:false});
                            });
                            options['options'] = optopt;
                                
                            console.log(options);
                       }
                       fieldsarr.push({
                                        type: element.type,
                                        value: element.default?element.default:'',
                                        name: ""+element.column,
                                        placeholder: '',
                                        options: options,
                                        label: element.name,
                                    })
                   });

                   console.log("fieldsarr");

                   this.setState({fields: []}, () => {this.setState({fields: fieldsarr})});
                }
                
            }).catch(error => {
                
            });
        }
    }

    
    render(){
        return(
            this.state.fields.length > 0?(
            <CreateFile
                createPermission = {'ALL'}
                createContainerCSS = {this.props.createContainerCSS}
                url = {'DynamicForms/addFormsValues.php'}
                onSuccess = {(data) => this.onSuccess(data)}
                onFailure = {(data) => this.onFailure(data)}
                buttonValue = {this.props.buttonValue}
                fields = {this.state.fields}
            />):null
        );
    }
}

export default Create;


/*
    props:
            formType
            dependency: {colA: 24}
            createContainerCSS
            url
            buttonValue
*/