import React from 'react';
import {VendorListSingle} from 'modules/Vendors/Single';
import ApproveVendor from 'modules/Vendors/ApproveVendor';
import EditVendor from 'modules/Vendors/Edit';
import AssignIndustryToVendor from 'modules/Vendors/AssignOrEdit/IndustryToVendor';

import UsersList from 'pages/UsersList/UsersList';
import TasksList from 'pages/TasksList/TasksList';

import Strings from 'Strings.js';

class VendorProfile extends React.Component{
    render(){
        return(
            <React.Fragment>
                <div className={this.props.listContainerCSS}>
                    <VendorListSingle companyName={this.props.companyName} contactPerson={this.props.contactPerson} 
                    serviceIndustry={this.props.serviceIndustry} vendorAdmin={this.props.vendorAdmin} 
                    vendorUsersCount={this.props.vendorUsersCount} isApproved={this.props.isApproved} 
                    isDeleted={this.props.isDeleted} />
                    <div>
                        <ApproveVendor vid={this.props.vid} isApproved={this.props.isApproved} refresh={this.props.refresh} />
                        <EditVendor vid={this.props.vid}
                                    companyName={this.props.companyName}
                                    contactPerson={this.props.contactPerson}
                                    phone={this.props.phone}
                                    address={this.props.address}
                                    email={this.props.email}
                                    refresh={this.props.refresh}
                                 />
                        {/*<AssignIndustryToVendor vid={this.props.vid} />*/}
                        
                    </div>
                    <div>
                        <UsersList vid={this.props.vid} />
                        <TasksList vid={this.props.vid} />
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default VendorProfile;