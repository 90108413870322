import React from 'react';
import ReactDOM from 'react-dom';
import CreateFile from 'modules/Form/Create/Create';
import Strings from 'Strings.js';
import axios from 'axios/axios.js';

class Create extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            fields: [{
                type: 'select',
                value: '',
                name: 'tid',
                id: 'templateList',
                options: {
                            required: true,
                            showLoader: [true, 'black', 'TaskTemplate/getDemoForTemplate.php?tid='+this.props.tid+'&part='+this.props.partNum],
                            options: [
                                {value: '', name: '', selected: true},
                            ]
                        },
                label: Strings.text.label.selectTaskTemplate,
            },{
                type: 'hidden',
                value: '',
                name: 'garbage',
                options: {
                            required: false,
                            showLoader: [true, 'black'],
                            onChange: 'templateList',
                            options: [
                                {value: '', name: '', selected: true},
                            ]
                            },
                label: '',
            }],
        };
    }

    onSuccess = (data) => {
        var editorMoreInfo = document.getElementById('editorMoreInfo');
        if(editorMoreInfo)
            editorMoreInfo.innerHTML = data.templateInfo;
        localStorage.removeItem('snapshot:'+this.props.tid+':'+this.props.partNum);
        this.props.refreshList()
    }

    onFailure = (data) => {

    }

    searchEFaxes = (dt) => {
        if(!Object.values(dt).some(function(i) { return i == null || i == ''; }))
        {
            dt['tid'] = this.props.tid;
            var fd = new FormData();
            for(var xcv in dt){
                fd.append(xcv, dt[xcv]);
            }
            axios.post('DynamicForms/searchEFaxes.php', fd).then(response => {
                if(response.data.r == 1)
                {
                    document.getElementById('hideEditorBox').style.display= null;
                    var tabletype = response.data.d.tabletype;
                    var tableval = null;
                    tableval = response.data.d.efaxesData.map((yu,ind)=>{
                        if(tabletype == 1){ 
                            var dudob = '';
                            var dudov = '';
                            if(yu.dob && yu.dob.length > 0){
                                var du = new Date(parseInt(yu.dob) * 1000);
                                dudob = (du.getDate() < 10?'0'+du.getDate():du.getDate())+'/'+(du.getMonth() < 9?'0'+(du.getMonth()+1):(du.getMonth()+1))+'/'+du.getFullYear();
                                yu.dob = dudob;
                            }
                            if(yu.dov && yu.dov.length > 0){
                                var du = new Date(parseInt(yu.dov) * 1000);
                                dudov = (du.getDate() < 10?'0'+du.getDate():du.getDate())+'/'+(du.getMonth() < 9?'0'+(du.getMonth()+1):(du.getMonth()+1))+'/'+du.getFullYear();
                                yu.dov = dudov;
                            }
                            return (<tr key={ind} className={'efaxlist1'} style={{cursor:'pointer'}}>
                                    <td onClick={(e)=>this.fillDemo(e, yu, 1, ind)}>{yu.mrn}</td>
                                    <td onClick={(e)=>this.fillDemo(e, yu, 1, ind)}>{yu.pfn}</td>
                                    <td onClick={(e)=>this.fillDemo(e, yu, 1, ind)}>{yu.pmn}</td>
                                    <td onClick={(e)=>this.fillDemo(e, yu, 1, ind)}>{yu.pln}</td>
                                    <td onClick={(e)=>this.fillDemo(e, yu, 1, ind)}>{'intg' in yu?yu.intg:''}</td>
                                    <td onClick={(e)=>this.fillDemo(e, yu, 1, ind)}>{yu.gender}</td>
                                    <td onClick={(e)=>this.fillDemo(e, yu, 1, ind)}>{yu.dob}</td>
                                    <td onClick={(e)=>this.fillDemo(e, yu, 1, ind)}>{yu.dov}</td>
                                    <td onClick={(e)=>this.fillDemo(e, yu, 1, ind)}>{yu.padd1}</td>
                                    <td onClick={(e)=>this.fillDemo(e, yu, 1, ind)}>{yu.padd2}</td>
                                    <td onClick={(e)=>this.fillDemo(e, yu, 1, ind)}>{yu.padd3}</td>
                                    <td onClick={(e)=>this.fillDemo(e, yu, 1, ind)}>{yu.padd4}</td>
                                    <td onClick={(e)=>this.fillDemo(e, yu, 1, ind)}>{yu.padd5}</td>
                                    <td onClick={(e)=>this.fillDemo(e, yu, 1, ind)}>{yu.ph1}</td>
                                    <td onClick={(e)=>this.fillDemo(e, yu, 1, ind)}>{yu.ph2}</td>
                                    <td onClick={(e)=>this.searchEFaxes({refcode:yu.refcode})}>{yu.refcode}</td>
                                </tr>);
                        }
                        else if(tabletype == 2){
                           return (<tr key={ind} className={'efaxlist2'} style={{cursor:'pointer'}}>
                                    <td onClick={(e)=>this.fillDemo(e, yu, 2, ind)}>{yu.refcode}</td>
                                    <td onClick={(e)=>this.fillDemo(e, yu, 2, ind)}>{yu.reffn}</td>
                                    <td onClick={(e)=>this.fillDemo(e, yu, 2, ind)}>{yu.refmn}</td>
                                    <td onClick={(e)=>this.fillDemo(e, yu, 2, ind)}>{yu.refln}</td>
                                    <td onClick={(e)=>this.fillDemo(e, yu, 2, ind)}>{yu.hosnm}</td>
                                    <td onClick={(e)=>this.fillDemo(e, yu, 2, ind)}>{yu.refadd1}</td>
                                    <td onClick={(e)=>this.fillDemo(e, yu, 2, ind)}>{yu.refadd2}</td>
                                    <td onClick={(e)=>this.fillDemo(e, yu, 2, ind)}>{yu.refadd3}</td>
                                    <td onClick={(e)=>this.fillDemo(e, yu, 2, ind)}>{yu.refadd4}</td>
                                    <td onClick={(e)=>this.fillDemo(e, yu, 2, ind)}>{yu.refadd5}</td>
                                    <td onClick={(e)=>this.fillDemo(e, yu, 2, ind)}>{yu.refadd6}</td>
                                    <td onClick={(e)=>this.fillDemo(e, yu, 2, ind)}>{yu.ph1}</td>
                                    <td onClick={(e)=>this.fillDemo(e, yu, 2, ind)}>{yu.fx1}</td>
                                    <td onClick={(e)=>this.fillDemo(e, yu, 2, ind)}>{yu.fx2}</td>
                                    <td onClick={(e)=>this.fillDemo(e, yu, 2, ind)}>{yu.fx3}</td>
                                </tr>);
                        }
                        return null;
                    })

                    if(tabletype == 1){
                        var plt = document.getElementById('patientListTable');
                        ReactDOM.unmountComponentAtNode (plt);
                        ReactDOM.render(<div style={{padding:'10px'}}><h4>Patient List</h4><div style={{height:'calc(((100vh - 170px) / 2) - 57px)', overflow:'auto'}}><table className="stylish">
                                <thead>
                                    <tr>
                                        <th>MRN</th>
                                        <th>Patient First Name</th>
                                        <th>Patient Middle Name</th>
                                        <th>Patient Last Name</th>
                                        <th>Integration Code</th>
                                        <th>Gender</th>
                                        <th>Date of Birth</th>
                                        <th>Date of Visit</th>
                                        <th>Address 1</th>
                                        <th>Address 2</th>
                                        <th>Address 3</th>
                                        <th>Address 4</th>
                                        <th>Address 5</th>
                                        <th>Phone 1</th>
                                        <th>Phone 2</th>
                                        <th>Ref. Code</th>
                                    </tr>
                                </thead>
                                <tbody>{tableval}</tbody>
                            </table></div></div>, plt);
                    }
                    else if(tabletype == 2){
                        var plt = document.getElementById('associateListTable');
                        ReactDOM.unmountComponentAtNode (plt);
                        ReactDOM.render(<div style={{padding:'10px'}}><h4>Associate Lists</h4><div style={{height:'calc(((100vh - 170px) / 2) - 57px)', overflow:'auto'}}><table className="stylish">
                        <thead>
                            <tr>
                                <th>Ref. code</th>
                                <th>Ref. First Name</th>
                                <th>Ref. Middle Name</th>
                                <th>Ref. Last Name</th>
                                <th>Hospital Name</th>
                                <th>Ref. Address 1</th>
                                <th>Ref. Address 2</th>
                                <th>Ref. Address 3</th>
                                <th>Ref. Address 4</th>
                                <th>Ref. Address 5</th>
                                <th>Ref. Address 6</th>
                                <th>Phone 1</th>
                                <th>Fax 1</th>
                                <th>Fax 2</th>
                                <th>Fax 3</th>
                            </tr>
                        </thead>
                        <tbody>{tableval}</tbody>
                    </table></div></div>, plt);
                    }
                }
            }).catch(error => {
                
            });
        }
    }

    fillDemo = (e, resp, type, ind) => {
        e.preventDefault();
        var alltrs = document.getElementsByClassName('efaxlist'+type)
        Object.keys(alltrs).forEach(trind => {
            if(trind == ind)
                alltrs[trind].style.background = '#ffc';
            else
                alltrs[trind].style.background = 'unset'; 
        })
        
        this.createFileRef.fieldRef.formRef.getValBut(e, 1, 1, (dy)=>{
            if(type==1){
                dy.fields.forEach((element,index) => {
                    if('showLoader' in element.options)
                        delete dy.fields[index].options.showLoader;

                    [{arr:['mrn'],name:'mrn'},{arr:['pat','first'],name:'pfn'},{arr:['pat','midd'],name:'pmn'},{arr:['pat','last'],name:'pln'},{arr:['date','birth'],name:'dob'},{arr:['date','vis'],name:'dov'},{arr:['pat','add','1'],name:'padd1'},{arr:['pat','add','2'],name:'padd2'},{arr:['pat','add','3'],name:'padd3'},{arr:['pat','add','4'],name:'padd4'},{arr:['pat','add','5'],name:'padd5'},{arr:['pat','phone','1'],name:'ph1'},{arr:['pat','phone','2'],name:'ph2'},{arr:['inte','code'],name:'intg'}].forEach(el => {
                        if(this.checkIfWordsExists(el.arr, element.label)){
                            dy.fields[index]['value'] = resp[el.name];
                        }
                    });
                    
                });
                
            }
            else if(type==2){
                dy.fields.forEach((element,index) => {
                    if('showLoader' in element.options)
                        delete dy.fields[index].options.showLoader;
                    [{arr:['ref','fir','name'],name:'reffn'},{arr:['ref','las','name'],name:'refln'},{arr:['ref','midd','name'],name:'refmn'},{arr:['prac','name'],name:'hosnm'},{arr:['ref','add','1'],name:'refadd1'},{arr:['ref','add','2'],name:'refadd2'},{arr:['ref','add','3'],name:'refadd3'},{arr:['ref','add','4'],name:'refadd4'},{arr:['ref','add','5'],name:'refadd5'},{arr:['ref','add','6'],name:'refadd6'},{arr:['ref','phone','1'],name:'ph1'},{arr:['ref','fax','1'],name:'fax1'},{arr:['ref','fax','2'],name:'fax2'},{arr:['ref','fax','3'],name:'fax3'}].forEach(el => {
                        if(this.checkIfWordsExists(el.arr, element.label)){
                            dy.fields[index]['value'] = resp[el.name];
                        }
                    });
                    
                });
            }

            this.setState({fields:[]},
                ()=>{this.setState({fields: dy.fields}, () => {
                    if(type == 1)
                        this.searchEFaxes({refcode:resp.refcode})
                })});
        });
    }

    getValues = (data) => {
        var dt = {};
        var vl = '';
        data.fields.forEach(element => {
            [{arr:['mrn'],name:'mrn'},{arr:['pat','first'],name:'pfn'},{arr:['pat','midd'],name:'pmn'},{arr:['pat','last'],name:'pln'},{arr:['date','birth'],name:'dob'},{arr:['date','vis'],name:'dov'}].forEach(el => {
                if(this.checkIfWordsExists(el.arr, element.label) && (vl=this.getValueUsingKey(element.name, data.val)).length > 0){
                    dt[el.name] = vl;
                }
            });
            
        });
        
        if(Object.keys(dt).length > 0){
            this.searchEFaxes(dt);
        }
    }

    getValueUsingKey = (key, dv) => {
        for(var p of dv)
        {
            if(p[0] == key)
                return p[1];
        }
        return '';
    }

    checkIfWordsExists = (words, str) => {
        var exists = 1;
        if(words.length == 0){
            exists = 0;
        }
        else{
            words.forEach(el=>{
                if(str.toLowerCase().indexOf(el.toLowerCase()) == -1)
                    exists = 0;
            });
        }

        return exists;
    }

    getDemoState = () => {
        return this.createFileRef.fieldRef.formRef.getFormState();
    }
    

    render(){
        return(
                <div>
                    <h6 style={{padding:'10px',marginBottom:'10px',borderBottom:'1px solid #ccc',background:'#FFA',marginTop:'-10px',marginLeft: '-10px',marginRight: '-10px'}}>
                        {Strings.text.heading.fillDemographics}
                        <span style={{
                            fontSize: '10px',
                        }}>{'( Alt+Q )'}</span>
                    </h6>
                    {this.state.fields.length>0?(<CreateFile
                        createPermission = {['ALL']}
                        createContainerCSS = {''}
                        url = {'Tasks/assignDemo.php?tid='+this.props.tid+'&part='+this.props.partNum}
                        onSuccess = {(data) => this.onSuccess(data)}
                        onFailure = {(data) => this.onFailure(data)}
                        buttonValue = {Strings.text.button.assignDemo}
                        buttonTrigger = {(buttonState) => this.props.buttonTrigger(buttonState)}
                        getValuesButton = {{func: (data) => this.getValues(data), style:{position:'absolute',top:'125px',right:'10px'}, name:['Search Demo', <span style={{
                                fontSize: '10px',
                            }}>{' ( Alt+S )'}</span>]}}
                        keepFormPopulated = {true}
                        ref={(node) => { this.createFileRef = node; }}
                        fields = {this.state.fields}
                    />):null}
                </div>
            
        );
    }
}

export default Create;