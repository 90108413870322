import React, {Component} from 'react';


class SearchReplace extends Component{
    state = {
        refresh : 1,
        button: this.props.search.length>0?1:0,
        search: this.props.search,
        replace: '',
    }

    fieldChange = (e, spval) => {
        var button = 0;
        var shortcutValue = this.state.search;
        var phraseValue = this.state.replace;

        if(spval == 'shortcutValue')
            shortcutValue = e.target.value;
        else if(spval == 'phraseValue')
            phraseValue = e.target.value;

        if(shortcutValue.length > 0)
            button = 1;

        this.setState({
            button: button,
            search: shortcutValue,
            replace: phraseValue,
        });
    }

    addNewShortcut = () => {
        if(this.state.search.length > 0){
            this.props.onClick(this.state.search, this.state.replace);
        }
    }

    componentDidMount(){
        document.getElementById('searchInputField').focus()
    }
    
    render() {
        
        return (
            this.state.refresh?(
                <table style={{width:'100%',borderCollapse:'collapse',border:'1px solid #888'}}>
                    <tr>
                        <td style={{width:'30%',padding:'10px',border:'1px solid #888',verticalAlign:'top'}}>
                            <label>Search</label>
                            <input id={'searchInputField'} style={{width:'100%'}} type="text" value={this.state.search} onChange={(e)=>this.fieldChange(e, "shortcutValue")} />
                            <label>Replace</label>
                            <textarea style={{width:'100%'}} onChange={(e)=>this.fieldChange(e, "phraseValue")}>{this.state.replace}</textarea>
                            {
                                this.state.button==1?(
                                    <button type={'button'} onClick={this.addNewShortcut}>Replace All</button>
                                ):(
                                    <button type={'button'} disabled="true">Replace All</button>
                                )
                            }
                            
                        </td>
                    </tr>
                </table>
            ):null
        );
    }
}
export default SearchReplace;