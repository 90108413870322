import React from 'react';
import {FileListList} from 'modules/Options/Files/Upload/Single';
import Pagination from 'components/Pagination/Pagination';
import Classes from './UploadFiles.module.css';
import axios from 'axios/axios.js';
import { cloneDeep } from 'lodash';
import Button from 'react-bootstrap/Button';


class UploadFiles extends React.Component{
    constructor(props){
        super(props);
        this.flag = 1;
        this.filterVars = {};
    }
 
    state = {
        isClicked: 0,
        list: [],
        matter: null,
        refresh:1,
        totPages: 1,
        currentPage: 1,
        selectAll: 0,
    }

    getListFromServer = (currentPage) => {
        if(this.flag)
        {
            this.flag = 0;

            if(!currentPage)
                currentPage = this.state.currentPage;

            var fd = new FormData();
            Object.keys(this.filterVars).forEach(el=>{
                fd.append(el, this.filterVars[el]);
            });
            
            axios.post('Files/processedFilesList.php?currentPage='+currentPage+'&uid='+this.props.uid+'&did='+this.props.did+'&vid='+this.props.vid+'&pool='+this.props.poolId+'&self='+this.props.self, fd).then(response => {
                if(response.data.r === 1)
                {
                    console.log(response.data.d.tasksList);
                    response.data.d.processedFilesList.forEach(ite => {
                        ite.selected = 0;
                    });
                    this.setState({
                        ...this.state,
                        totPages: response.data.totPages,
                        currentPage: response.data.currentPage,
                        list: response.data.d.processedFilesList,
                        refresh:0,
                    }, () => this.setState({refresh: 1}));
                }
                this.flag = 1;
            }).catch(error => {
                this.flag = 1;
            });
        }
    }


    
    componentDidMount(){
        if(this.state.list.length === 0){
            this.getListFromServer();
        }
    }

    refreshList = () => {
        this.setState({refresh: 0}, () => {
            this.getListFromServer();
        })
    }

    upload = (e) => {
        e.preventDefault();
        var data = new FormData();
        data.append('file', document.getElementById('fileUploadId').files[0]);
        axios.post('Files/uploadProcessedFiles.php', data)
            .then(res => {
                if(res.data.r == 1){
                    this.getListFromServer();
                    if(res.data.d.notUploaded.length > 0)
                        alert("Error! These Files Are Not Uploaded -\n"+res.data.d.notUploaded.join('\n'));
                    else
                        alert("Success! Files Uploaded Successfully.");
                }
            })
            .catch(err => {
            });
    }

    pageClicked = (ele) => {
        console.log(ele);
        this.getListFromServer(ele);
    }

    checkboxCallback = (eleid) => {
        const listArr = cloneDeep(this.state.list);
        listArr.forEach(ite => {
            if(ite.id == eleid){
                ite.selected = ite.selected == 1 ? 0 : 1;
            }
        })
        this.setState({
            selectAll: 0,
            list: listArr,
            refresh: 0,
        }, () => this.setState({refresh: 1}));
    }

    selectAllCheckbox = () => {
        const listArr = cloneDeep(this.state.list);
        listArr.forEach(ite => {
            ite.selected = this.state.selectAll == 1 ? 0 : 1;
        })
        this.setState({
            selectAll: this.state.selectAll == 1 ? 0 : 1,
            list: listArr,
            refresh: 0,
        }, () => this.setState({refresh: 1}));
    }

    downloadFiles = () => {
        var fd = new FormData();
        var noneSelected = 1;
        this.state.list.forEach(ite => {
            if(ite.selected == 1){
                fd.append('doc[]', ite.url);
                noneSelected = 0;
            }
        })
        
        if(noneSelected == 0)
        {
            axios.post('downloadLastCheckFileZip.php', fd, {responseType: 'arraybuffer'}).then(response => {
                var link = document.createElement('a');
                link.href = window.URL.createObjectURL(new Blob([response.data], {type: 'application/octet-stream'}));
                link.download = 'Uploaded_Files.zip';

                document.body.appendChild(link);

                link.click();
                setTimeout(function() {
                    window.URL.revokeObjectURL(link);
                }, 200);
            }).catch(error => {})
        }
    }

    sendToDictatorSelected = () => {
        var fd = new FormData();
        var noneSelected = 1;
        this.state.list.forEach(ite => {
            if(ite.selected == 1){
                fd.append('fid[]', ite.id);
                noneSelected = 0;
            }
        })
        
        if(noneSelected == 0)
        {
            axios.post('Tasks/markFinalValidationMultiple.php', fd).then(response => {
                this.refreshList();
            }).catch(error => {})
        }
    }

    render(){
        return(
            <div className={Classes.container}>
            {this.state.refresh === 1?(
                <div style={{marginRight:'20px',boxSizing:'border-box'}}>
                    <div style={{marginBottom:'10px',paddingBottom:'10px',borderBottom:'1ps solid #aaa'}}>
                        <form onsubmit="return false;">
                            <input id="fileUploadId" multiple type="file" />
                            <button id="upload" type="button" onClick={this.upload}>Upload</button>
                        </form>
                    </div>
                    <div style={{marginBottom: '20px'}}>
                        <input type="checkbox" defaultChecked={this.state.selectAll==1?true:false} onChange={this.selectAllCheckbox} />
                        {' Select All '}
                        <Button onClick={this.sendToDictatorSelected} size={'sm'} variant={'info'} style={{marginLeft: '30px', float: 'right'}}>Send To Dictator (Selected)</Button>
                        <Button onClick={this.downloadFiles} size={'sm'} variant={'success'} style={{marginLeft: '30px', float: 'right'}}>Download (Selected)</Button>
                        
                    </div>
                    <div>
                        <Pagination totPages={this.state.totPages} currentPage={this.state.currentPage} pageClicked={(ele) => this.pageClicked(ele)}>
                            <FileListList listContainerCSS={Classes.listContainer}
                                onListClick={(data) => {}}
                                refreshList={this.refreshList}
                                checkboxCallback={this.checkboxCallback}
                                list={this.state.list} />
                        </Pagination>
                    </div>
                </div>
            ):null}
                
            </div>
        )
    }
}



export default UploadFiles; 