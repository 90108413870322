import React from 'react';
import GetDynamicForm from 'modules/DynamicForm/GetDynamicForm/GetDynamicForm';

import Strings from 'Strings.js';


class Create extends React.Component{
    
    
    render(){
        return(
            <GetDynamicForm 
                formType={3} 
                dependency={{'colA':14}}
                createContainerCSS={''}
                buttonValue={'Create Form'}
            />
        );
    }
}

export default Create;