import React from 'react';
import EditFile from 'modules/Form/Edit/Edit';
import Strings from 'Strings.js';
import axios from 'axios/axios.js';
import {cloneDeep} from 'lodash';

class Edit extends React.Component{

    state = {refresh: 0, fields:[]}

    onSuccess = (data) => {
        this.props.refresh();
    }

    onFailure = (data) => {

    }

    componentDidMount(){
        if(this.state.fields.length == 0){
            axios.post('Vendors/selectVendorWithIndustry.php?iid='+this.props.iid, {}).then(response => {
                if(response.data.r == 1)
                {
                    var fid = [{
                        type: 'hidden',
                        value: this.props.iid,
                        name: 'iid',
                        options: {
                                    required: true,
                                },
                    },{
                        type: 'alphabets',
                        value: this.props.name,
                        name: 'name',
                        placeholder: Strings.text.placeholder.instituteName,
                        options: {
                                    required: true,
                                    minLength: 5,
                                    },
                        label: Strings.text.label.instituteName,
                    },{
                        type: 'email',
                        value: this.props.email,
                        name: 'email',
                        placeholder: Strings.text.placeholder.email,
                        options: {
                                    required: false,
                                },
                        label: Strings.text.label.email,
                    },{
                        type: 'text',
                        value: this.props.contactPerson,
                        name: 'contact_person',
                        placeholder: Strings.text.placeholder.contactPerson,
                        options: {
                                    required: true,
                                },
                        label: Strings.text.label.contactPerson,
                    },{
                        type: 'text',
                        value: this.props.address,
                        name:'address',
                        placeholder: Strings.text.placeholder.address,
                        options: {
                                    required: false,
                                    },
                        label: Strings.text.label.address,
                    },{
                        type: 'number',
                        value: this.props.phone,
                        name:'phone',
                        placeholder: Strings.text.placeholder.phone,
                        options: {
                                    required: false,
                                    minLength: 10,
                                    maxLength: 10,
                                    },
                        label: Strings.text.label.phone,
                    },{
                        type: 'select',
                        value: this.props.industry,
                        name: 'industry_id',
                        id: 'industryList',
                        options: {
                                    required: true,
                                    dependentChildren:['trainee[]','trans[]','prof1[]','prof2[]','blkchk[]'],
                                    showLoader: [true, 'black', 'Industry/getIndustryListWithSubcat.php?institute_id='+this.props.iid],
                                    options: [
                                        {value: '', name: '', selected: true},
                                    ]
                                },
                        label: Strings.text.label.selectIndustry,
                    }];

                    var groupOpts = {
                                        Transcription:{value:['','','','',''],pointer:{},opts:{1:[],2:[],3:[],4:[],5:[]}},
                                        Indexing:{value:['','','','',''],pointer:{},opts:{1:[],2:[],3:[],4:[],5:[]}},
                                        OCR:{value:['','','','',''],pointer:{},opts:{1:[],2:[],3:[],4:[],5:[]}},
                                        Translation:{value:['','','','',''],pointer:{},opts:{1:[],2:[],3:[],4:[],5:[]}},
                                    };

                    Object.keys(response.data.d.pointer).forEach(el => {
                        var service = null;
                        var firstOnly = parseInt(this.props.industry);
                        var defaultIndus = parseInt(this.props.industry);

                        if(el==1 || el==2){
                            service = 'Transcription';
                            firstOnly += 0;
                        }
                        else if(el==3 || el==4){
                            service = 'Indexing';
                            firstOnly += 2;
                        }
                        else if(el==5 || el==6){
                            service = 'OCR';
                            firstOnly += 4;
                        }
                        else if(el==7 || el==8){
                            service = 'Translation';
                            firstOnly += 6;
                        }

                        var hn = el%2==0?'2':'1';
                        groupOpts[service].pointer[hn] = response.data.d.pointer[el][hn];

                        console.log(el,firstOnly);

                        if('1' in response.data.d.default[el])
                            groupOpts[service]['value'][0] = response.data.d.default[el]['1'];

                        if('2' in response.data.d.default[el])
                            groupOpts[service]['value'][1] = response.data.d.default[el]['2'];

                        if('3' in response.data.d.default[el])
                            groupOpts[service]['value'][2] = response.data.d.default[el]['3'];

                        if('4' in response.data.d.default[el])
                            groupOpts[service]['value'][3] = response.data.d.default[el]['4'];

                        if('6' in response.data.d.default[el])
                            groupOpts[service]['value'][4] = response.data.d.default[el]['6'];


                        if(el == firstOnly){
                            console.log(el, response.data.d.pointer[el][defaultIndus]);
                            Object.keys(response.data.d.pointer[el][defaultIndus]).forEach(ele => {
                                groupOpts[service].opts['1'].push({value: response.data.d.pointer[el][defaultIndus][ele], name: ele, selected: response.data.d.default[el]['1']==ele?true:false});
                                groupOpts[service].opts['2'].push({value: response.data.d.pointer[el][defaultIndus][ele], name: ele, selected: response.data.d.default[el]['2']==ele?true:false});
                                groupOpts[service].opts['3'].push({value: response.data.d.pointer[el][defaultIndus][ele], name: ele, selected: response.data.d.default[el]['3']==ele?true:false});
                                groupOpts[service].opts['4'].push({value: response.data.d.pointer[el][defaultIndus][ele], name: ele, selected: response.data.d.default[el]['4']==ele?true:false});
                                groupOpts[service].opts['5'].push({value: response.data.d.pointer[el][defaultIndus][ele], name: ele, selected: response.data.d.default[el]['6']==ele?true:false});
                            });
                        }

                        

                    });
                    
                    console.log(groupOpts);

                    Object.keys(groupOpts).forEach(el => {
                        fid.push({
                            type: 'select',
                            value: groupOpts[el]['value'][0],
                            name: 'trainee[]',
                            options: {
                                        required: true,
                                        pointers: groupOpts[el]['pointer'],
                                        options: cloneDeep(groupOpts[el]['opts']['1'])
                                    },
                            label: el+': '+Strings.text.label.assignVendorToIndTrainee,
                        });
    
                        fid.push({
                            type: 'select',
                            value: groupOpts[el]['value'][1],
                            name: 'trans[]',
                            options: {
                                        required: true,
                                        pointers: groupOpts[el]['pointer'],
                                        options: cloneDeep(groupOpts[el]['opts']['2'])
                                    },
                            label: el+': '+Strings.text.label.assignVendorToIndTrans,
                        });
    
                        fid.push({
                            type: 'select',
                            value: groupOpts[el]['value'][2],
                            name: 'prof1[]',
                            options: {
                                        required: true,
                                        pointers: groupOpts[el]['pointer'],
                                        options: cloneDeep(groupOpts[el]['opts']['3'])
                                    },
                            label: el+': '+Strings.text.label.assignVendorToIndProf1,
                        });
    
                        fid.push({
                            type: 'select',
                            value: groupOpts[el]['value'][3],
                            name: 'prof2[]',
                            options: {
                                        required: true,
                                        pointers: groupOpts[el]['pointer'],
                                        options: cloneDeep(groupOpts[el]['opts']['4'])
                                    },
                            label: el+': '+Strings.text.label.assignVendorToIndProf2,
                        });
    
                        fid.push({
                            type: 'select',
                            value: groupOpts[el]['value'][4],
                            name: 'blkchk[]',
                            options: {
                                        required: true,
                                        pointers: groupOpts[el]['pointer'],
                                        options: cloneDeep(groupOpts[el]['opts']['5'])
                                    },
                            label: el+': '+Strings.text.label.assignVendorToIndBlkChk,
                        });
                    });   

                    

                    

                    fid.push({
                        type: 'checkbox',
                        value: this.props.category,
                        name: 'industry_subcat_ids',
                        options: {
                                    required: true,
                                    showLoader: [true, 'black'],
                                    onChange: 'industryList',
                                    group: 'industryCatList',
                                    newLine: true,
                                    options: [
                                        {value: '', name: '', selected: false},
                                    ]
                                },
                        label: Strings.text.label.selectIndustryCategory,
                    });

                    fid.push({
                        type: 'file',
                        value: '',
                        name: 'firm_logo',
                        options: {
                                    required: false,
                                    
                                },
                        label: Strings.text.label.firmLogo,
                    });

                    this.setState({refresh:0},()=>{
                        this.setState({
                            refresh:1,
                            fields:fid,
                        })
                    });
                }
            }).catch(error => {});
        }
        
    }

    render(){
        return(
            <React.Fragment>
            {
                    this.state.refresh?(
                        <EditFile
                            editPermission = {16}
                            ButtonCSS = {''}
                            NameCSS = {''}
                            editButtonValue = {Strings.text.button.editInstitute}
                            editContainerCSS = {''}
                            url = {'Institute/editInstitute.php'}
                            buttonValue = {Strings.text.button.editInstitute}
                            onSuccess = {(data) => this.onSuccess(data)}
                            onFailure = {(data) => this.onFailure(data)}
                            fields = {this.state.fields}
                        />
                ):(<div>Loading....</div>)
            }
        </React.Fragment>
    );
    }
}

export default Edit;


/*
    iid
    name
    email
    contactPerson
    phone
    address
    industry
    category
*/