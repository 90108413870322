import React from 'react';
import {UserListList} from 'modules/Users/Single';
import UserProfile from 'modules/Users/UserProfile';
import Classes from './UsersList.module.css';
import axios from 'axios/axios.js';
import Pagination from 'components/Pagination/Pagination';
import SortFilter from 'components/SortFilter/SortFilter';

class UsersList extends React.Component{

    constructor(props){
        super(props);
        this.flag = 1;
        this.filterVars = {};
    }

    state = {
        isClicked: 0,
        refreshList: 1,
        list: [],
        matter: null,
        curItem: null,
        totPages: 1,
        currentPage: 1,
    }

    callFromServer = (currentPage) => {
        if(this.flag)
        {
            this.flag = 0;
            if(!currentPage)
                currentPage = this.state.currentPage;

            var fd = this.filterVars;
            axios.post('Users/usersList.php?currentPage='+currentPage+'&vid='+this.props.vid, fd).then(response => {
                if(response.data.r === 1)
                {
                    console.log(response.data.d.usersList);
                    this.setState({
                        totPages: response.data.totPages,
                        currentPage: response.data.currentPage,
                        list: response.data.d.usersList,
                        refreshList: 1,
                    }, ()=>{
                        if(this.state.curItem){
                            var allEle = document.getElementsByClassName('users'+this.state.curItem.id);
                            if(allEle){
                                allEle[0].click();
                            }
                        }
                    });
                }
                this.flag = 1;
            }).catch(error => {
                this.flag = 1;
            });
        }
    }

    componentDidMount(){
        if(this.state.list.length === 0){
            this.callFromServer(this.state.currentPage);
        }
    }

    onListClick = (item) => {
        this.setState({
            isClicked: 0,
            matter: null
        }, () => {
            this.setState({
                isClicked: 1,
                curItem: item,
                matter: <UserProfile
                            listContainerCSS={Classes.SingleEntry}
                            id={item.id}
                            uid={item.id}
                            name={item.name} 
                            position={item.position} 
                            vendor={item.vendor} 
                            vendor_id={item.vendor_id}
                            iid={item.institute_id}
                            access={item.access}
                            totLines={item.totLines} 
                            totTimeDone={item.totTimeDone} 
                            activeSince={item.activeSince} 
                            nearestTAT={item.nearestTAT}
                            accountType={item.accountType}
                            approveUser={item.approveUser}
                            gender={item.gender}
                            approve={item.approveUser}
                            deleted={item.deleted}
                            phone={item.phone}
                            address={item.address}
                            email={item.email}
                            industry={item.industry}
                            sid={item.sid}
                            tat={item.tat}
                            nickname={item.nickname}
                            directPost={item.directPost}
                            asr={item.asr}
                            grade_all_1={item.grade_all_1}
                            grade_all_2={item.grade_all_2}
                            refresh={this.refreshList}
                        />
            });
        });
    }

    pageClicked = (ele) => {
        console.log(ele);
        this.callFromServer(ele);
    }

    filterSubmit = (data) => {
        this.filterVars = data[1];
        this.callFromServer(1);
    }

    refreshList = () => {
        this.setState({
            refreshList: 0,
            matter: null,
        }, () => {
            this.callFromServer(this.state.currentPage);
        });
    }

    render(){
        var userListVar = <Pagination totPages={this.state.totPages} currentPage={this.state.currentPage} pageClicked={(ele) => this.pageClicked(ele)}>
                                <UserListList listContainerCSS={Classes.listContainer}
                                onListClick={(data) => this.onListClick(data)}
                                list={this.state.list} />
                            </Pagination>
        
        if(!(this.props.vid)){
            userListVar = <SortFilter headings={[
                                'userName',
                                'vendorName',
                                'accountType',
                                'gender',
                                'userApproved' 
                            ]} filterProps={'userslist'} onFilterSubmit={(data) => this.filterSubmit(data)}>{userListVar}</SortFilter>
        }

        return(
            <div className={Classes.container}>
                {this.state.refreshList == 1?(
                    <React.Fragment>
                        <div style={{marginRight:'20px',boxSizing:'border-box'}}>
                            {userListVar}
                        </div>

                        <div>
                            {this.state.isClicked === 1?this.state.matter:null}
                        </div>
                    </React.Fragment>
                ):null}
            </div>
        )
    }
}

export default UsersList; 