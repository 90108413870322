import React from 'react';
import CreateFile from 'modules/Form/Create/Create';

import Strings from 'Strings.js';


class Create extends React.Component{
    
    onSuccess = (data) => {

    }

    onFailure = (data) => {

    }

    

    render(){
        return(
            <div>
                <div>
                    <CreateFile
                        createPermission = {23}
                        createContainerCSS = {''}
                        url = {'TaskTemplate/addTaskTemplateDemo.php'}
                        onSuccess = {(data) => this.onSuccess(data)}
                        onFailure = {(data) => this.onFailure(data)}
                        buttonValue = {Strings.text.button.addTaskTemplateDemo}
                        isTable = {[Strings.text.label.propertyKey, Strings.text.label.propertyFormat, Strings.text.label.propertyName]}
                        addProperty = {true}
                        fields = {[]}
                    />
                </div>
            </div>
        );
    }
}

export default Create;