import React from 'react';
import List from 'modules/List/List';
import TaskToSelf from 'modules/Tasks/AssignOrEdit/TaskToSelf';
import RemoveFromAudit from 'modules/Tasks/AssignOrEdit/RemoveFromAudit';
import Strings from 'Strings.js';


class Single extends React.Component{

    render(){
        var durhous = Math.floor(this.props.taskLength / 60);
        var dura = Math.floor(this.props.taskLength % 60);

        var tatinmins = Math.floor((parseInt(this.props.tat) - (Date.now() / 1000)) / 60);

        var tlhous = Math.floor(tatinmins / 60);
        var tldura = Math.abs(Math.floor(tatinmins % 60));

        var is_locked = null;
        if((this.props.nonclick && this.props.nonclick.is == true && this.props[this.props.nonclick.cond]==this.props.nonclick.lockon))
        {
            is_locked = '🔒';
        }    

        
        return(
                this.props.isSelf || this.props.isCompare?(
                    <React.Fragment>
                        <td>{is_locked} {this.props.name} {this.props.isSelf && this.props.to_be_graded==1 && this.props.escalation_level_id==3?<React.Fragment><br /><span style={{background:'darkgreen',display:'inline-block',color:'white',borderRadius:'3px',padding:'0 10px'}}>Grade This Task</span></React.Fragment>:null}</td>
                        <td className={this.props.isSelf?'taskTimeVal':''} data-val={this.props.tat}>{tlhous > 9 || tlhous < 0?tlhous:'0'+tlhous}{':'}{tldura > 9?tldura:'0'+tldura}</td>
                        <td>{durhous > 9?durhous:'0'+durhous}{':'}{dura > 9?dura:'0'+dura}</td>
                        <td>{this.props.escalationLevel}</td>
                        <td>{Object.keys(this.props.prevUsers).map((el,ind)=> (<React.Fragment key={ind}>
                                                                    <b>{el}: </b>{this.props.prevUsers[el]}<br />
                                                                </React.Fragment>))}</td>
                    </React.Fragment>
                ):(
                    <React.Fragment>
                        <td>{is_locked} {this.props.name}</td>
                        <td>{this.props.actionType}</td>
                        {/*<td>{this.props.tat}</td>*/}
                        <td data-val={this.props.tat}>{tlhous > 9 || tlhous < 0?tlhous:'0'+tlhous}{':'}{tldura > 9?tldura:'0'+tldura}</td>
                        <td>{durhous > 9?durhous:'0'+durhous}{':'}{dura > 9?dura:'0'+dura}</td>
                        <td>{this.props.currentlyAssignedTo}</td>
                        <td>{this.props.escalationLevel}</td>
                        <td>{this.props.isCompleted == 1?'Yes':'No'}</td>
                        {this.props.isPool?
                            <React.Fragment>
                                <td className={'picktask_'+this.props.tid+'_'+this.props.pool}><TaskToSelf tid={this.props.tid} perm={this.props.pool} onAssign={(tid) => this.props.onAssign(tid)} /></td>
                                {
                                    this.props.escalation_level_id == 5?(
                                        <td className={'removefromaudit_'+this.props.tid+'_'+this.props.pool}>
                                            <RemoveFromAudit tid={this.props.tid} onAssign={(tid) => this.props.onAssign(tid)} />
                                        </td>
                                    ):null
                                }
                            </React.Fragment>:null}
                    </React.Fragment>
                )
        );
    }
}

class _List extends React.Component{

    render(){
        const tableHead = {
            taskName:'name',
            actionType:'actionType',
            timeLeft:'timeLeft',
            taskLength:'duration',
            currentlyAssignedTo:'currentlyAssignedTo',
            escalationLevel:'escalationLevel', 
            isCompleted:'isCompleted',
        };
        if(this.props.isPool){
            tableHead.garbage = 'garbage';
        }
        return(
                <List 
                    listPermission={['ALL']}
                    onListClick={this.props.onListClick}
                    nonclick={this.props.nonclick}
                    list={this.props.list}
                    ref={this.props.inputRef}
                    listIdentifierForAutoClick={'tasks'}
                    activeClass={this.props.activeClass}
                    onMultipleListClick={(items) => this.props.onMultipleListClick(items)}
                    headings={
                        this.props.isSelf || this.props.isCompare ? {
                            taskName:'name',
                            timeLeft:'timeLeft',
                            taskLength:'duration',
                            [this.props.isCompare?'finalEscalationLevel':'escalationLevel']:this.props.isCompare?'finalEscalationLevel':'escalationLevel',
                            prevUsers:'prevUsers'
                        }:tableHead
                    }
                    singleItem={(item) => (
                            <Single 
                                tid={item.id}
                                isSelf={this.props.isSelf} 
                                isCompare={this.props.isCompare} 
                                isPool={this.props.isPool}
                                pool={this.props.pool}
                                onAssign={this.props.onAssign}
                                name={item.name} 
                                is_locked={item.is_locked} 
                                fileName={item.fileName} 
                                numberOfParts={item.numberOfParts} 
                                actionType={item.actionType} 
                                associatedDictator={item.associatedDictator} 
                                tat={item.tat} 
                                timeLeft={item.timeLeft}
                                taskLength={item.duration} 
                                currentlyAssignedTo={item.currentlyAssignedTo} 
                                assignedTaskTemplate={item.assignedTaskTemplate}
                                escalationLevel={item.escalationLevel} 
                                isCompleted={item.isCompleted} 
                                prevUsers={item.prevUsers} 
                                nonclick={this.props.nonclick} 
                                to_be_graded = {item.to_be_graded}
                                escalation_level_id={item.escalation_level_id} 
                            />
                        )
                    }
                    listContainerCSS={this.props.listContainerCSS}
                />
                
        );
    }
}

export {Single as TaskListSingle, _List as TaskListList};

/*
    for FileSingle -
        name, fileName, numberOfParts, actionType, associatedDictator, tat, timeLeft, currentlyAssignedTo, 
        assignedTaskTemplate, escalationLevel, isCompleted

    for FileList -
        onListClick, list, 
*/