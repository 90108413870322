import React, { Component } from 'react';

import {connect} from 'react-redux';


class RedirectPage extends Component{

    componentDidMount(){
        const { id, perm, tab, redir } = this.props.match.params;
        localStorage.setItem('awx', id);
        localStorage.setItem('per', perm);
        localStorage.setItem('tab', tab);
        this.props.changeLoginStatus(1);
        window.location.href = decodeURIComponent(redir);
    }

    
    render(){
        return(
            <div></div>
        )
    }
}

const mapDispatchToProps = dispatch => {
	return {
        changeLoginStatus: (isLogin) => dispatch({type: 'CHANGE_LOGIN', payload:{isLogin: isLogin}}),
	}
}


export default connect(null, mapDispatchToProps)(RedirectPage);