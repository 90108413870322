import { RichUtils, KeyBindingUtil } from 'draft-js';

const {hasCommandModifier} = KeyBindingUtil;

export default () => {
    return {
        customStyleMap: {
            'HIGHLIGHT': {
                background: '#fffe0d',
            }
        },
        keyBindingFn: e => {
            if (e.keyCode === 72 && hasCommandModifier(e)) {
                console.log("Highlight Shortcut");
                return "highlight";
            }
        },
        handleKeyCommand: (command, editorState, eventTimeStamp, { setEditorState }) => {
            if (command === "highlight") {
                setEditorState(RichUtils.toggleInlineStyle(editorState, "HIGHLIGHT"));
                return true;
            }
        }
    };
  };