import { RichUtils, KeyBindingUtil } from 'draft-js';

const {hasCommandModifier} = KeyBindingUtil;

export default () => {
    return {
        customStyleMap: {
            'LOWERCASE': {
                textTransform: 'lowercase',
            },
        },
        keyBindingFn: e => {
            if (e.keyCode === 51 && e.altKey) {
                //3
                return "lowercase";
            }
        },
        handleKeyCommand: (command, editorState, eventTimeStamp, { setEditorState }) => {
            if (command === "lowercase") {
                setEditorState(RichUtils.toggleInlineStyle(editorState, "LOWERCASE"));
                return true;
            }
        }
    };
  };