import React from 'react';
import List from 'modules/List/List';
import Strings from 'Strings.js';

class Single extends React.Component{

    render(){

        var gotToServer = parseInt(this.props.gotToServer) * 1000;

        var timeleft = 24*60 + ((gotToServer - Date.now()) / (60*1000));
        timeleft = timeleft > 0?parseInt(timeleft/60)+' Hours '+parseInt(timeleft % 60)+' Min.':'0 Hours';

        return(
            <React.Fragment>
                <td>{this.props.fileName}</td>
                <td>{this.props.fileType}</td>
                <td>{new Date(gotToServer).toLocaleString('en-AU')}</td>
                <td>{this.props.dictator_name}</td>
                <td>{this.props.tat} {'Hours'}</td>
                <td>{timeleft}</td>
            </React.Fragment>
        );
    }
}

class _List extends React.Component{

    render(){
        return(
            <List 
                listPermission={59}
                onListClick={(item) => this.props.onListClick(item)}
                list={this.props.list}
                activeClass={'listactive'}
                headings={{fileName:'fileName',
                            fileType:'fileType',
                            gotToServer:'datetime',
                            associatedDictator:'dictator_name',
                            tat:'tat',
                            timeleft:'tat',
                        }}
                singleItem={(item) => (<Single fileName={item.fileName} 
                    fileType={item.fileType}
                    gotToServer={item.datetime} 
                    dictator_id={item.dictator_id}
                    dictator_name={item.dictator_name}
                    tat={item.tat}
                    />)}
                listContainerCSS={this.props.listContainerCSS}
            />
        );
    }
}

export {Single as FileSingleList, _List as FileListList};

/*
    for FileSingle -
        fileName, fileType

    for FileList -
        onListClick, list, 
*/