import React from 'react';
import Classes from './Pagination.module.css';
import Pagination from 'react-bootstrap/Pagination'

class MyPagination extends React.Component{

    render(){
        var totPages = parseInt(this.props.totPages);
        var currentPage = parseInt(this.props.currentPage);
        var pageArr = [];
        if(totPages > 1){
            if(totPages <= 9){
                var i = 1;
                while(i <= totPages){
                    pageArr.push(i);
                    i++;
                }
            }
            else{
                if(currentPage <= 5)
                    pageArr = [1,2,3,4,5,6,7,8,'',totPages];
                else if(totPages - currentPage <= 4)
                    pageArr = [1,'',totPages-7,totPages-6,totPages-5,totPages-4,totPages-3,totPages-2,totPages-1,totPages];
                else
                    pageArr = [1,'',currentPage-3,currentPage-2,currentPage-1,currentPage,currentPage+1,currentPage+2,currentPage+3,'',totPages];
            }
        }

        const eleBar = pageArr.map((ele, ind) => {
                                if(ele == '')
                                    return <Pagination.Ellipsis key={ind} />;
                                else
                                    return <Pagination.Item key={ind} active={currentPage == ele?true:false}  onClick={currentPage == ele?()=>{}:()=>{this.props.pageClicked(ele)}}>{ele}</Pagination.Item>;
                            })


        return(
            <React.Fragment>
                {this.props.children}
                <div style={{marginTop:'15px'}}>
                    <Pagination style={{justifyContent:'center'}}>
                        {eleBar}
                    </Pagination>
                </div>
            </React.Fragment>
        );
    }
}

export default MyPagination