import React from 'react';
import ReactDOM from 'react-dom';
import CreateFile from 'modules/Form/Create/Create';
import Strings from 'Strings.js';
import axios from 'axios/axios.js';
import Classes from './TaskWithoutDemo.module.css';

class Create extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            fields: [{
                type: 'select',
                value: '',
                name: 'tid',
                id: 'templateList',
                options: {
                            required: true,
                            showLoader: [true, 'black', 'TaskTemplate/getDemoForTemplate.php?tid='+this.props.tid+'&part='+this.props.partNum],
                            options: [
                                {value: '', name: '', selected: true},
                            ]
                        },
                label: Strings.text.label.selectTaskTemplate,
            },{
                type: 'hidden',
                value: '',
                name: 'garbage',
                options: {
                            required: false,
                            showLoader: [true, 'black'],
                            onChange: 'templateList',
                            options: [
                                {value: '', name: '', selected: true},
                            ]
                            },
                label: '',
            }],
        };
    }

    onSuccess = (data) => {
        localStorage.removeItem('snapshot:'+this.props.tid+':'+this.props.partNum);
        this.props.refreshList()
    }

    onFailure = (data) => {

    }

    

    render(){
        return(
                <div>
                    {this.state.fields.length>0?(<CreateFile
                        createPermission = {['ALL']}
                        createContainerCSS = {Classes.taskWithoutDemo}
                        url = {'Tasks/assignDemo.php?tid='+this.props.tid+'&part='+this.props.partNum}
                        onSuccess = {(data) => this.onSuccess(data)}
                        onFailure = {(data) => this.onFailure(data)}
                        buttonValue = {Strings.text.button.assignDemo}
                        enableButton = {true}
                        buttonTrigger = {(buttonState) => this.props.buttonTrigger(buttonState)}
                        keepFormPopulated = {true}
                        ref={(node) => { this.createFileRef = node; }}
                        fields = {this.state.fields}
                    />):null}
                    
                </div> 
            
        );
    }
}

export default Create;