import React from 'react';
import CreateFile from 'modules/Form/Create/Create';
import Strings from 'Strings.js';

class DeleteTask extends React.Component{

    onSuccess = (data) => {
        this.props.refresh();
    }

    onFailure = (data) => {

    }

    render(){
        return(
            <CreateFile
                createPermission = {202}
                createContainerCSS = {''}
                url = {'Tasks/deleteTask.php'}
                onSuccess = {(data) => this.onSuccess(data)}
                onFailure = {(data) => this.onFailure(data)}
                buttonValue = {'Delete'}
                enableButton={true}
                fields = {[
                    {
                        type: 'hidden',
                        value: this.props.tid,
                        name: 'tid',
                        options: {
                                    required: true,
                                },
                    }
                ]}
            />
        );
    }
}

export default DeleteTask;

