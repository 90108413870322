import React, {Component} from 'react';
import {EditorState, RichUtils, Modifier, convertFromRaw, convertToRaw} from 'draft-js';
import Editor from "draft-js-plugins-editor";
import createHighlightPlugin from 'components/Plugins/highlight';
import isSoftNewlineEvent from 'draft-js/lib/isSoftNewlineEvent';

const highlightPlugin = createHighlightPlugin();

class FormEditor extends Component{
    constructor(props) {
        super(props);
        this.state = {editorState: this.props.cellContent && this.props.cellContent.length>0?EditorState.createWithContent(convertFromRaw(JSON.parse(this.props.cellContent))):EditorState.createEmpty()};
        this.onChange = editorState => this.setState({editorState});
        this.handleKeyCommand = this.handleKeyCommand.bind(this);
        this.setDomEditorRef = ref => this.domNewEditor = ref;
        this.plugins = [
            highlightPlugin,
        ];
    }

    getEditorData = () => {
        return JSON.stringify(convertToRaw(this.state.editorState.getCurrentContent()));
    }

    mapKeyToEditorCommand = (e) => {
        if (e.altKey && e.keyCode === 39) {
            //alt + right arrow
            this.addCharacter(null, '\t');
            return 'handled';
        } 
    }

    setFocusToEditor = (event, runfunc) => {
        if(event)
            event.preventDefault();
        this.domNewEditor.focus();
        setTimeout(runfunc, 0);
    }

    addCharacter = (event, symbol) => {
        const this2 = this;
        this.setFocusToEditor(event, ()=>{
            const editorState = this2.state.editorState;
            const selection = editorState.getSelection();
            if(selection.isCollapsed()){
                const curInlStyle = editorState.getCurrentInlineStyle();
                const contentState = editorState.getCurrentContent();
                const ncs = Modifier.insertText(contentState, selection, symbol, curInlStyle);
                const es = EditorState.push(editorState, ncs, 'insert-fragment');
                this2.onChange(es);
            }
        });
    }

    handleReturn = (event, editorState) => {
        event.preventDefault();
        if (isSoftNewlineEvent(event)) {
          //const { setEditorState, editorState } = this.props;
          this.onChange(RichUtils.insertSoftNewline(editorState));
          return 'handled';
        }
        
                
        return 'not-handled';
    }


    handleKeyCommand(command, editorState) {
        const newState = RichUtils.handleKeyCommand(editorState, command);
        if (newState) {
            this.onChange(newState);
            return 'handled';
        }
        return 'not-handled';
    }
    render() {
        return (
            <Editor
                
                editorState={this.state.editorState}
                handleKeyCommand={this.handleKeyCommand}
                keyBindingFn={this.mapKeyToEditorCommand}
                plugins={this.plugins}
                handleReturn={this.handleReturn}
                spellCheck={true}
                onChange={this.onChange}
                ref={this.setDomEditorRef}
            />
        );
    }
}
export default FormEditor;