export default {
    error: {
        required: 'Please fill this',
        onlyNumbers: 'Only numbers are allowed',
        invalidEmail: 'Please write valid email id',
        wrongDate: 'Please enter valid date',
        minDate: 'Minimum date should be - ',
        maxDate: 'Maximum date should be - ',
        wrongMinDate: 'Wrong Minimum Date Format. Contact Astra',
        wrongMaxDate: 'Wrong Maximum Date Format. Contact Astra',
        minValue: 'Value should be greater than ',
        maxValue: 'Value should be less than ',
        minLengthValue: ['Write at least ', ' characters'],
        maxLengthValue: ['At most ',' characters are allowed'],
        strongPass: ['- At least 1 lowercase character', '- At least 1 uppercase character', '- At least 1 number', '- At least 1 special character', '- At least 8 characters long'],
        "SELECTED_USER_INACTIVE_DELETED" 	: 	"Selected user is either inactive or deleted",
        "SELECTED_VENDOR_INACTIVE_DELETED"	:	"Selected vendor is either inactive or deleted",
        'SELECTED_DICTATOR_INACTIVE_DELETED':	'Selected dictator is either inactive or deleted',
        "ACCOUNT_NOT_APPROVED" 				: 	"Account is not approved yet",
        "ACCOUNT_DELETED" 					: 	"Account is deleted",
        'USER_NOT_SELECTED' 				: 	'Please select the user',
        'VENDOR_NOT_SELECTED' 				: 	'Please select the vendor',
        'TASK_TEMPLATE_NOT_SELECTED'		:	'Please select the task template',
        'INDUSTRY_NOT_SELECTED'				:	'Please select the industry',
        'INDUSTRY_SUBCATEGORY_NOT_SELECTED'	:	'Please select the industry sub-category',
        'NAME_EMPTY'						:	'Name field is empty',
        'COMPANY_NAME_EMPTY'				:	'Company name is not provided',
        'CONTACT_PERSON_EMPTY'				:	'Contact person name is not provided',
        'INSTITUTE_NAME_EMPTY'				:	'Institute name is not provided',
        'EMAIL_INVALID'						:	'Email id is invalid',
        'EMAIL_NOT_EXIST'					:	'Email does not exist',
        'PASSWORD_EMPTY'					:	'Password field is empty',
        'EMAIL_PASS_NOT_IN_RECORD'			:	'Email & Password not in our record',
        'EMAIL_PASS_EMPTY'					:	'Email or Password field is empty',
        'CONFIRM_PASSWORD_EMPTY'			:	'Confirm Password field is empty',
        'VERIFICATION_CODE_EMPTY'			:	'Verification Code field is empty',
        'VERIFICATION_CODE_NOT_SENT'		:	'Failed to send Verification Code',
        'CONFIRM_PASSWORD_NOT_MATCH'		:	'Password and Confirm Password do not match',
        'VERIFICATION_CODE_WRONG_OR_EXPIRED':	'Verification code is either wrong or expired',
        'ADDRESS_EMPTY'						:	'Address field is empty',
        'PHONE_INVALID'						:	'Phone number is not valid',
        'GENDER_NOT_SELECTED'				:	'Gender is not selected',
        'GENDER_INVALID'					:	'Gender is invalid',
        'VENDOR_INVALID'					:	'Vendor is invalid',
        'VENDOR_NOT_EXIST'					:	'Vendor does not exist',
        'ACCOUNT_TYPE_NOT_SELECTED'			:	'Account type is not selected',
        'INSTITUTE_NOT_SELECTED'			:	'Institute is not selected',
        'INSTITUTE_NOT_EXIST'				:	'Institute does not exist',
        'ACCOUNT_TYPE_NOT_EXIST'			:	'Account type does not exist',
        'USER_NOT_EXIST'					:	'User does not exist',
        'INDUSTRY_NOT_EXIST'				:	'Industry does not exist',
        'PERMISSION_NOT_GRANTED'			:	'Permission is not granted for this operation',
        'PERMISSION_DENIED_ASSIGN_USER_TO_OTHER_VENDOR'	:	'You do not have permission to assign user to other vendors',
        'SOME_PERMISSIONS_NOT_ACCESSIBLE'	:	'Some permissions are not accessible to you',
        'SOME_PERM_TEMPLATE_NOT_ACCESSIBLE'	:	'Some permission templates are not accessible to you',
        'EMAIL_ALREADY_REGISTERED'			:	'Email id is already registered',
        'PHONE_ALREADY_REGISTERED'			:	'Phone number is already registered',
        'SOME_INTERNAL_ERROR_OCCURRED'		:	'Some internal error occurred',
        'INVALID_ACTIVITY'					:	'Invalid Activity',
        'INVALID_NUMBER_FORMAT'             :   'Some fields are not numbers but set as numeric',
        'INVALID_DATE_FORMAT'               :   'Some fields are not valid dates.',
        'SELF_APPROVE_INVALID'				:	'You can not approve yourself',
        'CANT_APPROVE_OTHER_VENDOR_USERS'	:	'You are not allowed to approve users of other vendors',
        'PERMISSIONS_NOT_SELECTED'			:	'Please select some permissions',
        'TEMPLATE_NAME_EMPTY'				:	'Please provide a template name',
        'NO_PERMISSIONS_TO_ASSIGN'			:	'There are no permissions to assign',
        'NO_CATEGORY_SELECTED'				:	'Please select a category',
        'INVALID_CATEGORY'					:	'This category is invalid',
        'SOME_ACCOUNT_TYPES_NOT_VALID'		:	'Some selected account types are not valid',
        'PERM_TEMPLATE_NAME_ALREADY_EXISTS'	:	'Permission template name already exists',
        'CANT_EDIT_OTHER_VENDOR_USER'		:	'Not allowed to edit users of other vendors',
        'NO_PERMISSION_OR_TEMPLATE_SELECTED':	'No permissions or template selected',
        'SOME_INDUSTRY_SUBCAT_NOT_EXIST'	:	'Some selected industry subcategory does not exist',
        'SPECIALIZATION_NOT_SELECTED'		:	'Please select dictator specialization',
        'SPECIALIZATION_NOT_EXIST'			:	'Dictator specialization does not exist',
        'DICTATOR_NOT_SELECTED'				:	'Please select a dictator',
        'DICTATOR_NOT_EXIST'				:	'Selected dictator does not exist',
        'TASK_TEMPLATE_NAME_EMPTY'			:	'Please provide a task template name',
        'TASK_TEMPLATE_INFO_INVALID'		:	'Task template information is invalid',
        'TASK_TEMPLATE_CONTENT_EMPTY'		:	'Please write content for task template', 
        'TASK_TEMPLATE_NOT_EXIST'			:	'Selected task template does not exist',
        'FILE_TYPE_NAME_EMPTY'				:	'File type name is not provided',
        'FILE_TYPE_ALREADY_EXISTS'			:	'File type already exists',
        'TASK_NOT_SELECTED'					:	'Please select a task',
        'TASK_NOT_EXIST'					:	'Selected task does not exist',
        'ESCALATION_NOT_EXIST'				:	'Selected escalation level does not exist',
        'POOL_NOT_SELECTED'					:	'Please select the pool',
        'POOL_NOT_EXIST'					:	'Selected pool does not exist',
        'LOGGED_OUT'						:	'You are Logged Out',
        'INVALID_PHRASE_NAME'				:	'Entered Phrase name is invalid',
        'INVALID_SHORTCUT'					:	'Provided shortcut is invalid',
        'PHRASE_EMPTY'						:	'Please write the phrase',
        'SHORTCUT_EMPTY'					:	'Please provide a shortcut',
        'SHORTCUT_ALREADY_EXIST'			:	'Provided shortcut already exists',
        'PHRASE_NOT_SELECTED'				:	'Please select a phrase',
        'INDUSTRY_EMPTY'					:	'Please provide an industry name',
        'INDUSTRY_ALREADY_EXIST'			:	'Provided industry already exists',
        'SERVICE_NOT_SELECTED'				:	'Please select a service',
        'SERVICE_INDUSTRY_ALREADY_EXIST'	:	'Provided service industry already exists',
        'INDUSTRY_SUBCAT_EMPTY'				:	'Please provide industry subcategory',
        'INDUSTRY_SUBCAT_NAME_ALREADY_EXIST':	'Provided industry subcategory name already exists',
        'INDUSTRY_SUBCAT_NOT_SELECTED'		:	'Please select industry subcategory',
        'FILE_NAME_EMPTY'					:	'Please provide a file name',
        'FILE_TYPE_NOT_SELECTED'			:	'Please select a file type',
        'FILE_TYPE_NOT_EXIST'				:	'Selected file type does not exist',
        'FILE_ALREADY_EXIST'				:	'Provided file already exists',
        'FILE_NOT_EXIST'					:	'Selected file does not exist',
        'SERVICE_INDUSTRY_NOT_VALID'		:	'Selected service industry is not valid',
        'SERVICE_INDUSTRY_NOT_SELECTED'		:	'Please select a service industry',
        'PERM_TEMPLATE_NAME_ALREADY_EXIST'	:	'Permission template name already exists',
        'PERM_TEMPLATE_NAME_EMPTY'			:	'Please provide a permission template name',
        'TASK_NAME_EMPTY'					:	'Please provide a task name',
        'FILE_NOT_SELECTED'					:	'Please select a file',
        'ACTION_NOT_SELECTED'				:	'Please select an action',
        'TAT_EMPTY'							:	'Please fill the TAT',
        'ACTION_NOT_EXIST'					:	'Selected action does not exist',
        'TASK_TEMPLATE_NAME_ALREADY_EXIST'	:	'Provided task template name already exists',
        'TASK_NAME_ALREADY_EXISTS'			:	'Provided task name already exists',
        'SOME_PERMISSION_ARE_INACCESSIBLE'	:	'Some selected permissions are inaccessible',
        'SOME_SERVICE_INDUSTRY_NOT_EXISTS'	:	'Some selected service industry does not exist',
        'SOME_PERMISSIONS_NOT_VALID'		:	'Some selected permissions are invalid',
        'PERMISSIONS_TEMPLATE_NOT_SELECTED'	:	'Please select a permission template', 
        'ESCALATION_AT_LEVEL_NOT_PERMITTED'	:	'You can not escalate at this level',
        'TASK_NOT_IN_POOL'					:	'Selected task is not in pool',
        'TASK_SNAPSHOT_EMPTY'				:	'Please provide some content for the task',
        'IPADDRESS_EMPTY'					:	'Write IP Address of server',
        'HOSTUSERNAME_EMPTY'				:	'Write Host Username',
        'HOSTPASSWORD_EMPTY'				:	'Write Host Password',
        'AUDIO_DIRECTORY_EMPTY'				:	'Audio directory is empty',
        'FILES_DIRECTORY_EMPTY'				:	'Files directory is empty',
        'SSH2_MODULE_MISSING'				:	'SSH2 Module is missing from server',
        'REMOTE_SERVER_CONNECTION_FAILED'	:	'Remote Server Connection failed',
        'REMOTE_SERVER_AUTHENTICATION_FAILED':	'Remote Server Authentication failed',
        'REMOTE_SERVER_STREAM_NOT_CREATED'	:	'Failed in creating Remote Server Stream',
        'REMOTE_SERVER_DIRECTORY_ERROR'		:	'Error in accessing remote server directory',
        'REMOTE_FILE_OPEN_ERROR'			:	'Error in opeing remote files',
        'LOCAL_FILE_CREATE_ERROR'			:	'Error in creating files on local server',
        'FILE_WRITE_ERROR'					:	'Error in writing to the file',
        'FILES_NOT_SELECTED_TO_DOWNLOAD'	:	'Please select files to download',
        'ERROR_FILE_CREATION'				:	'Error in creating files',
        'REFRESH_FOR_NEW_FILES'				:	'Please refresh page for getting new files',
        'NICKNAME_EMPTY'					:	'Please write dictator nickname',
        'NICKNAME_ALREADY_REGISTERED'		:	'Provided nickname already exists',
        'ONLY_ASSIGNED_USER_OR_SUPER_ADMIN_MARK_COMPLETE'	:	'Only super admin or assigned user can mark task to be complete',
        'ONLY_ASSIGNED_USER_OR_SUPER_ADMIN_CAN_ESCALATE'	:	'Only super admin or assigned user can escalate the task',
        'ONLY_ASSIGNED_USER_OR_SUPER_ADMIN_CAN_ADD_PART'	:	'Only super admin or assigned user can add new part',
        'TASK_ALREADY_COMPLETED'			:	'Selected task is already marked completed',
        'NO_FILES_TO_SHOW'					:	'There are no files to show',
        'DICTATOR_SPECIALIZATION_EMPTY'		:	'Please provide a dictator specialization',
        'ONLY_ASSIGNED_USER_OR_SUPER_ADMIN_CAN_EDIT_DEMO'	:	'Only super admin or assigned user can edit demographics',
        'FILE_IS_REOPENED'					:	'This file is reopened by somebody',
        'NOT_PERMITTED_TO_ACCESS_WORKBENCH' :   'You don\'t have permission to access workbench',
        'NOT_PERMITTED_TO_ACCESS_CLIENT_APP':   'You don\'t have permission to access Client Application',
        'SOMETHING_UPDATED_PLEASE_RELOGIN'  :   'Your account is updated from backend. Please save work and Login Again.',
        'WORKBENCH_REQUIRES_USER_WITHIN_VENDOR': 'To access workbech you need to be associated with a vendor. Please contact Astra.',
        'CLIENT_APP_REQUIRES_USER_WITHIN_INSTITUTE' : 'To access client app, you need to be associated witha an institute. Please contact Astra.',
        'ACCESS_NOT_SELECTED'               :   'Please select access - workbench or client app',
        'VENDOR_MANDATORY_FOR_WORKBENCH'    :   'Vendor is mandatory to assign for workbench access',
        'INSTITUTE_MANDATORY_FOR_CLIENT_APP':   'Institute is mandatory to assign for client app access',
        'NOT_PERMITTED_TO_EDIT_THIS_USER'   :   'This user doesn\'t belong to your vendor or institute',
        'CANT_GRANT_ACCOUNT_TYPE_BIGGER'    :   'You can\'t grant account priviledges greater than yours.',
        'NO_ACCESS_TO_THIS_TASK'            :   'You don\'t have access to this service industry',
        'TASKS_NOT_ACCESSIBLE_TO_ADMINS'    :   'Admins can\'t pick a task. Only users can.',
        'TASK_UNALLOCATED'                  :   'This task is unallocated.',
        'PREV_LVL_PROCESSING_PENDING'       :   'Please wait for trainee or transcriptionists to process the tasks first.',
        'SOME_PARTS_EMPTY'                  :   'Some parts in this task are empty. Please check them.',
        'CANT_APPROVE_OTHER_INSTITUTE_USERS':   'You cannot approve other institute\'s users',
        'SOME_PARTS_INCOMPLETE'             :   'Some file parts are incomplete. Please check if template or demographics are assigned to all parts.',
        'NOT_ALLOWED_TO_SEE_PREVIEW'        :   'You are not allowed to see preview',
        'INTERNET_CONNECTION'               :   'Please check your internet connection',
        'LINK_EXPIRED'                      :   'Link is Expired. Please generate new link.',
        'AUDIO_NOT_EXIST'                   :   'Requested Audio Does Not Exist.',
        'DICTATOR_SPECIALIZATION_NAME_ALREADY_EXIST':'This specialization already exists.',
        'DICT_PROF_TYPE_NOT_SELECTED'       :   'Select Profile Type',
        GRADING_NOT_FILLED                  :   'Please fill the grading before submitting.',
    },
    text: {
        heading: {
            main: 'Astra Workbench',
            loginPage: 'Login Page',
            signupPage: 'Signup Page',
            forgetPass: 'Forget Password Page',
            changePass: 'Change Password Page',
            verificationMailSent: 'A mail with verification code is sent to your email id. Please check.',
            addUserPage: 'Add New User',
            AddUser: 'Add New User',
            AddVendor: 'Add New Vendor',
            AddDictator: 'Add New Dictator',
            AddInstitute: 'Add New Institute',
            AddIndustry: 'Add New Industry',
            alottedTasks: 'Alotted Tasks',
            AddFile: 'Add File',
            SelectDictatorToDownload: 'Select Dictators To Download Files',
            SelectFilesToDownload: 'Select Files To Download',
            filesDownloaded: 'Files Downloaded',
            fillDemographics: 'Fill Demographics',
            templateInfo: 'Template Information',
            CreateCaseForm: 'Create Case Form',
            CreateClientForm: 'Create Client Form',
            CreateFileForm: 'Create File Form',
            
        },
        link: {
            signup: 'Don\'t have account? Signup now.',
            login: 'Already have account? Login now.',
            forgetPass: 'Forgot Password?',
            backToLogin: '← Back to Login',
        },
        button: {
            login: 'Login',
            signup: 'Sign Up',
            logout: 'Log Out',
            sendCode: 'Send Code',
            changePass: 'Change Password',
            addUserButton: 'Add User',
            approveUserButton: 'Approve User',
            unApproveUserButton: 'Unapprove User',
            approveVendorButton: 'Approve Vendor',
            unApproveVendorButton: 'Unapprove Vendor',
            approveDictatorButton: 'Approve Dictator',
            unApproveDictatorButton: 'Unapprove Dictator',
            addDictator: 'Add Dictator',
            addInstitute: 'Add Institute',
            addVendorButton: 'Add Vendor',
            editVendorButton: 'Edit Vendor',
            editRoles: 'Edit Roles',
            editDictator: 'Edit Dictator',
            editInstitute: 'Edit Institute',
            editTemplate: 'Edit',
            updateRoles: 'Update Roles',
            assignVendor: 'Assign Vendor',
            editAccountType: 'Edit Account Type',
            updateAccountType: 'Update Account Type',
            addIndustry: 'Add Industry',
            editIndustry: 'Edit Industry',
            addIndustrySubcat: 'Add Industry Subcat',
            editIndustrySubcat: 'Edit Industry Subcat',
            addIndustryService: 'Add Industry Service',
            editPhrase: 'Edit Phrase',
            addPhrase: 'Add Phrase',
            createFile: 'Create File',
            editFile: 'Edit File',
            addTaskButton: 'Add New Task',
            createPermissionTemplate: 'Create Permission Template',
            editPermissionTemplate: 'Edit Permission Template',
            assignServiceIndustryToPT: 'Assign Service Industry',
            assignPermissionsToPT: 'Assign Permissions',
            editUser: 'Edit User',
            grantPermission: 'Grant Permission',
            assignPermission: 'Assign Permission',
            assignPermissionTemplate: 'Assign Permission Template',
            assignIndustryToVendor: 'Assign Industry To Vendor',
            assignIndustryToInstitute: 'Assign Industry To Institute',
            assignIndustrySubcatToInstitute: 'Assign Industry Subcategory To Institute',
            assignPhraseToDictator: 'Assign Phrase To Dictator',
            assignInstitueToDictator: 'Assign Institute To Dictator',
            assignSpecializationToDictator: 'Assign Specialization To Dictator',
            editTask: 'Edit Task',
            addTaskTemplate: 'Add Task Template',
            editTaskTemplate: 'Edit Task Template',
            assignDictatorToTT: 'Assign Dictator',
            editTaskAction: 'Edit Task Action Type',
            editTaskDictator: 'Edit Task Dictator',
            markComplete: 'Mark Complete',
            markInComplete: 'Mark Incomplete',
            markCompletePost: 'Post',
            assignTaskToPool: 'Assign Task To Pool',
            grantPermissionTemplate: 'Grant Permission Template',
            assignTaskToUser: 'Assign Task To User',
            editTaskTAT: 'Edit TAT',
            assignTaskToSelf: 'Pick_Task',
            addPartButton: 'Add New Part',
            addTaskSnapshotButton: 'Save',
            getFilesList: 'Get Files List',
            editFileLocationInfo: 'Edit File Location',
            download: 'Download',
            addTaskTemplateDemo: 'Create Demo',
            addProperty: 'Add Property',
            deleteProperty: 'Delete Property',
            addDictatorSpecialization: 'Add Specialization',
            assignTemplate: 'Assign Template',
            assignDemo: 'Assign Demo',
            closeButton: 'Close',
            testTemplate: 'Test',
            markValid: 'Send To Dictator',
            searchLabel: 'Search',
            nextPart: 'Next Part →',
            preview: 'Preview',
            forward: 'Forward →',
            backward: '← Send Back',
            blankCheck: 'Blank Check',
            addIndexingTemplate: 'Add Indexing Template',
            uploadEfaxes: 'Upload E-Faxes',
            addIndexingClientFormTemplate: 'Create Client Form',
            addIndexingCaseFormTemplate: 'Create Case Form',
            addIndexingFileFormTemplate: 'Create File Form',
            addIndexes: 'Submit Index',
        },
        tabs:{
            home:   'Home',

        },
        menu: {
            allocateTasks: 'Allocate Tasks',
            listOfTasks: 'List of Tasks',
            assignTasks: 'Assign Tasks',
            listOfUsers: 'List of Users',
            createUsers: 'Create Users / Vendors / Industries / Dictators',
            listOfDict : 'List of Dictators',
            listOfVend : 'List of Vendors',
            listOfInst : 'List of Institutes',
            listOfPerm : 'List of Permissions',
            listOfPermTemp: 'List of Permission Template',
            downloadF  : 'Download Files',
            uploadF    : 'Upload Files',
            listOfTemp : 'List of Templates',
            usersMonit : 'Users Monitor',
            createTemp : 'Create New Template',
            createTaskTemp: 'Create Task Template',
            createClie : 'Create New Client',
            filesGrad  : 'Grade Files',
            delete     : 'Delete Entities',
            urgentActi : 'Urgent Actions',
            users      : 'Users',
            vendors    : 'Vendors',
            tasks      : 'Tasks',
            dictators  : 'Dictators',
            industry   : 'Industry',
            institute  : 'Institute',
            files      : 'Files',
            permissionTemplate : 'Permission Template',
            assignedTasks: 'Assigned Tasks',
            pool: 'Task Pools',
            taskCompare: 'Learn By Comparison',
            createIndustrySubcategory: 'Create Industry Subcategory',
            createDictatorSpecialization: 'Create Dictator Specialization',
            taskTemplateList: 'List of Task Templates', 
            dictatorTemplateList: 'Dictator Headers List',
            CreateIndexingTemplate: 'Create Indexing Template',
            CreateDynamicForms: 'Create Dynamic Forms',
            uploadEFaxes:   'Upload Efaxes',
            dashboard: 'Dashboard',
        },
        placeholder: {
            email: 'Enter email address',
            pass: 'Enter Password',
            name: 'Enter Your Name',
            address: 'Enter Your Address',
            phone: 'Enter Your Mobile No',
            newPass: 'New Password',
            confirmPass: 'Confirm New Password',
            verificationCode: 'Enter Verification Code',
            addUserName: 'Enter User Name',
            addUserEmail: 'Enter User Email',
            addUserAddress: 'Enter User Address',
            addUserPhone: 'Enter User Phone',
            instituteName: 'Name of Institute',
            contactPerson: 'Contact Person Name',
            companyName: 'Write Company Name',
            industryName: 'Name of Industry',
            industrySubcatName: 'Industry Subcat Name',
            phraseName: 'Phrase',
            shortcut: 'Assign Shortcut',
            fileName: 'Enter File Name',
            permissionTemplateName: 'Name of Template',
            taskName: 'Add Task Name',
            tat: 'Turn Around Time',
            taskTemplateName: 'Write Task Template Name',
            ipaddress: 'Host Ip Address',
            username: 'Username',
            hostpassword: 'Password',
            audioPath: 'Audio Directory Path',
            filesPath: 'Processed Files Directory',
            nickname: 'Audio File Initials',
            specializationName: 'Dictator Specialization Name',
            indexingTemplateName: 'Template Name',
            createNewLabel: 'Enter New Label Name',
        },
        label: {
            email: 'Email Id',
            pass: 'Password',
            name: 'Full Name',
            address: 'Address',
            phone: 'Phone',
            gender: 'Gender',
            emailForgetPass: 'Write your email to get verification code.',
            newPass: 'New Password',
            confirmPass: 'Enter Password Again',
            verificationCode: 'Verification Code',
            selectVendor: 'Select Vendor',
            selectAccountType: 'Select Account Type',
            selectInstitute: 'Select Institute',
            selectSpecialization: 'Select Specialization',
            selectDictator: 'Select Dictator',
            instituteName: 'Institute Name',
            contactPerson: 'Contact Person',
            selectIndustry: 'Select Industry',
            selectIndustryCategory: 'Select Industry Category',
            companyName: 'Company Name',
            totalLinesCompleted: 'Total Lines Completed',
            totalTimeDone: 'Total Time Done',
            activeSince: 'Active Since',
            nearestJobTat: 'Nearest Job TAT',
            roles: 'User Roles',
            selectService: 'Select Service',
            processedTasks: 'Processed Tasks',
            underProcessingTasks: 'Under Processing Tasks',
            totalTemplates: 'Total Templates',
            associatedClinic: 'Associated Clinic',
            associatedServiceIndustry: 'Associated Service Industry',
            associatedIndustry: 'Associated Industry',
            industryName: 'Industry Name',
            industrySubcatName: 'Industry Subcategory',
            phraseName: 'Phrase',
            shortcut: 'Assign Shortcut',
            shortcutText: 'Shortcut',
            phraseDictator: 'Dictator',
            fileName: 'Name of File',
            fileType: 'File Type',
            selectFileType: 'Select File Type',
            permissionTemplateName: 'Permission Template Name',
            selectServiceIndustry: 'Select Service',
            selectPermissionsForPT: 'Select Permissions',
            grantPermission: 'Select Permissions',
            grantPermissionTemplate: 'Select Permission Template',
            assignPermission: 'Assign Permission',
            assignPermissionTemplate: 'Assign Permission Template',
            vendorAdmin: 'Vendor Admin',
            vendorTotalUsers: 'Vendor Total Users',
            vendorApproved: 'Vendor Approved?',
            vendorDeleted: 'Vendor Deleted?',
            userApproved: 'User Approved?',
            userDeleted: 'User Deleted?',
            dictatorApproved: 'Dictator Approved?',
            dictatorDeleted: 'Dictator Deleted?',
            specialization: 'Specialization',
            totalTaskProcessed: 'Total Task Processed',
            totalTaskPending: 'Total Task Pending',
            selectPhraseToAssign: 'Select Phrase To Assign',
            associatedIndustrySubcat: 'Associated Industry Subcategory',
            taskName: 'Task Name',
            selectFileForTask: 'Select File',
            selectActionType: 'Select Action Type',
            tat: 'Turn Around Time',
            numberOfParts: 'Number of Parts',
            taskTemplateName: 'Task Template Name',
            createdBy: 'Created By',
            selectPoolToEscalate: 'Select Pool To Escalate',
            instituteTotalDictators: 'Total Dictators',
            instituteDeleted: 'Institute Deleted?',
            actionType: 'Action Type',
            associatedDictator: 'Associated Dictator',
            timeLeft: 'Time Left',
            currentlyAssignedTo: 'Currently Assigned To',
            assignedTaskTemplate: 'Assigned Template',
            escalationLevel: 'Escalation Level',
            finalEscalationLevel: 'Final Level',
            prevUsers:'User Tree',
            isCompleted: 'Is Completed?',
            selectDictatorToAssign: 'Select Dictator',
            serviceIndustry: 'Service Industry',
            selectUser: 'Select User',
            traineePool: 'Trainee Pool',
            transcriptorPool: 'Transcriptor Pool',
            proofer1Pool: 'Proofer 1 Pool',
            proofer2Pool: 'Proofer 2 Pool',
            auditorPool: 'Auditor Pool',
            blankCheckPool: 'Blank Check Pool',
            createTemplate: 'Create Template',
            selectTaskTemplate: 'Select Task Template',
            transcriptBelow: 'Transcript Below',
            ipaddress: 'Server Ip Address',
            username: 'Username',
            hostpassword: 'Password',
            audioPath: 'Audio Directory Path',
            filesPath: 'Processed Files Directory Path',
            nickname: 'Audio File Initials',
            gotToServer: 'Got File On',
            timeleft: 'Time Left',
            tatinhours: 'TAT in Hours',
            dictatorName: 'Dictator Name',
            serial: 'Serial Number',
            permissionName: 'Permission Name',
            partsName: 'Parts Name',
            userName: 'Users Name',
            userId: 'User Id',
            vendorId: 'Vendor Id',
            vendorName: 'Vendor',
            accountType: 'Account Type',
            processedInTime: 'Processing Time',
            downloadFile: 'Download File',
            createHeader: 'Create Header',
            createFooter: 'Create Footer',
            propertyKey: 'Property Key',
            propertyName: 'Property Name',
            propertyFormat: 'Property Format',
            specializationName: 'Specialization Name',
            selectIndustrySubcat: 'Select Industry Subcategory',
            taskLength:'Length',
            poolId: 'Pool Id',
            poolName: 'Pool Name',
            allottedTime: 'Allotted Time',
            totalAllottedTime: 'Total Allotted Time',
            templateHeader: 'Dictator Template',
            sortLabel: 'Sort',
            filterLabel: 'Filter',
            searchLabel: 'Search...',
            templateInfo: 'Template Information',
            templateInEditor: 'Template Part To Show In MT Window',
            trainee: 'Trainee',
            transcriptionist: 'Transcriptionist',
            proofer1: 'Proofer 1',
            proofer2: 'Proofer 2',
            directPost: 'Direct Post Enabled',
            asr: 'ASR Enabled',
            totalFiles: 'Total Files',
            indexingTemplateName: 'Indexing Template Name',
            doclabel: 'Document Label',
            createNewLabel: 'Create New Label',
            patientLists: 'Patient Lists',
            associateLists: 'Associate Lists',
            selectEfaxes: 'Select E-Faxes',
            selectEfaxDate: 'Select E-Fax Date', 
            formFieldRequired: 'Is Required?',
            formFieldName: 'Field Name',
            formFieldType: 'Field_Type',
            formFieldOptionsText: 'Options Text',
            formFieldDefault: 'Default Value',
            formFieldPosition: 'Field Position Number',
            transcription: 'Transcription',
            indexing: 'Indexing',
            ocr: 'OCR',
            translation: 'Translation',
            selectAccess: 'Select Access',
            assignVendorToIndTrainee: 'Assign Vendor For Trainee Level',
            assignVendorToIndTrans: 'Assign Vendor For Transcriptionist Level',
            assignVendorToIndProf1: 'Assign Vendor For Proofer1 Level',
            assignVendorToIndProf2: 'Assign Vendor For Proofer2 Level',
            assignVendorToIndBlkChk: 'Assign Vendor For Blank Check Level',
            indexKey: 'Index Key',
            indexType: 'Index Type',
            indexValue: 'Index Value',
            dictatorProfileType: 'Profile Type',
            tempType: 'Template Type',
            firmLogo: 'Firm Logo (jpg, png, gif)',
        },
        value: {
            male: 'Male',
            female: 'Female',
            directPostYes: 'Yes',
            directPostNo: 'No',
            asryes: 'Yes',
            asrno: 'No',
        },
    }
};