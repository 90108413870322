import React from 'react';
import List from 'modules/List/List';
import Strings from 'Strings.js';

class Single extends React.Component{

    render(){
        return(
            <React.Fragment>
                <td>{this.props.templateName}</td>
                <td>{this.props.serviceIndustryValue}</td>
                <td>{this.props.createdBy}</td>
                <td>{this.props.tempType}</td>
            </React.Fragment>
        );
    }
}

class _List extends React.Component{

    render(){
        return(
            <List 
                listPermission={[61,63]}
                onListClick={(item) => this.props.onListClick(item)}
                activeClass={'listactive'}
                listIdentifierForAutoClick={'permissionTemplate'}
                list={this.props.list}
                headings={{permissionTemplateName:'templateName',
                            serviceIndustry:'serviceIndustryValue',
                            createdBy: 'createdBy',
                            tempType: 'tempType'
                        }}
                singleItem={(item) => (<Single templateName={item.templateName} serviceIndustryValue={item.serviceIndustryValue} createdBy={item.createdBy} tempType={item.tempType} />)}
                listContainerCSS={this.props.listContainerCSS}
            />
        );
    }
}

export {Single as PermTempListSingle, _List as PermTempListList};

/*
    for FileSingle -
        templateName

    for FileList -
        onListClick, list, 
*/