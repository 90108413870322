import React from 'react';
import EditFile from 'modules/Form/Edit/Edit';
import Strings from 'Strings.js';

class Edit extends React.Component{

    constructor(props){
        super(props);
        this.tableExtraInfo = <div>
                                <h6>Date Formats</h6>
                                <table>
                                    <tbody><tr><td><b>d</b></td><td>01-31</td></tr>
                                    <tr><td><b>j</b></td><td>1-31</td></tr>
                                    <tr><td><b>S</b></td><td>st, nd, rd, th (use with j)</td></tr>
                                    <tr><td><b>D</b></td><td>Mon-Sun</td></tr>
                                    <tr><td><b>l (small L)</b></td><td>Monday-Sunday</td></tr>
                                    <tr><td><b>F</b></td><td>January-December</td></tr>
                                    <tr><td><b>m</b></td><td>01-12</td></tr>
                                    <tr><td><b>n</b></td><td>1-12</td></tr>
                                    <tr><td><b>M</b></td><td>Jan-Dec</td></tr>
                                    <tr><td><b>Y</b></td><td>1999-2003</td></tr>
                                    <tr><td><b>y</b></td><td>99-03</td></tr>
                                    <tr><td style={{paddingRight:'30px'}}>To add string like <b>of</b></td><td>\of</td></tr>
                                </tbody></table>
                            </div>;
        this.downloadUrl = (<React.Fragment>
                                <span>{Strings.text.label.templateInfo} {'( '}</span>
                                <a href={localStorage.getItem('appUrl')+'uploadImage.php'} target='_blank'>Upload Image</a>
                                <span>{' )'}</span>
                            </React.Fragment>);
    }

    onSuccess = (data) => {
        this.props.closeDemo();
    }

    onFailure = (data) => {

    }

    afterEveryInput = (data) => {
        if(data['index'] == 3){
            var tabsize = parseFloat(data.fields[3].value);
            tabsize  = Math.ceil(tabsize * 48 / 1.27)+'px';
            Object.values(document.getElementsByClassName('editorClass')).forEach(el=>{
                el.style.tabSize = tabsize;
            })
        }
    }

    render(){
        console.log("tabSize", this.props.tabSize);
        return(
            <EditFile
                editPermission = {24}
                ButtonCSS = {''}
                NameCSS = {''}
                editButtonValue = {Strings.text.button.editTaskTemplate}
                editContainerCSS = {''}
                buttonClicked={1}
                url = {'TaskTemplate/editTaskTemplate.php'}
                buttonValue = {Strings.text.button.editTaskTemplate}
                onSuccess = {(data) => this.onSuccess(data)}
                onFailure = {(data) => this.onFailure(data)}
                isTable = {[Strings.text.label.propertyKey, Strings.text.label.propertyFormat, Strings.text.label.propertyName]}
                addProperty = {true}
                tableExtraInfo = {this.tableExtraInfo} 
                afterEveryInput = {(data) => this.afterEveryInput(data)}       
                fields = {[{
                        type: 'hidden',
                        value: this.props.ttid,
                        name: 'ttid',
                        options: {
                                    required: true,
                                },
                    },{
                        type: 'text',
                        value: this.props.name,
                        name: 'name',
                        placeholder: Strings.text.placeholder.taskTemplateName,
                        options: {
                                    required: true,
                                 },
                        label: Strings.text.label.taskTemplateName,
                    },{
                        type: 'select',
                        value: this.props.did,
                        name: 'did',
                        id: 'dictatorList',
                        options: {
                                    required: true,
                                    showLoader: [true, 'black', 'Dictators/getDictatorsListWithCompanyTemplates.php'],
                                    options: [
                                        {value: '', name: '', selected: false},
                                    ]
                                 },
                        label: Strings.text.label.selectDictator,
                    },{
                        type: 'text',
                        value: this.props.tabSize,
                        name: 'tabSize',
                        placeholder: '',
                        options: {
                                    required: true,
                                 },
                        label: 'Tab Size for this template (in cm)',
                    },{
                        type: 'select',
                        value: this.props.thid,
                        name: 'template_header',
                        options: {
                                    required: true,
                                    showLoader: [true, 'black'],
                                    onChange: 'dictatorList',
                                    options: [
                                        {value: '', name: '', selected: true},
                                    ]
                                 },
                        label: Strings.text.label.selectTaskTemplate,
                    },{
                        type: 'editor',
                        value: '',
                        name: 'template_info',
                        options: {
                                    required: true,
                                    tabsize: this.props.tabSize,
                                    showLoader: [true, 'black', 'TaskTemplate/getInfoSnapshot.php?ttid='+this.props.ttid],
                                 },
                        label: this.downloadUrl,
                    },{
                        type: 'editor',
                        value: '',
                        name: 'template_ineditor',
                        options: {
                                    required: false,
                                    tabsize: this.props.tabSize,
                                    showLoader: [true, 'black', 'TaskTemplate/getInEditorSnapshot.php?ttid='+this.props.ttid],
                                 },
                        label: Strings.text.label.templateInEditor,
                    },{
                        type: 'editor',
                        value: '',
                        name: 'template',
                        options: {
                                    required: true,
                                    tabsize: this.props.tabSize,
                                    showLoader: [true, 'black', 'TaskTemplate/getSnapshot.php?ttid='+this.props.ttid],
                                 },
                        label: Strings.text.label.createTemplate,
                    },{
                        type: 'hidden',
                        value: '',
                        name: 'property',
                        id: 'propertyList',
                        options: {
                                    required: false,
                                    showLoader: [true, 'black', 'TaskTemplate/getProperty.php?ttid='+this.props.ttid],
                                    options: [
                                        {value: '', name: '', selected: true},
                                    ]
                                 },
                        label: '',
                    },{
                        type: 'hidden',
                        value: '',
                        name: 'garbage',
                        taskProperty:1,
                        options: {
                                    required: false,
                                    showLoader: [true, 'black'],
                                    onChange: 'propertyList',
                                    options: [
                                        {value: '', name: '', selected: true},
                                    ]
                                 },
                        label: '',
                    }]}
            />
        );
    }
}

export default Edit;


/*
    ttid
    name
    did
   
*/