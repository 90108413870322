import React from 'react';
import CreateFile from 'modules/Form/Create/Create';
import axios from 'axios/axios.js';
import Strings from 'Strings.js';

class Create extends React.Component{

    onSuccess = (data) => {
        console.log("Create Submit Running");
        this.props.snapshotSaved();
    }

    onFailure = (data) => {

    }

    componentDidMount(){
    
    }

    render(){
        return(
            <CreateFile
                createPermission = {[134,135,136,137,138,139,146,147,148,149,150,151]} 
                createContainerCSS = {this.props.containerCss}
                url = {'Tasks/addTaskSnapshot.php'}
                onSuccess = {(data) => this.onSuccess(data)}
                onFailure = {(data) => this.onFailure(data)}
                buttonValue = {Strings.text.button.addTaskSnapshotButton}
                keepFormPopulated = {true}
                buttonTrigger = {(buttonState) => this.props.buttonTrigger(buttonState)}
                autoSave = {this.props.tid+':'+this.props.partNum}
                fields = {[{
                    type: 'hidden',
                    value: this.props.tid,
                    name: 'tid',
                    options: {
                                required: true,
                            },
                },{
                    type: 'hidden',
                    value: this.props.partNum,
                    name: 'part',
                    options: {
                                required: true,
                            },
                },{
                    type: 'editor',
                    value: '',
                    name: 'snapshot',
                    options: {
                                required: true,
                                tabsize: this.props.tabSize,
                                showLoader: [true, 'black', 'Tasks/getSnapshot.php?tid='+this.props.tid+'&part='+this.props.partNum],
                                grading: this.props.grading,
                                tid: this.props.tid,
                                to_be_graded:this.props.to_be_graded,
                                gradingChangeFunc:this.props.gradingChangeFunc
                             },
                    label: null,
                }]}
            />
        );
    }
}

export default Create;