import React from 'react';
import CreateFile from 'modules/Form/Create/Create';
import axios from 'axios/axios.js';
import Strings from 'Strings.js';

class Create extends React.Component{

    onSuccess = (data) => {
        console.log("Create Submit Running");
        this.props.snapshotSaved();
    }

    onFailure = (data) => {

    }

    componentDidMount(){
    
    }

    render(){
        return(
            <CreateFile
                {...this.props}
                createPermission = {[36,37,38,39,40,41,98,99,100,101,102,103]}
                createContainerCSS = {this.props.containerCss}
                url = {'Tasks/addTaskSnapshot.php'}
                onSuccess = {(data) => this.onSuccess(data)}
                onFailure = {(data) => this.onFailure(data)}
                buttonValue = {Strings.text.button.addTaskSnapshotButton}
                keepFormPopulated = {true}
                buttonTrigger = {(buttonState) => this.props.buttonTrigger(buttonState)}
                autoSave = {this.props.tid+':'+this.props.partNum}
                passToInput = {this.props.passToInput}
                ref={node => this.createFileRef = node}
                fields = {[{
                    type: 'hidden',
                    value: this.props.tid,
                    name: 'tid',
                    options: {
                                required: true,
                            },
                },{
                    type: 'hidden',
                    value: this.props.partNum,
                    name: 'part',
                    options: {
                                required: true,
                            },
                },{
                    type: 'editor',
                    value: '',
                    name: 'snapshot',
                    options: {
                                required: true,
                                expand: true,
                                tabsize: this.props.tabSize,
                                extraInfo: [{'Template: ': this.props.temp}, {'Audio File: ': this.props.audioUrl}, {'File Part Number: ':this.props.partNum}],
                                showLoader: [true, 'black', 'Tasks/getSnapshot.php?tid='+this.props.tid+'&part='+this.props.partNum],
                                tid: this.props.tid,
                                audioPlayer: this.props.audioUrl,
                                audioTime: this.props.audioTime,
                                did: this.props.did,
                                grading: this.props.grading,
                                to_be_graded:this.props.to_be_graded,
                                gradingChangeFunc:this.props.gradingChangeFunc
                             },
                    label: null,
                }]}
            />
        );
    }
}

export default Create;