import React from 'react';
import TasksList from 'pages/TasksList/TasksList';
import CheckPermission from 'components/CheckPermission/CheckPermission';
import Classes from './TaskPool.module.css';
import Strings from 'Strings.js';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import runningPage from 'global';
 
class TaskPool extends React.Component{

    constructor(props){
        super(props);
        this.dt = JSON.parse(localStorage.getItem('tab'));

        var makeState = {};
        this.refVar = {};
        var first = 1;
        this.siidpool = {
                            1: [36,37,38,39,40,41],
                            2: [98,99,100,101,102,103], 
                            5: [134,135,136,137,138,139], 
                            6: [146,147,148,149,150,151], 
                            3: [110,111,112,113,114,115], 
                            4: [122,123,124,125,126,127], 
                            7: [158,159,160,161,162,163], 
                            8: [170,171,172,173,174,175]
                        };
        
        this.pooltext = [
                            Strings.text.label.traineePool,
                            Strings.text.label.transcriptorPool,
                            Strings.text.label.proofer1Pool,
                            Strings.text.label.proofer2Pool,
                            Strings.text.label.auditorPool,
                            Strings.text.label.blankCheckPool
                        ];

        this.perm = JSON.parse(localStorage.getItem("per"));

        
        
        Object.keys(this.dt).forEach(el=>{
            
            makeState['tab'+el] = first;
            if(first){
                makeState['active'] = 'tab'+el;
            }
            first = 0;
            var fir = 1;
            this.siidpool[el].forEach((ele, ind)=>{
                if(this.perm.permissions === 'ALL' || Object.values(this.perm.permissions).includes(""+ele)){
                    makeState['pool'+makeState['active']+ind] = fir;
                    if(fir){
                        makeState['active1'] = 'pool' + makeState['active'] + ind;
                    }
                    fir = 0;
                }
            })
        })

        this.state = makeState;
    }

    setTab = (k, ext) => {
        let makeState = {...this.state};
        makeState[k] = 1;

        if(ext === '') {
            makeState['active'] = k;
            var fir = 1;
            this.siidpool[makeState['active'].substring(3)].forEach((ele, ind)=>{
                if(this.perm.permissions === 'ALL' || Object.values(this.perm.permissions).includes(""+ele)){
                    if(fir){
                        makeState['pool' + makeState['active'] + ind] = 1;
                        makeState['active1'] = 'pool' + makeState['active'] + ind;
                    }
                    fir = 0;
                    return;
                }
            })
        }
        else{
            makeState['active1'] = k;
        }
        this.setState(makeState);
    }

    toggleWorkbenchHome = (e) => {
        if(runningPage.page === 'home'){
            if(e.altKey){
                if(e.shiftKey){
                    if (e.keyCode == '1'.charCodeAt(0)) {
                        if('1' in this.dt)
                            this.setTab('tab1', '');
                    } else if (e.keyCode == '2'.charCodeAt(0)) {
                        if('2' in this.dt)
                            this.setTab('tab2', '');
                    } else if (e.keyCode == '3'.charCodeAt(0)) {
                        if('3' in this.dt)
                            this.setTab('tab3', '');
                    } else if (e.keyCode == '4'.charCodeAt(0)) {
                        if('4' in this.dt)
                            this.setTab('tab4', '');
                    } else if (e.keyCode == '5'.charCodeAt(0)) {
                        if('5' in this.dt)
                            this.setTab('tab5', '');
                    } else if (e.keyCode == '6'.charCodeAt(0)) {
                        if('6' in this.dt)
                            this.setTab('tab6', '');
                    } else if (e.keyCode == '7'.charCodeAt(0)) {
                        if('7' in this.dt)
                            this.setTab('tab7', '');
                    } else if (e.keyCode == '8'.charCodeAt(0)) {
                        if('8' in this.dt)
                            this.setTab('tab8', '');
                    }
                }
                else if (e.keyCode == '1'.charCodeAt(0)) {
                    if(this.perm.permissions === 'ALL' || Object.values(this.perm.permissions).includes(this.siidpool[this.state.active.substring(3)][0]+""))
                        this.setTab('pool'+this.state.active+'0', "1");
                } else if (e.keyCode == '2'.charCodeAt(0)) {
                    if(this.perm.permissions === 'ALL' || Object.values(this.perm.permissions).includes(this.siidpool[this.state.active.substring(3)][1]+""))
                        this.setTab('pool'+this.state.active+'1', "1");
                } else if (e.keyCode == '3'.charCodeAt(0)) {
                    if(this.perm.permissions === 'ALL' || Object.values(this.perm.permissions).includes(this.siidpool[this.state.active.substring(3)][2]+""))
                        this.setTab('pool'+this.state.active+'2', "1");
                } else if (e.keyCode == '4'.charCodeAt(0)) {
                    if(this.perm.permissions === 'ALL' || Object.values(this.perm.permissions).includes(this.siidpool[this.state.active.substring(3)][3]+""))
                        this.setTab('pool'+this.state.active+'3', "1");
                } else if (e.keyCode == '5'.charCodeAt(0)) {
                    if(this.perm.permissions === 'ALL' || Object.values(this.perm.permissions).includes(this.siidpool[this.state.active.substring(3)][4]+""))
                        this.setTab('pool'+this.state.active+'4', "1");
                } else if (e.keyCode == '6'.charCodeAt(0)) {
                    if(this.perm.permissions === 'ALL' || Object.values(this.perm.permissions).includes(this.siidpool[this.state.active.substring(3)][5]+""))
                        this.setTab('pool'+this.state.active+'5', "1");
                }
            } else if (e.keyCode == 38) {
                this.refVar[this.state.active1].setActive(-1);
            } else if (e.keyCode == 40) {
                this.refVar[this.state.active1].setActive(1);
            } else if (e.keyCode == 13) {
                this.refVar[this.state.active1].openActive('enter');
            } else if (e.keyCode == 46) {
                this.refVar[this.state.active1].openActive('delete');
            }
        }
    };

    componentDidMount(){
        runningPage.page = 'home';
        document.addEventListener('keydown', this.toggleWorkbenchHome);
    }

    componentWillUnmount(){
        document.removeEventListener('keydown', this.toggleWorkbenchHome);
    }

    render(){

        const tabs = Object.keys(this.dt).map(el=>{
            return (
                <Nav.Item key={'tab'+el}>
                    <Nav.Link eventKey={'tab'+el}>{[this.dt[el], <span style={{
                    fontSize: '10px',
                    display: 'block',
                }}>( Alt+Shift+{el} )</span>]}</Nav.Link>
                </Nav.Item>
                
            );
        })

        

        const tabContent = Object.keys(this.dt).map((el,ind)=>{
            return (
                <Tab.Pane key={'tab'+el} eventKey={'tab'+el}>
                    <Tabs id={"uncontrolled-tab-example"+el} activeKey={this.state.active1} onSelect={k => this.setTab(k, '1')}>
                        {this.siidpool[el].map((ele,pind)=>{
                            if(this.perm.permissions === 'ALL' || Object.values(this.perm.permissions).includes(""+ele)){
                                return(<Tab key={'pool'+'tab'+el+pind} eventKey={'pool'+'tab'+el+pind} title={[this.pooltext[pind], <span style={{
                                    fontSize: '10px',
                                    display: 'block',
                                }}>( Alt+{pind+1} )</span>]}>
                                    <div style={{marginRight:'20px',boxSizing:'border-box'}}>
                                        {this.state['tab'+el]==1 && this.state['pool'+'tab'+el+pind]==1?<TasksList inputRef={ref => this.refVar['pool'+'tab'+el+pind] = ref} poolId={ele} siid={el} />:<div>Loading....</div>}
                                    </div>
                                </Tab>)
                            }
                            return null;
                        })}
                    </Tabs>
                </Tab.Pane>
            );
        })

        return(
            <Tab.Container id="left-tabs-example" activeKey={this.state.active} onSelect={k => this.setTab(k, '')}>
                <Row>
                    <Col sm={3}>
                        <Nav variant="pills" className="flex-column">
                            {tabs}
                        </Nav>
                    </Col>
                    <Col sm={9}>
                        <Tab.Content>
                            {tabContent}
                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>
            
        )
    }
}



export default TaskPool; 