import React, { Component } from 'react';
import { connect } from 'react-redux';	
import {Redirect} from 'react-router-dom';

class CheckLogin extends Component{
    state = {
        isLogin:    this.props.isLogin,
    }
    render(){
        
        if(!(localStorage.getItem("per"))) {
            localStorage.removeItem("awx");
            this.props.changeLogin(0);
        }
        

        return(
            <React.Fragment>
                {this.state.isLogin === false?(<Redirect to="/login" />):(
                    this.props.children
                )}
            </React.Fragment>
        )
    }

    
}

const mapStateToProps = state => {
	return {
        isLogin: state.checkReducer.isLogin,
    }
}

const mapDispatchToProps = dispatch => {
	return {
        changeLogin: (code) => dispatch({type: 'CHANGE_LOGIN', payload:{isLogin: code}}),
	}
}


export default connect(mapStateToProps, mapDispatchToProps)(CheckLogin);