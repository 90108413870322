import React from 'react';
import Strings from 'Strings.js';
import axios from 'axios/axios.js';




class UsersListToAllocateTask extends React.Component{
 
    state = {
        poollist: [],
        userlist: [],
        draggedIds:[],
        draggedItems:[],
        openRows:[],
    }

    

    componentDidMount(){
        if(this.state.poollist.length === 0){
            axios.post('Users/usersListWithAssignedTasks.php?forAType='+this.props.type+'&siid='+this.props.siid, {}).then(response => {
                if(response.data.r === 1)
                {
                    this.setState({
                        ...this.state,
                        poollist: response.data.d.pools,
                        userlist: response.data.d.users,
                    });
                }
            }).catch(error => {
                
            });
        }
    }

    onDrop = (ev, userid, ispool) => {
        ev.preventDefault();
        var data1 = [];
        
        if(ev.dataTransfer.getData("textid") != "")
            data1 = JSON.parse(ev.dataTransfer.getData("textid"));

        var data2 = [];
        if(ev.dataTransfer.getData("textid2") != "")
            data2 = JSON.parse(ev.dataTransfer.getData("textid2"));

        //console.log(data1);
        //console.log(data2);

        
        var tids = [];
        data1.forEach(element => {
            tids.push(element.id);
        });

        data2.forEach(element => {
            tids.push(element.id);
        });

        ev.dataTransfer.setData("textid", "");
        ev.dataTransfer.setData("textid2", "");

        var fd = new FormData();
        fd.append('uid', userid);
        tids.forEach(el=>{
            fd.append('tid[]', el);
        })
        
        fd.append('ispool', ispool);


        axios.post('Tasks/assignMultiTasks.php?forAType='+this.props.type+'&siid='+this.props.siid, fd).then(response => {
            
            this.props.updateLists();
        }).catch(error => {
            this.props.updateLists();
        });
        
      }

      unAllocate = (taskid) => {
        var fd = new FormData();
        fd.append('tid', taskid);
        axios.post('Tasks/unAllocateTasks.php?forAType='+this.props.type+'&siid='+this.props.siid, fd).then(response => {
            
            this.props.updateLists();
        }).catch(error => {
            this.props.updateLists();
        });
      }

    dragStart = (ev) => {
        ev.dataTransfer.setData("textid2", JSON.stringify(this.state.draggedItems));
    }

    selectTask = (e, item) => {
        e.preventDefault();
        if(item.is_opened != 1 && item.is_completed != 1)
        {
            if (e.ctrlKey) {
                var tempstate = [...this.state.draggedIds];
                var dragitems = [...this.state.draggedItems];
                if(tempstate.includes(item.id))
                {
                    var index = tempstate.indexOf(item.id);
                    tempstate.splice(index, 1);
                    dragitems.splice(index, 1);
                }
                else{
                    tempstate.push(item.id);
                    dragitems.push(item);
                }

                this.setState({draggedIds: tempstate, draggedItems: dragitems});
            }
            else{
                this.setState({draggedIds: [item.id], draggedItems: [item]});
            }
        }
    }

    expandButton = (itemid) => {
        const expandbut = [...this.state.openRows];
        if(expandbut.includes(itemid))
        {
            expandbut.splice(expandbut.indexOf(itemid), 1);
        }
        else
            expandbut.push(itemid);

        this.setState({openRows: expandbut});
    }

    allowDrop = (e) => {
        e.preventDefault();
    }

    dragenter = (e) => {
        e.preventDefault();
        e.target.style.border = '2px solid blue';
    }

    dragleave = (e) => {
        e.preventDefault();
        e.target.style.border = 'none';
    }

    markForGrading = (taskid, itemid) => {
        var checkboxele = document.getElementById('gradingbox'+taskid);
        var userOrPoolEle = document.getElementById('gradingCount'+itemid);
        checkboxele.disabled = true;
        var fd = new FormData();
        fd.append('tid', taskid);
        axios.post('Tasks/markTaskForGrading.php', fd).then(response => {
            if(response.data.r == 1)
            {
                if(checkboxele.checked==0)
                    userOrPoolEle.innerHTML = parseInt(userOrPoolEle.innerHTML) + 1;
                else
                    userOrPoolEle.innerHTML = parseInt(userOrPoolEle.innerHTML) - 1;
                checkboxele.checked = !checkboxele.checked;
            }
            checkboxele.disabled = false;
        }).catch(error => {
            checkboxele.disabled = false; 
        });
    }
   

    render(){

        const poolslist = this.state.poollist.map((item, index) => {
            var taskDur = 0;
            var totalTask = 0;
            var totalGrading = 0;
            const taskrows = item.tasks.map((task,ind) => {
                totalTask += 1;
                if(task.tobegraded == 1)
                    totalGrading += 1;
                taskDur += parseInt(task.duration);
                var dura = Math.floor(parseInt(task.duration) % 60);

                var tatinmins = Math.floor((parseInt(task.tat) - (Date.now() / 1000)) / 60);

                var minu = Math.abs(Math.floor(tatinmins % 60));
                var durhours = Math.floor(task.duration / 60);
                var tlhours = Math.floor(tatinmins / 60);
                return (
                    <tr onClick={(e) => {this.selectTask(e, task)}} style={this.state.draggedIds.includes(task.id)?{background:'yellow'}:(task.is_completed==1?{background:'green', color:'white'}:(task.is_opened==1?{background:'red', color:'white'}:{}))} key={ind} id={'task:'+task.id} draggable={task.is_opened==1 || task.is_completed==1?"false":"true"} onDragStart={task.is_opened==1 || task.is_completed==1?null:this.dragStart} >
                        <td style={{padding:'0 10px'}}>{task.name}</td>
                        {/*<td style={{padding:'0 10px'}}>{task.tat}</td>*/}
                        <td style={{padding:'0 10px'}}>{tlhours}{':'}{minu > 9?minu:'0'+minu}{':00'}</td>
                        <td style={{padding:'0 10px'}}>{durhours > 9?durhours:'0'+durhours}{':'}{dura > 9?dura:'0'+dura}</td>
                        <td style={{padding:'0 10px'}}>{this.props.type==2?<input type="checkbox" id={"gradingbox"+task.id} defaultChecked={task.tobegraded==1?'true':null} onChange={() => this.markForGrading(task.id, item.id)} />:null}</td>
                        <td style={{padding:'0 10px'}}>{task.is_completed==1 || task.is_opened==1?null:<button style={{padding:'5px 2px 8px',lineHeight:'0px'}} onClick={() => this.unAllocate(task.id)}>{'x'}</button>}</td>
                    </tr>
                )
            });
            var hours = Math.floor(taskDur / 60);
            var mins = Math.floor(taskDur % 60);
            return (
                <React.Fragment>
                    <tr onDrop={(e) => this.onDrop(e, item.id, 1)} onDragOver={this.allowDrop} onDragEnter={this.dragenter} onDragLeave={this.dragleave}>
                        <td style={{padding:'0 10px'}}>{item.tasks.length > 0?(<button style={{padding:'5px 2px 8px',lineHeight:'0px'}} onClick={() => this.expandButton(item.id)}>{this.state.openRows.includes(item.id)?'-':'+'}</button>):null}</td>
                        <td style={{padding:'0 10px'}}>{item.id}</td>
                        <td style={{padding:'0 10px'}}>{item.name}</td>
                        <td style={{padding:'0 10px'}}>{hours > 9?hours:'0'+hours}{':'}{mins > 9?mins:'0'+mins}</td>
                        <td style={{padding:'0 10px'}}>{totalTask}</td>
                        {this.props.type==2?<td style={{padding:'0 10px'}} id={'gradingCount'+item.id}>{totalGrading}</td>:null}
                    </tr>
                    <tr onDrop={(e) => this.onDrop(e, item.id, 1)} onDragOver={this.allowDrop} onDragEnter={this.dragenter} onDragLeave={this.dragleave} style={this.state.openRows.includes(item.id)?{background:'#f5f5f5'}:{display:'none'}}>
                        <td colSpan={3} style={{paddingLeft:'40px'}}>
                            <table id={'user:'+item.id}>
                                {taskrows}
                            </table>
                        </td>
                    </tr>
                </React.Fragment>
                
            );
        });





        const userslist = this.state.userlist.map((item, index) => {
            var taskDur = 0;
            var totalTask = 0;
            var totalGrading = 0;
            const taskrows = item.tasks.map((task,ind) => {
                totalTask += 1;
                if(task.tobegraded == 1)
                    totalGrading += 1;
                taskDur += parseInt(task.duration);
                var dura = Math.floor(parseInt(task.duration) % 60);

                var tatinmins = Math.floor((parseInt(task.tat) - (Date.now() / 1000)) / 60);

                var minu = Math.abs(Math.floor(tatinmins % 60));
                var durhours = Math.floor(task.duration / 60);
                var tlhours = Math.floor(tatinmins / 60);
                return (
                    <tr onClick={(e) => {this.selectTask(e, task)}} style={this.state.draggedIds.includes(task.id)?{background:'yellow'}:(task.is_completed==1?{background:'green', color:'white'}:(task.is_opened==1?{background:'red', color:'white'}:{}))} key={ind} id={'task:'+task.id} draggable={task.is_opened==1 || task.is_completed==1?"false":"true"} onDragStart={task.is_opened==1 || task.is_completed==1?null:this.dragStart} >
                        <td style={{padding:'0 10px'}}>{task.name}</td>
                        {/*<td style={{padding:'0 10px'}}>{task.tat}</td>*/}
                        <td style={{padding:'0 10px'}}>{tlhours}{':'}{minu > 9?minu:'0'+minu}{':00'}</td>
                        <td style={{padding:'0 10px'}}>{durhours > 9?durhours:'0'+durhours}{':'}{dura > 9?dura:'0'+dura}</td>
                        <td style={{padding:'0 10px'}}>{this.props.type==2?<input type="checkbox" id={"gradingbox"+task.id} defaultChecked={task.tobegraded==1?'true':null} onChange={() => this.markForGrading(task.id, item.id)} />:null}</td>
                        <td style={{padding:'0 10px'}}>{task.is_completed==1 || task.is_opened==1?null:<button style={{padding:'5px 2px 8px',lineHeight:'0px'}} onClick={() => this.unAllocate(task.id)}>{'x'}</button>}</td>
                    </tr>
                )
            });
            var hours = Math.floor(taskDur / 60);
            var mins = Math.floor(taskDur % 60);
            return (
                <React.Fragment>
                    <tr onDrop={(e) => this.onDrop(e, item.id, 0)} onDragOver={this.allowDrop} onDragEnter={this.dragenter} onDragLeave={this.dragleave}>
                        <td style={{padding:'0 10px'}}>{item.tasks.length > 0?(<button style={{padding:'5px 2px 8px',lineHeight:'0px'}} onClick={() => this.expandButton(item.id)}>{this.state.openRows.includes(item.id)?'-':'+'}</button>):null}</td>
                        <td style={{padding:'0 10px'}}>{item.id}</td>
                        <td style={{padding:'0 10px'}}>{item.name}</td>
                        <td style={{padding:'0 10px'}}>{hours > 9?hours:'0'+hours}{':'}{mins > 9?mins:'0'+mins}</td>
                        <td style={{padding:'0 10px'}}>{totalTask}</td>
                        {this.props.type==2?<td style={{padding:'0 10px'}} id={'gradingCount'+item.id}>{totalGrading}</td>:null}
                    </tr>
                    <tr onDrop={(e) => this.onDrop(e, item.id, 0)} onDragOver={this.allowDrop} onDragEnter={this.dragenter} onDragLeave={this.dragleave} style={this.state.openRows.includes(item.id)?{background:'#f5f5f5'}:{display:'none'}}>
                        <td colSpan={3} style={{paddingLeft:'40px'}}>
                            <table id={'user:'+item.id}>
                                {taskrows}
                            </table>
                        </td>
                    </tr>
                </React.Fragment>
                
            );
        });
        return(
            <div>
                <table>
                    <thead>
                        <tr style={{background:'#eee'}}>
                            <th style={{padding:'0 10px'}}></th>
                            <th style={{padding:'0 10px'}}>{Strings.text.label.poolId}</th>
                            <th style={{padding:'0 10px'}}>{Strings.text.label.poolName}</th>
                            <th style={{padding:'0 10px'}}>{Strings.text.label.allottedTime}</th>
                            <th style={{padding:'0 10px'}}>{Strings.text.label.totalFiles}</th>
                            {this.props.type==2?<th style={{padding:'0 10px'}}>{'Marked For Grading'}</th>:null}
                        </tr>
                    </thead>
                    <tbody>
                        {poolslist}
                    </tbody>
                </table>
                    
                <table>
                    <thead>
                        <tr style={{background:'#eee'}}>
                            <th style={{padding:'0 10px'}}></th>
                            <th style={{padding:'0 10px'}}>{Strings.text.label.userId}</th>
                            <th style={{padding:'0 10px'}}>{Strings.text.label.userName}</th>
                            <th style={{padding:'0 10px'}}>{Strings.text.label.allottedTime}</th>
                            <th style={{padding:'0 10px'}}>{Strings.text.label.totalFiles}</th>
                            {this.props.type==2?<th style={{padding:'0 10px'}}>{'Marked For Grading'}</th>:null}
                        </tr>
                    </thead>
                    <tbody>
                        {userslist}
                    </tbody>
                </table>
            </div>
        )
    }
}



export default UsersListToAllocateTask; 