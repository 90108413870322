import React from 'react';
import TasksList from 'pages/TasksList/TasksList';
import CheckPermission from 'components/CheckPermission/CheckPermission';
import Classes from './OCRTaskPool.module.css';
import Strings from 'Strings.js';
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
 
class OCRTaskPool extends React.Component{

    render(){
        return(
            <div>
                <Tabs id="uncontrolled-tab-example">
                    
                        <Tab eventKey="traineePool" title={Strings.text.label.traineePool}>
                        <CheckPermission perm={36}>
                            <div style={{marginRight:'20px',boxSizing:'border-box'}}>
                                <TasksList poolId={36} />
                            </div>
                            </CheckPermission>
                        </Tab>
                    
                    
                        <Tab eventKey="transcriptorPool" title={Strings.text.label.transcriptorPool}>
                        <CheckPermission perm={37}>
                            <div style={{marginRight:'20px',boxSizing:'border-box'}}>
                                <TasksList poolId={37} />
                            </div>
                            </CheckPermission>
                        </Tab>
                    
                    
                        <Tab eventKey="proofer1Pool" title={Strings.text.label.proofer1Pool}>
                        <CheckPermission perm={38}>
                            <div style={{marginRight:'20px',boxSizing:'border-box'}}>
                                <TasksList poolId={38} />
                            </div>
                            </CheckPermission>
                        </Tab>
                    
                    
                        <Tab eventKey="proofer2Pool" title={Strings.text.label.proofer2Pool}>
                        <CheckPermission perm={39}>
                            <div style={{marginRight:'20px',boxSizing:'border-box'}}>
                                <TasksList poolId={39} />
                            </div>
                            </CheckPermission>
                        </Tab>
                    
                   
                        <Tab eventKey="auditorPool" title={Strings.text.label.auditorPool}>
                        <CheckPermission perm={40}>
                            <div style={{marginRight:'20px',boxSizing:'border-box'}}>
                                <TasksList poolId={40} />
                            </div>
                            </CheckPermission>
                        </Tab>
                    
                    
                        <Tab eventKey="blankCheckPool" title={Strings.text.label.blankCheckPool}>
                        <CheckPermission perm={41}>
                            <div style={{marginRight:'20px',boxSizing:'border-box'}}>
                                <TasksList poolId={41} />
                            </div>
                            </CheckPermission>
                        </Tab>
                    
                </Tabs>
            </div>
        )
    }
}



export default OCRTaskPool; 