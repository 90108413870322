import React from 'react';
import EditFile from 'modules/Form/Edit/Edit';
import Strings from 'Strings.js';

class Edit extends React.Component{

    onSuccess = (data) => {
        this.props.refresh();
    }

    onFailure = (data) => {

    }

    render(){
        return(
            <EditFile
                editPermission = {76}
                ButtonCSS = {''}
                NameCSS = {''}
                editButtonValue = {Strings.text.button.editTaskTAT}
                editContainerCSS = {''}
                url = {'Tasks/assignTATToTask.php'}
                buttonValue = {Strings.text.button.editTaskTAT}
                onSuccess = {(data) => this.onSuccess(data)}
                onFailure = {(data) => this.onFailure(data)}
                fields = {[
                    {
                        type: 'hidden',
                        value: this.props.tid,
                        name: 'tid',
                        options: {
                                    required: true,
                                },
                    },{
                        type: 'text',
                        value: this.props.tat,
                        name: 'tat',
                        placeholder: Strings.text.placeholder.tat,
                        options: {
                                    required: true,
                                 },
                        label: Strings.text.label.tat,
                    }
                ]}
            />
        );
    }
}

export default Edit;


/*
    tid
    tat
   
*/