import React from 'react';
import List from 'modules/List/List';
import Strings from 'Strings.js';

class Single extends React.Component{

    render(){
        return(
            <React.Fragment>
                <td>{this.props.name}</td>
                <td>{this.props.contactPerson}</td>
                <td>{this.props.industry}</td>
                <td>{Object.values(this.props.industrySubcat).join(', ')}</td>
                <td>{this.props.instituteDictatorsCount}</td>
                <td>{this.props.processedTaskCount}</td>
                <td>{this.props.pendingTaskCount}</td>
                <td>{this.props.isDeleted}</td>
            </React.Fragment>
        );
    }
}

class _List extends React.Component{

    render(){
        return(
            <List 
                listPermission={47}
                onListClick={(item) => this.props.onListClick(item)}
                list={this.props.list}
                activeClass={'listactive'}
                listIdentifierForAutoClick={'institutes'}
                headings={{instituteName:'name',
                            contactPerson:'contactPerson',
                            associatedIndustry:'industry',
                            industrySubcatName:'industrySubcat',
                            instituteTotalDictators:'instituteDictatorsCount',
                            totalTaskProcessed:'processedTaskCount',
                            totalTaskPending:'pendingTaskCount',
                            instituteDeleted:'isDeleted',
                        }}
                singleItem={(item) => (<Single name={item.name} 
                    contactPerson={item.contactPerson} industry={item.industry} industrySubcat={item.industrySubcat} 
                    instituteDictatorsCount={item.instituteDictatorsCount} processedTaskCount={item.processedTaskCount} 
                    pendingTaskCount={item.pendingTaskCount} isDeleted={item.isDeleted} />)}
                listContainerCSS={this.props.listContainerCSS}
            />
        );
    }
}

export {Single as InstituteListSingle, _List as InstituteListList};

/*
    for FileSingle -
        name, contactPerson, industry, industrySubcat, processedTaskCount, pendingTaskCount, instituteDictatorsCount, isDeleted

    for FileList -
        onListClick, list, 
*/