import React from 'react';
import CreateTaskTemp from 'modules/Tasks/Template/Create';
import CreateTaskTempDemo from 'modules/Tasks/Template/Demo/Create';
import Classes from './CreateTaskTemplate.module.css';

class CreateTaskTemplate extends React.Component{
    render(){
        return(
            <div className={Classes.container}>
                <div className={Classes.innerContainer}>
                    <CreateTaskTemp /> 
                    
                </div>
                
            </div>
            )
    }
}

export default CreateTaskTemplate;