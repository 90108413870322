import { RichUtils, KeyBindingUtil } from 'draft-js';

const {hasCommandModifier} = KeyBindingUtil;

export default () => {
    return {
        customStyleMap: {
            'UPPERCASE': {
                textTransform: 'uppercase',
            },
        },
        keyBindingFn: e => {
            if (e.keyCode === 52 && e.altKey) {
                //4
                return "uppercase";
            }
        },
        handleKeyCommand: (command, editorState, eventTimeStamp, { setEditorState }) => {
            if (command === "uppercase") {
                setEditorState(RichUtils.toggleInlineStyle(editorState, "UPPERCASE"));
                return true;
            }
        }
    };
  };