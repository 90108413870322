import React from 'react';
import List from 'modules/List/List';
import Form from 'components/Form/Form';
import axios from 'axios/axios';
import Strings from 'Strings.js';

class Single extends React.Component{

    onSuccess = () => {
        var fd = new FormData();
        fd.append('doc', this.props.templateHeader);
        axios.post('downloadHeaderFile.php', fd, {responseType: 'arraybuffer'}).then(response => {
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(new Blob([response.data], {type: 'application/octet-stream'}));
            link.download = this.props.templateHeader;

            document.body.appendChild(link);

            link.click();
            setTimeout(function() {
                window.URL.revokeObjectURL(link);
            }, 200);
        }).catch(error => {})
    }

    onFailure = (data) => {

    }

    render(){
        return(
            <React.Fragment>
                <td>{this.props.templateHeader}</td>
                <td><Form   
                    onSubmit={this.onSuccess}
                    buttonValue={Strings.text.button.testTemplate}
                    buttonType='primary'
                    enableButton='false'
                    fields={[]} />
                </td>
            </React.Fragment>
        );
    }
}

class _List extends React.Component{

    render(){
        return(
            <List 
                listPermission={176}
                list={this.props.list}
                headings={{templateHeader:'header'}}
                singleItem={(item) => (<Single templateHeader={item.header} />)}
                listContainerCSS={this.props.listContainerCSS}
            />
        );
    }
}

export {Single as DictatorTemplateSingle, _List as DictatorTemplateListList};

/*
    for FileSingle -
        name, associatedDictator, createdBy

    for FileList -
        onListClick, list, 
*/