import { RichUtils, KeyBindingUtil } from 'draft-js';

const {hasCommandModifier} = KeyBindingUtil;

export default () => {
     return {
        customStyleMap: {
            'FONTFAMILY-DEFAULT': {
                fontFamily: 'inherit'
            },
            'FONTFAMILY-TIMES NEW ROMAN': {fontFamily: 'Times New Roman'},
            'FONTFAMILY-ARIAL': {fontFamily: 'Arial'},
            'FONTFAMILY-ARIAL ROUNDED MT BOLD': {fontFamily: 'Arial Rounded MT'},
            'FONTFAMILY-ARIAL UNICODE MS': {fontFamily: 'Arial Unicode MS'},
            'FONTFAMILY-BAHNSCHRIFT': {fontFamily: 'Bahnschrift'},
            'FONTFAMILY-BAHNSCHRIFT SEMIBOLD': {fontFamily: 'Bahnschrift Semibold'},
            'FONTFAMILY-BOOK ANTIQUA': {fontFamily: 'Book Antiqua'},
            'FONTFAMILY-BOOKMAN OLD STYLE': {fontFamily: 'Bookman Old Style'},
            'FONTFAMILY-CALIBRI': {fontFamily: 'Calibri'},
            'FONTFAMILY-CALIBRI LIGHT': {fontFamily: 'Calibri Light'},
            'FONTFAMILY-CAMBRIA': {fontFamily: 'Cambria'},
            'FONTFAMILY-CENTURY GOTHIC': {fontFamily: 'Century Gothic'},
            'FONTFAMILY-COURIER NEW': {fontFamily: 'Courier New'},
            'FONTFAMILY-DEJAVU SERIF': {fontFamily: 'DejaVu Serif'},
            'FONTFAMILY-FRANKLIN GOTHIC MEDIUM': {fontFamily: 'Franklin Gothic Medium'},
            'FONTFAMILY-FREESTYLE SCRIPT': {fontFamily: 'Freestyle Script'},
            'FONTFAMILY-GARAMOND': {fontFamily: 'Garamond'},
            'FONTFAMILY-IMPACT': {fontFamily: 'Impact'},
            'FONTFAMILY-HELVETICA': {fontFamily: 'Helvetica'},
            'FONTFAMILY-MICROSOFT SANS SERIF': {fontFamily: 'Microsoft Sans Serif'},
            'FONTFAMILY-PALATINO LINOTYPE': {fontFamily: 'Palatino Linotype'},
            'FONTFAMILY-SANS-SERIF': {fontFamily: 'Sans-Serif'},
            'FONTFAMILY-TAHOMA': {fontFamily: 'Tahoma'},
            'FONTFAMILY-TREBUCHET MS': {fontFamily: 'Trebuchet MS'},
            'FONTFAMILY-VERDANA': {fontFamily: 'Verdana'},
            'FONTFAMILY-ARIALMT': {fontFamily: 'ArialMT'},
            'FONTFAMILY-GENEVA': {fontFamily: 'Geneva'},
        },
        handleKeyCommand: (command, editorState, eventTimeStamp, { setEditorState }) => {
            console.log('command', command);
            if(command){
                if (command.indexOf("fontfamily-") > -1) {
                    setEditorState(RichUtils.toggleInlineStyle(editorState, command.toUpperCase()));
                    return true;
                }
            }
        }
    };
  };