import React from 'react';
import CreateUsers from 'modules/Users/Create';
import CreateVendors from 'modules/Vendors/Create';
import CreateDictators from 'modules/Dictators/Create';
import CreateInstitutes from 'modules/Institutes/Create';
import Classes from './CreateNewUsers.module.css';

class CreateNewUsers extends React.Component{
    render(){
        
        return(
            <div className={Classes.container}>
                <div className={Classes.innerContainer}>
                    <CreateUsers />
                </div>
                <div className={Classes.innerContainer}>
                    <CreateVendors />
                </div>
                <div className={Classes.innerContainer}>
                    <CreateInstitutes />
                </div>
            </div>
        )
    }
}

export default CreateNewUsers;