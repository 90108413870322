import { RichUtils, KeyBindingUtil } from 'draft-js';

const {hasCommandModifier} = KeyBindingUtil;

export default () => {
    return {
        customStyleMap: {
            'STRIKETHROUGH': {
                textDecoration: 'line-through'
            }
        },
        keyBindingFn: e => {
            if (e.keyCode===68 && hasCommandModifier(e)) {
                console.log("ctrl + d");
                //if(e.which === 68)
                    return "strikethrough";
            }
        },
        handleKeyCommand: (command, editorState, eventTimeStamp, { setEditorState }) => {
            if (command === "strikethrough") {
                setEditorState(RichUtils.toggleInlineStyle(editorState, "STRIKETHROUGH"));
                return true;
            }
        }
    };
  };