import React from 'react';
import List from 'modules/List/List';
import Strings from 'Strings.js';

class Single extends React.Component{

    render(){
        var serInd = this.props.serviceIndustry.map(item => {
            return (
                <React.Fragment key={item}>{item}{<br />}</React.Fragment>
            )
        });
        return(
            <React.Fragment>
                <td>{this.props.companyName}</td>
                <td>{this.props.contactPerson}</td>
                <td>{serInd}</td>
                <td>{this.props.vendorAdmin}</td>
                <td>{this.props.vendorUsersCount}</td>
                <td>{this.props.isApproved}</td>
                {/*<td>{this.props.isDeleted}</td>*/}
            </React.Fragment>
        );
    }
}

class _List extends React.Component{

    render(){
        return(
            <List 
                listPermission={45}
                onListClick={(item) => this.props.onListClick(item)}
                activeClass={'listactive'}
                listIdentifierForAutoClick={'vendors'}
                list={this.props.list}
                headings={{companyName:'companyName',
                            contactPerson:'contactPerson',
                            associatedServiceIndustry: 'serviceIndustry',
                            vendorAdmin: 'vendorAdmin',
                            vendorTotalUsers: 'vendorUsersCount',
                            vendorApproved: 'isApproved',
                        //    vendorDeleted: 'isDeleted'
                        }}
                singleItem={(item) => (<Single companyName={item.companyName} contactPerson={item.contactPerson} 
                    serviceIndustry={item.serviceIndustry} vendorAdmin={item.vendorAdmin} 
                    vendorUsersCount={item.vendorUsersCount} isApproved={item.isApproved} 
                    isDeleted={item.isDeleted} />)}
                listContainerCSS={this.props.listContainerCSS}
            />
        );
    }
}


export {Single as VendorListSingle, _List as VendorListList};

/*
    for FileSingle -
        companyName, contactPerson, serviceIndustry, vendorAdmin, vendorUsersCount, isApproved, isDeleted

    for FileList -
        onListClick, list, 
*/