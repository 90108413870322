import React from 'react'
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'
import Classes from './SortFilter.module.css';
import Strings from 'Strings.js';
import axios from 'axios/axios.js';
import Form from 'react-bootstrap/Form'
import Badge from 'react-bootstrap/Badge'

class SortFilter extends React.Component{
    constructor(props){
        super(props);
        this.tog = 1;
        this.state = {
            searchField: '',
            filterHtml: [],
            multiTags:{},

        }
        this.sortArr = [];
    }

    openFilterPane = () => {
        var filterBar = document.getElementById("filterBar");
        var sidebar = document.getElementById("sidebar");
        if(this.tog){
            this.tog = 0;
            filterBar.style.overflowY = "hidden";
            filterBar.style.width = "0";
            filterBar.style.left = sidebar.style.width;
            document.getElementById("filterBarRight").style.marginLeft= "0";
        }
        else{
            this.tog = 1;
            filterBar.style.width = "400px";
            filterBar.style.left = sidebar.style.width;
            document.getElementById("filterBarRight").style.marginLeft= "400px";
            filterBar.style.overflowY = "auto";
        }
    }

    componentDidMount(){
        this.openFilterPane();
        axios.post('filterHtml.php?for='+this.props.filterProps, {}).then(response => {
            if(response.data.r === 1)
            {
                this.setState({
                    filterHtml: response.data.d,
                });
            }
        }).catch(error => {});
    }

    searchField = (e) => {
        e.preventDefault();
        this.setState({searchField: e.target.value});
    }


    dragStart = (e) => {
        e.dataTransfer.setData("sortdata", e.target.id);
    }

    onSortDrop = (ev) => {
        ev.preventDefault();
        var data = ev.dataTransfer.getData("sortdata");
        ev.target.parentNode.parentNode.insertBefore(document.getElementById(data), ev.target.parentNode);
    }

    addMultiInput = (e, inputName) => {
        e.preventDefault();
        var inputCont = document.getElementsByName(inputName)[0];
        if(inputCont.value.trim().length > 0){
            var newFormName = [];
            if(inputName in this.state.multiTags)
                newFormName = [...this.state.multiTags[inputName]];
            newFormName.push(inputCont.value.trim());

            this.setState({multiTags: {...this.state.multiTags, [inputName]:newFormName}}, ()=>{
                inputCont.value = '';
            });
        }
    }

    deleteBadge = (e, ind, formNm) => {
        var newFormName = [...this.state.multiTags[formNm]];
        newFormName.splice(ind, 1);
        this.setState({multiTags: {...this.state.multiTags, [formNm]:newFormName}});
    }

    makeFilterForm = () => {
        var filterForm = null;
        filterForm = this.state.filterHtml.map((item, index) => {
            const inputFields = item.fields.map((ite, inde) => {
                if(ite.type === 'MULTI_SELECT'){
                    var formName = null;
                    var defaultVals = [];
                    var options = ite.options.map((it, ind)=>{
                        formName = it.name;
                        if(it.selected)
                            defaultVals.push(it.value);
                        return (
                            <option key={ind} value={it.value}>{it.text}</option>
                        );
                    });
                    return (
                        <td key={inde} valign={'top'}>
                        <Form.Control as="select" multiple className={'multiSelect'} name={formName} defaultValue={defaultVals}>
                            {options} 
                        </Form.Control>
                        </td>
                    );
                }
                else if(ite.type === 'SINGLE_SELECT'){
                    var formName = null;
                    var defaultVals = null;
                    var options = ite.options.map((it, ind)=>{
                        formName = it.name;
                        if(it.selected)
                            defaultVals = it.value;
                        return (
                            <option key={ind} value={it.value}>{it.text}</option>
                        );
                    });
                    return (
                        <td key={inde} valign={'top'}>
                        <Form.Control key={inde} as="select" className={'singleSelect'} name={formName} defaultValue={defaultVals}>
                            {options} 
                        </Form.Control>
                        </td>
                    );
                }
                else if(ite.type === 'INPUT_NUMBER'){
                    var options = ite.options.map((it, ind)=>{
                        return (
                            <React.Fragment key={ind}>
                                <Form.Control type="number" className={'inputField'} name={it.name} defaultValue={it.value} />
                                {it.text}
                            </React.Fragment>
                        );
                    });
                    return (
                        <td key={inde} valign={'top'}>
                        <React.Fragment key={inde}>
                            {options}
                        </React.Fragment>
                        </td>
                    );
                }
                else if(ite.type === 'CHECKBOX'){
                    var options = ite.options.map((it, ind)=>{
                        return (
                            <Form.Check key={ind} inline type="checkbox" className={'checkBox'} label={it.text} name={it.name} value={it.value} defaultChecked={it.selected?true:null} />
                        );
                    });
                    return (
                        <td key={inde} valign={'top'}>
                        <React.Fragment key={inde}>
                            {options}
                        </React.Fragment>
                        </td>
                    );
                }
                else if(ite.type === 'INPUT_TEXT_MULTI'){
                    var formName = null;
                    var options = ite.options.map((it, ind)=>{
                        formName =  it.name;
                        return (
                            <React.Fragment key={ind}>
                                <Form.Control type="text" name={it.name} className={'multiInputBox'} defaultValue={it.value} style={{width:'150px',display:'inline-block',marginRight:'15px'}} />
                                <button onClick={(e) => this.addMultiInput(e, it.name)}>{it.text}</button>
                            </React.Fragment>
                        );
                    });

                    var multiTags = null;
                    if(formName in this.state.multiTags){
                        multiTags = this.state.multiTags[formName].map((it,ind) => {
                            return (
                                <Badge key={ind} variant="primary" style={{marginRight:'10px'}}>{it} <span onClick={(e) => this.deleteBadge(e, ind, formName)} style={{cursor:'pointer'}}>X</span></Badge>
                            );
                        });
                    }
                    
                    return (
                        <React.Fragment key={inde}>
                        <td key={inde} valign={'top'}>
                            {options}
                            <div>{multiTags}</div>
                        </td>
                        </React.Fragment>
                    );
                }
            });
            return (
                <Form.Group key={item.name+index}>
                    <Form.Label style={{fontWeight:'500'}}>{item.name}</Form.Label>
                    <table style={{width:'100%'}}>
                        <tbody>
                            <tr>
                                {inputFields}
                            </tr>
                        </tbody>
                    </table>
                </Form.Group>
            );
        });

        return filterForm;
        
    }

    sortFormSubmit = (e) => {
        e.preventDefault();
        var sortFilterArr = {'search':'',sort:[],filter:[]};
        sortFilterArr['search'] = this.state.searchField;
        var filterBarId = document.getElementById('filterBar');
        var sortContainer = filterBarId.getElementsByClassName('sortContainer')[0];
        var sortInputs = sortContainer.getElementsByTagName('input');
        for(var i = 0; i < sortInputs.length; i++)
        {
            if(sortInputs[i].value != 0 && sortInputs[i].checked){
                sortFilterArr['sort'].push({[sortInputs[i].name] : sortInputs[i].value});
            }
        }

        var filterContainer = filterBarId.getElementsByClassName('filterContainer')[0];
        
        var filterMultiSelect = filterContainer.getElementsByClassName('multiSelect');
        var filterSingleSelect = filterContainer.getElementsByClassName('singleSelect');
        var filterInputField = filterContainer.getElementsByClassName('inputField');
        var filterCheckBox = filterContainer.getElementsByClassName('checkBox');
        var filterMultiInputContainer = filterContainer.getElementsByClassName('multiInputBox');

        for(var i = 0; i < filterMultiSelect.length; i++)
        {
            var mutliSelArr = [];
            var opts = filterMultiSelect[i].getElementsByTagName('option');
            for(var j=0; j<opts.length; j++){
                if(opts[j].selected)
                    mutliSelArr.push(opts[j].value);
            }


            if(mutliSelArr.length > 0){
                sortFilterArr['filter'].push({[filterMultiSelect[i].name] : mutliSelArr});
            }
        }

        for(var i = 0; i < filterSingleSelect.length; i++)
        {
            if(filterSingleSelect[i].value != ''){
                sortFilterArr['filter'].push({[filterSingleSelect[i].name] : filterSingleSelect[i].value});
            }
        }

        for(var i = 0; i < filterInputField.length; i++)
        {
            if(filterInputField[i].value != ''){
                sortFilterArr['filter'].push({[filterInputField[i].name] : filterInputField[i].value});
            }
        }

        for(var i = 0; i < filterCheckBox.length; i++)
        {
            var chboxinput = filterCheckBox[i].getElementsByTagName('input');
            if(chboxinput[0].checked){
                sortFilterArr['filter'].push({[chboxinput[0].name] : chboxinput[0].value});
            }
        }

        for(var i = 0; i < filterMultiInputContainer.length; i++)
        {
            if(filterMultiInputContainer[i].name in this.state.multiTags){
                sortFilterArr['filter'].push({[filterMultiInputContainer[i].name] : this.state.multiTags[filterMultiInputContainer[i].name]});
            }
        }
        
        const formData = new FormData();
        this.buildFormData(formData, sortFilterArr);

        this.props.onFilterSubmit([sortFilterArr, formData]);
    }

    buildFormData = (formData, data, parentKey) => {
        if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
          Object.keys(data).forEach(key => {
            this.buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
          });
        } else {
          const value = data == null ? '' : data;
      
          formData.append(parentKey, value);
        }
    }

    render(){

        var headingsVar = null;
        const makeSortTable = this.props.headings.map((ele, ind) => {
            return(
                <tr id={ele+ind} key={ind} draggable={true} onDragStart={this.dragStart}  onDrop={this.onSortDrop} onDragOver={(e)=>{e.preventDefault()}}>
                    <td>{Strings.text.label[ele]}</td>
                    <td><input type="radio" name={ele} value="1"  /></td>
                    <td><input type="radio" name={ele} value="2"  /></td>
                    <td><input type="radio" name={ele} value="0" defaultChecked /></td>
                </tr>
            );
        });

        if(makeSortTable.length > 0){
            headingsVar = <table style={{width:'100%'}}>
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>Asc</th>
                                        <th>Desc</th>
                                        <th>None</th>
                                    </tr>
                                </thead>
                                <tbody className={'sortContainer'}>
                                    {makeSortTable}
                                </tbody>
                        </table>
        }

        return(
            <div>
                <div id={'filterBar'} className={Classes.filterBar}>
                    <div style={{padding:'16px'}}>
                        <form onSubmit={this.sortFormSubmit}>
                            <div style={{marginBottom:'10px'}}>
                                <input onChange={this.searchField} value={this.state.searchField} style={{width:'100%'}} placeholder={Strings.text.label.searchLabel} type="text" />
                            </div>
                            <Accordion defaultActiveKey="0">
                                <Card>
                                    <Accordion.Toggle as={Card.Header} eventKey="0">
                                        {Strings.text.label.sortLabel}
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="0">
                                        <Card.Body>{headingsVar}</Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card> 
                                    <Accordion.Toggle as={Card.Header} eventKey="1">
                                        {Strings.text.label.filterLabel}
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="1">
                                        <Card.Body className={'filterContainer'}>{this.makeFilterForm()}</Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </Accordion>
                            <div style={{marginTop:'10px'}}>
                                <button type={'submit'}>{Strings.text.button.searchLabel}</button>
                            </div>
                        </form>
                    </div>
                </div>
                <div id={'filterBarRight'} className={Classes.filterBarRight}>
                    <div style={{marginBottom:'10px'}}>
                        <span role="img" aria-label="settings" onClick={this.openFilterPane} style={{cursor:'pointer'}}>⚙️</span>
                    </div>
                    {this.props.children}
                </div>
            </div>
        )
    }
}

export default SortFilter;