import React from 'react';
import CreateSubcategory from 'modules/Industry/Subcategory/Create';
import axios from 'axios/axios';
import Classes from './CreateIndustrySubcategory.module.css';

class CreateIndustrySubcategory extends React.Component{
    state = {
        refreshList: 1,
        list: {},
    }

    refreshList = () => {
        this.setState({
            refreshList: 0,
        }, () => {
            this.callFromServer();
        });
    }

    callFromServer = () => {
        axios.get('Industry/subcategoryList.php').then(response => {
            if(response.data.r === 1)
            {
                this.setState({
                    list: response.data.d.industrySubcatList,
                    refreshList: 1,
                });
            }
        }).catch(error => { });
     }

    componentDidMount(){
        if(Object.keys(this.state.list).length === 0){
            this.callFromServer();
        }
    }

    render(){
        var industries = [], subcategories = [];
        Object.keys(this.state.list).forEach(ele => {
            industries.push(<th>{ele}</th>);
            var listItems = [];
            this.state.list[ele].forEach(el => {
                listItems.push(<span style={{display:'block'}}>{el.subcat}</span>)
            })
            subcategories.push(<td>{listItems}</td>)
        })

        return(
            <div className={Classes.container}>
                <div className={Classes.innerContainer}>
                    <CreateSubcategory refresh={this.refreshList} />
                </div>
                {this.state.refreshList == 1?(
                    <div className={Classes.innerContainer}>
                        <h4>Industry Subcategories List</h4>
                        <table border={'collapse'}>
                            <thead>
                                <tr>
                                    {industries}
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    {subcategories}
                                </tr>
                            </tbody>
                        </table>
                    </div>
                ):null}
            </div>
        )
    }
}

export default CreateIndustrySubcategory;