import axios from 'axios';
localStorage.setItem('appUrl', 'https://workbench.asteriasoftech.com/workbench/html/api/');
const instance = axios.create({baseURL: localStorage.getItem('appUrl')});

if(localStorage.getItem('awx'))
    instance.defaults.headers.common['Authorization'] = localStorage.getItem('awx');
    instance.defaults.headers.common['Content-Type'] = 'multipart/form-data';
    //instance.defaults.headers.common['testing'] = '1';
    


export default instance;
