import React, { Component } from 'react';
import styles from './ImageAdd.module.css';
import axios from 'axios/axios.js';
import Button from 'react-bootstrap/Button'


export default class ImageAdd extends Component {
  // Start the popover closed
  state = {
    url: '',
    open: false,
    imagesLoaded: {},
    selectedImage: -1,
  };

  // When the popover is open and users click anywhere on the page,
  // the popover should close
  componentDidMount() {
    document.addEventListener('click', this.closePopover);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.closePopover);
  }

  // Note: make sure whenever a click happens within the popover it is not closed
  onPopoverClick = () => {
    this.preventNextClose = true;
  }

  openPopover = (e) => {
    e.preventDefault();
    if (!this.state.open) {
      this.preventNextClose = true;
      this.setState({open: true});
      axios.get('getImages.php').then(response => {
          if(response.data.r === 1)
          {
              this.setState({...this.state, open:true, imagesLoaded: response.data.d.images});
          }
      }).catch(error => {
          console.log(error);
      });
      
    }
  }

  closePopover = (e) => {
    //if(e)
    //  e.preventDefault();
    if (!this.preventNextClose && this.state.open) {
      this.setState({
        open: false,
      });
    }

    this.preventNextClose = false;
  }

  addImage = (e) => {
    e.preventDefault();
    console.log("props in imageadd",this.props);
    const { editorState, onChange } = this.props;
    onChange(this.props.modifier(editorState, this.state.url));
    this.closePopover();
  }

  changeUrl = (evt) => {
    this.setState({ url: evt.target.value });
  }

  selectImage = (image, index) => {
    this.setState({ url: image, selectedImage: index });
  }

  render() {
    const popoverClassName = this.state.open ?
      styles.addImagePopover :
      styles.addImageClosedPopover;
    const buttonClassName = this.state.open ?
      styles.addImagePressedButton :
      styles.addImageButton;

      let allImages = null;
    
    if(Object.keys(this.state.imagesLoaded).length > 0)
    {
      allImages = Object.keys(this.state.imagesLoaded).map(index => {
        const image = localStorage.getItem('appUrl')+'Images/'+index;
        return (
          <div onClick={() => this.selectImage(image, index)} key={index} className={[styles.item, this.state.selectedImage==index?styles.selected:''].join(' ')}><img src={image} alt={this.state.imagesLoaded[index]} style={{width:'100%'}} /></div>
        );
      })
    }

    return (
      
        <div className={styles.addImage}>
          <Button
            size={'sm'}
            onMouseUp={this.openPopover}
            type="button"
            variant={'light'}
          >
            <span role={'img'} aria-label={'Add Image'}>📷</span>
          </Button>
        <div
          className={popoverClassName}
          onClick={this.onPopoverClick}
        > 
          <h4 style={{textAlign:'center'}}>Select Image From List <button type={'button'} style={{float:'right'}} onClick={this.closePopover}>X</button></h4>
          <div style={{height:'400px',overflow:'auto'}}>
            <div className={styles.masonry}>
              {allImages}
            </div>
          </div>
          {
            this.state.url != ''?(<button
              className={styles.addImageConfirmButton}
              type="button"
              onClick={this.addImage}
            >
              Add
            </button>):(<button
              type="button"
              disabled
            >
              Click On Required Image
            </button>)
          }
         
        </div>
      </div>
      
      
    );
  }
}