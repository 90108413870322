import React from 'react';
import {VendorListList} from 'modules/Vendors/Single';
import VendorProfile from 'modules/Vendors/VendorProfile';
import Classes from './VendorsList.module.css';
import axios from 'axios/axios.js';
import Pagination from 'components/Pagination/Pagination';

class VendorsList extends React.Component{

    constructor(props){
        super(props);
        this.flag = 1;
    }

    state = {
        isClicked: 0,
        refreshList: 1,
        list: [],
        matter: null,
        curItem: null,
        totPages: 1,
        currentPage: 1,
    }

    callFromServer = (currentPage) => {
        if(this.flag)
        {
            this.flag = 0;
            axios.post('Vendors/vendorsList.php?currentPage='+currentPage, {}).then(response => {
                if(response.data.r === 1)
                {
                    console.log(response.data.d.vendorList);
                    this.setState({
                        totPages: response.data.totPages,
                        currentPage: response.data.currentPage,
                        list: response.data.d.vendorList,
                        refreshList: 1,
                    }, ()=>{
                        if(this.state.curItem){
                            var allEle = document.getElementsByClassName('vendors'+this.state.curItem.id);
                            if(allEle){
                                allEle[0].click();
                            }
                        }
                    });
                }
                this.flag = 1;
            }).catch(error => {
                this.flag = 1;
            });
        }
    }

    componentDidMount(){
        if(this.state.list.length === 0){
            this.callFromServer(this.state.currentPage);
        }
    }

    onListClick = (item) => {
        this.setState({
            isClicked: 0,
            matter: null
        }, () => {
            this.setState({
                isClicked: 1,
                curItem: item,
                matter: <VendorProfile
                            listContainerCSS={Classes.SingleEntry}
                            id={item.id}
                            vid={item.id}
                            companyName={item.companyName} 
                            contactPerson={item.contactPerson} 
                            serviceIndustry={item.serviceIndustry} 
                            vendorAdmin={item.vendorAdmin} 
                            vendorUsersCount={item.vendorUsersCount}
                            isApproved={item.isApproved} 
                            isDeleted={item.isDeleted}
                            approveUser={item.approveUser}
                            phone={item.phone}
                            address={item.address}
                            email={item.email}
                            refresh={this.refreshList}
                        />
            });
        });
    }

    pageClicked = (ele) => {
        console.log(ele);
        this.callFromServer(ele);
    }

    refreshList = () => {
        this.setState({
            refreshList: 0,
            matter: null,
        }, () => {
            this.callFromServer(this.state.currentPage);
        });
    }

    render(){
        return(
            <div className={Classes.container}>
                {this.state.refreshList == 1?(
                    <React.Fragment>
                        <div style={{marginRight:'20px',boxSizing:'border-box'}}>
                            <Pagination totPages={this.state.totPages} currentPage={this.state.currentPage} pageClicked={(ele) => this.pageClicked(ele)}>
                                <VendorListList listContainerCSS={Classes.listContainer}
                                onListClick={(data) => this.onListClick(data)}
                                list={this.state.list} />
                            </Pagination>
                        </div>

                        <div>
                            {this.state.isClicked === 1?this.state.matter:null}
                        </div>
                    </React.Fragment>
                ):null}
            </div>
        )
    }
}

export default VendorsList; 