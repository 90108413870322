import React from 'react';
import {DictatorListSingle} from 'modules/Dictators/Single';
import ApproveUser from 'modules/Users/ApproveUser';
import EditUser from 'modules/Users/Edit';
import EditDictator from 'modules/Dictators/Edit';
import AssignInstituteToDictator from 'modules/Dictators/AssignOrEdit/InstituteToDictator';
import AssignSpecializationToDictator from 'modules/Dictators/AssignOrEdit/SpecializationToDictator';
import AssignFilesInfo from 'modules/Dictators/AssignOrEdit/FilesInfo';

import AssignPermission from 'modules/Dictators/AssignOrEdit/Permission';
import AssignPermissionTemplate from 'modules/Dictators/AssignOrEdit/PermissionTemplate';
import AssignGrantPermission from 'modules/Dictators/AssignOrEdit/GrantPermission';
import AssignGrantPermissionTemplate from 'modules/Dictators/AssignOrEdit/GrantPermissionTemplate';

import TasksList from 'pages/TasksList/TasksList';

import Strings from 'Strings.js';

class UserProfile extends React.Component{
    render(){
        return(
            <React.Fragment>
                <div className={this.props.listContainerCSS}>
                        <DictatorListSingle id={this.props.id}
                            name={this.props.name} 
                            specialization={this.props.specialization} 
                            institute={this.props.institute}
                            totTemplatesCount={this.props.totTemplatesCount}
                            processedTaskCount={this.props.processedTaskCount}
                            pendingTaskCount={this.props.pendingTaskCount}
                            isApproved={this.props.isApproved} 
                            isDeleted={this.props.isDeleted} />
                    <div>
                        <ApproveUser uid={this.props.did} approveUser={this.props.isApproved} refresh={this.props.refresh} />
                        <EditUser uid={this.props.did}
                            gender={this.props.gender}
                            directPost={this.props.directPost}
                            asr={this.props.asr}
                            phone={this.props.phone}
                            address={this.props.address}
                            email={this.props.email}
                            iid={this.props.iid}
                            tat={this.props.tat}
                            access={this.props.access}
                            sid={this.props.specialization_id}
                            nickname={this.props.nickname}
                            vendor_id={this.props.vendor_id}
                            accountType={this.props.accountType}
                            grade_all_1={this.props.grade_all_1}
                            grade_all_2={this.props.grade_all_2}
                            refresh={this.props.refresh}
                            name={this.props.name} />
                            
                        {/*<AssignInstituteToDictator iid={this.props.iid} did={this.props.did} institute={this.props.institute} />
                        <AssignSpecializationToDictator did={this.props.did} specialization={this.props.specialization} />
                        <AssignFilesInfo did={this.props.did} />*/}
                        <AssignPermission uid={this.props.did} refresh={this.props.refresh} />
                        <AssignPermissionTemplate uid={this.props.did} refresh={this.props.refresh} />
                        <AssignGrantPermission uid={this.props.did} refresh={this.props.refresh} />
                        <AssignGrantPermissionTemplate uid={this.props.did} refresh={this.props.refresh} />
                    </div>
                    <div>
                        <TasksList did={this.props.did} />
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default UserProfile;