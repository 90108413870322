import React from 'react';
import {DictatorTemplateListList} from 'modules/Tasks/Template/Dictator/Single';
import Classes from './DictatorTemplateList.module.css';
import Strings from 'Strings.js';
import axios from 'axios/axios.js';

class DictatorTemplateList extends React.Component{
 
    state = {
        list: [],
        refreshList: 1,
    }

    callFromServer = () => {
        axios.post('TaskTemplate/dictatorTemplateList.php', {}).then(response => {
            if(response.data.r === 1)
            {
                this.setState({
                    ...this.state,
                    list: response.data.d.dictatorTemplateList,
                    refreshList: 1,
                });
            }
        }).catch(error => {
            
        });
    }

    componentDidMount(){
        if(this.state.list.length === 0){
            this.callFromServer();
        }
    }

    upload = (e) => {
        e.preventDefault();
        var data = new FormData();
        data.append('file', document.getElementById('dictFileUploadId').files[0]);
        axios.post('TaskTemplate/uploadDictatorTemplate.php', data)
            .then(res => {
                if(res.data.r == 1){
                    this.refreshList();
                    if(res.data.d.notUploaded.length > 0)
                        alert("Error! These Files Are Not Uploaded -\n"+res.data.d.notUploaded.join('\n'));
                    else
                        alert("Success! Files Uploaded Successfully.");
                }
            })
            .catch(err => { 
            });
    }

    refreshList = () => {
        this.setState({
            refreshList: 0,
        }, () => {
            this.callFromServer();
        });
    }

    
    render(){
        return(
            <div className={Classes.container}>
                {this.state.refreshList === 1?(
                    <div style={{marginRight:'20px',boxSizing:'border-box'}}>
                        <div>
                            <form onsubmit="return false;">
                                <input id="dictFileUploadId" multiple type="file" />
                                <button id="upload" type="button" onClick={this.upload}>Upload</button>
                            </form>
                        </div>
                        <DictatorTemplateListList listContainerCSS={Classes.listContainer}
                            onListClick={(data) => {}}
                            list={this.state.list} />
                    </div>
                ):null}
            </div>
        )
    }
}



export default DictatorTemplateList; 