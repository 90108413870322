import React from 'react';
import {TaskListList} from 'modules/Tasks/Single';
import CreateTasks from 'modules/Tasks/Create';
import TaskProfile from 'modules/Tasks/TaskProfile';
import PartsList from 'pages/PartsList/PartsList';
import IndexingPartsList from 'pages/IndexingPartsList/IndexingPartsList';
import OCRPartsList from 'pages/OCRPartsList/OCRPartsList';
import TranslationPartsList from 'pages/TranslationPartsList/TranslationPartsList';
import TaskComparePage from 'pages/TaskCompare/TaskComparePage';
import Classes from './TasksList.module.css';
import axios from 'axios/axios.js';
import Pagination from 'components/Pagination/Pagination';
import SortFilter from 'components/SortFilter/SortFilter';
import Strings from 'Strings.js';


class TasksList extends React.Component{

    constructor(props){
        super(props);
        this.flag = 1;
        this.filterVars = {};
        this.timeUpdateInterval = null;
    }
 
    state = {
        isClicked: 0,
        refreshList:1,
        list: [],
        matter: null,
        totPages: 1,
        currentPage: 1,
        totItems: 0,
        totDuration: 0,
        curItem: null,
    }

    removeFromList = (tid) => {
        const newList = [...this.state.list];
        for(var v in newList){
            if(newList[v]['id'] == tid)
            {
                newList.splice(v, 1);
                break;
            }
        }
        
        this.setState({...this.state, list: newList, matter: null});
    }

    onMultipleListClick = (items) => {
        
    }

    callFromServer = (currentPage) => {
        if(this.flag)
        {
            this.flag = 0;

            if(!currentPage)
                currentPage = this.state.currentPage;

            var fd = this.filterVars;

            if(this.props.poolId){
                fd = new FormData();
                fd.append('sort[0][timeLeft]', 1);
            }
                
            
            axios.post('Tasks/tasksList.php?currentPage='+currentPage+'&uid='+this.props.uid+'&did='+this.props.did+'&vid='+this.props.vid+'&pool='+this.props.poolId+'&compare='+this.props.compare+'&self='+this.props.self+'&siid='+this.props.siid, fd).then(response => {
                if(response.data.r === 1)
                {
                    this.setState({
                        ...this.state,
                        refreshList: 1,
                        totPages: response.data.totPages,
                        currentPage: response.data.currentPage,
                        totItems: response.data.totItems,
                        totDuration: response.data.totDuration,
                        list: response.data.d.tasksList,
                    }, ()=>{
                        if(this.state.curItem){
                            var allEle = document.getElementsByClassName('tasks'+this.state.curItem.id);
                            if(allEle && allEle[0]){
                                allEle[0].click();
                            }
                        }
                    });
                }
                this.flag = 1;
            }).catch(error => {
                this.flag = 1;
            });
        }
    }

    componentDidMount(){
        if(this.state.list.length === 0){
            this.callFromServer(this.state.currentPage);
        }

        setTimeout(this.updateTaskTat, 5000);

        this.timeUpdateInterval = setInterval(this.updateTaskTat, 60000);
    }

    updateTaskTat = () => {
        var totTimeEle = document.getElementsByClassName('taskTimeVal');
        Object.values(totTimeEle).forEach(el => {
            var tatinmins = Math.floor((parseInt(el.getAttribute('data-val')) - (Date.now() / 1000)) / 60);

            var tlhous = Math.floor(tatinmins / 60);
            var tldura = Math.abs(Math.floor(tatinmins % 60));

            el.innerHTML = (tlhous > 9 || tlhous < 0?tlhous:'0'+tlhous)+':'+(tldura > 9?tldura:'0'+tldura);

            if(tatinmins < 240){
                var parnode = el.parentNode;
                //parnode.style.background = 'red';
                parnode.style.color = 'crimson';
            }
            else if(tatinmins < 360){
                el.parentNode.style.color = 'darkorange';
            }
            else if(tatinmins < 480){
                el.parentNode.style.color = 'darkgoldenrod';
            }
        })
    }

    componentWillUnmount(){
        clearInterval(this.timeUpdateInterval);
    }

    refreshList = () => {
        this.setState({
            refreshList: 0,
            matter: null,
        }, () => {
            this.callFromServer(this.state.currentPage);
        });
    }

    onListClick = (item, type) => {
        this.setState({
            isClicked: 0,
            matter: null
        }, () => {
            if(this.props.self == null && this.props.compare == null && this.props.poolId == null){
                this.setState({
                    isClicked: 1,
                    curItem: item,
                    matter: <TaskProfile
                                listContainerCSS={Classes.SingleEntry}
                                id={item.id}
                                tid={item.id}
                                name={item.name} 
                                fileName={item.fileName}
                                numberOfParts={item.numberOfParts}
                                actionType={item.actionType}
                                associatedDictator={item.associatedDictator}
                                tat={item.tat}
                                timeLeft={item.timeLeft}
                                currentlyAssignedTo={item.currentlyAssignedTo} 
                                assignedTaskTemplate={item.assignedTaskTemplate}
                                escalationLevel={item.escalationLevel}
                                escalation_level_id={item.escalation_level_id}
                                isCompleted={item.isCompleted}
                                fileId={item.fileId}
                                actionTypeId={item.actionTypeId}
                                did={item.did}
                                pool={this.props.poolId}
                                prevUsers={item.prevUsers}
                                onAssign={(tid) => this.removeFromList(tid)}
                                refresh={this.refreshList}
                            />
                });
            }
            else if(this.props.self != null){
                console.log("siid", this.props.siid) 
                if(this.props.siid == 1 || this.props.siid == 2){
                    this.setState({
                        isClicked: 1,
                        matter: <PartsList tid={item.id} file={item.fileName} escalation_level_id={item.escalation_level_id} to_be_graded={item.to_be_graded} refreshList={this.refreshList} />
                    });
                }
                else if(this.props.siid == 3 || this.props.siid == 4){
                    this.setState({
                        isClicked: 1,
                        matter: <IndexingPartsList tid={item.id} file={item.fileName} refreshList={this.refreshList} />
                    });
                } 
                else if(this.props.siid == 5 || this.props.siid == 6){
                    this.setState({
                        isClicked: 1,
                        matter: <OCRPartsList tid={item.id} file={item.fileName} escalation_level_id={item.escalation_level_id} to_be_graded={item.to_be_graded} refreshList={this.refreshList} />
                    });
                }
                else if(this.props.siid == 7 || this.props.siid == 8){
                    this.setState({
                        isClicked: 1,
                        matter: <TranslationPartsList tid={item.id} file={item.fileName} escalation_level_id={item.escalation_level_id} to_be_graded={item.to_be_graded} refreshList={this.refreshList} />
                    });
                }
            }
            else if(this.props.compare != null){
                this.setState({
                    isClicked: 1,
                    matter: <TaskComparePage tid={item.id} />
                });
            } else if(this.props.poolId != null){
                if(type == 'enter') {
                    var elebut = document.getElementsByClassName('picktask_'+item.id+'_'+this.props.poolId)[0];
                    console.log(elebut.getElementsByClassName('autoFormSubmit'));
                    if(elebut)
                        elebut.getElementsByClassName('autoFormSubmit')[0].click();
                } else if(type == 'delete') {
                    var elebut = document.getElementsByClassName('removefromaudit_'+item.id+'_'+this.props.poolId)[0];
                    if(elebut)
                        elebut.getElementsByClassName('autoFormSubmit')[0].click();
                }
            }
        });
    }

    pageClicked = (ele) => {
        console.log(ele);
        this.callFromServer(ele);
    }

    
    filterSubmit = (data) => {
        this.filterVars = data[1];
        this.callFromServer(1);
    }

    render(){
        var taskListVar = <Pagination totPages={this.state.totPages} currentPage={this.state.currentPage} pageClicked={(ele) => this.pageClicked(ele)}>
                            <TaskListList 
                                listContainerCSS={Classes.listContainer}
                                onListClick={(data, type) => this.onListClick(data, type)}
                                onMultipleListClick={(items) => this.onMultipleListClick(items)}
                                showCheck={true}
                                nonclick={{is:this.props.self || this.props.poolId || this.props.compare?true:false, lock:true, lockclass:Classes.lock, cond:'is_locked', lockon:true}}
                                isSelf={this.props.self?true:false}
                                isCompare={this.props.compare?true:false}
                                isPool={this.props.poolId?true:false}
                                activeClass={Classes.active}
                                inputRef={this.props.inputRef}
                                list={this.state.list} 
                                pool={this.props.poolId}
                                onAssign={(tid) => this.removeFromList(tid)}
                            />
                        </Pagination>
        
        if(!(this.props.uid || this.props.did || this.props.vid || this.props.poolId || this.props.self || this.props.compare)){
            taskListVar = <SortFilter headings={[
                                'taskName','actionType','timeLeft',
                                'escalationLevel', 'isCompleted' 
                            ]} filterProps={'taskslist'} onFilterSubmit={(data) => this.filterSubmit(data)}>{taskListVar}</SortFilter>
        }

        var totalFilesDur = null;
        if(this.props.self){
            var hr = Math.floor(parseInt(this.state.totDuration) / 3600);
            var min = Math.floor((parseInt(this.state.totDuration) % 3600)/60);
            var sec = Math.floor(parseInt(this.state.totDuration) % 3600 % 60);

            totalFilesDur = <div><b>{Strings.text.label.totalFiles}{' - '}</b>{this.state.totItems}{' | '}<b>{Strings.text.label.totalAllottedTime}{' - '}</b>{hr > 9?hr:'0'+hr}{':'}{min > 9?min:'0'+min}{':'}{sec > 9?sec:'0'+sec}</div>
        }




        return(
            <React.Fragment>
                {totalFilesDur==null?null:<div className={Classes.container}>{totalFilesDur}</div>}
                <div className={Classes.container}>
                    {/*this.props.uid || this.props.did || this.props.vid || this.props.poolId || this.props.self?null:(
                        <div style={{width:'500px',marginRight:'20px',boxSizing:'border-box',border:'1px solid', padding:'10px'}}>
                            <CreateTasks />
                        </div>
                    )*/}
                    
                    <div style={{marginRight:'20px',boxSizing:'border-box'}}>
                        {this.state.refreshList === 1?taskListVar:null}
                    </div>

                    <div>
                        {this.state.isClicked === 1?this.state.matter:null}
                    </div>
                </div>
            </React.Fragment>
        )
    }
}



export default TasksList; 