import React from 'react';
import CreateClientTemplate from 'modules/Testing/ClientForm';
import Classes from './CreateNewUsers.module.css';

class CreateNewUsers extends React.Component{
    render(){
        
        return( 
            <div className={Classes.container}>
                <div className={Classes.innerContainer}>
                    <CreateClientTemplate />
                </div>
                
            </div>
        )
    }
}

export default CreateNewUsers;