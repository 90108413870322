import React from 'react';
import {PermissionListList} from 'modules/Options/Permission/Single';
import Classes from './PermissionList.module.css';
import axios from 'axios/axios.js';

class UsersList extends React.Component{

    state = {
        isClicked: 0,
        list: [],
        matter: null
    }

    componentDidMount(){
        if(this.state.list.length === 0){
            axios.post('Permission/permissionList.php?tid='+this.props.tid, {}).then(response => {
                if(response.data.r === 1)
                {
                    console.log(response.data.d.permissionList);
                    this.setState({
                        ...this.state,
                        list: response.data.d.permissionList
                    });
                }
            }).catch(error => {
                
            });
        }
    }

    onListClick = (item) => {
        
    }

    render(){
        return(
            <div className={Classes.container}>
                <div style={{marginRight:'20px',boxSizing:'border-box'}}>
                    <PermissionListList tid={this.props.tid} listContainerCSS={Classes.listContainer}
                        onListClick={(data) => this.onListClick(data)}
                        list={this.state.list} />
                    </div>

                    <div>
                        {this.state.isClicked === 1?this.state.matter:null}
                    </div>
            </div>
        )
    }
}

export default UsersList; 