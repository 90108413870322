import React, {Component} from 'react';
import Form from 'components/Form/Form'; 

class PartialText extends Component{
    state = {
        refresh : 1,
        button: 0,
        shortcutValue: '',
        phraseValue: '',
    }

    fieldChange = (e, spval) => {
        var button = 0;
        var shortcutValue = this.state.shortcutValue;
        var phraseValue = this.state.phraseValue;

        if(spval == 'shortcutValue')
            shortcutValue = e.target.value.toLowerCase().trim();
        else if(spval == 'phraseValue')
            phraseValue = e.target.value;

        if(shortcutValue.length > 0 && phraseValue.trim().length > 0)
            button = 1;

        this.setState({
            button: button,
            shortcutValue: shortcutValue,
            phraseValue: phraseValue,
        });
    }

    addNewShortcut = () => {
        var partText = localStorage.getItem('PartialText');
        if(partText){
            partText = JSON.parse(partText);
        }
        else{
            partText = {};
        }
        if(!(this.props.did in partText)){
            partText[this.props.did] = {};
        }
        partText[this.props.did][this.state.shortcutValue] = this.state.phraseValue;

        localStorage.setItem('PartialText', JSON.stringify(partText));

        this.setState({refresh:0},()=>{
            this.setState({refresh: 1});
        })
    }

    componentDidMount(){
        document.getElementById('shortPhraseInputField').focus()
    }
    
    render() {
        var alreadyText = '';
        var tableVal = [];
        var partText = localStorage.getItem('PartialText');
        if(partText){
            partText = JSON.parse(partText);
            if(this.props.did in partText){
                if(this.props.shortcut in partText[this.props.did])
                    alreadyText = partText[this.props.did][this.props.shortcut];
            }
            Object.keys(partText).forEach(el => {
                tableVal.push(<tr><th colSpan="2">{'Dictator: '}{el}</th></tr>);
                Object.keys(partText[el]).forEach(shortcut => {
                    tableVal.push(<tr><td>{shortcut}</td><td>{partText[el][shortcut]}</td></tr>);
                })

            })
            tableVal = <table style={{width:'100%'}}>{tableVal}</table>

        }
        return (
            this.state.refresh?(
                <table style={{width:'100%',borderCollapse:'collapse',border:'1px solid #888'}}>
                    <tr>
                        <td style={{width:'30%',padding:'10px',border:'1px solid #888',verticalAlign:'top'}}>
                            <label>Shortcut</label>
                            <input id={'shortPhraseInputField'} style={{width:'100%'}} type="text" value={this.state.shortcutValue} onChange={(e)=>this.fieldChange(e, "shortcutValue")} />
                            <label>Phrase</label>
                            <textarea style={{width:'100%'}} onChange={(e)=>this.fieldChange(e, "phraseValue")}>{this.state.phraseValue}</textarea>
                            {
                                this.state.button==1?(
                                    <button type={"button"} onClick={this.addNewShortcut}>Add Shortcut</button>
                                ):(
                                    <button type={'button'} disabled="true">Add Shortcut</button>
                                )
                            }
                             
                        </td>
                        <td style={{width:'70%',padding:'10px',border:'1px solid #888',verticalAlign:'top'}}><div style={{overflow:'auto'}}>{tableVal}</div></td>
                    </tr>
                </table>
            ):null
        );
    }
}
export default PartialText;