import React, { Component } from 'react';
import {Link, Redirect} from 'react-router-dom';
import Form from 'components/Form/Form';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Strings from 'Strings.js';

class ChangePassword extends Component{

    state = {redirect: false}

    successSubmit = (data) => {
        this.setState({redirect: true});
    }

    failSubmit = (data) => {
        console.log("Login Failed : " + data.e);
        
    }

    render(){
        let params = (new URL(document.location)).searchParams;
        let email = params.get("email");

        const fields = [{
            type: 'hidden',
            value: email,
            name: 'email',
            options: {
                        required: true,
                    },
        },{
            type: 'password',
            value: '',
            name: 'password',
            placeholder: Strings.text.placeholder.newPass,
            options: {
                        strongPass: true,
                        required: true,
                        match: 1,
                    },
            label: Strings.text.label.newPass,
        },{
            type: 'password',
            value: '',
            name: 'confirm_password',
            placeholder: Strings.text.placeholder.confirmPass,
            options: {
                        required: true,
                        match: 1,
                    },
            label: Strings.text.label.confirmPass,
        },{
            type: 'text',
            value: '',
            name: 'code',
            placeholder: Strings.text.placeholder.verificationCode,
            options: {
                        required: true,
                    },
            label: Strings.text.label.verificationCode,
        }];


        return(
            <div style={{marginTop:'30px'}}>
            {this.state.redirect === true?<Redirect to={"/login"} />:(
                <Container>
                    <Row className="justify-content-center">
                        <Col xs={6}>
                            <h1>{Strings.text.heading.main}</h1>
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col xs={6}>
                            <h4>{Strings.text.heading.changePass}</h4>
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col xs={6}>
                            <h6>{Strings.text.heading.verificationMailSent}</h6>
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col xs={6}>
                            <Form   url={'changePass.php'}
                            buttonValue={Strings.text.button.changePass}
                            buttonType='success'
                            successSubmit={(data) => this.successSubmit(data)}
                            failSubmit={(data) => this.failSubmit(data)}
                            fields={fields} />
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col xs={6}>
                            <Link to={{
                                pathname: '/login',
                            }}>{Strings.text.link.backToLogin}</Link>      
                        </Col>
                    </Row>
                </Container>
            )}
            </div>
        )
    }
}

export default ChangePassword;