import React from 'react';
import CreateEfaxForm from 'modules/Efaxes/Create';
import Classes from './UploadEfaxes.module.css';
import Strings from 'Strings.js';

class UploadEfaxes extends React.Component{
    render(){
        return(
            <div className={Classes.container}>
                <div className={Classes.innerContainer}>
                    <h3>{Strings.text.label.patientLists}</h3>
                    <CreateEfaxForm formType={4} buttonValue={'Upload Patient List'} /> 
                </div>
                <div className={Classes.innerContainer}>
                    <h3>{Strings.text.label.associateLists}</h3>
                    <CreateEfaxForm formType={5} buttonValue={'Upload Associate List'} /> 
                </div>
            </div>
            )
    }
}

export default UploadEfaxes;