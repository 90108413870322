import React from 'react';
import CreateFile from 'modules/Form/Create/Create';

import Strings from 'Strings.js';

class Create extends React.Component{


    onSuccess = (data) => {

    }

    onFailure = (data) => {

    }

    render(){
        return(
            <CreateFile
                createPermission = {81}
                createContainerCSS = {''}
                url = {'IndexingTemplate/addIndexingTemplate.php'}
                onSuccess = {(data) => this.onSuccess(data)}
                onFailure = {(data) => this.onFailure(data)}
                buttonValue = {Strings.text.button.addIndexingTemplate}
                isTable = {[Strings.text.label.propertyKey, Strings.text.label.propertyFormat, Strings.text.label.propertyName]}
                addProperty = {true}
                fields = {[{
                    type: 'select',
                    value: '',
                    name: 'iid',
                    id: 'instituteList',
                    options: {
                                required: true,
                                showLoader: [true, 'black', 'Institute/getInstituteListWithUndoneDoclabels.php'],
                                options: [
                                    {value: '', name: '', selected: true},
                                ]
                             },
                    label: Strings.text.label.selectInstitute,
                },{
                    type: 'select',
                    value: '',
                    name: 'doclabels',
                    options: {
                                required: true,
                                showLoader: [true, 'black'],
                                onChange: 'instituteList',
                                options: [
                                    {value: '', name: '', selected: true},
                                ]
                             },
                    label: Strings.text.label.doclabel,
                },{
                    type: 'text',
                    value: '',
                    name: 'label',
                    placeholder: Strings.text.placeholder.createNewLabel,
                    options: {
                                required: false,
                             },
                    label: Strings.text.label.createNewLabel,
                }]}
            />
        );
    }
}

export default Create;