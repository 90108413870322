import React from 'react';
import CreateIndexTemp from 'modules/Indexing/Template/Create';
import Classes from './CreateIndexingTemplate.module.css';

class CreateIndexingTemplate extends React.Component{
    render(){
        return(
            <div className={Classes.container}>
                <div className={Classes.innerContainer}>
                    <CreateIndexTemp />
                    
                </div>
                
            </div>
            )
    }
}

export default CreateIndexingTemplate;