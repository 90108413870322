import React from 'react';
import Fields from '../Fields/Fields';
import CheckPermission from 'components/CheckPermission/CheckPermission';
import Classes from './Create.module.css';

class Create extends React.Component{
    render(){
        return(
            <CheckPermission perm={this.props.createPermission}>
                <div className={this.props.createContainerCSS}>
                    <Fields {...this.props} ref={(node) => { this.fieldRef = node; }}  />
                </div>
            </CheckPermission>
        );
    }
}

export default Create;


/*
    props: 
        createPermission = permission number for creating
        createContainerCSS = CSS for create block
        url = Url to call for create
        onSuccess = on success func
        onFailure = on failure func
        buttonValue = create button text
        fields = create array
*/