import React from 'react';
import Fields from '../Fields/Fields';
import Strings from 'Strings.js';
import Form from 'components/Form/Form';
import CheckPermission from 'components/CheckPermission/CheckPermission';
import Classes from './Edit.module.css';

class Edit extends React.Component{

    state = {
        buttonClicked: this.props.buttonClicked === 1?1:0
    }

    onSuccess = (data) => {
        this.props.onSuccess(data);
    }

    onFailure = (data) => {
        this.props.onFailure(data);
    }

    showEdit = () => {
        this.setState({
            buttonClicked: 1
        });
    }

    render(){
        return(
            <React.Fragment>
                <CheckPermission perm={this.props.editPermission}  hideComponent={true}>
                {this.state.buttonClicked === 0?(
                    <div className={this.props.ButtonCSS}>
                        {this.props.Name?<span className={this.props.NameCSS}>{this.props.Name}</span>:null}
                        <Form  onSubmit={() => this.showEdit()}
                            buttonValue={this.props.editButtonValue}
                            buttonType='success'
                            enableButton='true'
                            fields={[]} /> 
                    </div>
                ):(
                    <div className={this.props.editContainerCSS}>
                        <Fields {...this.props} />
                    </div>

                )}
                </CheckPermission>
            </React.Fragment>
        );
    }
}

export default Edit;


/*
    editPermission = permission for edit
    ButtonCSS = css for button and container
    NameCSS = css for name text
    Name = Name of the item (Optional)
    editButtonValue = Button name which opens edit form
    editContainerCSS = Css for edit form container
    url = url for edit request
    buttonValue = Button name when form is opened
    onSuccess = called on success
    onFailure = called on failure
    fields = form fields on edit


*/