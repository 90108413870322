import React from 'react';
import axios from 'axios/axios.js';
import CreateDynamicForm from 'modules/DynamicForm/CreateDynamicForm/CreateDynamicForm';

class SelectTypeOfForm extends React.Component{

    state = {
        formtypes: [],
        defaultValue: null,
        extraFields: [],
    }

    onFormTypeChange = (e) => {
        e.preventDefault();
        this.loadAppropriateForm(e.target.value);
    }

    loadAppropriateForm = (ft) => {
        if(ft != 0){
            var formtype = ft;
            axios.get('DynamicForms/makeFormType.php?formType='+ft).then(response => {
                if(response.data.r == 1)
                {
                    this.setState({defaultValue: 0, extraFields:[]},()=>{
                        this.setState({
                            defaultValue: formtype,
                            extraFields: response.data.d
                        })
                    });
                } 
            }).catch(error => {
            
            });
        }
        else{
            this.setState({defaultValue: 0, extraFields:[]});
        }
    }



    componentDidMount(){
        if(this.state.formtypes.length==0){
            axios.get('DynamicForms/getFormTypes.php').then(response => {
                if(response.data.r == 1)
                {
                    this.setState({formtypes:[]},()=>{
                        this.setState({
                            defaultValue: 0,
                            formtypes: response.data.d
                        })
                    });
                } 
            }).catch(error => {
            
            });
        }
    }

    render(){
        var selopts = this.state.formtypes.map(item => {
            return <option value={item.name}>{item.value}</option>;
        });
        return (
            this.state.formtypes.length > 0?(
                <div style={{padding:'10px'}}>
                    <label style={{marginRight:'20px'}}>Select Type of Form</label>
                    <select value={this.state.defaultValue} onChange={this.onFormTypeChange}>
                        {selopts}
                    </select>
                    {
                        this.state.defaultValue != 0?(
                            <CreateDynamicForm onSuccess={(data)=>this.loadAppropriateForm(this.state.defaultValue)} formType={this.state.defaultValue} extraFields={this.state.extraFields} createContainerCSS={null} />
                        ):null
                    }
                    
                </div>
            ):null
        );
    }
}

export default SelectTypeOfForm