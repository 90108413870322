import React from 'react';
import CreateFile from 'modules/Form/Create/Create';
import Strings from 'Strings.js';

class Create extends React.Component{

    onSuccess = (data) => {
        this.props.refresh();
    }

    onFailure = (data) => {

    }

    render(){
        return(
            <CreateFile
                createPermission = {50}
                createContainerCSS = {''}
                url = {'Dictators/addDictatorSpecialization.php'}
                onSuccess = {(data) => this.onSuccess(data)}
                onFailure = {(data) => this.onFailure(data)}
                buttonValue = {Strings.text.button.addDictatorSpecialization}
                fields = {[{
                    type: 'select',
                    value: '',
                    name: 'iid',
                    options: {
                                required: true,
                                showLoader: [true, 'black', 'Industry/getIndustrySubcatList.php'],
                                options: [
                                    {value: '', name: '', selected: true},
                                ]
                            },
                    label: Strings.text.label.selectIndustrySubcat,
                },{
                    type: 'text',
                    value: '',
                    name: 'name',
                    placeholder: Strings.text.placeholder.specializationName,
                    options: {
                                required: true,
                            },
                    label: Strings.text.label.specializationName,
                }]}
            />
        );
    }
}

export default Create;