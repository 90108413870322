import React from 'react';
import CreateFile from 'modules/Form/Create/Create';
import Strings from 'Strings.js';
import axios from 'axios/axios.js';


class Create extends React.Component{

    state = {refresh: 0, fields:[]}

    onSuccess = (data) => {

    }

    onFailure = (data) => {

    }

    componentDidMount(){
        if(this.state.fields.length == 0){
            axios.post('Vendors/selectVendorWithIndustry.php', {}).then(response => {
                if(response.data.r == 1)
                {
                    var fid = [{
                        type: 'alphabets',
                        value: '',
                        name: 'name',
                        placeholder: Strings.text.placeholder.instituteName,
                        options: {
                                    required: true,
                                    minLength: 5,
                                    },
                        label: Strings.text.label.instituteName,
                    },{
                        type: 'email',
                        value: '',
                        name: 'email',
                        placeholder: Strings.text.placeholder.email,
                        options: {
                                    required: false,
                                },
                        label: Strings.text.label.email,
                    },{
                        type: 'text',
                        value: '',
                        name: 'contact_person',
                        placeholder: Strings.text.placeholder.contactPerson,
                        options: {
                                    required: true,
                                },
                        label: Strings.text.label.contactPerson,
                    },{
                        type: 'text',
                        value: '',
                        name:'address',
                        placeholder: Strings.text.placeholder.address,
                        options: {
                                    required: false,
                                    },
                        label: Strings.text.label.address,
                    },{
                        type: 'number',
                        value: '',
                        name:'phone',
                        placeholder: Strings.text.placeholder.phone,
                        options: {
                                    required: false,
                                    minLength: 10,
                                    maxLength: 10,
                                    },
                        label: Strings.text.label.phone,
                    },{
                        type: 'select',
                        value: '',
                        name: 'industry_id',
                        id: 'industryList',
                        options: {
                                    required: true,
                                    dependentChildren:['trainee','trans','prof1','prof2','blkchk'],
                                    showLoader: [true, 'black', 'Industry/getIndustryListWithSubcat.php'],
                                    options: [
                                        {value: '', name: '', selected: true},
                                    ]
                                },
                        label: Strings.text.label.selectIndustry,
                    }];

                    var groupOpts = {
                                        Transcription:{value:['','','','',''],pointer:{},opts:[]},
                                        Indexing:{value:['','','','',''],pointer:{},opts:[]},
                                        OCR:{value:['','','','',''],pointer:{},opts:[]},
                                        Translation:{value:['','','','',''],pointer:{},opts:[]},
                                    };

                    Object.keys(response.data.d.pointer).forEach(el => {
                        var service = null;
                        var firstOnly = 0;
                        var defaultIndus = 1;

                        if(el==1 || el==2){
                            service = 'Transcription';
                            firstOnly = 1;
                        }
                        else if(el==3 || el==4){
                            service = 'Indexing';
                            firstOnly = 3;
                        }
                        else if(el==5 || el==6){
                            service = 'OCR';
                            firstOnly = 5;
                        }
                        else if(el==7 || el==8){
                            service = 'Translation';
                            firstOnly = 7;
                        } 

                        groupOpts[service].pointer[Object.keys(response.data.d.pointer[el])] = Object.values(response.data.d.pointer[el]);

                        if(el == firstOnly){
                            Object.keys(response.data.d.pointer[el][defaultIndus]).forEach(ele => {
                                groupOpts[service].opts.push({value: response.data.d.pointer[el][defaultIndus][ele], name: ele, selected: false});
                            });
                        }

                        if('1' in response.data.d.default[el])
                            groupOpts[service]['value'][0] = response.data.d.default[el]['1'];

                        if('2' in response.data.d.default[el])
                            groupOpts[service]['value'][1] = response.data.d.default[el]['2'];

                        if('3' in response.data.d.default[el])
                            groupOpts[service]['value'][2] = response.data.d.default[el]['3'];

                        if('4' in response.data.d.default[el])
                            groupOpts[service]['value'][3] = response.data.d.default[el]['4'];

                        if('6' in response.data.d.default[el])
                            groupOpts[service]['value'][4] = response.data.d.default[el]['6'];

                    });    

                    Object.keys(groupOpts).forEach(el => {
                        fid.push({
                            type: 'select',
                            value: groupOpts[el]['value'][0],
                            name: 'trainee[]',
                            options: {
                                        required: true,
                                        pointers: groupOpts[el]['pointer'],
                                        options: groupOpts[el]['opts']
                                    },
                            label: el+': '+Strings.text.label.assignVendorToIndTrainee,
                        });
    
                        fid.push({
                            type: 'select',
                            value: groupOpts[el]['value'][1],
                            name: 'trans[]',
                            options: {
                                        required: true,
                                        pointers: groupOpts[el]['pointer'],
                                        options: groupOpts[el]['opts']
                                    },
                            label: el+': '+Strings.text.label.assignVendorToIndTrans,
                        });
    
                        fid.push({
                            type: 'select',
                            value: groupOpts[el]['value'][2],
                            name: 'prof1[]',
                            options: {
                                        required: true,
                                        pointers: groupOpts[el]['pointer'],
                                        options: groupOpts[el]['opts']
                                    },
                            label: el+': '+Strings.text.label.assignVendorToIndProf1,
                        });
    
                        fid.push({
                            type: 'select',
                            value: groupOpts[el]['value'][3],
                            name: 'prof2[]',
                            options: {
                                        required: true,
                                        pointers: groupOpts[el]['pointer'],
                                        options: groupOpts[el]['opts']
                                    },
                            label: el+': '+Strings.text.label.assignVendorToIndProf2,
                        });
    
                        fid.push({
                            type: 'select',
                            value: groupOpts[el]['value'][4],
                            name: 'blkchk[]',
                            options: {
                                        required: true,
                                        pointers: groupOpts[el]['pointer'],
                                        options: groupOpts[el]['opts']
                                    },
                            label: el+': '+Strings.text.label.assignVendorToIndBlkChk,
                        });
                    });   

                    

                    

                    fid.push({
                        type: 'checkbox',
                        value: '',
                        name: 'industry_subcat_ids',
                        options: {
                                    required: true,
                                    showLoader: [true, 'black'],
                                    onChange: 'industryList',
                                    group: 'industryCatList',
                                    newLine: true,
                                    options: [
                                        {value: '', name: '', selected: false},
                                    ]
                                },
                        label: Strings.text.label.selectIndustryCategory,
                    });

                    fid.push({
                        type: 'file',
                        value: '',
                        name: 'firm_logo',
                        options: {
                                    required: false,
                                    
                                },
                        label: Strings.text.label.firmLogo,
                    });

                    this.setState({refresh:0},()=>{
                        this.setState({
                            refresh:1,
                            fields:fid,
                        })
                    });
                }
            }).catch(error => {});
        }
        
    }

    render(){
        return(
            <React.Fragment>
            <h4>{Strings.text.heading.AddInstitute}</h4>
            {
                this.state.refresh?(
                    <CreateFile
                        createPermission = {15}
                        createContainerCSS = {''}
                        url = {'Institute/createNewInstitute.php'}
                        onSuccess = {(data) => this.onSuccess(data)}
                        onFailure = {(data) => this.onFailure(data)}
                        buttonValue = {Strings.text.button.addInstitute}
                        fields = {this.state.fields}
                    />
                ):(<div>Loading....</div>)
            }
            </React.Fragment>
        );
    }
}

export default Create;