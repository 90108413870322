import React from 'react';

import Classes from './AllocateTasks.module.css';
import UsersListWithTaskTime from 'modules/Tasks/UsersListWithTaskTime';
import UnallocatedTasksList from 'modules/Tasks/UnallocatedTasksList';
import UsersListToAllocateTask from 'modules/Tasks/UsersListToAllocateTask';
import Strings from 'Strings.js';
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'


class AllocateTasks extends React.Component{

    state={
        trainee: 1,
        transcriptionist: 0,
        proofer1: 0,
        proofer2: 0
    }

    setTab = (k) => {
        this.setState({
            [k] : 1
        });
    }
 
    

    updateLists = (ty) => {
        console.log("Update List Called");
        if(ty==1)
            this.setState({trainee: 0},()=>{this.setState({trainee:1})});
        else if(ty==2)
            this.setState({transcriptionist: 0},()=>{this.setState({transcriptionist:1})});
        else if(ty==3)
            this.setState({proofer1: 0},()=>{this.setState({proofer1:1})});
        else if(ty==4)
            this.setState({proofer2: 0},()=>{this.setState({proofer2:1})});
    }
    
    render(){
        return(
            <div className={Classes.container}>
                <Tabs id={"uncontrolled-tab-example"+this.props.siid} onSelect={k=>this.setTab(k)}>
                    <Tab eventKey="trainee" title={Strings.text.label.trainee}>
                        {this.state.trainee == 1?(
                            <React.Fragment>
                                <div style={{marginRight:'20px',boxSizing:'border-box','border':'1px solid #888',float:'left',height:'calc(100vh - 220px)',overflowY:'auto'}}>
                                    <UnallocatedTasksList siid={this.props.siid} type={1} />
                                </div>
        
                                <div style={{marginRight:'20px',boxSizing:'border-box','border':'1px solid #888',float:'left',height:'calc(100vh - 220px)',overflowY:'auto'}}>
                                    <UsersListToAllocateTask siid={this.props.siid} type={1} updateLists={() => this.updateLists(1)} />
                                </div>
        
                                <div style={{marginRight:'20px',boxSizing:'border-box','border':'1px solid #888',float:'left',height:'calc(100vh - 220px)',overflowY:'auto'}}>
                                    <UsersListWithTaskTime siid={this.props.siid} type={1} />
                                </div>
                            </React.Fragment>
                        ):null}
                    </Tab>
                    <Tab eventKey="transcriptionist" title={Strings.text.label.transcriptionist}>
                        {this.state.transcriptionist == 1?(
                            <React.Fragment>
                                <div style={{marginRight:'20px',boxSizing:'border-box','border':'1px solid #888',float:'left',height:'calc(100vh - 220px)',overflowY:'auto'}}>
                                    <UnallocatedTasksList siid={this.props.siid} type={2} />
                                </div>
        
                                <div style={{marginRight:'20px',boxSizing:'border-box','border':'1px solid #888',float:'left',height:'calc(100vh - 220px)',overflowY:'auto'}}>
                                    <UsersListToAllocateTask siid={this.props.siid} type={2} updateLists={() => this.updateLists(2)} />
                                </div>
        
                                <div style={{marginRight:'20px',boxSizing:'border-box','border':'1px solid #888',float:'left',height:'calc(100vh - 220px)',overflowY:'auto'}}>
                                    <UsersListWithTaskTime siid={this.props.siid} type={2} />
                                </div>
                            </React.Fragment>
                        ):null}
                    </Tab>
                    <Tab eventKey="proofer1" title={Strings.text.label.proofer1}>
                        {this.state.proofer1 == 1?(
                            <React.Fragment>
                                <div style={{marginRight:'20px',boxSizing:'border-box','border':'1px solid #888',float:'left',height:'calc(100vh - 220px)',overflowY:'auto'}}>
                                    <UnallocatedTasksList siid={this.props.siid} type={3} />
                                </div>
        
                                <div style={{marginRight:'20px',boxSizing:'border-box','border':'1px solid #888',float:'left',height:'calc(100vh - 220px)',overflowY:'auto'}}>
                                    <UsersListToAllocateTask siid={this.props.siid} type={3} updateLists={() => this.updateLists(3)} />
                                </div>
        
                                <div style={{marginRight:'20px',boxSizing:'border-box','border':'1px solid #888',float:'left',height:'calc(100vh - 220px)',overflowY:'auto'}}>
                                    <UsersListWithTaskTime siid={this.props.siid} type={3} />
                                </div>
                            </React.Fragment>
                        ):null}
                    </Tab>
                    <Tab eventKey="proofer2" title={Strings.text.label.proofer2}>
                        {this.state.proofer2 == 1?(
                            <React.Fragment>
                                <div style={{marginRight:'20px',boxSizing:'border-box','border':'1px solid #888',float:'left',height:'calc(100vh - 220px)',overflowY:'auto'}}>
                                    <UnallocatedTasksList siid={this.props.siid} type={4} />
                                </div>
        
                                <div style={{marginRight:'20px',boxSizing:'border-box','border':'1px solid #888',float:'left',height:'calc(100vh - 220px)',overflowY:'auto'}}>
                                    <UsersListToAllocateTask siid={this.props.siid} type={4} updateLists={() => this.updateLists(4)} />
                                </div>
        
                                <div style={{marginRight:'20px',boxSizing:'border-box','border':'1px solid #888',float:'left',height:'calc(100vh - 220px)',overflowY:'auto'}}>
                                    <UsersListWithTaskTime siid={this.props.siid} type={4} />
                                </div> 
                            </React.Fragment>
                        ):null}
                    </Tab>
                </Tabs>
                
                
            </div>
        )
    }
}



export default AllocateTasks; 