import React from 'react';
import EditFile from 'modules/Form/Edit/Edit';
import Strings from 'Strings.js';

class Edit extends React.Component{

    state = {
        update: 1,
        approveUser: this.props.approveUser
    }
 
    onSuccess = (data) => {
        console.log(data);
        this.setState({...this.state, update: 0}, () => {
            this.setState({
                update: 1,
                approveUser: parseInt(data['user_approved'])
            });
        });
        this.props.refresh();
    }

    onFailure = (data) => {

    }

    render(){
        const editFile = <EditFile
                editPermission = {2}
                ButtonCSS = {''}
                NameCSS = {''}
                editButtonValue = {this.state.approveUser == 0 ? Strings.text.button.approveUserButton : Strings.text.button.unApproveUserButton}
                editContainerCSS = {''}
                url = {'Users/approveUser.php'}
                buttonClicked={1}
                enableButton='true'
                buttonValue = {this.state.approveUser == 0 ? Strings.text.button.approveUserButton : Strings.text.button.unApproveUserButton}
                onSuccess = {(data) => this.onSuccess(data)}
                onFailure = {(data) => this.onFailure(data)}
                fields = {[
                    {
                        type: 'hidden',
                        value: this.props.uid,
                        name: 'uid',
                        options: {
                                    required: true,
                                },
                    },
                    {
                        type: 'hidden',
                        value: this.state.approveUser==1?0:1,
                        name: 'approved',
                        options: {
                                    required: true,
                                },
                    },
                ]}
            />
        
        return(
            this.state.update === 1?editFile:null
        );
    }
}

export default Edit;

/*
    uid, approveUser
*/