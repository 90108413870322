import React from 'react';
import TasksList from 'pages/TasksList/TasksList';
import CreateFile from 'modules/Form/Create/Create';
import Classes from './AssignedIndexingTasks.module.css';

class AssignedIndexingTasks extends React.Component{

    state = {
        openPage: 0
    }

    openIndexing = () => {
        this.setState({
            openPage: 1
        });
    }

    closeIndexing = () => {
        this.setState({
            openPage: 0
        });
    }

    render(){
        return(
            <React.Fragment>
            {
                this.state.openPage == 1?(
                    <div style={{background:'white',position:'fixed',top:0,left:0,width:'100vw',height:'100vh',zIndex:'5555',padding:'20px',overflow:'auto'}}>
                        <button style={{float:'right'}} onClick={this.closeIndexing}>Close</button>
                        <div style={{width:'50%',float:'left',boxSizing:'border-box',padding:'10px'}}>
                            <div style={{border:'1px solid #aaa',textAlign:'center',margin:'auto'}}>
                                <img src={localStorage.getItem('appUrl')+"Digitization/1/dfgryrthygfhdgdffg.png"} alt="End User License" style={{width:'100%',height:'100%'}} />
                            </div>
                        </div>
                        <div style={{width:'50%',float:'left',boxSizing:'border-box',padding:'10px'}}>
                            <div style={{border:'1px solid #aaa',padding:'20px'}}>
                                <CreateFile
                                    createPermission = {81}
                                    createContainerCSS = {''}
                                    url = {''}
                                    onSuccess = {(data) => this.onSuccess(data)}
                                    onFailure = {(data) => this.onFailure(data)}
                                    buttonValue = {'Add Indexing'}
                                    fields = {[{
                                        type: 'select',
                                        value: '',
                                        name: 'file_id',
                                        options: {
                                                    required: true,
                                                    options: [
                                                        {value: 'End User License', name: '1', selected: true},
                                                        {value: 'Khata', name: '2', selected: true},
                                                        {value: 'Agreement', name: '3', selected: true},
                                                        {value: 'Invoice', name: '4', selected: true},
                                                    ]
                                                },
                                        label: 'Document Type',
                                    },{
                                        type: 'text',
                                        value: '',
                                        name: 'name',
                                        placeholder: 'Licensee Name',
                                        options: {
                                                    required: true,
                                                },
                                        label: 'Licensee Name',
                                    },{
                                        type: 'text',
                                        value: '',
                                        name: 'productType',
                                        placeholder: 'Product Type',
                                        options: {
                                                    required: true,
                                                },
                                        label: 'Product Type',
                                    },{
                                        type: 'text',
                                        value: '',
                                        name: 'data',
                                        placeholder: 'Effective Date',
                                        options: {
                                                    required: true,
                                                },
                                        label: 'Effective Date',
                                    },{
                                        type: 'radio',
                                        value: '',
                                        name:'origin',
                                        options: {
                                                    required: true,
                                                    group : 'origin',
                                                    options:[
                                                        {value: 'Exported', name: 'exported', selected: true},
                                                        {value: 'Imported', name: 'imported', selected: false},
                                                    ],
                                                 },
                                        label: 'Item Origin',
                                    },{
                                        type: 'radio',
                                        value: '',
                                        name:'warranty',
                                        options: {
                                                    required: true,
                                                    group : 'warranty',
                                                    options:[
                                                        {value: 'Yes', name: 'yes', selected: true},
                                                        {value: 'No', name: 'no', selected: false},
                                                    ],
                                                 },
                                        label: 'Warranty on Item',
                                    },{
                                        type: 'text',
                                        value: '',
                                        name: 'warrantyPeriod',
                                        placeholder: 'Warranty Period',
                                        options: {
                                                    required: true,
                                                },
                                        label: 'Warranty Period',
                                    },{
                                        type: 'text',
                                        value: '',
                                        name: 'ipcode',
                                        placeholder: 'IP Code',
                                        options: {
                                                    required: false,
                                                },
                                        label: 'IP Code',
                                    },]}
                                />
                            </div>
                        </div>
                    </div>
                ):(
                    <div style={{marginRight:'20px',boxSizing:'border-box'}}>
                        <div>
                            <div onClick={this.openIndexing} style={{width:'400px',border:'1px solid #aaa',padding:'10px',marginTop:'20px',cursor:'pointer'}}>
                                <b>{'End User License'}</b>
                                {' | '}<b>File Id: </b>{'license/2020'}
                                {' | '}<b>Case Id: </b>{'agreement/2020'}
                                {' | '}<b>Client Id: </b>{'Rak/Meh/29/01/2020'}
                            </div>
                        </div>
                        
                    </div>
                )
            }
            
            </React.Fragment>
        )
    }
}



export default AssignedIndexingTasks; 