import React from 'react';
import CreateFile from 'modules/Form/Create/Create';

import Strings from 'Strings.js';
import {cloneDeep} from 'lodash';

class Create extends React.Component{
    constructor(props){
        super(props);
        this.oldFields = [{
            type: 'hidden',
            value: this.props.formType,
            name: 'form_type',
            placeholder: '',
            options: {
                        required: true,
                     },
        },{
            type: 'checkbox',
            value: '1',
            name: 'array_required',
            options: {
                        required: false,
                        group:'requiredField',
                        options: [{name: '0', value:'', selected:true}],
                        table: {rowStart: true},
                     },
        },{
            type: 'text',
            value: '',
            name: 'array_name',
            placeholder: '',
            options: {
                        required: true,
                        table: {},
                     },
        },{
            type: 'select',
            value: 'text',
            name: 'array_type',
            options: {
                        required: true,
                        table: {},
                        options: [
                            {value: 'Text', name: 'text', selected: true},
                            {value: 'Date', name: 'date', selected: false},
                            {value: 'Number', name: 'number', selected: false},
                            {value: 'Email', name: 'email', selected: false},
                            {value: 'Password', name: 'password', selected: false},
                            {value: 'Textarea', name: 'textarea', selected: false},
                            {value: 'Select Box', name: 'select', selected: false},
                            {value: 'Checkbox', name: 'checkbox', selected: false},
                            {value: 'Radio', name: 'radio', selected: false},
                            {value: 'Hidden', name: 'hidden', selected: false},
                        ],
                     },
        },{
            type: 'text',
            value: '',
            name: 'array_option',
            placeholder: '',
            options: {
                        required: false,
                        table: {},
                     },
        },{
            type: 'text',
            value: '',
            name: 'array_default',
            placeholder: '',
            options: {
                        required: false,
                        table: {},
                     },
        },{
            type: 'number',
            value: '',
            name: 'array_num',
            placeholder: '',
            options: {
                        required: true,
                        table: {rowEnd: true},
                        addFieldsBut: {plus:true, func:(data) => this.addDeleteFields(data)}
                     }, 
        }];

        this.props.extraFields.forEach((ele,ind) => {
            this.oldFields.splice(ind,0,ele);
        })




        this.state = {
            fields: cloneDeep(this.oldFields)
        }
    }
    


    onSuccess = (data) => {
        this.setState({fields: []}, () => {this.setState({fields: cloneDeep(this.oldFields)})});
    }

    onFailure = (data) => {

    }

    addDeleteFields = (data) => {
        
        if(data.addminus == 1){
            data.fields.splice(data.index+1,0,{
                type: 'checkbox',
                value: '1',
                name: 'array_required',
                options: {
                            required: false,
                            group:'requiredField',
                            options: [{name: '0', value:'', selected:true}],
                            table: {rowStart: true},
                         },
            },{
                type: 'text',
                value: '',
                name: 'array_name',
                placeholder: '',
                options: {
                            required: true,
                            table: {},
                         },
            },{
                type: 'select',
                value: 'text',
                name: 'array_type',
                options: {
                            required: true,
                            table: {},
                            options: [
                                {value: 'Text', name: 'text', selected: true},
                                {value: 'Date', name: 'date', selected: false},
                                {value: 'Number', name: 'number', selected: false},
                                {value: 'Email', name: 'email', selected: false},
                                {value: 'Password', name: 'password', selected: false},
                                {value: 'Textarea', name: 'textarea', selected: false},
                                {value: 'Select Box', name: 'select', selected: false},
                                {value: 'Checkbox', name: 'checkbox', selected: false},
                                {value: 'Radio', name: 'radio', selected: false},
                                {value: 'Hidden', name: 'hidden', selected: false},
                            ],
                         },
            },{
                type: 'text',
                value: '',
                name: 'array_option',
                placeholder: '',
                options: {
                            required: false,
                            table: {},
                         },
            },{
                type: 'text',
                value: '',
                name: 'array_default',
                placeholder: '',
                options: {
                            required: false,
                            table: {},
                         },
            },{
                type: 'number',
                value: '',
                name: 'array_num',
                placeholder: '',
                options: {
                            required: true,
                            table: {rowEnd: true},
                            addFieldsBut: {plus:true, minus:true, func:(data) => this.addDeleteFields(data)}
                         }, 
            });
        }
        else if(data.addminus == -1){
            data.fields.splice(data.index-6,6);
        }

        var counter = 0;
        data.fields.forEach((el,ind) => {
            if(el.name == 'array_required'){
                el.options.options[0].name = counter++;
            }
            if('showLoader' in el.options)
                delete data.fields[ind].options.showLoader;
        });

        this.setState({fields: []}, () => {this.setState({fields: data.fields})});
    }

    render(){
        return(
            <React.Fragment>
            {this.state.fields.length > 0?(
                <CreateFile
                    createPermission = {83}
                    createContainerCSS = {this.props.createContainerCSS}
                    url = {'DynamicForms/addForm.php'}
                    onSuccess = {(data) => this.props.onSuccess(data)}
                    onFailure = {(data) => this.onFailure(data)}
                    buttonValue = {Strings.text.button.addIndexingCaseFormTemplate}
                    isTable = {[Strings.text.label.formFieldRequired, Strings.text.label.formFieldName, Strings.text.label.formFieldType, Strings.text.label.formFieldOptionsText, Strings.text.label.formFieldDefault, Strings.text.label.formFieldPosition]}
                    inlineTable = {true}
                    fields = {this.state.fields}
                />):null}
            </React.Fragment>
        );
    }
}

export default Create;


/*
    props:
            formType
            extraFields
            createContainerCSS
            onSuccess
*/