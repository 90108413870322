import React from 'react';
import List from 'modules/List/List';
import Form from 'components/Form/Form';
import axios from 'axios/axios';
import Strings from 'Strings.js';

class Single extends React.Component{

    download = () => {
        var fd = new FormData();
        fd.append('doc', this.props.downloadFile);
        axios.post('downloadLastCheckFile.php', fd, {responseType: 'arraybuffer'}).then(response => {
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(new Blob([response.data], {type: 'application/octet-stream'}));
            link.download = this.props.downloadFile;

            document.body.appendChild(link);

            link.click();
            setTimeout(function() {
                window.URL.revokeObjectURL(link);
            }, 200);
        }).catch(error => {})
    
    }

    startAudio = (e) => {
        e.target.outerHTML = '<audio style="height:35px;width:250px;" controls preload="auto"><source src="'+localStorage.getItem('appUrl')+'serveMP3.php?audio='+encodeURIComponent(this.props.audio)+'" /></audio>';
    }

    onSuccess = (data) => {
        this.props.refreshList();
    }

    render(){
        var gotToServer = parseInt(this.props.gotToServer) * 1000;
        
        
        var processedInTime = ((Date.now() - gotToServer) / (60*1000));
        processedInTime = parseInt(processedInTime/60)+' Hours '+parseInt(processedInTime % 60)+' Min.';
        
        return(
            
                <React.Fragment>
                    <td><input type="checkbox" defaultChecked={this.props.selected===1?true:false} onChange={() => this.props.checkboxCallback(this.props.id)} /></td>
                    <td>{this.props.fileName}<br /><button onClick={this.startAudio}>Audio</button></td>
                    <td>{new Date(gotToServer).toLocaleString('en-AU')}</td>
                    <td>{this.props.dictator_name}</td>
                    <td>{this.props.tat} {'Hours'}</td>
                    <td>{processedInTime}</td>
                    <td><Form   
                            onSubmit={() => this.download()}
                            buttonValue={Strings.text.button.download}
                            buttonType='success'
                            enableButton='false'
                            fields={[{
                                type: 'hidden',
                                value: this.props.id,
                                name: 'fid',
                                options: {
                                            required: false,
                                        },
                            }]} />
                    </td>
                    {this.props.curPart === 1?(
                        <td rowSpan={this.props.curPart===1 && this.props.totPart > 1?this.props.totPart:1}>
                            <Form   
                                successSubmit = {(data) => this.onSuccess(data)}
                                failSubmit = {(data) => this.onFailure(data)}
                                url = {'Tasks/markFinalValidation.php'}
                                buttonValue={Strings.text.button.markValid}
                                buttonType='success'
                                enableButton='false'
                                fields={[{
                                    type: 'hidden',
                                    value: this.props.id,
                                    name: 'fid',
                                    options: {
                                                required: true,
                                            },
                            }]} />
                        </td>

                    ):null}
                    
                </React.Fragment>
            
        );
    }
}

class _List extends React.Component{
    render(){
        return(
            <List 
                listPermission={29}
                list={this.props.list}
                headings={{
                            checkit: '',
                            fileName:'fileName',
                            gotToServer:'datetime',
                            associatedDictator:'dictator_name',
                            tat:'tat',
                            processedInTime:'',
                            downloadFile: '',
                        }}
                singleItem={(item) => (<Single 
                    id={item.id}
                    fileName={item.fileName} 
                    selected={'selected' in item?item.selected:0}
                    checkboxCallback={this.props.checkboxCallback}
                    audio={item.audio}
                    gotToServer={item.datetime} 
                    dictator_name={item.dictator_name}
                    tat={item.tat}
                    downloadFile={item.url}
                    curPart={item.curPart}
                    totPart={item.totPart}
                    refreshList={this.props.refreshList}
                />)}
                listContainerCSS={this.props.listContainerCSS}
            />
        );
    }
}

export {Single as FileSingleList, _List as FileListList};

/*
    for FileSingle -
        fileName, fileType

    for FileList -
        onListClick, list, 
*/