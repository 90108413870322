import { RichUtils, KeyBindingUtil } from 'draft-js';

const {hasCommandModifier} = KeyBindingUtil;

export default () => {
    return {
        customStyleMap: {
            'CAPITALIZE': {
                textTransform: 'capitalize',
            },
        },
        keyBindingFn: e => {
            if (e.keyCode === 53 && e.altKey) {
                //5
                return "capitalize";
            }
        },
        handleKeyCommand: (command, editorState, eventTimeStamp, { setEditorState }) => {
            if (command === "capitalize") {
                setEditorState(RichUtils.toggleInlineStyle(editorState, "CAPITALIZE"));
                return true;
            }
        }
    };
  };