import React from 'react';
import Strings from 'Strings.js';
import axios from 'axios/axios.js';




class UsersListWithTaskTime extends React.Component{
 
    state = {
        poollist: [],
        userlist: [],
    }

    

    componentDidMount(){
        if(this.state.poollist.length === 0){
            axios.post('Users/usersListWithTaskTime.php?forAType='+this.props.type+'&siid='+this.props.siid, {}).then(response => {
                if(response.data.r === 1)
                {
                    this.setState({
                        ...this.state,
                        poollist: response.data.d.pools,
                        userlist: response.data.d.users,
                    });
                }
            }).catch(error => {
                
            });
        }
    }

    
    render(){
        var totalAllottedTime = 0;
        const poolslist = this.state.poollist.map((item, index) => {
            totalAllottedTime += parseInt(item.duration);
            var dura = Math.floor(parseInt(item.duration) % 60);
            var durhours = Math.floor(item.duration / 60);
            return (
                <React.Fragment>
                    <tr>
                        <td style={{padding:'0 10px'}}>{item.id}</td>
                        <td style={{padding:'0 10px'}}>{item.name}</td>
                        <td style={{padding:'0 10px'}}>{durhours > 9?durhours:'0'+durhours}{':'}{dura > 9?dura:'0'+dura}</td>
                    </tr>
                </React.Fragment>
                
            );
        });
        const userslist = this.state.userlist.map((item, index) => {
            totalAllottedTime += parseInt(item.duration);
            var dura = Math.floor(parseInt(item.duration) % 60);
            var durhours = Math.floor(item.duration / 60);
            return (
                <tr>
                    <td style={{padding:'0 10px'}}>{item.id}</td>
                    <td style={{padding:'0 10px'}}>{item.name}</td>
                    <td style={{padding:'0 10px'}}>{durhours > 9?durhours:'0'+durhours}{':'}{dura > 9?dura:'0'+dura}</td>
                </tr>
            );
        });

        var allotHours = Math.floor(totalAllottedTime / (60*60));
        var allotMins = Math.floor((totalAllottedTime % (60*60))/60);
        var allotSecs = Math.floor((totalAllottedTime % (60*60)) % 60);


        return(
            <div>
                <table>
                    <thead>
                        <tr style={{background:'#eee'}}>
                            <th style={{padding:'0 10px'}}>{Strings.text.label.poolId}</th>
                            <th style={{padding:'0 10px'}}>{Strings.text.label.poolName}</th>
                            <th style={{padding:'0 10px'}}>{Strings.text.label.allottedTime}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {poolslist}
                    </tbody>
                </table>
                    
                <table>
                    <thead>
                        <tr style={{background:'#eee'}}>
                            <th style={{padding:'0 10px'}}>{Strings.text.label.userId}</th>
                            <th style={{padding:'0 10px'}}>{Strings.text.label.userName}</th>
                            <th style={{padding:'0 10px'}}>{Strings.text.label.allottedTime}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {userslist}
                    </tbody>
                </table>
                <div style={{marginTop:'20px',borderTop:'1px solid #aaa'}}>
                    <b>{Strings.text.label.totalAllottedTime}</b>
                    {' - '}
                    {allotHours > 9?allotHours:allotHours}{':'}
                    {allotMins > 9?allotMins:allotMins}{':'}
                    {allotSecs > 9?allotSecs:allotSecs}
                </div>
            </div>
        )
    }
}



export default UsersListWithTaskTime; 