import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import Loader from 'components/Loader/Loader';
import {RichUtils, Modifier, EditorState, getDefaultKeyBinding, SelectionState, ContentBlock, genKey, ContentState} from 'draft-js';
import {getSelectionEntity, getEntityRange, getSelectedBlock, getSelectionText} from 'draftjs-utils';
import Immutable from 'immutable';
import Editor, {composeDecorators} from "draft-js-plugins-editor";
import createAlignmentPlugin from 'draft-js-alignment-plugin';
import createImagePlugin from 'draft-js-image-plugin';
import createFocusPlugin from 'draft-js-focus-plugin';
import createResizeablePlugin from 'draft-js-resizeable-plugin';


import ImageAdd from 'components/Plugins/ImageAdd';
import createHighlightPlugin from 'components/Plugins/highlight';
import createSuperscriptPlugin from 'components/Plugins/superscript';
import createSubscriptPlugin from 'components/Plugins/subscript';
import createStrikethroughPlugin from 'components/Plugins/strikethrough';
import createLowerCasePlugin from 'components/Plugins/lowercase';
import createUpperCasePlugin from 'components/Plugins/uppercase';
import createCapitalizePlugin from 'components/Plugins/capitalize';
import createFontFamilyPlugin from 'components/Plugins/fontfamily';
import createFontSizePlugin from 'components/Plugins/fontsize';
import createFontColorPlugin from 'components/Plugins/fontcolor';
import isSoftNewlineEvent from 'draft-js/lib/isSoftNewlineEvent';

import {stateToHTML} from 'draft-js-export-html';

import FormTable from 'components/FormTable/FormTable';
import PartialText from 'components/FormTable/PartialText';
import SearchReplace from 'components/FormTable/SearchReplace';
import GradingBox from 'components/FormTable/GradingBox';
import axios from 'axios/axios.js';
import Classes from './input.module.css';
import Strings from 'Strings.js';
import 'draft-js-image-plugin/lib/plugin.css';

import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import FormControl from 'react-bootstrap/FormControl'
import Button from 'react-bootstrap/Button'
import Accordion from 'react-bootstrap/Accordion';
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';
import Card from 'react-bootstrap/Card';
import runningPage from 'global';


const highlightPlugin = createHighlightPlugin();
const superscriptPlugin = createSuperscriptPlugin();
const subscriptPlugin = createSubscriptPlugin();
const strikePlugin = createStrikethroughPlugin();
const lowerCasePlugin = createLowerCasePlugin();
const upperCasePlugin = createUpperCasePlugin();
const capitalizePlugin = createCapitalizePlugin();
const fontFamilyPlugin = createFontFamilyPlugin();
const fontSizePlugin = createFontSizePlugin();
const fontColorPlugin = createFontColorPlugin();
const alignmentPlugin = createAlignmentPlugin();

const focusPlugin = createFocusPlugin();
const resizeablePlugin = createResizeablePlugin();
const { AlignmentTool } = alignmentPlugin;


const decorator = composeDecorators(
    resizeablePlugin.decorator,
    alignmentPlugin.decorator,
    focusPlugin.decorator,
  );


const imagePlugin = createImagePlugin({decorator});

const DropDownList = (props) => {
    const dropDownRef = React.useRef();
    const [show, setShow] = React.useState(false);

    React.useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        const handleClickOutside = (event) => {
            if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
                setShow(false);
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div className={Classes.dropDownContainer} ref={dropDownRef}>
            <div className={Classes.dropDownSelector} onClick={() => setShow(!show)} style={show===true?{background: '#138496', color: 'white'}:{}}>Select {show===true?'⏶':'⏷'}</div>
            <div className={Classes.dropDownList} style={{display:show===true?'block':'none'}}>
                {props.children}
            </div>
        </div>
    );
}

const CustomToggle = (props) => {
    const decoratedOnClick = useAccordionToggle(props.val.name);
  
    return (
        <div className={props.class}>
            <Form.Check type={'checkbox'} 
                key={props.val.name+'-'+props.val.selected}
                style={{float: 'left'}}
                value={'selectAllCheckBox-'+props.val.name}
                onChange={props.onChange}
                defaultChecked={props.val.selected===true?'checked':null}
            />
            <span
                style={{ cursor: 'pointer' }}
                onClick={decoratedOnClick}
            >
                {props.val.value}
            </span>
        </div>
    );
}

class Input extends Component{
    constructor(props){
        super(props);
        this.shiftf3 = 1;
        this.startF = 1;

        this.state = {
            expand: false,
            curPageNum: 1,
            curPageNumInput: 1,
            fontSize: '',
            hangingVal: 0,
        }

        this.totalPages = 1;

        this.currentSelectionState = null;
        this.editorInFocus = false;
        this.newEditorStateSet = null;
        this.grading = [];
        try{
            this.grading = this.props.options.grading;
        }
        catch(e){}

        this.fontSizeRef = React.createRef();
        this.hangingRef = React.createRef();
        
        this.plugins = [
            highlightPlugin,
            superscriptPlugin,
            subscriptPlugin,
            strikePlugin,
            lowerCasePlugin,
            upperCasePlugin,
            capitalizePlugin,
            fontFamilyPlugin,
            fontSizePlugin,
            fontColorPlugin,
            alignmentPlugin,
            imagePlugin,
            focusPlugin,
            resizeablePlugin,

        ];

        
        console.log(props);
    }

    componentDidMount(){
        if('audioPlayer' in this.props.options){
            this.setAudioTime();
            document.addEventListener("keydown", this.footPedalFunc);
            if(localStorage.getItem('externalPlayerAlways') !== null){
                this.downloadAudio(null, this.props.options.audioPlayer);
            }
            if(localStorage.getItem('playback_speed') !== null){
                setTimeout(()=>{
                    var audp = document.getElementById('audioPlayer');
                    var plspeed = parseInt(localStorage.getItem('playback_speed'));
                    audp.playbackRate = (plspeed / 100);
                }, 3000);
                
            }
        }
        var this2 = this;
        setTimeout(() => {var ele = document.getElementById('commentContainer'); if(ele) this2.dragElement(ele)}, 100);
        setTimeout(() => {var ele = document.getElementById('tableContainer'); if(ele) this2.dragElement(ele)}, 100);
        setTimeout(() => {var ele = document.getElementById('partialTextContainer'); if(ele) this2.dragElement(ele)}, 100);
        setTimeout(() => {
            if(this2.domEditor){
                this2.setEditorFocusCursorEnd();
            }
        }, 1000);
        document.addEventListener('keydown', this.partsListEvent);
    }

    setAudioTime = () => {
        var audT = document.getElementById('audioPlayer');
        if(audT)
            audT.currentTime = this.props.options.audioTime;
        else
            setTimeout(this.setAudioTime, 200);
    }

    footPedalFunc = (event) => {
        if(event.which == 114 || event.which == 115 || event.which == 118)
            event.preventDefault();
        switch (event.which) {
            case 114:
                this.rewind(null, 2);
                break;
            case 115:
                this.forward(null, 2);
                break;
            case 118:
                this.rewind(null, 2);
                this.playpause();
                break;
            default:
                break;
        }
    }

    componentWillUnmount(){
        document.removeEventListener('keydown', this.partsListEvent);
        if('audioPlayer' in this.props.options)
            document.removeEventListener("keydown", this.footPedalFunc);
    }


    partsListEvent = (e) => {
        if(runningPage.page === 'taskopen'){
            if(e.keyCode === 27){
                var taCont = document.getElementById('tableContainer');
                if(taCont && taCont.style.display != 'none'){
                    this.props.commentStarting([2,0]);
                    taCont.style.display = 'none';
                    document.getElementById('editorMoreInfo').style.display = 'block';
                    document.getElementById('focusEditorBut').click();
                } else{
                    taCont = document.getElementById('partialTextContainer');
                    if (taCont && taCont.style.display != 'none'){
                        taCont.style.display = 'none';
                        
                        document.getElementById('focusEditorBut').click();
                    } else{
                        taCont = document.getElementById('demoPreviewCloseButton');
                        if (taCont){
                            if(this.props.passToInput && 'demoPreviewClose' in this.props.passToInput){
                                this.props.passToInput.demoPreviewClose(() => document.getElementById('focusEditorBut').click());
                            }
                        } else {
                            taCont = document.getElementById('commentContainer');
                            if (taCont && taCont.style.display != 'none'){
                                taCont.style.display = 'none';
                                document.getElementById('editorMoreInfo').style.display = 'block';
                                document.getElementById('focusEditorBut').click();
                            }
                        }
                    }
                } 
            }
        }
    }

    

    

   findWithRegex = (regex, contentBlock, callback) => {
    const text = contentBlock.getText();
    let matchArr, start, end;
    while ((matchArr = regex.exec(text)) !== null) {
      start = matchArr.index;
      end = start + matchArr[0].length;
      callback(start, end);
    }
  };

   onReplace = (editorState, toreplace, by) => {
    if(document.activeElement.classList.contains('public-DraftEditor-content'))
        this.editorInFocus = true;

    const regex = new RegExp(toreplace, 'g');
    const selectionsToReplace = [];
    const blockMap = editorState.getCurrentContent().getBlockMap();
  
    blockMap.forEach((contentBlock) => (
      this.findWithRegex(regex, contentBlock, (start, end) => {
        const blockKey = contentBlock.getKey();
        const blockSelection = SelectionState
          .createEmpty(blockKey)
          .merge({
            anchorOffset: start,
            focusOffset: end,
          });
  
        selectionsToReplace.push(blockSelection)
      })
    ));
    
    let contentState = editorState.getCurrentContent();
  
    selectionsToReplace.forEach(selectionState => {
      contentState = Modifier.replaceText(
        contentState,
        selectionState,
        by,
      )
    });
    
    if(this.editorInFocus){
        this.props.onChangeEditor(EditorState.forceSelection(EditorState.push(
            editorState,
            contentState,
        ), this.currentSelectionState));
    }
    else{
        this.props.onChangeEditor(EditorState.push(
            editorState,
            contentState,
        ));
    }
      //const newState = SelectionState.createEmpty();
      //newState.merge(this.currentSelectionState);
  }

    myBlockRenderer = (contentBlock) => {
        
        /*return {
            component: MediaComponent,
            
          };*/
    }
    
    myBlockStyleFn = (contentBlock) => {
        
        var data = contentBlock.getData();
        var toreturn = [];
        if(this.startF==1){
            toreturn.push('showHardReturn');
            if(contentBlock.getText() == '')
                toreturn.push('emptyBlock');
            else
                toreturn.push('noEmptyBlock');
        }
        if(data.has('data')){
            var datadata = data.get('data');

            if('indentation' in datadata){
                toreturn.push('indentation'+datadata['indentation']);
            }

            if('hanging' in datadata){
                toreturn.push('hanging'+datadata['hanging']);
            }

            if('lineHeight' in datadata){
                switch(datadata['lineHeight']){
                    case 1:
                        toreturn.push('lh1');
                        break;
                    case 1.5:
                        toreturn.push('lh1-5');
                        break;
                    case 2:
                        toreturn.push('lh2');
                        break;
                    default:
                        toreturn.push('lh1');
                        break;
                }
            }
            else{
                toreturn.push('lh1');
            }

            if('textAlign' in datadata){
                switch(datadata['textAlign']){
                    case 'left':
                        toreturn.push('tAL');
                        break;
                    case 'center':
                        toreturn.push('tAC');
                        break;
                    case 'right':
                        toreturn.push('tAR');
                        break;
                    case 'justify':
                        toreturn.push('tAJ');
                        break;
                }
            }
        }
        else{
            toreturn.push('lh1');
        }

        return toreturn.join(' ');
    }

    createNewBlock = (editorState, contentState, newDataToPass) => {
        var blockMap = contentState.getBlockMap();
        console.log("newdatatopass", newDataToPass);
        for(var v in newDataToPass){
          blockMap = blockMap.set(v, blockMap.get(v).merge({
            data: Immutable.Map(newDataToPass[v]), 
          }));
          
        }
        
        const newContentState = contentState.merge({
          blockMap: blockMap,
          selectionAfter: editorState.getSelection(),
        });
    
        //console.log("newContentState", newContentState.getBlockMap());
        return EditorState.push(editorState, newContentState, 'change-block-type');
      }


      createEmptyBlock = (editorState) => {
        const newBlock = new ContentBlock({
            key: genKey(),
            type: "unstyled",
            text: "I",
            characterList: Immutable.List()
        })
    
        const contentState = editorState.getCurrentContent()
        const newBlockMap = contentState.getBlockMap().set(newBlock.getKey(), newBlock)
    
        return EditorState.push(
            editorState,
            ContentState
                .createFromBlockArray(newBlockMap.toArray())
                .set('selectionAfter', contentState.getSelectionAfter().merge({
                    anchorKey: newBlock.getKey(),
                    anchorOffset: 0,
                    focusKey: newBlock.getKey(),
                    focusOffset: 0,
                    isBackward: false,
                })),
            "split-block"
        )
    }

    getMultiSelection = (changeData, toAddVal) => {
        //this.props.changeReRender(0);
        const {editorState} = this.props;
        
        const contentState = editorState.getCurrentContent();
        const selectionState = editorState.getSelection();
    
        var startKey = selectionState.getStartKey();
        var endKey = selectionState.getEndKey();
        
    
        const blockMap = contentState.getBlockMap();
    
        var firstBlock = 0;
        var lastBlock = 0;
    
        var newDataToPass = {};
    
        for(const [key, value] of blockMap.entries()){
          if(!firstBlock && value.getKey() !== startKey)
          {
            continue;
          }
          else if(!firstBlock && value.getKey() === startKey){
            firstBlock = 1;
            const data = value.getData();
            var data1 = {};
            if(data.has("data"))
                data1 = data.get("data");

            for(var x in changeData)
            {
                if(toAddVal){
                    if(x in data1){
                        if(data1[x]+changeData[x] > 0)
                            data1[x] += changeData[x];
                        else if(data1[x]+changeData[x] <= 0)
                            delete data1[x];
                    }
                    else{
                        if(changeData[x] > 0)
                            data1[x] = changeData[x];
                    }
                }
                else
                    data1[x] = changeData[x];
            }
            
            newDataToPass[value.getKey()] = data.set("data", data1);
          //  newEditorState = this.createNewBlock(newEditorState, newEditorState.getCurrentContent(), value, blockMap, value.getKey(), newEditorState.getSelection(), newData);
          }
          else if(firstBlock && !lastBlock && value.getKey() !== startKey && value.getKey() !== endKey && startKey !== endKey){
            var data = value.getData();
            var data1 = {};
            if(data.has("data"))
                data1 = data.get("data");
                
            for(var x in changeData)
            {
                if(toAddVal){
                    if(x in data1){
                        if(data1[x]+changeData[x] > 0)
                            data1[x] += changeData[x];
                        else if(data1[x]+changeData[x] <= 0)
                            delete data1[x];
                    }
                    else{
                        if(changeData[x] > 0)
                            data1[x] = changeData[x];
                    }
                }
                else
                    data1[x] = changeData[x];
            }
            
            newDataToPass[value.getKey()] = data.set("data", data1);
          }
          else if(!lastBlock && value.getKey() === endKey && startKey !== endKey){
            lastBlock = 1;
            var data = value.getData();
            var data1 = {};
            if(data.has("data"))
                data1 = data.get("data");
            
            for(var x in changeData)
            {
                if(toAddVal){
                    if(x in data1){
                        if(data1[x]+changeData[x] > 0)
                            data1[x] += changeData[x];
                        else if(data1[x]+changeData[x] <= 0)
                            delete data1[x];
                    }
                    else{
                        if(changeData[x] > 0)
                            data1[x] = changeData[x];
                    }
                }
                else
                    data1[x] = changeData[x];
            }
    
           newDataToPass[value.getKey()] = data.set("data", data1);
          }
          else if(lastBlock && value.getKey() !== endKey)
          {
            break;
    
          }
    
          
        }
        //this.props.onChangeEditor(this.createNewBlock(editorState, contentState, newDataToPass));
        
        this.newEditorStateSet = this.createNewBlock(editorState, contentState, newDataToPass);
      //  this.props.changeReRender(1);
      }

   lineHeight = (event) => {
       event.preventDefault();
        const type = event.target.value;

        this.getMultiSelection({lineHeight: (type == '1'?1:(type == '1.5'?1.5:(type == '2'?2:1)))}, 0);

        this.editorInFocus = false;
        this.domEditor.focus();
    }

    justificationShortcut = (event, type) => {
        const {editorState} = this.props;
        const selection = editorState.getSelection();
        
        this.getMultiSelection({textAlign: type}, 0);
        this.domEditor.blur();
        const this2 = this
        setTimeout(() => {
            this2.domEditor.focus();
            console.log("justification shortcut 4");
            this2.props.onChangeEditor(EditorState.forceSelection(
            this2.props.editorState,
            this2.props.editorState.getSelection().merge(selection)
            
          ))
        }, 50);
 
             
        // this.editorInFocus = false;
         
     } 

    
     indentationShortcut = (event, amount) => {
        const {editorState} = this.props;
        const selection = editorState.getSelection();
        
        this.getMultiSelection({indentation: amount}, 1);
        this.domEditor.blur();
        const this2 = this
        setTimeout(() => {
            this2.domEditor.focus();
            this2.props.onChangeEditor(EditorState.forceSelection(
            this2.props.editorState,
            this2.props.editorState.getSelection().merge(selection)
        ))
        }, 50);
     }

    
   justification = (event, type) => {
       if(event)
        event.preventDefault();
        
        this.getMultiSelection({textAlign: type}, 0);

            
        this.editorInFocus = false;
        this.domEditor.focus();
    } 

    indentation = (event, amount) => {
        if(event)
            event.preventDefault();
        
        this.getMultiSelection({indentation: amount}, 1);

            
        this.editorInFocus = false;
        this.domEditor.focus();
    }
    
    onHangingSelect = (event) => {
        event.preventDefault();
        if(event.keyCode === 13){
            var eventVal = event.target.value;
            
        
            this.getMultiSelection({hanging: eventVal}, 0);

                
            this.editorInFocus = false;
            this.domEditor.focus();
            return false;
        }
    };

    createNewBlockWithPageNum = () => {
        console.log("createNewBlock")
        const {editorState} = this.props;
        const newBlock = new ContentBlock({
            key: genKey(),
            type: "unstyled",
            text: "*****PAGE BREAK - TYPE FROM NEXT LINE*****",
            characterList: Immutable.List()
        })

        var selection = editorState.getSelection();
        const contentState = editorState.getCurrentContent();
        const blockMap = contentState.getBlockMap();
        var blockMapArray = blockMap.toArray();

        var currentBlockKey = selection.getStartKey();
        var ind = 0;
        blockMap.forEach(contentBlock => {
            if(contentBlock.getKey() == currentBlockKey){
                if(ind == blockMapArray.length-1)
                    blockMapArray.push(newBlock)
                else
                    blockMapArray.splice(ind+1, 0, newBlock)
            }
            ind++;
        })

        var newContentState = Modifier.splitBlock(editorState.getCurrentContent(), selection.merge({
            anchorKey: currentBlockKey,
            anchorOffset: 50000,
            focusKey: currentBlockKey,
            focusOffset: 50000,
            isBackward: false,
        }))
        var newBlockKey = newContentState.selectionAfter.getStartKey();
        //var newBlockKey = newContentState.selectionAfter.getStartKey();
        //var newBlockMap = newContentState.getBlockMap()
        
        console.log(blockMapArray, ContentState.createFromBlockArray(blockMapArray))
        
        this.props.onChangeEditor(EditorState.push(
            editorState,
            newContentState.set('selectionAfter', newContentState.getSelectionAfter().merge({
                anchorKey: newBlockKey,
                anchorOffset: 0,
                focusKey: newBlockKey,
                focusOffset: 0,
                isBackward: false,
            })),
           'split-block'
        ))
    }

    openCommentBox = () => {
        this.props.commentStarting([1,0]);
        const {editorState} = this.props;
        const selection = editorState.getSelection();
        var commentContainer = document.getElementById('commentContainer');
        const moreInfo = document.getElementById('editorMoreInfo')
        if (!selection.isCollapsed()) {
            
            if(commentContainer){
                commentContainer.style.display = 'flex';
                
                if(moreInfo)
                    moreInfo.style.display = 'none';

                const contentState = editorState.getCurrentContent();
                const startKey = editorState.getSelection().getStartKey();
                const startOffset = editorState.getSelection().getStartOffset();
                const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey);
                const linkKey = blockWithLinkAtBeginning.getEntityAt(startOffset);

                
                var noPrevComment = 1;

                if (linkKey) {
                    const ent = contentState.getEntity(linkKey);
                    if(ent && ent.getType()=='COMMENT'){
                        var {comment} = ent.getData();
                        var commentBox = document.getElementById('commentBox');
                        var allComments = comment.map((elem,inde) => {
                            return (<div style={{background:'#FFF',marginBottom:'5px',padding:'10px'}}><b>{elem.user}</b>: <a href={'javascript:void(0)'} style={{float:'right',color:'blue',textDecoration:'underline',cursor:'pointer'}} onClick={(e)=>this.props.deleteComment(e, inde, this.props.inputIndex, this.openCommentBox)}>Delete</a><div>{elem.comment}</div></div>);
                        });
                        ReactDOM.render(<div><div style={{overflow:'hidden'}}><a href={'javascript:void(0)'} style={{float:'right',color:'blue',textDecoration:'underline',cursor:'pointer'}} onClick={(e)=>this.props.deleteComment(e, -1, this.props.inputIndex, this.openCommentBox)}>Delete All Comments</a></div>{allComments}</div>, commentBox);
                    
                        noPrevComment = 0;
                    }
                }

                if(noPrevComment){
                    var commentBox = document.getElementById('commentBox');
                    ReactDOM.unmountComponentAtNode(commentBox)
                //    commentBox.innerHTML = '';
                }

                document.getElementById('commentTextarea').focus();

                this.props.commentStarting([1,1]);
            }

        } else {
            var ent = getSelectionEntity(editorState);
            if(commentContainer && !this.commentStarting){
                var ctstate = editorState.getCurrentContent();
                if(ent && ctstate.getEntity(ent).getType()=='COMMENT'){
                    if(moreInfo)
                        moreInfo.style.display = 'none';
                    var {comment} = ctstate.getEntity(ent).getData();
                    
                    var commentBox = document.getElementById('commentBox');
                    commentContainer.style.display = 'flex';
                    var allComments = comment.map((elem,inde) => {
                        return (<div style={{background:'#FFF',marginBottom:'5px',padding:'10px'}}><b>{elem.user}</b>: <a href={'javascript:void(0)'} style={{float:'right',color:'blue',textDecoration:'underline',cursor:'pointer'}} onClick={(e)=>this.props.deleteComment(e, inde, this.props.inputIndex, this.openCommentBox)}>Delete</a><div>{elem.comment}</div></div>);
                    });
                    ReactDOM.render(<div><div style={{overflow:'hidden'}}><a href={'javascript:void(0)'} style={{float:'right'}} onClick={(e)=>this.props.deleteComment(e, -1, this.props.inputIndex, this.openCommentBox)}>Delete All Comments</a></div>{allComments}</div>, commentBox);
                    document.getElementById('commentTextarea').focus();
                }
                else{
                    commentContainer.style.display = 'none';
                }
                this.props.commentStarting([1,1]);
            }
        }
    }

    mapKeyToEditorCommand = (e) => {
        console.log("map key to editory", e.keyCode);

        if (e.keyCode === 9 /* TAB */) {
            const {editorState} = this.props;
            const selection = editorState.getSelection();
        
            const contentState = editorState.getCurrentContent();
            const blockType = contentState
            .getBlockForKey(selection.getStartKey())
            .getType();

            if(blockType === "unordered-list-item" || blockType === "ordered-list-item")
            {
                const newEditorState = RichUtils.onTab(
                    e,
                    this.props.editorState,
                    4, /* maxDepth */
                );
                if (newEditorState !== this.props.editorState) 
                {
                    console.log("map key change called");
                    this.props.onChangeEditor(newEditorState);
                }
            }
            else{
                this.addCharacter(null, '\t');
                return 'handled';
            }
          return;
        }
        else if(e.keyCode === 32){
            //space
            const {editorState} = this.props;
            const selection = editorState.getSelection();
            const contentState = editorState.getCurrentContent();
            const startKey = selection.getStartKey();
            const startOffset = selection.getStartOffset();
            const blockWithCursor = contentState.getBlockForKey(startKey);
            var strtext = blockWithCursor.getText();
            var requiredText = '';
            var newEndOffset = 0;
            for(var k=startOffset-1; k>=0; k--){
                if(strtext[k] == " ")
                    break;
                else{
                    requiredText += strtext[k];
                    newEndOffset = k;
                }
            }
            requiredText = requiredText.split("").reverse().join("").toLowerCase();
            var partText = localStorage.getItem('PartialText');
            var alreadyText = '';
            if(partText){
                partText = JSON.parse(partText);
                if('did' in this.props.options){
                    if(this.props.options.did in partText){
                        if(requiredText in partText[this.props.options.did])
                            alreadyText = partText[this.props.options.did][requiredText]+' ';
                    }
                }
                
                if(alreadyText.length == 0)
                {
                    var partTextKeys = Object.keys(partText);
                    for(var o=0; o<partTextKeys.length; o++){
                        if(requiredText in partText[partTextKeys[o]]){
                            alreadyText = partText[partTextKeys[o]][requiredText]+' ';
                            break;
                        }
                    }
                    
                }
                
                if(alreadyText.length > 0){
                    this.props.onChangeEditor(EditorState.push(
                        editorState,
                        Modifier.replaceText(
                            contentState,
                            selection.merge({
                                focusOffset: startOffset,
                                anchorOffset: newEndOffset,
                            }),
                            alreadyText,
                            editorState.getCurrentInlineStyle()
                        )
                    ));

                    return 'handled';
                }

            }
        }
        else if(e.altKey && e.ctrlKey){
            if(e.keyCode === 81){
                //q
                this.justificationShortcut(null, 'left');
                //this.domEditor.blur();
                //const this2 = this;
               // setTimeout(() => this2.domEditor.focus(), 0);
                return 'handled';
            }
            else if(e.keyCode === 87){
                //w
                this.justificationShortcut(null, 'center');
               // this.domEditor.blur();
               // const this2 = this;
               // setTimeout(() => this2.domEditor.focus(), 0);
                return 'handled';
            }
            else if(e.keyCode === 69){
                //e
                this.justificationShortcut(null, 'right');
               // this.domEditor.blur();
               // const this2 = this;
              //  setTimeout(() => this2.domEditor.focus(), 0);
                return 'handled';
            }
            else if(e.keyCode === 82){
                //r
                this.justificationShortcut(null, 'justify');
                //this.domEditor.blur();
               // const this2 = this;
             //   setTimeout(() => this2.domEditor.focus(), 0);
                return 'handled';
            }
            else if(e.shiftKey && e.keyCode === 73){
                //i
                this.indentationShortcut(null, -1);
                return 'handled';
            }
            else if(e.keyCode === 73){
                //i
                this.indentationShortcut(null, 1);
                return 'handled';
            }
            else if(e.keyCode === 66){
                //b
                this.addCharacter(null, '_____');
                return 'handled';
            }
            
            else if(e.keyCode === 79){
                //o
                this.props.onChangeEditor(
                    RichUtils.toggleBlockType(this.props.editorState, "ordered-list-item")
                )
                 return 'handled';
            }
            else if(e.keyCode === 85){
                //u
                this.props.onChangeEditor(
                    RichUtils.toggleBlockType(this.props.editorState, "unordered-list-item")
                )
                 return 'handled';
            }
            else if(e.keyCode === 68){
                //ctrl+alt+d - for demo preview box
                if(this.props.passToInput && 'demoPreview' in this.props.passToInput){
                    this.props.passToInput.demoPreview(this.setEditorFocus);
                    return 'handled';
                }
            }
            else if(e.keyCode === 67){
                // ctrl+alt+c for commenting
                this.openCommentBox();
                return 'handled';
            }
            else if(e.keyCode === 84){
                // ctrl+alt+t for table
                    const {editorState} = this.props;
                    const selection = editorState.getSelection();

                    var ctstate = editorState.getCurrentContent();
                    
                    
                    var tableContainer = document.getElementById('tableContainer');
                    const moreInfo = document.getElementById('editorMoreInfo')

                    if (!selection.isCollapsed()) {
                        if(tableContainer){
                            tableContainer.style.display = 'block';
                            if(moreInfo)
                                moreInfo.style.display = 'none';

                            const contentState = editorState.getCurrentContent();
                            const startKey = editorState.getSelection().getStartKey();
                            const startOffset = editorState.getSelection().getStartOffset();
                            const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey);
                            const linkKey = blockWithLinkAtBeginning.getEntityAt(startOffset);

                            
                            var noPrevTable = 1;

                            if (linkKey) {
                                const ent = contentState.getEntity(linkKey);
                                if(ent && ent.getType()=='TABLE'){
                                    var {table} = ent.getData();
                                    var tableBox = document.getElementById('tableBox');
                                    ReactDOM.unmountComponentAtNode(tableBox)
                                    ReactDOM.render(<FormTable postTable={(data)=>this.props.tablePostFunc(data)} table={table} />, tableBox);
                                
                                    noPrevTable = 0;
                                }
                            }

                            if(noPrevTable){
                                var tableBox = document.getElementById('tableBox');
                                ReactDOM.unmountComponentAtNode(tableBox)
                                ReactDOM.render(<FormTable postTable={(data)=>this.props.tablePostFunc(data)} table={[['']]} />, tableBox);
                                
                            //    commentBox.innerHTML = '';
                            }

                            this.props.commentStarting([2,1]);
                        }

                    } else {
                        var ent = getSelectionEntity(editorState);
                        if(ent && ctstate.getEntity(ent).getType()=='TABLE'){
                            if(moreInfo)
                                moreInfo.style.display = 'none';
                            var {table} = ctstate.getEntity(ent).getData();
                            
                            tableContainer.style.display = 'block';
                            var tableBox = document.getElementById('tableBox');
                            ReactDOM.unmountComponentAtNode(tableBox)
                            ReactDOM.render(<FormTable postTable={(cbdata)=>this.props.tablePostFunc(cbdata)} table={table} />, tableBox);
                                
                        
                        }
                        else{
                            tableContainer.style.display = 'none';
                        }
                        this.props.commentStarting([2,1]);
                    }
                return 'handled';
            }
            else if(e.keyCode === 80){
                // ctrl+alt+p for phrase shortcut
                
                var strtxt = '';
                var partialTextContainer = document.getElementById('partialTextContainer');
                if(partialTextContainer){
                    partialTextContainer.style.display = 'block';
                    var partialTextBox = document.getElementById('partialTextBox');
                    ReactDOM.unmountComponentAtNode(partialTextBox)
                    ReactDOM.render(<PartialText shortcut={strtxt} did={'did' in this.props.options?this.props.options.did:0} />, partialTextBox);
                }
                return 'handled';
            }
            else if(e.keyCode === 70){
                // ctrl+alt+f for replace shortcut
                
                var strtxt = getSelectionText(this.props.editorState);
                var partialTextContainer = document.getElementById('partialTextContainer');
                if(partialTextContainer){
                    partialTextContainer.style.display = 'block';
                    var partialTextBox = document.getElementById('partialTextBox');
                    ReactDOM.unmountComponentAtNode(partialTextBox)
                    ReactDOM.render(<SearchReplace search={strtxt} onClick={(searchStr, replaceStr) => this.replaceText(searchStr, replaceStr)} />, partialTextBox);
                }
                return 'handled';
            }
            else if(e.keyCode === 71){
                // ctrl+alt+g for grading shortcut
                
                var strtxt = getSelectionText(this.props.editorState);
                var partialTextContainer = document.getElementById('partialTextContainer');
                if(partialTextContainer){
                    partialTextContainer.style.display = 'block';
                    var partialTextBox = document.getElementById('partialTextBox');
                    ReactDOM.unmountComponentAtNode(partialTextBox)
                    ReactDOM.render(<GradingBox tid={this.props.options.tid} grading={this.grading} toReplace={strtxt} onClick={(data) => this.replaceThisTextOnly(data)} />, partialTextBox);
                }
                return 'handled';
            }
        }
        else if(e.ctrlKey){
            if(e.keyCode === 83){
                //ctrl+s - for save
                if(this.props.handleSubmit){
                    this.props.handleSubmit();
                    return 'handled';
                }
            }
            else if(e.keyCode === 80){
                //ctrl+p - for post
                if(this.props.passToInput && 'post' in this.props.passToInput){
                    this.props.passToInput.post();
                    return 'handled';
                }
            }
            else if(e.keyCode === 81){
                //ctrl+q - for preview
                if(this.props.passToInput && 'preview' in this.props.passToInput){
                    this.props.passToInput.preview();
                    return 'handled';
                }
            }
            else if(e.keyCode === 70 && e.shiftKey){
                //ctrl+shift+f - for forward
                if(this.props.passToInput && 'forward' in this.props.passToInput){
                    this.props.passToInput.forward();
                    return 'handled';
                }
            }
            else if(e.keyCode === 46 && e.shiftKey){
                //ctrl+shift+del - for delete
                if(this.props.passToInput && 'delete' in this.props.passToInput){
                    this.props.passToInput.delete();
                    return 'handled';
                }
            }
        }
        else if(e.altKey){
            if(e.keyCode === 39){
                //alt+ right arrow - for preview
                if(this.props.passToInput && 'nextPart' in this.props.passToInput){
                    this.props.passToInput.nextPart();
                    return 'handled';
                }
            } else if (e.keyCode === 77) {
                //d
                this.addCharacter(null, 'μ');
                return 'handled';
            } else if (e.keyCode === 68) {
                //m
                this.addCharacter(null, '°');
                return 'handled';
            }
        }
        
      }

      findWithRegex = (regex, contentBlock, callback) => {
        const text = contentBlock.getText();
        let matchArr, start, end;
        while ((matchArr = regex.exec(text)) !== null) {
          start = matchArr.index;
          end = start + matchArr[0].length;
          callback(start, end);
        }
      }

      replaceText = (searchStr, replaceStr) => {
         
        const regex = new RegExp("\\b"+searchStr+"\\b", 'ig');
        const { editorState } = this.props;
        const selectionsToReplace = [];
        const blockMap = editorState.getCurrentContent().getBlockMap();
      
        blockMap.forEach((contentBlock) => (
          this.findWithRegex(regex, contentBlock, (start, end) => {
            const blockKey = contentBlock.getKey();
            const blockSelection = SelectionState
              .createEmpty(blockKey)
              .merge({
                anchorOffset: start,
                focusOffset: end,
              });
      
            selectionsToReplace.push(blockSelection)
          })
        ));
        
        let contentState = editorState.getCurrentContent();
      
        selectionsToReplace.reverse().forEach(selectionState => {

          contentState = Modifier.replaceText(
            contentState,
            selectionState,
            replaceStr,
            contentState.getBlockForKey(selectionState.getStartKey()).getCharacterList().get(selectionState.getStartOffset()).getStyle()
          )
        });
      
        this.props.onChangeEditor(EditorState.push(
            editorState,
            contentState,
          )
        )
        document.getElementById('partialTextContainer').style.display = 'none'; 
      }

      replaceThisTextOnly = (data) => {
        this.grading = data;
        this.props.options.gradingChangeFunc(data);
        document.getElementById('partialTextContainer').style.display = 'none';
        this.setFocusToEditor();
      }

      getNotToDelete = (e, getBlock, contentState, _startOffset, _endOffset) => {
        var notToDelete = 0;
        getBlock.findEntityRanges(
          (character) => { 
            const entityKey = character.getEntity();
    
            return (
              entityKey !== null &&
              contentState.getEntity(entityKey).getType() === 'UNEDITABLE_CONTENT'
            );
          },
          (start, end) => {
            if((e.keyCode === 8 || e.keyCode === 46) && (_startOffset === _endOffset))
            {
              if((e.keyCode === 8 && start < _startOffset && end >= _startOffset) || (e.keyCode === 46 && start <= _startOffset && end > _startOffset))
                notToDelete = 1;
            }
            else if(!(end < _startOffset || start > _endOffset)){
              notToDelete = 1;
            }
          }
        );
    
        return notToDelete;
      }

    handleKeyCommand = (command, editorState) => {
        if(command == 'handled' || command == 'no-delete' || command == 'audio-manipulate')
            return 'handled';
        const newState = RichUtils.handleKeyCommand(editorState, command);
        if (newState) {
            this.props.onChangeEditor(newState);
            return 'handled';
        }
        return 'not-handled';
      }
    
    setFocusToEditor = (event, runfunc) => {
        if(event)
            event.preventDefault();
        this.domEditor.focus();
        setTimeout(runfunc, 0);
    }

    oneStyleAtATime = (editorState, arrayStyle, reqStyle) => {
        const selection = editorState.getSelection();

        var currentStyle = editorState.getCurrentInlineStyle();
        var newArrayStyle = [];
            
        if(typeof arrayStyle === 'string'){
            currentStyle.forEach(singStyle => {
                if(singStyle.indexOf(arrayStyle) > -1)
                    newArrayStyle.push(singStyle)
            })
        }
        else
            newArrayStyle = arrayStyle;

        // Let's just allow one color at a time. Turn off all active colors.
        const nextContentState = newArrayStyle.reduce((contentState, color) => {
            return Modifier.removeInlineStyle(contentState, selection, color)
        }, editorState.getCurrentContent());

        let nextEditorState = EditorState.push(
            editorState,
            nextContentState,
            'change-inline-style'
        );

        currentStyle = editorState.getCurrentInlineStyle();

        // Unset style override for current color.
        if (selection.isCollapsed()) {
            nextEditorState = currentStyle.reduce((state, color) => {
                return RichUtils.toggleInlineStyle(state, color);
            }, nextEditorState);
        }

        // If the color is being toggled on, apply it.
        if (!currentStyle.has(reqStyle)) {
            nextEditorState = RichUtils.toggleInlineStyle(
                nextEditorState,
                reqStyle
            );
        }

        return nextEditorState;
    }


    onUnderlineClick = (event) => {
        this.setFocusToEditor(event, ()=>{this.props.onChangeEditor(
			RichUtils.toggleInlineStyle(this.props.editorState, "UNDERLINE")
		);});
	};

	onBoldClick = (event) => {
        this.setFocusToEditor(event, ()=>{this.props.onChangeEditor(RichUtils.toggleInlineStyle(this.props.editorState, "BOLD"))});

	};

	onItalicClick = (event) => {
        this.setFocusToEditor(event, ()=>{
		this.props.onChangeEditor(
			RichUtils.toggleInlineStyle(this.props.editorState, "ITALIC")
		);});
    }; 

    onULClick = (event) => {
        this.setFocusToEditor(event, ()=>{
		this.props.onChangeEditor(
			RichUtils.toggleBlockType(this.props.editorState, "unordered-list-item")
		);});
    }

    onOLClick = (event) => {
        this.setFocusToEditor(event, ()=>{
		this.props.onChangeEditor(
			RichUtils.toggleBlockType(this.props.editorState, "ordered-list-item")
		);});
    }
    
    onHighlightClick = (event) => {
        this.setFocusToEditor(event, ()=>{
		this.props.onChangeEditor(
			RichUtils.toggleInlineStyle(this.props.editorState, "HIGHLIGHT")
		);});
    };

    onUndoClick = (event) => {
        this.setFocusToEditor(event, ()=>{
		this.props.onChangeEditor(
			EditorState.undo(this.props.editorState)
		);});
    };

    onRedoClick = (event) => {
        this.setFocusToEditor(event, ()=>{
		this.props.onChangeEditor(
			EditorState.redo(this.props.editorState)
		);});
    };

    onSuperscriptClick = (event) => {
        const this2 = this;
        this.setFocusToEditor(event, ()=>{
            this2.props.onChangeEditor(
                this2.oneStyleAtATime(this2.props.editorState, ["SUPERSCRIPT","SUBSCRIPT"], "SUPERSCRIPT")
            );
        });
    };

    onSubscriptClick = (event) => {
        const this2 = this;
        this.setFocusToEditor(event, ()=>{
            this2.props.onChangeEditor(
                this2.oneStyleAtATime(this2.props.editorState, ["SUPERSCRIPT","SUBSCRIPT"], "SUBSCRIPT")
            );
        });
    };

    onStrikeClick = (event) => {
        this.setFocusToEditor(event, ()=>{
		this.props.onChangeEditor(
			RichUtils.toggleInlineStyle(this.props.editorState, "STRIKETHROUGH")
		);});
    };

    onAllCapsClick = (event) => {
        const this2 = this;
        this.setFocusToEditor(event, ()=>{
            this2.props.onChangeEditor(
                this2.oneStyleAtATime(this2.props.editorState, ["UPPERCASE","LOWERCASE","CAPITALIZE"], "UPPERCASE")
            );
        });
    };

    onAllSmallClick = (event) => {
        const this2 = this;
        this.setFocusToEditor(event, ()=>{
            this2.props.onChangeEditor(
                this2.oneStyleAtATime(this2.props.editorState, ["UPPERCASE","LOWERCASE","CAPITALIZE"], "LOWERCASE")
            );
        });
    };

    onCapitalizeClick = (event) => {
        const this2 = this;
        this.setFocusToEditor(event, ()=>{
            this2.props.onChangeEditor(
                this2.oneStyleAtATime(this2.props.editorState, ["UPPERCASE","LOWERCASE","CAPITALIZE"], "CAPITALIZE")
            );
        });        
    };

    onFontSelect = (event) => {
        var eventVal = event.target.value.toUpperCase();
        const this2 = this;
        this.setFocusToEditor(event, ()=>{
            this2.props.onChangeEditor(
                this2.oneStyleAtATime(this2.props.editorState, 'FONTFAMILY-', "FONTFAMILY-"+eventVal)
            );
        });
    };

    onFontSizeSelect = (event) => {
        if(event.keyCode === 13){
            event.preventDefault();
            
            var eventVal = event.target.value.toUpperCase();
            eventVal = eventVal == ''?'DEFAULT':eventVal;
            const this2 = this;
            this.setFocusToEditor(event, ()=>{
                this2.props.onChangeEditor(
                    this2.oneStyleAtATime(this2.props.editorState, 'FONTSIZE-', "FONTSIZE-"+eventVal)
                );
            });
        }
    };

    onFontColorSelect = (event) => {
        var eventVal = event.target.value.toUpperCase();
        const this2 = this;
        this.setFocusToEditor(event, ()=>{
            this2.props.onChangeEditor(
                this2.oneStyleAtATime(this2.props.editorState, 'FONTCOLOR-', "FONTCOLOR-"+eventVal)
            );
        });
    };

    onFormattingClick = (event) => {
        event.preventDefault(); 
        this.startF = !this.startF; 
        this.props.setStartF(this.startF, this.props.editorState);
        //this.props.onChangeEditor();
        this.domEditor.focus();
    };

    setEditorFocus = () => {
        if(this.domEditor){
            this.domEditor.focus();
        }
    }

    setEditorFocusCursorEnd = () => {
        if(this.domEditor){
            this.domEditor.focus();
            var es = EditorState.moveSelectionToEnd(this.props.editorState)
            this.props.onChangeEditor(EditorState.forceSelection(es, es.getSelection()));
        }
    }

    addCharacter = (event, symbol) => {
        const this2 = this;
        this.setFocusToEditor(event, ()=>{
            const editorState = this2.props.editorState;
            const selection = editorState.getSelection();
            if(selection.isCollapsed()){
                const curInlStyle = editorState.getCurrentInlineStyle();
                const contentState = editorState.getCurrentContent();
                const ncs = Modifier.insertText(contentState, selection, symbol, curInlStyle);
                const es = EditorState.push(editorState, ncs, 'insert-fragment');
                this2.props.onChangeEditor(es);
            }
        });
    }

    handleReturn = (event, editorState) => {
        event.preventDefault();
        if (isSoftNewlineEvent(event)) {
          //const { setEditorState, editorState } = this.props;
          this.props.onChangeEditor(RichUtils.insertSoftNewline(editorState));
          return 'handled';
        }
        
                
        return 'not-handled';
      }
    
    onChange = (editorState) => {
        
        /*currentSelectionState = editorState.getSelection();
        if(startF)
            this.onReplace(editorState, ' ', '·');
        else
            this.onReplace(editorState, '·', ' ');
            */
        //console.log("Editor Called Onchange");
        if(editorState != this.props.editorState)
        {
            if(this.newEditorStateSet != null){
                //console.log("Using newEditorStateSet");
                const this2 = this;
                this.props.onChangeEditor(this.newEditorStateSet, () => {this2.newEditorStateSet = null;this2.domEditor.blur();this2.domEditor.focus();}); 
                
            }  
            else
            {
                //console.log("Using EditorState");
                this.props.onChangeEditor(editorState);
            }

        }
    }

    
    
      makeUneditable = (e) => {
        e.preventDefault();
        const {editorState} = this.props;
        const selection = editorState.getSelection();
        if (!selection.isCollapsed()) {
            const contentState = editorState.getCurrentContent();
            
            const contentStateWithEntity = contentState.createEntity(
              'UNEDITABLE_CONTENT',
              'MUTABLE',
              {}
            );
            const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
            const contentStateWithLink = Modifier.applyEntity(
                contentStateWithEntity,
                selection,
                entityKey
              );
            const newEditorState = EditorState.set(editorState, { currentContent: contentStateWithLink });
            
            this.onChange(newEditorState);
    
    
          }
        }

    removeUneditable = (e) => {
        e.preventDefault();
        const {editorState} = this.props;
        const selection = editorState.getSelection();
        if (!selection.isCollapsed()) {
          this.onChange(RichUtils.toggleLink(editorState, selection, null));
        }
      }

      expandEditor = (e) => {
          e.preventDefault();
          console.log("Expand Called");
          this.setState({expand: !this.state.expand}, () => {console.log("Expand Completed")});
      }

      playpause = (e) => {
            if(e)
                e.preventDefault();

            var playe = document.getElementById('audioPlayer');
            if(playe.paused)
            {
                playe.play();
            }
            else
                playe.pause();
      }

    rewind = (e, num) => {
        if(e)
            e.preventDefault();
        document.getElementById('audioPlayer').currentTime -= num;
        console.log("Rewind Fired");
    }

    forward = (e, num) => {
        if(e)
            e.preventDefault();
        document.getElementById('audioPlayer').currentTime += num;
    }

    speedControl = (e, num) => {
        if(e)
            e.preventDefault();
        var audp = document.getElementById('audioPlayer');
        if(audp.playbackRate + num > 0){
            audp.playbackRate += num;
            localStorage.setItem('playback_speed', Math.round(audp.playbackRate * 100));
            document.getElementById('playbackSpeedSpan').innerHTML = Math.round(audp.playbackRate * 100) + '%';
        }
    }

    postComment = (e) => {
        e.preventDefault();
        var commentVal = document.getElementById('commentTextarea').value;
        if(commentVal.trim().length > 0){
            this.props.commentPostFunc(commentVal.trim(), this.openCommentBox);
        }
    }

    

    addNewPage = () => {
        this.totalPages += 1;
        this.createNewBlockWithPageNum()
        var this2 = this;
        setTimeout(() => this2.addCharacter(null, "*****PAGE BREAK - TYPE FROM NEXT LINE*****"), 0);
        setTimeout(() => this2.createNewBlockWithPageNum(), 100);
        //this.setEditorFocus()
        //this.setPageNum(this.totalPages, this.createNewBlockWithPageNum);
    }

    dragMouseDown = (e, elmnt, posarr) => {
        e = e || window.event;
        e.preventDefault();
        // get the mouse cursor position at startup:
        console.log("Drag Mouse Down", e.clientX, e.clientY)
        posarr[2] = e.clientX;
        posarr[3] = e.clientY;
        document.onmouseup = this.closeDragElement;
        // call a function whenever the cursor moves:
        document.onmousemove = (e) => this.elementDrag(e, elmnt, posarr);
    }

    elementDrag = (e, elmnt, posarr) => {
        e = e || window.event;
        e.preventDefault();
        // calculate the new cursor position:
        posarr[0] = posarr[2] - e.clientX;
        posarr[1] = posarr[3] - e.clientY;
        posarr[2] = e.clientX;
        posarr[3] = e.clientY;
        
        // set the element's new position:
        var top = elmnt.offsetTop - posarr[1];
        var left = elmnt.offsetLeft - posarr[0];

        if(top > 0 && top < window.innerHeight)
            elmnt.style.top = top + "px";
        else
            elmnt.style.top = '0px';

        if(left > 0 && left < window.innerWidth)    
            elmnt.style.left = left + "px";
        else
            elmnt.style.left = '0px';
    }
    
    closeDragElement = () => {
        // stop moving when mouse button is released:
        document.onmouseup = null;
        document.onmousemove = null;
    }

    dragElement = (elmnt) => {
        var posarr = [0, 0, 0, 0];
        if (document.getElementById(elmnt.id + "header")) {
          // if present, the header is where you move the DIV from:
          document.getElementById(elmnt.id + "header").onmousedown = (e) => this.dragMouseDown(e, elmnt, posarr);
        } else {
          // otherwise, move the DIV from anywhere inside the DIV:
          elmnt.onmousedown = (e) => this.dragMouseDown(e, elmnt, posarr);
        }
    }

    setToAlwaysOpenInExtPlayer = (e) => {
        if(e.target.checked){
            localStorage.setItem('externalPlayerAlways', "yes");
        } else {
            localStorage.removeItem('externalPlayerAlways');
        }
    }

    downloadAudio = (e, audio) => {
        if(e)
            e.preventDefault();
        var fd = new FormData();
        fd.append('audio', audio);
        
        axios.post('downloadAudio.php', fd, {responseType: 'arraybuffer'}).then(response => {
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(new Blob([response.data], {type: 'application/octet-stream'}));
            link.download = audio;

            document.body.appendChild(link);

            link.click();
            setTimeout(function() {
                window.URL.revokeObjectURL(link);
            }, 200);
            document.body.removeChild(link);
        }).catch(error => {})

    }

    
    render(){
        let inputField = null;
        let errorText = this.props.errorText;

        console.log("Render Called");

        if('showLoader' in this.props.options && this.props.options.showLoader[0] === true){
            if(this.props.options.showLoader[1] !== undefined)
                inputField = <Loader size={'small'} color={this.props.options.showLoader[1]} />
            else
                inputField = <Loader size={'small'} />
        }
        else{
            switch(this.props.type){
                case 'text':
                case 'password':
                case 'number':
                case 'email': 
                case 'hidden':   
                case 'alphabets':
                case 'date':   
                case 'datetime-local': 
                    inputField = <Form.Control type={this.props.type==='alphabets'?'text':this.props.type} 
                                        value={this.props.value} 
                                        placeholder={this.props.placeholder}
                                        onChange={this.props.onChange} noValidate />;
                    break;
                case 'file':    
                    inputField = <input type="file" onChange={this.props.onChange} multiple />;
                    break;
                case 'checkbox':
                case 'radio':
                    let newinpfield1 = null;
                    let newinpfield2 = null;

                    if('selectAll' in this.props.options){
                        if(this.props.options.selectAll.set === true){
                            newinpfield1 = <React.Fragment>
                                            <Form.Check type={this.props.type} 
                                                value={'selectAllCheckBox'}
                                                onChange={this.props.onChange}
                                                checked={this.props.options.selectAll.selected===true?'checked':null}
                                                label={'Select All'}
                                            />
                                            <br />
                                        </React.Fragment>
                        }
                    }
                    newinpfield2 = this.props.options.options.map((val,ind) => (<React.Fragment key={ind}><Form.Check type={this.props.type} 
                                        value={val.name}
                                        name={this.props.options.group}
                                        onChange={this.props.onChange}
                                        checked={val.selected===true?'checked':null}
                                        label={val.value}
                                            /> </React.Fragment>));
                    inputField = <React.Fragment>{newinpfield1}{newinpfield2}</React.Fragment>
                    break;
                case 'checkboxlist':
                    var newinpfield3 = [];
                    var newinpfield4 = [];

                    if('selectAll' in this.props.options){
                        if(this.props.options.selectAll.set === true){
                            newinpfield3 =  <div className={this.props.options.class}>
                                                <Form.Check type={'checkbox'} 
                                                    key={'selectAllCheckBox-'+this.props.options.selectAll.selected}
                                                    value={'selectAllCheckBox'}
                                                    onChange={this.props.onChange}
                                                    defaultChecked={this.props.options.selectAll.selected===true?'checked':null}
                                                    label={'Select All'}
                                                />
                                            </div>
                        }
                    }

                    this.props.options.options.forEach((val, ind) => {
                        newinpfield4.push(
                            <CustomToggle key={ind} class={this.props.options.class} val={val} onChange={this.props.onChange} />
                        );
                        if('children' in val){
                            var tempinpFie = [];
                            val.children.forEach((child, indi) => {
                                tempinpFie.push(
                                    <div key={child.name+'-'+child.value+'-'+child.selected} className={this.props.options.subClass}>
                                        <Form.Check type={'checkbox'} 
                                            value={child.name}
                                            onChange={this.props.onChange}
                                            defaultChecked={child.selected===true?'checked':null}
                                            label={child.value}
                                        />
                                    </div>
                                );
                            });
                            newinpfield4.push(
                                <Accordion.Collapse eventKey={val.name}>
                                    <React.Fragment>
                                    {tempinpFie}
                                    </React.Fragment>
                                </Accordion.Collapse>
                            );
                        }
                    });

                    
                    inputField = (  <DropDownList>
                                        <React.Fragment>
                                            {newinpfield3}
                                            <Accordion>{newinpfield4}</Accordion>
                                        </React.Fragment>
                                    </DropDownList>)
                    break;
                case 'textarea':
                    inputField = <Form.Control 
                                        as="textarea" 
                                        value={this.props.value} 
                                        placeholder={this.props.placeholder}
                                        row={3}
                                        onChange={this.props.onChange} />;
                    break; 
                case 'select':
                    var options = this.props.options.options.map((val,ind) => <option value={val.name} key={ind} selected={val.selected !== null? val.selected : 'false'}>{val.value}</option>);
                    inputField = <Form.Control as="select" 
                                        onChange={this.props.onChange}>
                                    {options}    
                                </Form.Control>;
                    break;    
                case 'editor':
                    var inlineStyles = this.props.editorState.getCurrentInlineStyle();
                    const currblock = this.props.editorState
                                        .getCurrentContent()
                                        .getBlockForKey(this.props.editorState.getSelection().getAnchorKey());
                    const blockStyles = currblock.getType();

                    const blockdata = currblock.getData();

                    var lineHeightVal = "1";
                    var alignVal = '';
                    var indentation = 0;
                    var fontSizeVal = '';
                    var hangingVal = 0;
                    var fontColVal = 'Default';
                    var fontFamily = 'DEFAULT';

                    if(blockdata.has('data')){
                        var datadata = blockdata.get('data');
                        
                        if('lineHeight' in datadata)
                            lineHeightVal = datadata['lineHeight'];
                        if('textAlign' in datadata)
                            alignVal = datadata['textAlign'];
                        if('indentation' in datadata)
                            indentation = datadata['indentation'];
                        if('hanging' in datadata){
                            hangingVal = datadata['hanging'];
                        }
                    }    

                    if(this.hangingRef.current)
                        this.hangingRef.current.value = hangingVal;

                    //if(this.fontSizeRef.current)
                    //    this.fontSizeRef.current.value = fontSizeVal;

                    inlineStyles.forEach(sty => {
                        if(sty.indexOf('FONTSIZE-')==0){
                            var num = sty.split('-');
                            if(num[1] === 'DEFAULT')
                                fontSizeVal = '';
                            else
                                fontSizeVal = num[1];
                            if(this.fontSizeRef.current)
                                this.fontSizeRef.current.value = fontSizeVal;
                        } else if(this.fontSizeRef.current){
                            this.fontSizeRef.current.value = '';
                        }
                        if(sty.indexOf('FONTCOLOR-')==0){
                            var num = sty.split('-');
                            if(num[1] === 'DEFAULT')
                                fontColVal = 'Default';
                            else
                                fontColVal = num[1];

                        }
                        if(sty.indexOf('FONTFAMILY-')==0){
                            var num = sty.split('-');
                            fontFamily = num[1];
                        }
                    });

                    //console.log("hanging: ", hangingVal, "; FontSize: ", fontSizeVal, "; fontsizeref: ", this.fontSizeRef, "; hangingref: ", this.hangingRef)

                    var fontColorOptions = [<option value={'Default'}>Select Font Color</option>];
                    [
                        ['#FFFFFF','White'],
                        ['#000000','Black'],
                        ['#FF0000','Red'],
                        ['#00FF00','Green'],
                        ['#0000FF','Blue'],
                        ['#FFFF00','Yellow'],
                        ['#FF00FF','Magenta'],
                        ['#00FFFF','Cyan'],
                        ['#AA0000','Dark Red'],
                        ['#00AA00','Dark Green'],
                        ['#0000AA','Dark Blue'],
                    ].forEach(colVal => {
                        fontColorOptions.push(<option value={colVal[0]} style={{background:colVal[0]}}>{colVal[1]}</option>);
                    })
                    
                                

                    
                    var tabSize = '48';
                    if('tabsize' in this.props.options)
                    {
                        var newtabsize = parseFloat(this.props.options.tabsize);
                        tabSize = Math.ceil(newtabsize * 48 / 1.27);
                    }


                    document.documentElement.style.setProperty('--var-indent', tabSize);



                    inputField = (
                        <div style={{tabSize:tabSize+'px'}} data-id={'editor'} className={['editorClass', Classes.editorContainer, this.state.expand==true || ('expand' in this.props.options && this.props.options.expand)?Classes.expand:'', 'fullwidth' in this.props.options?Classes.fullwidth:''].join(' ')}>
                            {this.props.options.audioPlayer?(
                                <div className={'optionContainer'} style={{marginBottom:'10px',borderBottom:'1px solid #aaa',paddingBottom:'10px'}}>
                                    <audio style={{verticalAlign:'middle',height:'35px',width:'300px'}} id={'audioPlayer'} controls controlsList="nodownload">
                                        <source src={localStorage.getItem('appUrl')+'serveMP3.php?audio='+encodeURIComponent(this.props.options.audioPlayer)} />
                                    </audio>
                                    <Button type={'button'} variant={'light'} size={'sm'} onClick={(e)=>this.speedControl(e, -0.1)}>-</Button>{' '}
                                    <span id={'playbackSpeedSpan'}>{
                                        localStorage.getItem('playback_speed') !== null ? 
                                        localStorage.getItem('playback_speed') : '100'
                                    }%</span>
                                    <Button type={'button'} variant={'light'} size={'sm'} onClick={(e)=>this.speedControl(e, 0.1)}>+</Button>{' '}
                                    <Button type={'button'} variant={'light'} size={'sm'} onClick={(e)=>this.rewind(e, 2)}>«</Button>{' '}
                                    <Button type={'button'} variant={'light'} size={'sm'} onClick={(e)=>this.forward(e, 2)}>»</Button>{' '}
                                    <div style={{display:'inline-block'}}>
                                        <InputGroup size="sm">
                                            <InputGroup.Prepend>
                                                <Button type={'button'} variant={'info'} size={'sm'} onClick={(e)=>this.downloadAudio(e, this.props.options.audioPlayer)}>External Player</Button>
                                            </InputGroup.Prepend>
                                            <InputGroup.Append>
                                                <InputGroup.Checkbox defaultChecked={localStorage.getItem('externalPlayerAlways')===null?false:true} onChange={(e) => this.setToAlwaysOpenInExtPlayer(e)} aria-label="always open in external player" />
                                            </InputGroup.Append>

                                        </InputGroup>{' '}
                                    </div>
                                </div>
                            ):null}
                            <div className={'optionContainer'} style={{background: '#FFF'}}>
                            {this.props.options.imgonly == null?(
                                <React.Fragment>
                                <Button type={'button'} title={'Ctrl + U'} variant={inlineStyles.has('UNDERLINE')?'primary':'light'} size={'sm'} onClick={this.onUnderlineClick}>U</Button>{' '}
                                <Button type={'button'} title={'Ctrl + B'} variant={inlineStyles.has('BOLD')?'primary':'light'} size={'sm'} onClick={this.onBoldClick}>
                                    <b>B</b>
                                    </Button>{' '}
                                <Button type={'button'} title={'Ctrl + I'} variant={inlineStyles.has('ITALIC')?'primary':'light'} size={'sm'} onClick={this.onItalicClick}>
                                    <em>I</em>
                                    </Button>{' '}
                                <Button type={'button'} title={'Ctrl + D'} variant={inlineStyles.has('STRIKETHROUGH')?'primary':'light'} size={'sm'} onClick={this.onStrikeClick}>
                                    S
                                    </Button>{' '}
                                <Button type={'button'} title={'Ctrl + Alt + U'} variant={blockStyles == 'unordered-list-item'?'primary':'light'} size={'sm'} onClick={this.onULClick}>
                                    UL
                                    </Button>{' '}
                                <Button type={'button'} title={'Ctrl + Alt + o'} variant={blockStyles == 'ordered-list-item'?'primary':'light'} size={'sm'} onClick={this.onOLClick}>
                                    OL
                                    </Button>{' '}
                                <Button type={'button'} title={'Ctrl + H'} variant={inlineStyles.has('HIGHLIGHT')?'primary':'light'} size={'sm'} onClick={this.onHighlightClick}>
                                    <span style={{background:'yellow'}}>A</span>
                                </Button>{' '}
                                <Button type={'button'} title={'Ctrl + Z'} variant={'light'} size={'sm'} onClick={this.onUndoClick}>
                                    Undo
                                </Button>{' '}
                                <Button type={'button'} title={'Ctrl + Y'} variant={'light'} size={'sm'} onClick={this.onRedoClick}>
                                    Redo
                                </Button>{' '}
                                <Button type={'button'} title={'Alt + 2'} variant={inlineStyles.has('SUPERSCRIPT')?'primary':'light'} size={'sm'} onClick={this.onSuperscriptClick}>
                                    S<span style={{verticalAlign:'super', fontSize:'smaller'}}>x</span>
                                </Button>{' '}
                                <Button type={'button'} title={'Alt + 1'} variant={inlineStyles.has('SUBSCRIPT')?'primary':'light'} size={'sm'} onClick={this.onSubscriptClick}>
                                    S<span style={{verticalAlign:'sub', fontSize:'smaller'}}>x</span>
                                </Button>{' '}
                                <Button type={'button'} title={'Alt + 4'} variant={inlineStyles.has('UPPERCASE')?'primary':'light'} size={'sm'} onClick={this.onAllCapsClick}>
                                    AA
                                </Button>{' '}
                                <Button type={'button'} title={'Alt + 3'} variant={inlineStyles.has('LOWERCASE')?'primary':'light'} size={'sm'} onClick={this.onAllSmallClick}>
                                    aa
                                </Button>{' '}
                                <Button type={'button'} title={'Alt + 5'} variant={inlineStyles.has('CAPITALIZE')?'primary':'light'} size={'sm'} onClick={this.onCapitalizeClick}>
                                    Aa
                                </Button>{' '}
                                <div style={{display:'inline-block'}}>
                                    <Form.Control size={'sm'} as="select" onChange={this.onFontSelect} value={fontFamily}>
                                        <option value={'Default'.toUpperCase()}>Select Font</option>
                                        <option value={'Arial'.toUpperCase()}>Arial</option>
                                        <option value={'ArialMT'.toUpperCase()}>ArialMT</option>
                                        <option value={'Arial Rounded MT Bold'.toUpperCase()}>Arial Rounded MT Bold</option>
                                        <option value={'Arial Unicode MS'.toUpperCase()}>Arial Unicode MS</option>
                                        <option value={'Bahnschrift'.toUpperCase()}>Bahnschrift</option>
                                        <option value={'Bahnschrift Semibold'.toUpperCase()}>Bahnschrift Semibold</option>
                                        <option value={'Book Antiqua'.toUpperCase()}>Book Antiqua</option>
                                        <option value={'Bookman Old Style'.toUpperCase()}>Bookman Old Style</option>
                                        <option value={'Calibri'.toUpperCase()}>Calibri</option>
                                        <option value={'Calibri Light'.toUpperCase()}>Calibri Light</option>
                                        <option value={'Cambria'.toUpperCase()}>Cambria</option>
                                        <option value={'Century Gothic'.toUpperCase()}>Century Gothic</option>
                                        <option value={'Courier New'.toUpperCase()}>Courier New</option>
                                        <option value={'DejaVu Serif'.toUpperCase()}>DejaVu Serif</option>
                                        <option value={'Franklin Gothic Medium'.toUpperCase()}>Franklin Gothic Medium</option>
                                        <option value={'Freestyle Script'.toUpperCase()}>Freestyle Script</option>
                                        <option value={'Garamond'.toUpperCase()}>Garamond</option>
                                        <option value={'Geneva'.toUpperCase()}>Geneva</option>
                                        <option value={'Helvetica'.toUpperCase()}>Helvetica</option>
                                        <option value={'Impact'.toUpperCase()}>Impact</option>
                                        <option value={'Microsoft Sans Serif'.toUpperCase()}>Microsoft Sans Serif</option>
                                        <option value={'Palatino Linotype'.toUpperCase()}>Palatino Linotype</option>
                                        <option value={'Sans-Serif'.toUpperCase()}>Sans-Serif</option>
                                        <option value={'Tahoma'.toUpperCase()}>Tahoma</option>
                                        <option value={'Times New Roman'.toUpperCase()}>Times New Roman</option>
                                        <option value={'Trebuchet MS'.toUpperCase()}>Trebuchet MS</option>
                                        <option value={'Verdana'.toUpperCase()}>Verdana</option>    
                                    </Form.Control>
                                </div>{' '}
                            
                                <div style={{display:'inline-block'}}>
                                    <InputGroup size="sm">
                                        <InputGroup.Prepend>
                                            <InputGroup.Text>Font Size</InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <FormControl aria-label="font size"
                                        type={'number'} max={30} min={8} step={0.5} ref={node => this.fontSizeRef=node} defaultValue={fontSizeVal} onKeyDown={this.onFontSizeSelect} />
                                        <InputGroup.Append>
                                            <InputGroup.Text>px</InputGroup.Text>
                                        </InputGroup.Append>
                                    </InputGroup>
                                </div>{' '}

                                <div style={{display:'inline-block'}}>
                                    <Form.Control size={'sm'} as="select" onChange={this.onFontColorSelect} value={fontColVal}>
                                        {fontColorOptions}
                                    </Form.Control>
                                </div>{' '}

                                <div style={{display:'inline-block'}}>
                                    <InputGroup size="sm">
                                        <InputGroup.Prepend>
                                            <InputGroup.Text>Hanging</InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <FormControl aria-label="Hanging"
                                        type={'number'} max={10} min={0} step={0.01} ref={node => this.hangingRef = node} defaultValue={hangingVal} onKeyDown={this.onHangingSelect} />
                                    </InputGroup>
                                </div>{' '}

                            
                            <Button type={'button'} title={'Ctrl + Alt + Q'} variant={alignVal === 'left'?'primary':'light'} size={'sm'} onClick={(event) => this.justification(event,'left')}>Left Align</Button>{' '}
                            <Button type={'button'} title={'Ctrl + Alt + E'} variant={alignVal === 'right'?'primary':'light'} size={'sm'} onClick={(event) => this.justification(event,'right')}>Right Align</Button>{' '}
                            <Button type={'button'} title={'Ctrl + Alt + W'} variant={alignVal === 'center'?'primary':'light'} size={'sm'} onClick={(event) => this.justification(event,'center')}>Center Align</Button>{' '}
                            <Button type={'button'} title={'Ctrl + Alt + R'} variant={alignVal === 'justify'?'primary':'light'} size={'sm'} onClick={(event) => this.justification(event,'justify')}>Justify</Button>{' '}

                            <Button type={'button'} title={'Ctrl + Alt + I'} variant={'light'} size={'sm'} onClick={(event) => this.indentation(event,1)}>Indent</Button>{' '}
                            <Button type={'button'} title={'Ctrl + Alt + Shift + I'} variant={'light'} size={'sm'} onClick={(event) => this.indentation(event,-1)}>Outdent</Button>{' '}

                            <Button type={'button'} title={'Alt + M'} variant={'light'} size={'sm'} onClick={(event) => this.addCharacter(event, 'μ')}>μ</Button>{' '}
                            <Button type={'button'} title={'Alt + D'} variant={'light'} size={'sm'} onClick={(event) => this.addCharacter(event, '°')}>°</Button>{' '}
                            <div style={{display:'inline-block'}}>
                                <Form.Control size={'sm'} as="select" onChange={this.lineHeight} value={lineHeightVal}>
                                    <option value={'1'}>Line Height - 1</option>
                                    <option value={'1.5'}>Line Height - 1.5</option>
                                    <option value={'2'}>Line Height - 2</option>
                                </Form.Control>
                            </div>{' '}
                            <Button type={'button'} variant={'light'} size={'sm'} onClick={this.addNewPage}>Page Break</Button>{' '}
                            <Button type={'button'} title={'Ctrl + Alt + B'} variant={'light'} size={'sm'} onClick={(event) => this.addCharacter(event, '_____')}>Add Blanks</Button>{' '}
                            <Button type={'button'} variant={'light'} size={'sm'} onClick={(event) => this.addCharacter(event, '\t')}>Add Tab</Button>{' '}
                            
                            {/*this.props.options.showUneditable?(
                                <React.Fragment>
                                    <button onClick={this.makeUneditable}>Make Uneditable</button>
                                    <button onClick={this.removeUneditable}>Remove Uneditable</button>
                                </React.Fragment>
                            ):null*/}
                            </React.Fragment>
                            ):null}
                            <ImageAdd
                                editorState={this.props.editorState}
                                onChange={this.props.onChangeEditor}
                                modifier={imagePlugin.addImage}
                                />{' '}
                              
                            {this.props.options.imgonly == null?(
                                <React.Fragment>
                                    <Button type={'button'} variant={'light'} size={'sm'} onClick={this.onFormattingClick}>¶</Button>{' '}
                                </React.Fragment>
                            ):null}
                            <Button type={'button'} variant={this.state.expand?'primary':'light'} size={'sm'} onClick={this.expandEditor}>⤢</Button>  
                            </div>
                            <div className={'optionContainerHeightFix dispnone'}></div>
                            <div className={[Classes.editors, Classes.editorDiv].join(' ')}>
                                <div style={'expand' in this.props.options && this.props.options.expand?{marginRight:'225px',marginLeft:'300px'}:{}}>
                                    <div style={{height:'30px',overflow:'hidden',borderBottom:'1px solid #ccc'}}>
                                        <div className={'addPageBut'} style={{float:'right'}}>
                                            <div>
                                                <span className={Classes.noselect}  onClick={this.addNewPage} style={{cursor:'pointer',lineHeight:0,padding:'10px 7px 12px',background:'blue',border:'1px solid darkblue',borderRadius:'3px',display:'inline-block',color:'#FFF',float:'right',marginTop:'3px',marginRight:'20px',fontSize:'14px'}}>+ Add Page</span>
                                            </div>
                                        </div>
                                    </div>
                                    <Editor 
                                        customStyleMap={this.styleMap}
                                        editorState={this.props.editorState}
                                        handleKeyCommand={this.handleKeyCommand} 
                                        keyBindingFn={this.mapKeyToEditorCommand}
                                        blockStyleFn={this.myBlockStyleFn}
                                        blockRendererFn={this.myBlockRenderer}
                                        plugins={this.plugins}
                                        handleReturn={this.handleReturn}
                                        autoCapitalize='on'
                                        spellCheck={true}
                                        stripPastedStyles={true}
                                        ref={node => this.domEditor = node}
                                        onChange={this.onChange} />
                                </div>
                            </div>
                            <button id={'focusEditorBut'} type={'button'} style={{display:'none'}} onClick={(e) => {
                                 e.preventDefault();
                                 this.setEditorFocus();
                            }}></button>
                            <div id={'commentContainer'} style={{
                                background:'#ffffaa',
                                height: 'calc(100vh - 170px)',
                                width:'300px',
                                boxSizing:'border-box',
                                border:'1px solid #aaaa00',
                                boxShadow:'0 0 3px rgba(0,0,0,0.3)',
                                position:'fixed',
                                right:'25px',
                                top: '134px',
                                display: 'none',
                                flexDirection: 'column',
                                zIndex: '5',
                            }}>
                                <div id="commentContainerheader">Click here to move</div>
                                <button type={'button'} onClick={(e) => {
                                    e.preventDefault();
                                    document.getElementById('commentContainer').style.display = 'none';
                                    document.getElementById('editorMoreInfo').style.display = 'block';
                                    this.setEditorFocus();
                                }} style={{
                                    width: 'fit-content',
                                    alignSelf: 'flex-end',
                                }}>Close</button>
                                <div id={'commentBox'} style={{flexGrow:'1'}}></div>
                                <div style={{display:'flex'}}>
                                    <textarea id={'commentTextarea'} style={{padding:'10px',flexGrow:'1'}}></textarea>
                                    <button type={'button'} onClick={this.postComment}>{'POST'}</button>
                                </div>
                            </div>
                            <div id={'tableContainer'} style={{
                                background:'#ffe',
                                height: '400px',
                                width:'800px',
                                boxSizing:'border-box',
                                border:'1px solid #aaaa00',
                                boxShadow:'0 0 3px rgba(0,0,0,0.3)',
                                position:'fixed',
                                right:'25px',
                                top: '60px',
                                display: 'none',
                                zIndex: '5',
                                overflow: 'auto',
                                padding: '10px',
                            }}>
                                <div style={{overflow:'hidden'}}>
                                    <button type={'button'} style={{float:'right'}} onClick={(e)=>{
                                        e.preventDefault();
                                        this.props.commentStarting([2,0]);
                                        document.getElementById('tableContainer').style.display = 'none';
                                        document.getElementById('editorMoreInfo').style.display = 'block';
                                        this.setEditorFocus();
                                    }}>CLOSE</button>
                                    <div id="tableContainerheader">Click here to move</div>
                                </div>
                                <div id={'tableBox'}></div>
                                
                            </div>
                            <div id={'partialTextContainer'} style={{
                                background:'#ffe',
                                height: '400px',
                                width:'600px',
                                boxSizing:'border-box',
                                border:'1px solid #aaaa00',
                                boxShadow:'0 0 3px rgba(0,0,0,0.3)',
                                position:'fixed',
                                left:'400px',
                                top: '150px',
                                display: 'none',
                                zIndex: '5',
                                overflow: 'auto',
                                padding: '10px',
                            }}>
                                <div style={{overflow:'hidden'}}>
                                    <button type="button" style={{float:'right'}} onClick={(e)=>{
                                        e.preventDefault();
                                        document.getElementById('partialTextContainer').style.display = 'none';
                                        this.setEditorFocus();
                                    }}>CLOSE</button>
                                    <div id="partialTextContainerheader">Click here to move</div>
                                </div>
                                <div id={'partialTextBox'}></div>
                                
                            </div>
                            {'extraInfo' in this.props.options?(
                            <div className={Classes.editors} style={{overflow:'unset'}}>
                                {this.props.options.extraInfo.map(items => {
                                        return <span style={{fontSize:'12px','marginRight':'15px'}}><b>{Object.keys(items)[0]}</b>{Object.values(items)[0]}</span>;
                                    })
                                }
                            </div>
                            ):null}
			            </div>);
                    break;            
                default:
                    inputField = null;
                    break;        
            }
        }

        

        return(
            <Form.Group style={'noFormGroup' in this.props.options && this.props.options.noFormGroup?{marginBottom:0}:{}}>
                {this.props.type != 'hidden' && this.props.label != null?<Form.Label>{this.props.label} {this.props.options.required==true && !('noRequiredShow' in this.props.options)?' (Required)':''}</Form.Label>:null}
                {inputField}
                {this.props.type != 'hidden' && errorText != null && errorText != ''?<Form.Text className={["text-muted", 'errorTextClass' in this.props.options ? this.props.options.errorTextClass : ''].join(' ')}>{errorText}</Form.Text>:''}
            </Form.Group>
        )
    }
}

export default Input;