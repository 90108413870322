import React from 'react';
import EditFile from 'modules/Form/Edit/Edit';
import Strings from 'Strings.js';

class Edit extends React.Component{

    onSuccess = (data) => {
        this.props.refresh();
    }

    onFailure = (data) => {

    }

    render(){
        return(
            <EditFile
                editPermission = {31}
                ButtonCSS = {''}
                NameCSS = {''}
                editButtonValue = {this.props.isCompleted == 0 ? Strings.text.button.markComplete : Strings.text.button.markInComplete}
                editContainerCSS = {''}
                buttonClicked={1}
                enableButton={this.props.isCompleted == 1?'false':'true'}
                url = {'Tasks/markTaskComplete.php'}
                buttonValue = {this.props.isCompleted == 0 ? Strings.text.button.markComplete : Strings.text.button.markInComplete}
                onSuccess = {(data) => this.onSuccess(data)}
                onFailure = {(data) => this.onFailure(data)}
                fields = {[
                    {
                        type: 'hidden',
                        value: this.props.tid,
                        name: 'tid',
                        options: {
                                    required: true,
                                },
                    },
                    {
                        type: 'hidden',
                        value: this.props.isCompleted==1?0:1,
                        name: 'is_complete',
                        options: {
                                    required: true,
                                },
                    },
                ]}
            />
        );
    }
}

export default Edit;


/*
    tid
   
*/