import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import FormEditor from 'components/FormTable/FormEditor';
import runningPage from 'global';


class FormTable extends Component{
    state = {
        refresh: 1,
        current: this.props.table.length-1,
        prevStack: this.props.table,
        table: this.props.table
    }

    getEditorText = () => {
        var toreturn = [];
        this.state.table.forEach((row, rowindex)=>{
            var cells = [];
            row.forEach((cell, cellindex)=>{
                cells.push(this.refs['FE'+rowindex+'_'+cellindex].getEditorData());
            });
            toreturn.push(cells);
        });
        return toreturn;
    }

    addSubCol = (e, addSub, index) => {
        if(e)
            e.preventDefault();
        var newTable = this.getEditorText();
        if(addSub){
            newTable.forEach((ele,ind) => {
                newTable[ind].splice(index+1, 0, '');
            });
        }
        else{
            newTable.forEach((ele,ind) => {
                newTable[ind].splice(index, 1);
            });
        }

        this.setState({refresh:0}, () => this.setState({
            table: newTable,
            refresh: 1,
        }));
    }

    addSubRow = (e, addSub, index) => {
        if(e)
            e.preventDefault();
        var newTable = this.getEditorText();
        console.log("new table", newTable);
        if(addSub){
            newTable.splice(index+1, 0, [...Array(newTable[0].length)].map((u, i) => ''));
        }
        else{
            newTable.splice(index, 1);
        }

        this.setState({refresh:0}, () => this.setState({
            table: newTable,
            refresh: 1,
        }));
    }

    sendPostData = (e) => {
        e.preventDefault();
        this.props.postTable(this.getEditorText())
    }

    partsListEvent = (e) => {
        if(runningPage.page === 'taskopen'){
            if(e.altKey && e.ctrlKey){}
            else if(e.altKey){
                if (e.keyCode == 187 && e.keyCode == 82){
                    this.tableref.getElementsByTagName('button')[0].focus();
                }
            }
        }
    }

    componentDidMount(){
        this.refs['FE0_0'].domNewEditor.focus();
        document.addEventListener('keydown', this.partsListEvent);
    }

    componentWillUnmount(){
        document.removeEventListener('keydown', this.partsListEvent);
    }

    render(){
        var table = []
        console.log("Table State", this.state.table, this.refs);

        var firstrow = [];
        var lastrow = [];

        this.state.table[0].forEach((cell, cellindex)=>{
            if(cellindex == 0){
                firstrow.push(<td style={{textAlign:'center'}}></td>);
                lastrow.push(<td style={{textAlign:'center'}}></td>);
            }

            firstrow.push(<td style={{textAlign:'center'}}><button onClick={(e)=>this.addSubCol(e, 1, cellindex)}>+</button></td>);
            
            if(cellindex != 0)
                lastrow.push(<td style={{textAlign:'center'}}><button onClick={(e)=>this.addSubCol(e, 0, cellindex)}>-</button></td>);
            else
                lastrow.push(<td style={{textAlign:'center'}}></td>);

            
            if(cellindex == this.state.table[0].length-1){
                firstrow.push(<td style={{textAlign:'center'}}></td>);
                lastrow.push(<td style={{textAlign:'center'}}></td>);
            }
            
        });

        table.push(<tr>{firstrow}</tr>);

        this.state.table.forEach((row, rowindex)=>{
            var rows = [];
            row.forEach((cell, cellindex)=>{
                if(cellindex == 0)
                    rows.push(<td style={{textAlign:'center'}}><button onClick={(e)=>this.addSubRow(e, 1, rowindex)}>+</button></td>);

                rows.push(<td style={{border: '1px solid black',verticalAlign:'top'}}><div style={{background:'#FFF',borderBottom:'1px solid #ddd'}}><FormEditor ref={'FE'+rowindex+'_'+cellindex} cellContent={cell} /></div></td>);

                if(cellindex == row.length-1)
                    rows.push(rowindex > 0?<td style={{textAlign:'center'}}><button onClick={(e)=>this.addSubRow(e, 0, rowindex)}>-</button></td>:<td style={{textAlign:'center'}}></td>);
            });

            table.push(<tr>{rows}</tr>);
        });

        table.push(<tr>{lastrow}</tr>);



        return (this.state.refresh?(
            <div>
                <table ref={node=>this.tableref = node} style={{tableLayout: 'fixed',
                    width: '100%',borderCollapse:'collapse'}}>
                    {table}     
                </table>
                <div>
                    <button onClick={(e)=>this.sendPostData(e)}>Save</button>
                </div>
            </div>):null);
    }
}

export default FormTable;