import React from 'react';
import {NavLink, Route, Switch} from 'react-router-dom';
import Classes from './WorkBench.module.css';
import {connect} from 'react-redux';
import TaskPool from 'pages/TaskPool/TaskPool';
import AssignedTasks from 'pages/AssignedTasks/AssignedTasks';
import TaskCompare from 'pages/TaskCompare/TaskCompare';
import Strings from 'Strings.js';



class WorkBench extends React.Component{
    constructor(props){
        super(props);
        const menuStrings = Strings.text.menu;
        this.menuList = [
            {'m':menuStrings.assignedTasks, u:this.getUrl('task-list'), r:AssignedTasks, s: '( Alt+A )'},
            {'m':menuStrings.pool, u:this.getUrl('pool'), r:TaskPool, s: '( Alt+T )'},
            {'m':menuStrings.taskCompare, u:this.getUrl('task-compare'), r:TaskCompare, s: '( Alt+C )'},
        ];
    }

    state = {
        isLogin: true,
    }; 

    getUrl = (par) => {
        var at = ['task-list','pool','task-compare'];
        var spl = window.location.pathname.split('/');
        spl.splice(1,1)
        at.forEach(ite => {
            if(spl[spl.length-1] == ite)
            {
                spl.pop();
                return;
            }    
        });
        return `/dashboard/workbench/${par}`;
    }

    render(){

        

        

        const menus = this.menuList.map((items, index) => {
            return (
                <NavLink key={index} to={items.u} className={Classes.MenuItem} activeClassName={Classes.active}>
                    {items.m}
                    <span style={{
                        fontSize: '10px',
                        display: 'block',
                    }}>{items.s}</span>
                </NavLink>
            );
        });

        const routes = this.menuList.map((items, index) => {
            return (
                <Route key={index} path={items.u} component={items.r} />
            );
        });

        return(
            <React.Fragment>
                <div className={Classes.container}>
                    <div className={Classes.leftBar} id={'sidebar'}>{menus}</div>
                    <div className={Classes.rightBar} id={'workspace'}><Switch>{routes}</Switch></div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
	return {
      dashboardTabs: state.tabsReducer.dashboardTabs,
  }
}

export default connect(mapStateToProps, null)(WorkBench);
