import React from 'react';
import {TaskListSingle} from 'modules/Tasks/Single';
import EditTask from 'modules/Tasks/Edit';
import Action from 'modules/Tasks/AssignOrEdit/Action';
import Dictator from 'modules/Tasks/AssignOrEdit/Dictator';
import IsComplete from 'modules/Tasks/AssignOrEdit/IsComplete';
import TaskToPool from 'modules/Tasks/AssignOrEdit/TaskToPool';
import DeleteTask from 'modules/Tasks/Delete';
import TaskToUser from 'modules/Tasks/AssignOrEdit/TaskToUser';
import TaskToSelf from 'modules/Tasks/AssignOrEdit/TaskToSelf';
import RemoveFromAudit from 'modules/Tasks/AssignOrEdit/RemoveFromAudit';

import TAT from 'modules/Tasks/AssignOrEdit/tat';

import Strings from 'Strings.js';

class TaskProfile extends React.Component{ 
    render(){
        return(
            <React.Fragment>
                <div className={this.props.listContainerCSS}>
                    <TaskListSingle name={this.props.name} 
                        fileName={this.props.fileName}
                        numberOfParts={this.props.numberOfParts}
                        actionType={this.props.actionType}
                        associatedDictator={this.props.associatedDictator}
                        tat={this.props.tat}
                        timeLeft={this.props.timeLeft}
                        currentlyAssignedTo={this.props.currentlyAssignedTo} 
                        assignedTaskTemplate={this.props.assignedTaskTemplate}
                        escalationLevel={this.props.escalationLevel}
                        isCompleted={this.props.isCompleted}
                        prevUsers={this.props.prevUsers}
                     />
                    <div>
                        {this.props.pool!=null?(
                            <React.Fragment>
                                <TaskToSelf tid={this.props.tid} perm={this.props.pool} onAssign={(tid) => this.props.onAssign(tid)} />
                                {
                                    this.props.escalation_level_id == 5?(
                                        <RemoveFromAudit tid={this.props.tid} onAssign={(tid) => this.props.onAssign(tid)} />
                                    ):null
                                }
                            </React.Fragment>
                        ):(
                            <React.Fragment>
                                {/*<EditTask 
                                    tid={this.props.tid}
                                    taskName={this.props.name}
                                    fileId={this.props.fileId}
                                    actionType={this.props.actionTypeId}
                                    did={this.props.did}
                                    tat={this.props.tat}
                                />
                                <Action
                                    tid={this.props.tid}
                                    actionType={this.props.actionTypeId}
                                />
                                <Dictator
                                    tid={this.props.tid}
                                    did={this.props.did}
                                />*/}
                                <IsComplete
                                    tid={this.props.tid}
                                    isCompleted={this.props.isCompleted}
                                    refresh={this.props.refresh}
                                />
                                <TaskToPool
                                    refresh={this.props.refresh}
                                    tid={this.props.tid}
                                />

                                {/*<TaskToUser tid={this.props.tid} />*/}

                                <TAT tid={this.props.tid} refresh={this.props.refresh} />
                                <DeleteTask tid={this.props.tid} refresh={this.props.refresh} />
                            </React.Fragment>
                        )}
                        
                    </div>
                    <div>
                        
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default TaskProfile;