import React from 'react';
import {InstituteListSingle} from 'modules/Institutes/Single';
import EditInstitute from 'modules/Institutes/Edit';
import AssignIndustry from 'modules/Institutes/AssignOrEdit/Industry';
import AssignSubcat from 'modules/Institutes/AssignOrEdit/Subcat';

//import UsersList from 'pages/UsersList/UsersList';
//import TasksList from 'pages/TasksList/TasksList';

import Strings from 'Strings.js';

class InstituteProfile extends React.Component{
    render(){
        return(
            <React.Fragment>
                <div className={this.props.listContainerCSS}>
                    <InstituteListSingle name={this.props.name} 
                    contactPerson={this.props.contactPerson} industry={this.props.industry} industrySubcat={this.props.industrySubcat} 
                    instituteDictatorsCount={this.props.instituteDictatorsCount} processedTaskCount={this.props.processedTaskCount} 
                    pendingTaskCount={this.props.pendingTaskCount} isDeleted={this.props.isDeleted} />
                    <div>
                        <EditInstitute iid={this.props.iid}
                                        name={this.props.name}
                                        email={this.props.email}
                                        contactPerson={this.props.contactPerson}
                                        phone={this.props.phone}
                                        address={this.props.address}
                                        industry={this.props.industryId}
                                        category={this.props.industrySubcat}
                                        refresh={this.props.refresh}
                                 />
                        {/*<AssignIndustry iid={this.props.iid} industryId={this.props.industryId} />
        <AssignSubcat iid={this.props.iid} industryId={this.props.industryId} category={this.props.industrySubcat} />*/}
                    </div>
                    <div>
                        
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default InstituteProfile;