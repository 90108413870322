import React from 'react';
import EditFile from 'modules/Form/Edit/Edit';
import Strings from 'Strings.js';

class RemoveFromAudit extends React.Component{

    onSuccess = (data) => {
        console.log(data);
        this.props.onAssign(this.props.tid);
    }

    onFailure = (data) => {

    }

    render(){
        return(
            <EditFile
                editPermission = {'ALL'}
                ButtonCSS = {''}
                NameCSS = {''}
                editButtonValue = {'Don\'t_Audit'}
                editContainerCSS = {''}
                url = {'Tasks/removeTaskFromAudit.php'}
                buttonClicked={1}
                enableButton='true'
                buttonValue = {'Don\'t_Audit'}
                buttonShortcut={'Delete'}
                onSuccess = {(data) => this.onSuccess(data)}
                onFailure = {(data) => this.onFailure(data)}
                fields = {[
                    {
                        type: 'hidden',
                        value: this.props.tid,
                        name: 'tid',
                        options: {
                                    required: true,
                                },
                    }
                ]}
            />
        );
    }
}

export default RemoveFromAudit;


/*
    tid
    
    
   
*/