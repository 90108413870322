import React, { Component } from 'react';
import {Link, Redirect} from 'react-router-dom';
import Form from 'components/Form/Form';
import Strings from 'Strings.js';
import {connect} from 'react-redux';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

class Login extends Component{

    state = {redirect: false, url: '/dashboard/home'}

    successSubmit = (data) => {
        localStorage.setItem('awx', data.jwt);
        localStorage.setItem('per', JSON.stringify(Object.values(data.permissions)[0]));
        localStorage.setItem('tab', JSON.stringify(data.dashboardTabs));
        //this.props.changeDashboardTabs(data.dashboardTabs);
        this.props.changeLoginStatus(1);
        //this.setState({redirect: true, url: Object.values(data.permissions)[0]['account_type'] > 3?'/dashboard/workbench/task-list':'/dashboard/home'});
        console.log('perm',Object.values(data.permissions)[0]['account_type'] > 3)
        if(Object.values(data.permissions)[0]['account_type'] > 3)
            window.location.href = '/dashboard/workbench/task-list';
        else
            window.location.href = '/dashboard/home/dashboard';
            
    }

    failSubmit = (data) => {
        console.log("Login Failed : " + data.e);
        
    }

    render(){
        const fields = [{
            type: 'email',
            value: '',
            name: 'email',
            placeholder: Strings.text.placeholder.email,
            options: {
                        required: true,
                    },
            label: Strings.text.label.email,
        },{
            type: 'password',
            value: '',
            name: 'password',
            placeholder: Strings.text.placeholder.pass,
            options: {
                        required: true,
                    },
            label: Strings.text.label.pass,
        }];


        return(
            <div style={{marginTop:'30px'}}>
                {this.state.redirect === true?<Redirect to={this.state.url} />:(
                <Container>
                    <Row className="justify-content-center">
                        <Col xs={6}>
                            <h1>{Strings.text.heading.main}</h1>
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col xs={6}>
                            <h4>{Strings.text.heading.loginPage}</h4>
                        </Col>
                    </Row>

                    <Row className="justify-content-center">
                        <Col xs={6}>
                            <Form   url={'login.php'}
                                buttonValue={Strings.text.button.login}
                                buttonType='success'
                                successSubmit={(data) => this.successSubmit(data)}
                                failSubmit={(data) => this.failSubmit(data)}
                                fields={fields} />
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        {/*<Col xs={3}>
                            <Link to={{
                                pathname: '/signup',
                            }}>{Strings.text.link.signup}</Link>
                        </Col>*/}
                        <Col xs={6}>
                            <Link to={{
                                pathname: '/forget-password',
                            }}>{Strings.text.link.forgetPass}</Link>   
                        </Col>
                    </Row>
                    
                    
                                 
                </Container>)}
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => {
	return {
        //changeDashboardTabs: (tabs) => dispatch({type: 'CHANGE_DASHBOARD_TABS', payload:{tabs: tabs}}),
        changeLoginStatus: (isLogin) => dispatch({type: 'CHANGE_LOGIN', payload:{isLogin: isLogin}}),
	}
}


export default connect(null, mapDispatchToProps)(Login);
