import { RichUtils, KeyBindingUtil } from 'draft-js';

const {hasCommandModifier} = KeyBindingUtil;

export default () => {
     return {
        customStyleMap: {
            'FONTCOLOR-DEFAULT': {
                color: 'inherit'
            },
            'FONTCOLOR-#FFFFFF': {color: '#FFF'},
            'FONTCOLOR-#000000': {color: '#000'},
            'FONTCOLOR-#FF0000': {color: '#F00'},
            'FONTCOLOR-#00FF00': {color: '#0F0'},
            'FONTCOLOR-#0000FF': {color: '#00F'},
            'FONTCOLOR-#FFFF00': {color: '#FF0'},
            'FONTCOLOR-#FF00FF': {color: '#F0F'},
            'FONTCOLOR-#00FFFF': {color: '#0FF'},
            'FONTCOLOR-#AA0000': {color: '#A00'},
            'FONTCOLOR-#00AA00': {color: '#0A0'},
            'FONTCOLOR-#0000AA': {color: '#00A'},
        },
        handleKeyCommand: (command, editorState, eventTimeStamp, { setEditorState }) => {
            if(command){
                if (command.indexOf("fontcolor-") > -1) {
                    setEditorState(RichUtils.toggleInlineStyle(editorState, command.toUpperCase()));
                    return true;
                }
            }
        }
    };
  };