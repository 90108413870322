import React from 'react';
import EditFile from 'modules/Form/Edit/Edit';
import {cloneDeep} from 'lodash';
import Strings from 'Strings.js';
import axios from 'axios/axios.js';

class Edit extends React.Component{

    state = {refresh: 0, fields:[{
        type: 'hidden',
        value: this.props.uid,
        name: 'uid',
        options: {
                    required: true,
                },
    },{
        type: 'alphabets',
        value: this.props.name,
        name: 'name',
        placeholder: Strings.text.placeholder.addUserName,
        options: {
                    required: true,
                    minLength: 5,
                },
        label: Strings.text.label.name,
    },{
        type: 'email',
        value: this.props.email,
        name: 'email',
        placeholder: Strings.text.placeholder.addUserEmail,
        options: {
                    required: true,
                },
        label: Strings.text.label.email,
    },{
        type: 'text',
        value: this.props.address,
        name:'address',
        placeholder: Strings.text.placeholder.addUserAddress,
        options: {
                    required: true,
                },
        label: Strings.text.label.address,
    },{
        type: 'number',
        value: this.props.phone,
        name:'phone',
        placeholder: Strings.text.placeholder.addUserPhone,
        options: {
                    required: true,
                    minLength: 10,
                    maxLength: 10,
                },
        label: Strings.text.label.phone,
    },{
        type: 'radio',
        value: this.props.gender.toLowerCase(),
        name:'gender',
        options: {
                    required: true,
                    group : 'gender',
                    options:[
                        {value: Strings.text.value.male, name: 'male', selected: this.props.gender.toLowerCase() == 'male'?true:false},
                        {value: Strings.text.value.female, name: 'female', selected: this.props.gender.toLowerCase() == 'female'?true: false},
                    ],
                },
        label: Strings.text.label.gender,
    }], optionsToNotInclude:{}}

    onSuccess = (data) => {
        this.props.refresh();
    }

    onFailure = (data) => {

    }

    makeForm = (fields) => {
        var wb = 'workbench' in this.state.optionsToNotInclude['access'];
        var cb = 'client' in this.state.optionsToNotInclude['access'];
        var opts = [];
        if(wb)
            opts.push({value: 'Workbench', name: '1', selected: this.props.access == 1 || this.props.access == 12?true:false});
        if(cb)
            opts.push({value: 'Client App', name: '2', selected: this.props.access == 2 || this.props.access == 12?true:false});

        fields.push({
            type: wb && cb?'checkbox':'hidden',
            value: wb && cb?'':(wb?'1':'2'),
            name: wb && cb?'access':'access[]',
            options: {
                        required: true,
                        options: opts
                    },
            label: wb && cb?Strings.text.label.selectAccess:null,
        });
        this.afterMakeForm(fields, {wb:wb && (this.props.access == 1 || this.props.access == 12), cb:cb && (this.props.access == 2 || this.props.access == 12)});
    }

    afterMakeForm = (fields, access) => {
        if(access.wb == 1){
            if(!('vid' in this.state.optionsToNotInclude)){
                fields.push({
                    type: 'select',
                    value: this.props.vendor_id,
                    name: 'vid',
                    options: {
                                required: true,
                                showLoader: [true, 'black', 'Vendors/getVendorList.php?uid='+this.props.uid],
                                options: [
                                    {value: '', name: '', selected: true},
                                ]
                            },
                    label: Strings.text.label.selectVendor,
                });
            }
            if(!('siid' in this.state.optionsToNotInclude)){
                fields.push({
                    type: 'checkbox',
                    value: '',
                    name: 'siid',
                    options: {
                                required: true,
                                group: 'siidgroup',
                                showLoader: [true, 'black', 'Industry/getIndustryWithServices.php?uid='+this.props.uid],
                                options: [
                                    {value: '', name: '', selected: true},
                                ]
                            },
                    label: Strings.text.label.selectServiceIndustry,
                });
            }
            if(!('account_type' in this.state.optionsToNotInclude)){
                fields.push({
                    type: 'select',
                    value: this.props.accountType,
                    name: 'account_type',
                    options: {
                                required: true,
                                showLoader: [true, 'black', 'AccountType/accountTypeList.php?uid='+this.props.uid],
                                options: [
                                    {value: '', name: '', selected: true},
                                ]
                            },
                    label: Strings.text.label.selectAccountType,
                });
            }
            if(!('grade_all_1' in this.state.optionsToNotInclude)){
                fields.push({
                    type: 'radio',
                    value: this.props.grade_all_1,
                    name: 'grade_all_1',
                    options: {
                                required: true,
                                group: 'grade_all_1',
                                options: [
                                    {value: 'No', name: '0', selected: this.props.grade_all_1==0?true:false},
                                    {value: 'Yes', name: '1', selected: this.props.grade_all_1==1?true:false},
                                ]
                            },
                    label: 'Grade all files of this USER',
                });
            }
        }
        if(access.cb == 1){
            if(!('directPost' in this.state.optionsToNotInclude)){
                fields.push({
                    type: 'radio',
                    value: this.props.directPost,
                    name:'directPost',
                    groupid: 1,
                    options: {
                                required: true,
                                group : 'directPost',
                                options:[
                                    {value: Strings.text.value.directPostYes, name: '1', selected: this.props.directPost == '1'?true:false},
                                    {value: Strings.text.value.directPostNo, name: '0', selected: this.props.directPost == '0'?true:false},
                                ],
                            },
                    label: Strings.text.label.directPost,
                })
            }
            if(!('asr' in this.state.optionsToNotInclude)){
                fields.push({
                    type: 'radio',
                    value: this.props.asr,
                    name:'asr',
                    groupid: 1,
                    options: {
                                required: true,
                                group : 'asr',
                                options:[
                                    {value: Strings.text.value.asryes, name: '1', selected: this.props.asr == '1'?true:false},
                                    {value: Strings.text.value.asrno, name: '0', selected: this.props.asr == '0'?true:false},
                                ],
                            },
                    label: Strings.text.label.asr,
                })
            }
            if(!('nickname' in this.state.optionsToNotInclude)){
                fields.push({
                    type: 'text',
                    value: this.props.nickname,
                    name:'nickname',
                    placeholder: Strings.text.placeholder.nickname,
                    groupid: 1,
                    options: {
                                required: true,
                                },
                    label: Strings.text.label.nickname,
                })
            }
            if(!('tat' in this.state.optionsToNotInclude)){
                fields.push({
                    type: 'number',
                    value: this.props.tat,
                    name:'tat',
                    groupid: 1,
                    options: {
                                required: true,
                            },
                    label: Strings.text.label.tatinhours,
                })
            }

            if(!('grade_all_2' in this.state.optionsToNotInclude)){
                fields.push({
                    type: 'radio',
                    value: this.props.grade_all_2,
                    name: 'grade_all_2',
                    options: {
                                required: true,
                                group: 'grade_all_2',
                                options: [
                                    {value: 'No', name: '0', selected: this.props.grade_all_2==0?true:false},
                                    {value: 'Yes', name: '1', selected: this.props.grade_all_2==1?true:false},
                                ]
                            },
                    label: 'Grade all files of this DICTATOR',
                });
            }
            
            if(!('iid' in this.state.optionsToNotInclude)){
                fields.push({
                    type: 'select',
                    value: this.props.iid,
                    name: 'iid',
                    id: 'instituteList',
                    options: {
                                required: true,
                                showLoader: [true, 'black', 'Dictators/getInstituteWithSpecializationAndProf.php?uid='+this.props.uid],
                                options: [
                                    {value: '', name: '', selected: true},
                                ]
                            },
                    label: Strings.text.label.selectInstitute,
                });
                fields.push({
                    type: 'select',
                    value: '',
                    name: 'sid',
                    groupid: 1,
                    id: 'specList',
                    options: {
                                required: true,
                                showLoader: [true, 'black'],
                                onChange: 'instituteList',
                                options: [
                                    {value: '', name: '', selected: true},
                                ]
                            },
                    label: Strings.text.label.selectSpecialization,
                })
                fields.push({
                    type: 'select',
                    value: '',
                    name: 'dict_prof_type',
                    groupid: 2,
                    options: {
                                required: true,
                                showLoader: [true, 'black'],
                                onChange: 'specList',
                                options: [
                                    {value: '', name: '', selected: true},
                                ]
                            },
                    label: Strings.text.label.dictatorProfileType,
                })
            }
        }

        this.setState({refresh:0},()=>{
            this.setState({
                refresh:1,
                fields:fields
            })
        });
    }

    afterEveryInput = (data) => {
        if(data.fields[data.index].name == 'access'){
            var newField = cloneDeep(data.fields.slice(0, data.index+1));
            var wb = 0,cb = 0;
            data.fields[data.index].options.options.forEach(opts => {
                if(opts.name == '1')
                    wb = opts.selected;
                else if(opts.name == '2')
                    cb = opts.selected;
            })
            this.afterMakeForm(newField, {wb:wb, cb:cb});
        }
    }

    componentDidMount(){
        if(Object.keys(this.state.optionsToNotInclude).length == 0){
            axios.post('Users/selectProperCreateUserForm.php', {}).then(response => {
                if(response.data.r == 1)
                {
                    this.setState({optionsToNotInclude: response.data.d}, () => this.makeForm(cloneDeep(this.state.fields)))
                }
            }).catch(error => {});
        }
        
    }

    render(){
        return(
            <React.Fragment>
            {
                this.state.refresh==1?(
                    <EditFile
                        editPermission = {[3,91]}
                        ButtonCSS = {''}
                        NameCSS = {''}
                        editButtonValue = {Strings.text.button.editUser}
                        editContainerCSS = {''}
                        url = {'Users/editUser.php'}
                        buttonClicked={this.props.buttonClicked}
                        enableButton={this.props.enableButton}
                        buttonValue = {Strings.text.button.editUser}
                        onSuccess = {(data) => this.onSuccess(data)}
                        onFailure = {(data) => this.onFailure(data)} 
                        fields = {this.state.fields}
                        afterEveryInput = {(data) => this.afterEveryInput(data)}
                    />
                ):(<div>Loading....</div>)
            }
            </React.Fragment>
            
        );
    }
}

export default Edit;


/*
    uid
    accountType
    vendor
    gender
    phone
    address
    email
    name
*/