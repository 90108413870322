import React from 'react';
import List from 'modules/List/List';
import Strings from 'Strings.js';

class Single extends React.Component{

    render(){
        return(
            <React.Fragment>
                <td>{this.props.name}</td>
                <td>{this.props.vendor}</td>
                <td>{this.props.position}</td>
                <td>{this.props.gender}</td>
                <td>{this.props.approve}</td>
                {/*<td>{this.props.deleted}</td>*/}
            </React.Fragment>
        );
    }
}

class _List extends React.Component{

    render(){
        return(
            <List 
                listPermission={[5, 45]}
                onListClick={(item) => this.props.onListClick(item)}
                activeClass={'listactive'}
                listIdentifierForAutoClick={'users'}
                list={this.props.list}
                headings={{userName:'name',
                            vendorName:'vendor',
                            accountType: 'position',
                            gender: 'gender',
                            userApproved: 'approveUser',
                           // userDeleted: 'deleted',
                        }}
                
                singleItem={(item) => (<Single name={item.name} 
                    position={item.position} 
                    vendor={item.vendor} 
                    totLines={item.totLines} 
                    totTimeDone={item.totTimeDone} 
                    approve={item.approveUser}
                    gender={item.gender}
                    deleted={item.deleted}
                    activeSince={item.activeSince} 
                    nearestTAT={item.nearestTAT} />)}
                listContainerCSS={this.props.listContainerCSS}
            />
        );
    }
}

export {Single as UserListSingle, _List as UserListList};

/*
    for FileSingle -
        name, position, vendor, totLines, totTimeDone, activeSince, nearestTAT

    for FileList -
        onListClick, list, listContainerCss
*/