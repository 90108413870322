import React from 'react';
import {TaskTemplateListList} from 'modules/Tasks/Template/Single';
import TaskTemplateEdit from 'modules/Tasks/Template/Edit';
import Classes from './TaskTemplateList.module.css';
import Strings from 'Strings.js';
import axios from 'axios/axios.js';
import Pagination from 'components/Pagination/Pagination';

class TaskTemplateList extends React.Component{

    constructor(props){
        super(props);
        this.flag = 1;
    }
 
    state = {
        list: [],
        openScreen: 0,
        ttarr: {},
        totPages: 1,
        currentPage: 1,
    }

    assignDemo = (ttarr) => {
        document.getElementsByTagName('body')[0].style.overflow = 'hidden';
        this.setState({openScreen: 1, ttarr: ttarr});
    }

    closeDemo = (e) => {
        if(e)
            e.preventDefault();
        document.getElementsByTagName('body')[0].style.overflow = 'auto';
        this.setState({openScreen: 0});
    }

    callFromServer = (currentPage) => {
        if(this.flag)
        {
            this.flag = 0;
            axios.post('TaskTemplate/taskTemplateList.php?currentPage='+currentPage, {}).then(response => {
                if(response.data.r === 1)
                {
                    console.log(response.data.d.taskTemplateList);
                    this.setState({
                        ...this.state,
                        totPages: response.data.totPages,
                        currentPage: response.data.currentPage,
                        list: response.data.d.taskTemplateList
                    });
                }
                this.flag = 1;
            }).catch(error => {
                this.flag = 1;
            });
        }
    }

    componentDidMount(){
        if(this.state.list.length === 0){
            this.callFromServer(this.state.currentPage);
        }
    }

    pageClicked = (ele) => {
        console.log(ele);
        this.callFromServer(ele);
    }
    
    render(){
        return(
            <div className={Classes.container}>
               
                <div style={{marginRight:'20px',boxSizing:'border-box'}}>
                    <Pagination totPages={this.state.totPages} currentPage={this.state.currentPage} pageClicked={(ele) => this.pageClicked(ele)}>
                        <TaskTemplateListList listContainerCSS={Classes.listContainer}
                        onListClick={(data) => {}}
                        editTemplate={(ttid) => this.assignDemo(ttid)}
                        list={this.state.list} />
                    </Pagination>
                </div>

                <div>
                {this.state.openScreen === 1?(
                    <div style={{position:'fixed', width:'100vw', height:'100vh', top:0, left:0, zIndex:'5555', background:'#fff', boxSizing:'border-box', padding:'20px',overflow:'auto'}}>
                        <div style={{overflow:'hidden'}}>
                            <button style={{float:'right'}} onClick={this.closeDemo}>{Strings.text.button.closeButton}</button>
                        </div>
                        <div>
                            <TaskTemplateEdit
                                ttid={this.state.ttarr.ttid}
                                name={this.state.ttarr.name}
                                did={this.state.ttarr.did}
                                tabSize={this.state.ttarr.tabSize}
                                thid={this.state.ttarr.thid}
                                closeDemo={() => {this.closeDemo();this.callFromServer(this.state.currentPage);}}
                            />
                        </div>
                    </div>
                ):null}
                
            </div>

            </div>
        )
    }
}



export default TaskTemplateList; 