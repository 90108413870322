import React from 'react';
import {InstituteListList} from 'modules/Institutes/Single';
import InstituteProfile from 'modules/Institutes/InstituteProfile';
import Classes from './InstitutesList.module.css';
import axios from 'axios/axios.js';
import Pagination from 'components/Pagination/Pagination';

class InstitutesList extends React.Component{

    constructor(props){
        super(props);
        this.flag = 1;
    }

    state = {
        isClicked: 0,
        list: [],
        matter: null,
        curItem: null,
        totPages: 1,
        currentPage: 1,
        refreshList: 1,
    }

    callFromServer = (currentPage) => {
        if(this.flag)
        {
            this.flag = 0;
            axios.post('Institute/instituteList.php?currentPage='+currentPage, {}).then(response => {
                if(response.data.r === 1)
                {
                    console.log(response.data.d.instituteList);
                    this.setState({
                        totPages: response.data.totPages,
                        currentPage: response.data.currentPage,
                        list: response.data.d.instituteList,
                        refreshList: 1,
                    }, ()=>{
                        if(this.state.curItem){
                            var allEle = document.getElementsByClassName('institutes'+this.state.curItem.id);
                            if(allEle){
                                allEle[0].click();
                            }
                        }
                    });
                }
                this.flag = 1;
            }).catch(error => {
                this.flag = 1;
            });
        }
    }

    componentDidMount(){
        if(this.state.list.length === 0){
            this.callFromServer(this.state.currentPage);
        }
    }

    onListClick = (item) => {
        this.setState({
            isClicked: 0,
            matter: null
        }, () => {
            this.setState({
                isClicked: 1,
                curItem: item,
                matter: <InstituteProfile
                            listContainerCSS={Classes.SingleEntry}
                            id={item.id}
                            iid={item.id}
                            name={item.name}
                            contactPerson={item.contactPerson}
                            industry={item.industry}
                            industrySubcat={item.industrySubcat} 
                            processedTaskCount={item.processedTaskCount}
                            pendingTaskCount={item.pendingTaskCount}
                            instituteDictatorsCount={item.instituteDictatorsCount} 
                            isDeleted={item.isDeleted}
                            address={item.address}
                            phone={item.phone}
                            email={item.email}
                            industryId={item.industryId}
                            refresh={this.refreshList}
                        />
            });
        });
    }

    pageClicked = (ele) => {
        console.log(ele);
        this.callFromServer(ele);
    }

    refreshList = () => {
        this.setState({
            refreshList: 0,
            matter: null,
        }, () => {
            this.callFromServer(this.state.currentPage);
        });
    }

    render(){
        return(
            <div className={Classes.container}>
                {this.state.refreshList == 1?(
                    <React.Fragment>
                        <div style={{marginRight:'20px',boxSizing:'border-box'}}>
                            <Pagination totPages={this.state.totPages} currentPage={this.state.currentPage} pageClicked={(ele) => this.pageClicked(ele)}>
                                <InstituteListList listContainerCSS={Classes.listContainer}
                                onListClick={(data) => this.onListClick(data)}
                                list={this.state.list} />
                            </Pagination>
                        </div>

                        <div>
                            {this.state.isClicked === 1?this.state.matter:null}
                        </div>
                    </React.Fragment>
                ):null}
            </div>
        )
    }
}

export default InstitutesList; 