import React, { Component } from 'react';
import {Link, Redirect} from 'react-router-dom';
import Form from 'components/Form/Form';
import Strings from 'Strings.js';
import {connect} from 'react-redux';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

class Signup extends Component{

    state = {redirect: false}

    successSubmit = (data) => {
        this.setState({redirect: true});
    }

    failSubmit = (data) => {
        console.log(data);
    }

    render(){
        const fields = [{
            type: 'alphabets',
            value: '',
            name: 'name',
            placeholder: Strings.text.placeholder.name,
            options: {
                        required: true,
                        minLength: 5,
                     },
            label: Strings.text.label.name,
        },{
            type: 'email',
            value: '',
            name: 'email',
            placeholder: Strings.text.placeholder.email,
            options: {
                        required: true,
                    },
            label: Strings.text.label.email,
        },{
            type: 'password',
            value: '',
            name: 'password',
            placeholder: Strings.text.placeholder.pass,
            options: {
                        strongPass: true,
                        required: true,
                    },
            label: Strings.text.label.pass,
        },{
            type: 'text',
            value: '',
            name:'address',
            placeholder: Strings.text.placeholder.address,
            options: {
                        required: true,
                     },
            label: Strings.text.label.address,
        },{
            type: 'number',
            value: '',
            name:'phone',
            placeholder: Strings.text.placeholder.phone,
            options: {
                        required: true,
                        minLength: 10,
                        maxLength: 10,
                     },
            label: Strings.text.label.phone,
        },{
            type: 'radio',
            value: 'male',
            name:'gender',
            options: {
                        required: true,
                        group : 'gender',
                        options:[
                            {value: Strings.text.value.male, name: 'male', selected: true},
                            {value: Strings.text.value.female, name: 'female', selected: false},
                        ],
                     },
            label: Strings.text.label.gender,
        },{
            type: 'select',
            value: '',
            name: 'vid',
            options: {
                        required: true,
                        showLoader: [true, 'black', 'Vendors/getVendorList.php'],
                        options: [
                            {value: '', name: '', selected: true},
                        ]
                     },
            label: Strings.text.label.selectVendor,
        },{
            type: 'select',
            value: '',
            name: 'account_type',
            options: {
                        required: true,
                        showLoader: [true, 'black', 'AccountType/accountTypeList.php'],
                        options: [
                            {value: '', name: '', selected: true},
                        ]
                     },
            label: Strings.text.label.selectAccountType,
        },];


        return(
            <div style={{marginTop:'30px',marginBottom:'50px'}}>
                {this.state.redirect === true?<Redirect to="/login" />:(
                <Container>
                    <Row className="justify-content-center">
                        <Col xs={6}>
                            <h1>{Strings.text.heading.main}</h1>
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col xs={6}>
                            <h4>{Strings.text.heading.signupPage}</h4>
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col xs={6}>
                            <Form   url={'signup.php'}
                                    buttonValue={Strings.text.button.signup}
                                    buttonType='success'
                                    successSubmit={(data) => this.successSubmit(data)}
                                    failSubmit={(data) => this.failSubmit(data)}
                                    fields={fields} />
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col xs={6}>
                            <Link to={{
                                pathname: '/login',
                            }}>{Strings.text.link.login}</Link>       
                        </Col>
                    </Row> 
                </Container>)}
                
            </div>
        )
    }
}

export default connect(null, null)(Signup);