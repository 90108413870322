import React from 'react';
import Form from 'components/Form/Form';
import Loader from 'components/Loader/Loader';
import Strings from 'Strings.js';
import Classes from './TaskComparePage.module.css';
import axios from 'axios/axios.js';
import runningPage from 'global';


class TaskComparePage extends React.Component{

    constructor(props){
        super(props);
        this.myVer = '';
        this.finalVer = '';
        this.state = {
            isOpen: 0,
            isData: 0,
        }

        
    }


    successTaskComplete = (data) => {
        
    }

    failTaskComplete = (data) => {

    }

    partsListEvent = (e) => {
        if(runningPage.page === 'comparetaskopen'){
            if(e.altKey){
                if (e.keyCode == 192){
                    this.closeDemo();
                }
            }
        }
    }

    componentWillUnmount(){
        runningPage.page = 'home';
        document.removeEventListener('keydown', this.partsListEvent);
    }

    componentDidMount(){
        if(this.state.isOpen === 0){
            document.getElementsByTagName('body')[0].style.overflow = 'hidden';
            this.setState({isOpen: 1});
            axios.get('Tasks/getMySnapshotVersion.php?tid='+this.props.tid+'&part=1').then(response => {
                if(response.data.r==1){
                    this.myVer = JSON.parse(response.data.d);
                    axios.get('Tasks/getFinalSnapshotVersion.php?tid='+this.props.tid+'&part=1').then(resp => {
                        if(resp.data.r==1){
                            this.finalVer = JSON.parse(resp.data.d);
                            this.setState({isData: 1});
                        }
                    }).catch(e => {})
                }
            }).catch(e => {})
            
        }
        runningPage.page = 'comparetaskopen';
        document.addEventListener('keydown', this.partsListEvent);
    }


    closeDemo = (e) => {
        if(e)
            e.preventDefault();
        document.getElementsByTagName('body')[0].style.overflow = 'auto';
        this.setState({isOpen: 0});
        runningPage.page = 'home';
        document.removeEventListener('keydown', this.partsListEvent);
    }

     

    
    render(){
        var mixedText = [];
        if(this.state.isData == 1){
            this.myVer.blocks.forEach((block, ind) => {
                var tempBlock = [];
                if(this.finalVer.blocks[ind]){
                    var myVerBlockText = block.text.split(' ');
                    var finalVerBlockText = this.finalVer.blocks[ind].text.split(' ');
                    var commonWordIndexesMyVer = [];
                    var commonWordIndexesFinalVer = [];

                    var secondStart = 0;
                    for(var i=0; i<myVerBlockText.length; i++){
                        for(var j=secondStart; j<finalVerBlockText.length; j++){
                            if(myVerBlockText[i] == finalVerBlockText[j]){
                                commonWordIndexesMyVer.push(i);
                                commonWordIndexesFinalVer.push(j);
                                secondStart = j+1;
                                break;
                            }
                        }
                    }
                    
                    var firstInd = 0;
                    var secondInd = 0;
                    
                    commonWordIndexesMyVer.forEach((wordind, pos) => {
                        var temparr = [];
                        var tabsplit = '';
                        for(var i=firstInd; i<wordind; i++){
                            temparr = [];
                            tabsplit = myVerBlockText[i].split('\t');
                            tabsplit.forEach((ite, iteind) => {
                                temparr.push(ite);
                                if(iteind < tabsplit.length - 1)
                                    temparr.push(<React.Fragment>&nbsp;&nbsp;&nbsp;&nbsp;</React.Fragment>);
                            })
                            tempBlock.push(<span style={{color:'red'}}><del>{temparr}</del>{' '}</span>);
                        }
                        firstInd = wordind+1;

                        for(var i=secondInd; i<commonWordIndexesFinalVer[pos]; i++){
                            temparr = [];
                            tabsplit = finalVerBlockText[i].split('\t');
                            tabsplit.forEach((ite, iteind) => {
                                temparr.push(ite);
                                if(iteind < tabsplit.length - 1)
                                    temparr.push(<React.Fragment>&nbsp;&nbsp;&nbsp;&nbsp;</React.Fragment>);
                            })
                            tempBlock.push(<span style={{color:'red'}}>{temparr}{' '}</span>);
                        }
                        secondInd = commonWordIndexesFinalVer[pos] + 1;
                        

                        temparr = [];
                        tabsplit = myVerBlockText[wordind].split('\t');
                        tabsplit.forEach((ite, iteind) => {
                            temparr.push(ite);
                            if(iteind < tabsplit.length - 1)
                                temparr.push(<React.Fragment>&nbsp;&nbsp;&nbsp;&nbsp;</React.Fragment>);
                        })
                        tempBlock.push(<span>{temparr}{' '}</span>);
                    })

                    var temparr = [];
                    var tabsplit = '';
                    for(var i=firstInd; i<myVerBlockText.length; i++){
                        temparr = [];
                        tabsplit = myVerBlockText[i].split('\t');
                        tabsplit.forEach((ite, iteind) => {
                            temparr.push(ite);
                            if(iteind < tabsplit.length - 1)
                                temparr.push(<React.Fragment>&nbsp;&nbsp;&nbsp;&nbsp;</React.Fragment>);
                        })
                        tempBlock.push(<span style={{color:'red'}}><del>{temparr}</del>{' '}</span>);
                    }

                    for(var i=secondInd; i<finalVerBlockText.length; i++){
                        temparr = [];
                        tabsplit = finalVerBlockText[i].split('\t');
                        tabsplit.forEach((ite, iteind) => {
                            temparr.push(ite);
                            if(iteind < tabsplit.length - 1)
                                temparr.push(<React.Fragment>&nbsp;&nbsp;&nbsp;&nbsp;</React.Fragment>);
                        })
                        tempBlock.push(<span style={{color:'red'}}>{finalVerBlockText[i]}{' '}</span>);
                    }
                }
                else{
                    var temparr = [];
                    var tabsplit = block.text.split('\t');
                    tabsplit.forEach((ite, iteind) => {
                        temparr.push(ite);
                        if(iteind < tabsplit.length - 1)
                            temparr.push(<React.Fragment>&nbsp;&nbsp;&nbsp;&nbsp;</React.Fragment>);
                    })
                    tempBlock.push(<del style={{color:'red'}}>{temparr}</del>);
                }

                mixedText.push(<p>{tempBlock}</p>);
            })

            for(var i=this.myVer.blocks.length; i < this.finalVer.blocks.length; i++){
                var temparr = [];
                var tabsplit = this.finalVer.blocks[i].text.split('\t');
                tabsplit.forEach((ite, iteind) => {
                    temparr.push(ite);
                    if(iteind < tabsplit.length - 1)
                        temparr.push(<React.Fragment>&nbsp;&nbsp;&nbsp;&nbsp;</React.Fragment>);
                })
                mixedText.push(<p style={{color:'red'}}>{temparr}</p>);
            }
        }

        return(
            <div>
                {this.state.isOpen?(
                    <div className={'editorsSideBySide'} style={{position:'fixed', width:'100vw', height:'100vh', top:0, left:0, zIndex:'5555', background:'#fff', boxSizing:'border-box', padding:'20px',overflow:'auto'}}>
                        <button style={{float:'right'}} onClick={this.closeDemo}>
                            {'Close'}
                            <span style={{
                                fontSize: '10px',
                                display: 'block',
                            }}>( Alt+` )</span>
                        </button>
                    {
                            this.state.isData == 1?(
                                <React.Fragment>
                                    {mixedText}
                                </React.Fragment>
                            ):(
                                <div style={{margin:'auto', textAlign:'center'}}>
                                    <Loader color={'darkblue'} size={'big'} />
                                </div>
                            )
                        }                                       
                    </div> 
                ):null}
            </div>
        )
    }
}



export default TaskComparePage 