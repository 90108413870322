import React from 'react';

import Classes from './AllocateTasksWrapper.module.css';
import AllocateTasks from 'pages/AllocateTasks/AllocateTasks';
import AllocateTasksToVendors from 'pages/AllocateTasksToVendors/AllocateTasksToVendors';
import CheckPermission from 'components/CheckPermission/CheckPermission';
import Strings from 'Strings.js';
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';


class AllocateTasksWrapper extends React.Component{
    constructor(props){
        super(props);
        this.dt = JSON.parse(localStorage.getItem('tab'));

        var makeState = {};
        var first = 1;
        
        this.perm = JSON.parse(localStorage.getItem("per"));
        
        Object.keys(this.dt).forEach(el=>{
            makeState['tab'+el] = first;
            first = 0;
        })

        

        this.state = makeState;
    }

    setTab = (k) => {
        this.setState({
            [k] : 1
        });
    }

    render(){
        var defaultActiveKey = null;

        const tabs = Object.keys(this.dt).map(el=>{
            if(defaultActiveKey === null)
                defaultActiveKey = 'tab'+el;
            return (
                <Nav.Item key={'tab'+el}>
                    <Nav.Link eventKey={'tab'+el}>{this.dt[el]}</Nav.Link>
                </Nav.Item>
                
            );
        })

        var per = JSON.parse(localStorage.getItem('per'));

        const tabContent = Object.keys(this.dt).map((el,ind)=>{
            return (
                <Tab.Pane key={ind} eventKey={'tab'+el}>
                    {per['account_type'] == 3?<AllocateTasks siid={el} />:(per['account_type'] < 3?<AllocateTasksToVendors siid={el} />:null)}
                </Tab.Pane>
            );
        })

        return(
            <div className={Classes.container}>
                <Tab.Container id="left-tabs-example" defaultActiveKey={defaultActiveKey} onSelect={k => this.setTab(k)}>
                    <Row>
                        <Col sm={2}>
                            <Nav variant="pills" className="flex-column">
                                {tabs}
                            </Nav>
                        </Col>
                        <Col sm={10}>
                            <Tab.Content>
                                {tabContent}
                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
            </div>
        )
    }
}



export default AllocateTasksWrapper; 