import React, {Component} from 'react';
import Loader from 'components/Loader/Loader.js';
import Classes from './Button.module.css';
import Button from 'react-bootstrap/Button'

class ButtonComp extends Component{

    render(){
        let buttonRender = null;
        if(this.props.disable == true){
           /* buttonRender = <div className={[Classes.Button, Classes.disable].join(' ')}>
                                <span>{this.props.value}</span>
                            </div>;*/
            buttonRender = <Button ref={this.props.innerRef} type={'button'} className={'formSubmitButton'} variant="secondary" disabled>
                                {this.props.value}
                            </Button>
        }
        else if(this.props.loader == true){
            /*buttonRender = <div className={[Classes.Button, Classes.loader].join(' ')}>
                                <span>{this.props.value}</span>
                                <div className={Classes.loaderContainer}>
                                    <Loader size='small'></Loader>
                                </div>
                            </div>;*/
            buttonRender    = <Button ref={this.props.innerRef} type={'button'} className={'formSubmitButton'} variant="secondary" disabled>
                                {this.props.value}{' '}
                                <Loader size='small'></Loader>
                            </Button>
        }
        else{
            buttonRender = <Button
                                className={'formSubmitButton'}
                                variant={this.props.type}
                                ref={this.props.innerRef}
                                type="submit"
                                style={this.props.buttonShortcut ? {
                                    paddingBottom: 0,
                                    paddingTop: 0,
                                } : {}}
                            >
                                {this.props.value}
                                {
                                    this.props.buttonShortcut ? (
                                        <span style={{
                                            fontSize: '10px',
                                            display: 'block',
                                        }}>( {this.props.buttonShortcut} )</span>
                                    ) : null
                                }
                                
                            </Button>
        }

        return buttonRender;
    }
}

export default ButtonComp;


/*
    this.props.disable = true | false
    this.props.loader = true | false
    this.props.value = button text
    this.props.type = success | normal | danger

*/