import { combineReducers } from 'redux';
import errorReducer from './errorReducer';
import tabsReducer from './tabsReducer';
import checkReducer from './checkReducer';

const rootReducer = combineReducers({
    errorReducer:   errorReducer,
    tabsReducer:    tabsReducer,
    checkReducer:   checkReducer,
});

export default rootReducer;