import React from 'react';
import {PermTempListList} from 'modules/Options/PermissionTemplate/Single';
import PermTempProfile from 'modules/Options/PermissionTemplate/PermTempProfile';
import CreatePermissionTemplate from 'modules/Options/PermissionTemplate/Create';
import Classes from './PermissionTemplateList.module.css';
import axios from 'axios/axios.js';
import Pagination from 'components/Pagination/Pagination';

class PermissionTemplateList extends React.Component{

    constructor(props){
        super(props);
        this.flag = 1;
    }

    state = {
        isClicked: 0,
        list: [],
        matter: null,
        curItem: null,
        totPages: 1,
        currentPage: 1,
        refreshList: 1,
    }

    callFromServer = (currentPage) => {
        if(this.flag)
        {
            this.flag = 0;
            axios.post('PermissionTemplate/permissionTemplateList.php?currentPage='+currentPage, {}).then(response => {
                if(response.data.r === 1)
                {
                    console.log(response.data.d.permTemplateList);
                    this.setState({
                        totPages: response.data.totPages,
                        currentPage: response.data.currentPage,
                        list: response.data.d.permTemplateList,
                        refreshList: 1,
                    }, ()=>{
                        if(this.state.curItem){
                            var allEle = document.getElementsByClassName('permissionTemplate'+this.state.curItem.id);
                            if(allEle){
                                allEle[0].click();
                            }
                        }
                    });
                }
                this.flag = 1;
            }).catch(error => {
                this.flag = 1;
            });
        }
    }

    componentDidMount(){
        if(this.state.list.length === 0){
            this.callFromServer(this.state.currentPage);
        }
    }

    onListClick = (item) => {
        this.setState({
            isClicked: 0,
            matter: null
        }, () => {
            this.setState({
                isClicked: 1,
                curItem: item,
                matter: <PermTempProfile
                            listContainerCSS={Classes.SingleEntry}
                            id={item.id}
                            tid={item.id}
                            templateName={item.templateName} 
                            createdBy={item.createdBy}
                            serviceIndustryValue={item.serviceIndustryValue} 
                            siid={item.siid}
                            tempType={item.tempType}
                            refresh={this.refreshList}
                       />
            });
        });
    }

    pageClicked = (ele) => {
        console.log(ele);
        this.callFromServer(ele);
    }

    refreshList = () => {
        this.setState({
            refreshList: 0,
            matter: null,
        }, () => {
            this.callFromServer(this.state.currentPage);
        });
    }

    render(){
        return(
            <div className={Classes.container}>
                    <div>
                        <CreatePermissionTemplate refresh={this.refreshList} />
                    </div>
                    {this.state.refreshList == 1?(
                        <React.Fragment>
                            <div style={{marginRight:'20px',boxSizing:'border-box'}}>
                                <Pagination totPages={this.state.totPages} currentPage={this.state.currentPage} pageClicked={(ele) => this.pageClicked(ele)}>
                                    <PermTempListList listContainerCSS={Classes.listContainer}
                                    onListClick={(data) => this.onListClick(data)}
                                    list={this.state.list} />
                                </Pagination>
                            </div>

                            <div>
                                {this.state.isClicked === 1?this.state.matter:null}
                            </div>
                        </React.Fragment>
                    ):null}
            </div>
        )
    }
}

export default PermissionTemplateList; 