import React from 'react';
import {UserListSingle} from 'modules/Users/Single';
import ApproveUser from 'modules/Users/ApproveUser';
import EditUser from 'modules/Users/Edit';
import AssignAccountType from 'modules/Users/AssignOrEdit/AccountType';
import AssignRoles from 'modules/Users/AssignOrEdit/Roles';
import AssignVendor from 'modules/Users/AssignOrEdit/Vendor';
import AssignPermission from 'modules/Users/AssignOrEdit/Permission';
import AssignPermissionTemplate from 'modules/Users/AssignOrEdit/PermissionTemplate';
import AssignGrantPermission from 'modules/Users/AssignOrEdit/GrantPermission';
import AssignGrantPermissionTemplate from 'modules/Users/AssignOrEdit/GrantPermissionTemplate';

import TasksList from 'pages/TasksList/TasksList';

import Strings from 'Strings.js';

class UserProfile extends React.Component{
    render(){
        return(
            <React.Fragment>
                <div className={this.props.listContainerCSS}>
                    <UserListSingle name={this.props.name} 
                        position={this.props.position} vendor={this.props.vendor} totLines={this.props.totLines} 
                        totTimeDone={this.props.totTimeDone} activeSince={this.props.activeSince} 
                        vendor_id={this.props.vendor_id}
                        nearestTAT={this.props.nearestTAT}
                        approve={this.props.approveUser}
                        gender={this.props.gender}
                        deleted={this.props.deleted}
                        />
                    <div>
                        <ApproveUser uid={this.props.uid} approveUser={this.props.approveUser} refresh={this.props.refresh} />
                        <EditUser uid={this.props.uid}
                                    accountType={this.props.accountType}
                                    vendor={this.props.vendor}
                                    vendor_id={this.props.vendor_id}
                                    gender={this.props.gender}
                                    phone={this.props.phone}
                                    address={this.props.address}
                                    email={this.props.email}
                                    iid={this.props.iid}
                                    access={this.props.access}
                                    asr={this.props.asr}
                                    directPost={this.props.directPost}
                                    sid={this.props.sid}
                                    tat={this.props.tat}
                                    nickname={this.props.nickname}
                                    refresh={this.props.refresh}
                                    grade_all_1={this.props.grade_all_1}
                                    grade_all_2={this.props.grade_all_2}
                                    name={this.props.name} />
                        {/*<AssignAccountType uid={this.props.uid} accountType={this.props.accountType} />
                        <AssignRoles uid={this.props.uid} industry={this.props.industry} />
                        <AssignVendor uid={this.props.uid} vendor={this.props.vendor} vendor_id={this.props.vendor_id} />*/}
                        <AssignPermission uid={this.props.uid} refresh={this.props.refresh} />
                        <AssignPermissionTemplate uid={this.props.uid} refresh={this.props.refresh} />
                        <AssignGrantPermission uid={this.props.uid} refresh={this.props.refresh} />
                        <AssignGrantPermissionTemplate uid={this.props.uid} refresh={this.props.refresh} />
                    </div>
                    <div>
                        <TasksList uid={this.props.uid} />
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default UserProfile;