import React, {Component} from 'react';
import CreateFile from 'modules/Form/Create/Create';
import Classes from './GradingBox.module.css';

class GradingBox extends Component{
    constructor(props){
        super(props)
        this.initialFields = [{
            type: 'textarea',
            value: '',
            name: 'array_wrong',
            placeholder: '',
            options: {
                        required: true,
                        table: {rowStart: true},
                     },
        },{ 
            type: 'textarea',
            value: '',
            name: 'array_right',
            placeholder: '',
            options: {
                        required: true,
                        table: {},
                     },
        },{
            type: 'select',
            value: '0',
            name: 'array_errorType',
            placeholder: '',
            options: {
                        required: true,
                        table: {},
                        options: [
                            {value: 'No Error', name: '0', selected: true},
                            {value: 'Demo Error', name: '1', selected: false},
                            {value: 'Major Error', name: '2', selected:  false},
                            {value: 'Minor Error', name: '3', selected:  false},
                        ]
                     },
        },{
            type: 'textarea',
            value: '',
            name: 'array_comment',
            placeholder: '',
            options: {
                        required: false,
                        table: {rowEnd: true},
                        addFieldsBut: {plus:true, minus:false, func:(data) => this.addDeleteFields(data)}
                     },
        }];

        this.state = {
            fields: this.makeForm(this.props.grading)
        }

        

    }

    makeForm = (grading) => {
        var field = [{
            type: 'hidden',
            value: this.props.tid,
            name: 'tid',
            options: {
                        required: true,
                     },
        }];
        grading.forEach((ele,ind) => {
            field.push({
                type: 'textarea',
                value: ele.wrong_text,
                name: 'array_wrong',
                placeholder: '',
                options: {
                            required: true,
                            table: {rowStart: true},
                         },
            });
            field.push({
                type: 'textarea',
                value: ele.right_text,
                name: 'array_right',
                placeholder: '',
                options: {
                            required: true,
                            table: {},
                         },
            });
            field.push({
                type: 'select',
                value: ele.error_type,
                name: 'array_errorType',
                placeholder: '',
                options: {
                            required: true,
                            table: {},
                            options: [
                                {value: 'No Error', name: '0', selected: ele.error_type=='0'?true:false},
                                {value: 'Demo Error', name: '1', selected: ele.error_type=='1'?true:false},
                                {value: 'Major Error', name: '2', selected: ele.error_type=='2'?true:false},
                                {value: 'Minor Error', name: '3', selected: ele.error_type=='3'?true:false},
                            ]
                         },
            });
            field.push({
                type: 'textarea',
                value: ele.comment,
                name: 'array_comment',
                placeholder: '',
                options: {
                            required: false,
                            table: {rowEnd: true},
                            addFieldsBut: {plus:true, minus:ind==0?false:true, func:(data) => this.addDeleteFields(data)}
                         },
            });
        })

        if(field.length == 1){
            field = [...field, ...this.initialFields];
        }

        return field;
    }

    addDeleteFields = (data) => {
        
        if(data.addminus == 1){
            data.fields.splice(data.index+1,0,{
                type: 'textarea',
                value: '',
                name: 'array_wrong',
                placeholder: '',
                options: {
                            required: true,
                            table: {rowStart: true},
                         },
            },{
                type: 'textarea',
                value: '',
                name: 'array_right',
                placeholder: '',
                options: {
                            required: true,
                            table: {},
                         },
            },{
                type: 'select',
                value: '0',
                name: 'array_errorType',
                placeholder: '',
                options: {
                            required: true,
                            table: {},
                            options: [
                                {value: 'No Error', name: '0', selected: true},
                                {value: 'Demo Error', name: '1', selected: false},
                                {value: 'Major Error', name: '2', selected:  false},
                                {value: 'Minor Error', name: '3', selected:  false},
                            ]
                         },
            },{
                type: 'textarea',
                value: '',
                name: 'array_comment',
                placeholder: '',
                options: {
                            required: false,
                            table: {rowEnd: true},
                            addFieldsBut: {plus:true, minus:true, func:(data) => this.addDeleteFields(data)}
                         },
            });
        }
        else if(data.addminus == -1){
            data.fields.splice(data.index-3,4);
            if(data.fields.length == 1)
                data.fields = [...data.fields, ...this.initialFields];
        }

        this.setState({fields: []}, () => {this.setState({fields: data.fields})});
    }

    componentDidMount(){
        this.gradingBoxRef.fieldRef.formRef.mainFormRef.getElementsByTagName('textarea')[0].focus()
    }
    
    
    render() {
        
        return (
            this.state.fields.length > 0?(
                <CreateFile
                    createPermission = {'ALL'}
                    createContainerCSS = {Classes.GradingBox}
                    url={'Tasks/addGrading.php'}
                    onSuccess = {(data) => this.props.onClick(data)}
                    buttonValue = {'Submit'}
                    isTable = {['Wrong Text', 'Correct Text', 'Type of Error', 'Comment']}
                    inlineTable = {true}
                    ref={node => this.gradingBoxRef = node}
                    fields = {this.state.fields}
                />
            ):null
        );
    }
}
export default GradingBox;