import React from 'react';
import Strings from 'Strings.js';
import Form from 'components/Form/Form';

class Fields extends React.Component{

    successSubmit = (data) => {
        console.log("Field Submit Running");
        this.props.onSuccess(data);
    }

    failSubmit = (data) => {
        console.log("Submission Failed : " + data.e);
        this.props.onFailure(data);
    }

    render(){
        return(
            <React.Fragment>
                <Form   {...this.props}
                        url={this.props.url}
                        buttonValue={this.props.buttonValue}
                        buttonType='success'
                        successSubmit={(data) => this.successSubmit(data)}
                        failSubmit={(data) => this.failSubmit(data)}
                        ref={(node) => { this.formRef = node; }}
                        fields={this.props.fields} />        
            </React.Fragment>
        );
    }
}

export default Fields;