import React from 'react';
import {PermTempListSingle} from 'modules/Options/PermissionTemplate/Single';
import EditPermTemp from 'modules/Options/PermissionTemplate/Edit';
import AssignIndustry from 'modules/Options/PermissionTemplate/AssignOrEdit/Industry';
import AssignPermission from 'modules/Options/PermissionTemplate/AssignOrEdit/Permission';

import PermissionList from 'pages/PermissionList/PermissionList';

import Strings from 'Strings.js';

class PermTempProfile extends React.Component{
    render(){
        return(
            <React.Fragment>
                <div className={this.props.listContainerCSS}>
                    <PermTempListSingle templateName={this.props.templateName} serviceIndustryValue={this.props.serviceIndustryValue} createdBy={this.props.createdBy} tempType={this.props.tempType} />
                    <div>
                        <EditPermTemp tid={this.props.tid} refresh={this.props.refresh} templateName={this.props.templateName} serviceIndustryValue={this.props.serviceIndustryValue} siid={this.props.siid} />
                        {/*<AssignIndustry refresh={this.props.refresh} tid={this.props.tid}
        serviceIndustryValue={this.props.serviceIndustryValue} />*/}
                        <AssignPermission refresh={this.props.refresh} tid={this.props.tid} />
                    </div>
                    <div>
                        <PermissionList tid={this.props.tid} />
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default PermTempProfile;