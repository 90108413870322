import React from 'react';
import EditFile from 'modules/Form/Edit/Edit';
import Strings from 'Strings.js';

class Edit extends React.Component{

    onSuccess = (data) => {
        console.log(data);
        this.props.onAssign(this.props.tid);
    }

    onFailure = (data) => {

    }

    render(){
        return(
            <EditFile
                editPermission = {this.props.perm}
                ButtonCSS = {''}
                NameCSS = {''}
                editButtonValue = {Strings.text.button.assignTaskToSelf}
                editContainerCSS = {''}
                url = {'Tasks/assignTaskToSelf.php'}
                buttonClicked={1}
                buttonShortcut={'Enter'}
                enableButton='true'
                buttonValue = {Strings.text.button.assignTaskToSelf}
                onSuccess = {(data) => this.onSuccess(data)}
                onFailure = {(data) => this.onFailure(data)}
                fields = {[
                    {
                        type: 'hidden',
                        value: this.props.tid,
                        name: 'tid',
                        options: {
                                    required: true,
                                },
                    }
                ]}
            />
        );
    }
}

export default Edit;


/*
    tid
    
    
   
*/