import { RichUtils, KeyBindingUtil } from 'draft-js';

const {hasCommandModifier} = KeyBindingUtil;

export default () => {
    var fontSizeObj = {'FONTSIZE-DEFAULT': {
                            fontSize: 'inherit'
                        }}
    for(var i=8; i<30; i += 0.5)
        fontSizeObj['FONTSIZE-'+i] = {
            fontSize: (i*4/3)+'px'
        }
     return {
        customStyleMap: fontSizeObj,
        handleKeyCommand: (command, editorState, eventTimeStamp, { setEditorState }) => {
            if(command){
                if (command.indexOf("fontsize-") > -1) {
                    setEditorState(RichUtils.toggleInlineStyle(editorState, command.toUpperCase()));
                    return true;
                }
            }
        }
    };
  };