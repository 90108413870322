import React, {useState, useEffect} from 'react';
import Classes from './DashboardPage.module.css';
import axios from 'axios/axios.js';
import Form from 'components/Form/Form';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Table from 'react-bootstrap/Table';
import InputGroup from 'react-bootstrap/InputGroup'
import FormControl from 'react-bootstrap/FormControl'
import Button from 'react-bootstrap/Button'
import ApexCharts from 'react-apexcharts';
import {NavLink, Route, Switch} from 'react-router-dom';

const JSONToCSVConvertor = (JSONData, ReportTitle, ShowLabel) => {
    //If JSONData is not an object then JSON.parse will parse the JSON string in an Object
    var arrData = typeof JSONData != 'object' ? JSON.parse(JSONData) : JSONData;
    
    var CSV = '';    
    
    //This condition will generate the Label/Header
    if (ShowLabel) {
        var row = "";
        
        //This loop will extract the label from 1st index of on array
        for (var index in arrData[0]) {
            
            //Now convert each value to string and comma-seprated
            row += index + ',';
        }

        row = row.slice(0, -1);
        
        //append Label row with line break
        CSV += row + '\r\n';
    }
    
    //1st loop is to extract each row
    for (var i = 0; i < arrData.length; i++) {
        var row = "";
        
        //2nd loop will extract each column and convert it in string comma-seprated
        for (var index in arrData[i]) {
            row += '"' + arrData[i][index] + '",';
        }

        row.slice(0, row.length - 1);
        
        //add a line break after each row
        CSV += row + '\r\n';
    }

    if (CSV == '') {        
        alert("Invalid data");
        return;
    }   
    
    //Generate a file name
    var fileName = "MyReport_";
    //this will remove the blank-spaces from the title and replace it with an underscore
    fileName += ReportTitle.replace(/ /g,"_");   
    
    //Initialize file format you want csv or xls
    var uri = 'data:text/csv;charset=utf-8,' + escape(CSV);
    
    // Now the little tricky part.
    // you can use either>> window.open(uri);
    // but this will not work in some browsers
    // or you will not get the correct file extension    
    
    //this trick will generate a temp <a /> tag
    var link = document.createElement("a");    
    link.href = uri;
    
    //set the visibility hidden so it will not effect on your web-layout
    link.style = "visibility:hidden";
    link.download = fileName + ".csv";
    
    //this part will append the anchor tag and remove it after automatic click
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}


const FileLineCount = (props) => {
    const [filesLines, setFilesLines] = useState({files: 0, lines: 0});
    useEffect(() => {
        axios.post('Users/lineFileCount.php', {}).then(response => {
            if(response.data.r === 1)
            {
                setFilesLines({files: response.data.d.files, lines: (response.data.d.characters / 65).toFixed(2)});
            }
        }).catch(error => {});
    }, []);

    return (
        <Container fluid>
            <Row className={Classes.fLRow}>
                <Col xs={8} className={Classes.fileLineCountHead}>Line Count For The Month</Col>
                <Col xs={4} className={Classes.fileLineCountNum}>{filesLines.lines}</Col>
            </Row>
            <Row className={Classes.fLRow}>
                <Col xs={8} className={Classes.fileLineCountHead}>File Count For The Month</Col>
                <Col xs={4} className={Classes.fileLineCountNum}>{filesLines.files}</Col>
            </Row>
        </Container>
    );
}

const FilterForm = (props) => {
    const formatDate = (date) => {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return [year, month, day].join('-');
    }

    const fields = [];

    if('datefrom' in props.fields){
        fields.push({
            type: 'date',
            value: formatDate(Date.now()),
            name: 'datefrom',
            class: Classes.singleField,
            options: {
                        required: true,
                        noRequiredShow: true,
                        errorTextClass: Classes.errorText,
                    },
            label: 'Date From',
        });
    }
    if('dateto' in props.fields){
        fields.push({
            type: 'date',
            value: formatDate(Date.now()),
            name: 'dateto',
            class: Classes.singleField,
            options: {
                        required: true,
                        noRequiredShow: true,
                        errorTextClass: Classes.errorText,
                    },
            label: 'Date To',
        });
    }
    if('dictators' in props.fields){
        fields.push({
            type: 'checkboxlist',
            value: '',
            name: 'dictators',
            class: Classes.singleField,
            options: {
                        required: true,
                        class: Classes.singleListItem,
                        errorTextClass: Classes.errorText,
                        subClass: Classes.singleListSubItem,
                        noRequiredShow: true,
                        selectAll: {set: true, selected: true},
                        showLoader: [true, 'black', props.fields.dictators],
                        options: [
                            {value: '', name: '', selected: true},
                        ]
                    },
            label: 'Clinic / Dictator',
        });
    }
    if('siid' in props.fields){
        fields.push({
            type: 'checkboxlist',
            value: '',
            name: 'siid',
            class: Classes.singleField,
            options: {
                        required: true,
                        class: Classes.singleListItem,
                        errorTextClass: Classes.errorText,
                        subClass: Classes.singleListSubItem,
                        noRequiredShow: true,
                        selectAll: {set: true, selected: true},
                        showLoader: [true, 'black', props.fields.siid],
                        options: [
                            {value: '', name: '', selected: true},
                        ]
                    },
            label: 'Services',
        });
    }

    if('levels' in props.fields){
        fields.push({
            type: 'checkboxlist',
            value: '',
            name: 'levels',
            class: Classes.singleField,
            options: {
                        required: true,
                        class: Classes.singleListItem,
                        errorTextClass: Classes.errorText,
                        subClass: Classes.singleListSubItem,
                        noRequiredShow: true,
                        selectAll: {set: true, selected: true},
                        showLoader: [true, 'black', props.fields.levels],
                        options: [
                            {value: '', name: '', selected: true},
                        ]
                    },
            label: 'Levels',
        });
    }

    return (
        <Form   onSubmit={(stateData, formdt) => props.afterSubmit(stateData, formdt)}
                buttonValue={'Filter'}
                allowFormOverflow
                buttonType='success'
                fields={fields} />
    );
}

const PendingFiles = (props) => {
    const [fileDur, setFileDur] = useState(null);
    const fileDurRef = React.useRef();
    useEffect(() => {
        axios.post('Files/getPendingFilesStats.php', props.formdata).then(response => {
            if(response.data.r === 1)
            {
                const files = {};
                const finalObj = {type: [], totalCount: [], userCount: [], poolCount: [], dur: []};
                
                response.data.d.forEach((element,index) => {
                    if(!([element.type] in files))
                        files[element.type] = {count: 0, pool: 0, dur: 0};

                    if(element.type == 'Unallocated' || element.type == 'Trainee' || element.type == 'Transcriptionist'){
                        if(!('Unallocated' in files))
                            files['Unallocated'] = {count: 0, pool: 0, dur: 0};

                        files.Unallocated.pool += element.pool_count;
                        files.Unallocated.dur += element.pool_duration;

                        if(element.type == 'Unallocated'){
                            files.Unallocated.count = element.user_count;
                            files.Unallocated.dur += element.user_duration;
                        }
                        else
                            files[element.type] = {count: element.user_count, pool: 0, dur: element.user_duration};
                    }
                    else{
                        files[element.type] = {count: element.user_count, pool: element.pool_count, dur: element.user_duration + element.pool_duration};
                    }
                });

                Object.keys(files).forEach(eletype => {
                    finalObj.type.push(eletype);
                    finalObj.totalCount.push(files[eletype].count + files[eletype].pool);
                    finalObj.userCount.push(files[eletype].count);
                    finalObj.poolCount.push(files[eletype].pool);
                    finalObj.dur.push(Math.ceil(files[eletype].dur / 60));
                });
                fileDurRef.current = finalObj;
                setFileDur(finalObj);
            }
        }).catch(error => {});
    }, [props]);

    const tooltip = (seriesIndex, w) => {
        console.log(fileDurRef.current);
        return '<div class="arrow_box" style="padding:10px 20px">' +
        '<b style="display:block;">'+w.globals.labels[seriesIndex]+'</b>'+
        '<span style="display:block;">Users - ' + fileDurRef.current.userCount[seriesIndex] + '</span>' +
        '<span style="display:block;">Pool - ' + fileDurRef.current.poolCount[seriesIndex] + '</span>' +
        '</div>';
    }

    const pieChartOptions = () => ({
        chart: {
          type: 'donut',
        },
        colors: ['#2E93fA', '#66DA26', '#546E7A', '#E91E63', '#FF9800', '#ED0003', '#0900FF', '#122C5A', '#017F01', '#A12000'],
        labels: fileDurRef.current.type,
        plotOptions: {
            pie: {
                startAngle: 0,
                donut: {
                    size: '30%',
                    labels: {
                        show: true,
                        name: {
                            show: false,
                          },
                          value: {
                            show: true,
                            fontSize: '35px',
                            fontFamily: 'Helvetica, Arial, sans-serif',
                            fontWeight: 600,
                            color: undefined,
                            offsetY: 16,
                            formatter: function (val) {
                              return val
                            }
                          },
                        total: {
                            show: true,
                            showAlways: true,
                            // label: 'Total',
                            // fontSize: '35px',
                            // fontFamily: 'Helvetica, Arial, sans-serif',
                            // fontWeight: 600,
                            // color: '#373d3f',
                            // formatter: function (w) {
                            //   return w.globals.seriesTotals.reduce((a, b) => {
                            //     return a + b
                            //   }, 0)
                            // }
                        }
                    },

                }
            }
        },
        dataLabels: {
            enabled: true,
            formatter: function(val, opt) {
                return opt.w.globals.labels[opt.seriesIndex] + ":  " + opt.w.globals.series[opt.seriesIndex]
            },
        },
        title: {
            text: 'Pending Files',
            align: 'left',
            floating: false,
            style: {
                fontSize:  '22px',
                fontWeight:  'bold',
                fontFamily:  undefined,
                color:  '#263238'
            },
        },
        tooltip: {
            custom: function({series, seriesIndex, dataPointIndex, w}) {
                return tooltip(seriesIndex, w);
            }
        },
    });

    const barChartOptions = () => ({
        chart: {
            type: 'bar',
            toolbar: {
                show: false
            }
        },
        plotOptions: {
            bar: {
              barHeight: '100%',
              distributed: true,
              horizontal: true,
              dataLabels: {
                position: 'top',
              },
            }
        },
        dataLabels: {
            position: 'top',
            distributed: true,
            offsetX: 40,
            offsetY: 0,
            background: {
                enabled: true,
                foreColor: '#000',
                padding: 4,
                borderRadius: 2,
                borderWidth: 1,
                borderColor: '#aaa',
                opacity: 0.9,
            },
        },
        stroke: {
            width: 1,
            colors: ['#fff']
        },
        legend: {
            show: false,
        },
        colors: ['#2E93fA', '#66DA26', '#546E7A', '#E91E63', '#FF9800', '#ED0003', '#0900FF', '#122C5A', '#017F01', '#A12000'],
        xaxis: {
            categories: fileDurRef.current.type,
        },
        yaxis: {
            labels: {
                show: true
            }
        },
        title: {
            text: 'Pending Duration (Minutes)',
            align: 'left',
            floating: false,
            style: {
                fontSize:  '22px',
                fontWeight:  'bold',
                fontFamily:  undefined,
                color:  '#263238'
            },
        },
        tooltip: {
            theme: 'dark',
            x: {
              show: true,
            },
            y: {
              title: {
                formatter: function () {
                  return ''
                }
              }
            }
        },
    });

    return (
        <Row>
            <Col md={6}>
                {fileDur !== null?
                <ApexCharts options={pieChartOptions()} series={fileDur.totalCount} type="donut" />
                :null}
            </Col>
            <Col md={6}>
                {fileDur !== null?
                <ApexCharts options={barChartOptions()} series={[{data: fileDur.dur}]} type="bar" />
                :null}
            </Col>
        </Row>);
}

const PerformanceComponent = (props) => {
    const [newFilter, setNewFilter] = useState(new FormData());
    
    const PerformanceTable = (props) => {
        const [usersStats, setUsersStats] = useState(null);
        const [searchStr, setSearchStr] = useState('');
        const [singleUser, setSingleUser] = useState(0);
        const fileCountRef = {current: {}};
        const lineCountRef = {current: {}};

        useEffect(() => {
            axios.post('Files/getUsersFilesStats.php', props.formdata).then(response => {
                if(response.data.r === 1)
                {
                    setUsersStats(response.data.d);
                    if(Object.keys(response.data.d.users).length == 1) {
                        setSingleUser(Object.keys(response.data.d.users)[0]);
                    }
                }
            }).catch(error => {});
        }, [props]);

        const exportData = () => {
            const finalJSON = [];
            let exportFileName = 'Performance_Report';
            let exportUserName = '';
            let skip = 0;
            usersStats !== null && 'users' in usersStats && Object.keys(usersStats.users).forEach(userid => {
                
                skip = 0;
                if (singleUser === 0){
                    if(searchStr.trim().length > 0){
                        if(usersStats.users[userid].name.toUpperCase().indexOf(searchStr.toUpperCase().trim()) > -1) skip = 0;
                        else skip = 1;
                    }
                } else {
                    if(userid === singleUser) skip = 0;
                    else skip = 1;
                }

                if(skip === 0){
                    Object.keys(usersStats.users[userid].escl).forEach(accttype => {
                        Object.keys(usersStats.users[userid].escl[accttype]).forEach(taskid => {
                            if(singleUser > 0 && searchStr.trim().length > 0){
                                exportUserName = '_' + usersStats.users[userid].name;
                                if(usersStats.task[taskid].name.toUpperCase().indexOf(searchStr.toUpperCase().trim()) > -1) skip = 0;
                                else skip = 1;
                            }

                            if(skip === 0){
                                var tempop = usersStats.users[userid].escl[accttype][taskid];
                                finalJSON.push(
                                    { 
                                        'Name' : usersStats.users[userid].name,
                                        'Level' : (accttype == 1 ? 'Trainee' :
                                                    accttype == 2 ? 'MT' :
                                                    accttype == 3 ? 'Proofer 1' :
                                                    accttype == 4 ? 'Proofer 2' :
                                                    accttype == 5 ? 'Audit' :
                                                    accttype == 6 ? 'Blank C' :
                                                    accttype == 7 ? 'Direct' : ''),
                                        'File Name' : usersStats.task[taskid].name,
                                        'File Count' : 1,
                                        'Line Count' : (Object.values(usersStats.task[taskid].lc).reduce(function(a, b){
                                                            return parseInt(a) + parseInt(b);
                                                        }, 0) / 65).toFixed(2),
                                        'Critical' : (tempop.graded == 1 ? tempop['1'] : '-'),
                                        'Major' : (tempop.graded == 1 ? tempop['2'] : '-'),
                                        'Minor' : (tempop.graded == 1 ? tempop['3'] : '-'),
                                        'Comment' : (tempop.graded == 1 ? tempop.comment : '-'),
                                        'Accuracy' : (tempop.graded == 1 ? (100 - ((tempop['1'] * 2.5) + (tempop['2'] * 1) + (tempop['3'] * 0.5))).toFixed(2) + '%' : '-'),
                                        'Turn Around Time' : (usersStats.task[taskid].tat == 1?'Yes':'No'),
                                    }
                                );
                            }
                        });
                    });
                }
            });
            if(finalJSON.length > 0){
                JSONToCSVConvertor(finalJSON, exportFileName+exportUserName, true);
            }
        }
        

        return (
            <React.Fragment> 
                <div>
                    <Button size={'sm'} style={{float: 'right', marginLeft: 20}} variant={'info'} onClick={exportData}>Export</Button>
                    <InputGroup style={{width: '40%', float: 'right'}} size={'sm'} className="mb-3">
                        <InputGroup.Prepend>
                            <InputGroup.Text id="basic-addon1">🔍</InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                            placeholder="Filter Tasks"
                            aria-label="Filter Tasks"
                            aria-describedby="basic-addon1"
                            value={searchStr}
                            onChange={(e) => setSearchStr(e.target.value)}
                        />
                    </InputGroup>
                    
                </div>
                {singleUser === 0 ? (
                    <Table bordered size="sm">
                        <thead>
                            <tr style={{backgroundColor:'bisque'}}>
                                <th>Name</th>
                                <th>File Count</th>
                                <th>Line Count</th>
                                <th>Critical</th>
                                <th>Major</th>
                                <th>Minor</th>
                                <th>Comment</th>
                                <th>Accuracy (Percentage)</th>
                                <th>Turn Around Time (Percentage)</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                usersStats !== null && 'users' in usersStats && Object.keys(usersStats.users).map(userid => {

                                    if(searchStr.trim().length > 0){
                                        if(usersStats.users[userid].name.toUpperCase().indexOf(searchStr.toUpperCase().trim()) > -1);
                                        else return null;
                                    }

                                    var name = [<span style={{display: 'block', fontSize: '14px', fontWeight: '600'}}>{usersStats.users[userid].name}</span>];
                                    var criterror = [<span style={{display: 'block'}}>&nbsp;</span>];
                                    var majerror = [<span style={{display: 'block'}}>&nbsp;</span>];
                                    var minerror = [<span style={{display: 'block'}}>&nbsp;</span>];
                                    var commerror = [<span style={{display: 'block'}}>&nbsp;</span>];
                                    var lineCount = [<span style={{display: 'block'}}>&nbsp;</span>];
                                    var fileCount = [<span style={{display: 'block'}}>&nbsp;</span>];
                                    var tat = [<span style={{display: 'block'}}>&nbsp;</span>];
                                    var accuracy = [<span style={{display: 'block'}}>&nbsp;</span>];

                                    Object.keys(usersStats.users[userid].escl).forEach(accttype => {
                                        name.push(<span style={{display: 'block', marginLeft: 20}}>{
                                            accttype == 1 ? 'Trainee' :
                                            accttype == 2 ? 'MT' :
                                            accttype == 3 ? 'Proofer 1' :
                                            accttype == 4 ? 'Proofer 2' :
                                            accttype == 5 ? 'Audit' :
                                            accttype == 6 ? 'Blank C' :
                                            accttype == 7 ? 'Direct' : ''
                                        }</span>);
                                        fileCount.push(<span style={{display: 'block'}}>
                                            {Object.keys(usersStats.users[userid].escl[accttype]).length}
                                        </span>);
                                        var crit = 0; 
                                        var maj = 0;
                                        var min = 0;
                                        var comm = 0;
                                        var totalGraded = 0;
                                        var tatStat = 0;
                                        var lineCntStat = 0;
                                        Object.keys(usersStats.users[userid].escl[accttype]).forEach(taskid => {
                                            fileCountRef.current[taskid] = 1;
                                            var tempop = usersStats.users[userid].escl[accttype][taskid];
                                            if(tempop.graded == 1){
                                                totalGraded += 1;
                                                crit += tempop['1'];
                                                maj += tempop['2'];
                                                min += tempop['3'];
                                                comm += tempop.comment;
                                            }
                                            if(usersStats.task[taskid].tat == 1){
                                                tatStat += 1;
                                            }
                                            lineCountRef.current[taskid] = Object.values(usersStats.task[taskid].lc).reduce(function(a, b){
                                                return parseInt(a) + parseInt(b);
                                            }, 0);

                                            lineCntStat += lineCountRef.current[taskid];
                                        })
                                        criterror.push(<span style={{display: 'block'}}>{totalGraded > 1?crit:'-'}</span>);
                                        majerror.push(<span style={{display: 'block'}}>{totalGraded > 1?maj:'-'}</span>);
                                        minerror.push(<span style={{display: 'block'}}>{totalGraded > 1?min:'-'}</span>);
                                        commerror.push(<span style={{display: 'block'}}>{totalGraded > 1?comm:'-'}</span>);
                                        tat.push(<span style={{display: 'block'}}>{(tatStat * 100 / Object.keys(usersStats.users[userid].escl[accttype]).length).toFixed(2)} %</span>);
                                        lineCount.push(<span style={{display: 'block'}}>{(lineCntStat / 65).toFixed(2)}</span>);
                                        accuracy.push(<span style={{display: 'block'}}>{totalGraded > 1?(((totalGraded * 100) - ((crit * 2.5) + (maj * 1) + (min * 0.5))) / totalGraded).toFixed(2) + '%':'-'}</span>);
                                    })
                                    return (
                                        <tr key={userid}>
                                            <td style={{cursor: 'pointer'}} onClick={() => setSingleUser(userid)}>{name}</td>
                                            <td>{fileCount}</td>
                                            <td>{lineCount}</td>
                                            <td>{criterror}</td>
                                            <td>{majerror}</td>
                                            <td>{minerror}</td>
                                            <td>{commerror}</td>
                                            <td>{accuracy}</td>
                                            <td>{tat}</td>
                                        </tr>
                                    );
                                })
                            }
                            <tr>
                                <td><strong>{'Total'}</strong></td>
                                <td><strong>{Object.keys(fileCountRef.current).length}</strong></td>
                                <td><strong>{(Object.values(lineCountRef.current).reduce(function(a, b){
                                    return parseInt(a) + parseInt(b);
                                }, 0) / 65).toFixed(2)}</strong></td>
                            </tr>
                        </tbody>
                    </Table>
                ) : (
                    <React.Fragment>
                        <h3 style={{cursor: 'pointer'}} onClick={() => setSingleUser(0)}>{'⬅ '}{usersStats !== null && 'users' in usersStats && singleUser in usersStats.users ? usersStats.users[singleUser].name : ''}</h3>
                        <Table bordered size="sm">
                            <thead>
                                <tr style={{backgroundColor:'bisque'}}>
                                    <th>Level</th>
                                    <th>File</th>
                                    <th>Line Count</th>
                                    <th>Critical</th>
                                    <th>Major</th>
                                    <th>Minor</th>
                                    <th>Comment</th>
                                    <th>Accuracy (Percentage)</th>
                                    <th>Turn Around Time (Yes/No)</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    usersStats !== null && 'users' in usersStats && singleUser in usersStats.users && Object.keys(usersStats.users[singleUser].escl).map(accttype => {
                                        let name =  accttype == 1 ? 'Trainee' :
                                                    accttype == 2 ? 'MT' :
                                                    accttype == 3 ? 'Proofer 1' :
                                                    accttype == 4 ? 'Proofer 2' :
                                                    accttype == 5 ? 'Audit' :
                                                    accttype == 6 ? 'Blank C' :
                                                    accttype == 7 ? 'Direct' : '';
                                        return Object.keys(usersStats.users[singleUser].escl[accttype]).map((taskid, ind) => {

                                            if(searchStr.trim().length > 0){
                                                if(usersStats.task[taskid].name.toUpperCase().indexOf(searchStr.toUpperCase().trim()) > -1);
                                                else return null;
                                            }

                                            fileCountRef.current[taskid] = 1;

                                            var tempop = usersStats.users[singleUser].escl[accttype][taskid];
                                            var lineCnt = Object.values(usersStats.task[taskid].lc).reduce(function(a, b){
                                                                return parseInt(a) + parseInt(b);
                                                            }, 0);
                                            lineCountRef.current[taskid] = lineCnt;
                                            
                                            if(ind > 0)
                                                name = '';
                                            return (
                                                <tr>
                                                    <td>{name}</td>
                                                    <td>{usersStats.task[taskid].name}</td>
                                                    <td>{(lineCnt / 65).toFixed(2)}</td>
                                                    <td>{tempop.graded == 1 ? tempop['1'] : '-'}</td>
                                                    <td>{tempop.graded == 1 ? tempop['2'] : '-'}</td>
                                                    <td>{tempop.graded == 1 ? tempop['3'] : '-'}</td>
                                                    <td>{tempop.graded == 1 ? tempop.comment : '-'}</td>
                                                    <td>{tempop.graded == 1 ? (100 - ((tempop['1'] * 2.5) + (tempop['2'] * 1) + (tempop['3'] * 0.5))).toFixed(2) + '%' : '-'}</td>
                                                    <td><span style={{fontWeight: 'bold', color: usersStats.task[taskid].tat == 1?'green':'red'}}>{usersStats.task[taskid].tat == 1?'Yes':'No'}</span></td>
                                                </tr>
                                            );
                                        })
                                    })
                                }
                                <tr>
                                    <td><strong>{'Total'}</strong></td>
                                    <td><strong>{Object.keys(fileCountRef.current).length}</strong></td>
                                    <td><strong>{(Object.values(lineCountRef.current).reduce(function(a, b){
                                        return parseInt(a) + parseInt(b);
                                    }, 0) / 65).toFixed(2)}</strong></td>
                                </tr>
                            </tbody>
                        </Table>
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            <Row>
                <Col>
                    <FilterForm 
                        afterSubmit={(statedata, formdt) => setNewFilter(formdt)} 
                        fields={{
                            datefrom:'', 
                            dateto: '',
                            siid: 'Industry/getIndustryWithServices.php',
                            levels: 'Pool/getPoolWithUsers.php',
                        }}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <PerformanceTable formdata={newFilter} />
                </Col>
            </Row>
        </React.Fragment>
    );
}

const TATReportComponent = (props) => {
    const [newFilter, setNewFilter] = useState(new FormData());
    
    
    const TATTable = (props) => {
        const [usersStats, setUsersStats] = useState(null);
        const [showTatBreak, setShowTatBreak] = useState(false);
        const [searchStr, setSearchStr] = useState('');

        useEffect(() => {
            axios.post('Files/getDictatorsTATStats.php', props.formdata).then(response => {
                if(response.data.r === 1)
                {
                    setUsersStats(response.data.d);
                }
            }).catch(error => {});
        }, [props]);

        const exportData = () => {
            const finalJSON = [];
            let exportFileName = 'TAT_Report';
            let exportUserName = '';
            let skip = 0;
            usersStats !== null && 'task' in usersStats && usersStats.task.forEach(task => {
                
                skip = 0;
                var d = new Date(task.open_time * 1000);
                if(searchStr.trim().length > 0){
                    if(task.name.toUpperCase().indexOf(searchStr.toUpperCase().trim()) > -1);
                    else skip = 1;
                }

                if(skip === 0){
                    finalJSON.push(
                        { 
                            'Date' : d.getDate() + '/' + (d.getMonth()+1) + '/' + d.getFullYear(),
                            'File Name' : task.name,
                            'Turn Around Time' : (task.tat_achieved == 1?'Yes':'No'),
                        }
                    );
                }
            });
            if(finalJSON.length > 0){
                JSONToCSVConvertor(finalJSON, exportFileName+exportUserName, true);
            }
        }
        

        return (
            <React.Fragment>
                <div>
                    <input type="checkbox" defaultChecked={showTatBreak} onChange={() => setShowTatBreak(showTatBreak === true?false:true)} /> Show Time Consumption
                    <Button size={'sm'} style={{float: 'right', marginLeft: 20}} variant={'info'} onClick={exportData}>Export</Button>
                    <InputGroup style={{width: '40%', float: 'right'}} size={'sm'} className="mb-3">
                        <InputGroup.Prepend>
                            <InputGroup.Text id="basic-addon1">🔍</InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                            placeholder="Filter Tasks"
                            aria-label="Filter Tasks"
                            aria-describedby="basic-addon1"
                            value={searchStr}
                            onChange={(e) => setSearchStr(e.target.value)}
                        />
                    </InputGroup>
                </div>
                <Table bordered size="sm">
                    <thead>
                        <tr style={{backgroundColor:'bisque'}}>
                            <th>Dates</th>
                            <th>File Name</th>
                            <th>With TAT (Yes / No)</th>
                            {
                                showTatBreak === true ? <th>TAT Consumption</th> : null
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            usersStats !== null && 'task' in usersStats && usersStats.task.map(task => {
                                var d = new Date(task.open_time * 1000);
                                if(searchStr.trim().length > 0){
                                    if(task.name.toUpperCase().indexOf(searchStr.toUpperCase().trim()) > -1);
                                    else return null;
                                }
                                return (
                                    <tr key={task.id}>
                                        <td>{d.getDate() + '/' + (d.getMonth()+1) + '/' + d.getFullYear()}</td>
                                        <td>{task.name}</td>
                                        <td><span style={{fontWeight: 'bold', color: task.tat_achieved == 1?'green':'red'}}>{task.tat_achieved == 1?'Yes':'No'}</span></td>
                                        {
                                            showTatBreak === true && Object.keys(task.users).length > 0 ? (
                                                <td>
                                                    {
                                                        Object.keys(task.users).map(fileno => {
                                                            return (
                                                                <React.Fragment>
                                                                    <p>File Number - {fileno}</p>
                                                                    <Table bordered size="sm">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>Level</th>
                                                                                <th>User</th>
                                                                                <th>Received Time</th>
                                                                                <th>Sent Time</th>
                                                                                <th>Hold Time</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                        {
                                                                            Object.keys(task.users[fileno]).sort((a,b) => {
                                                                                return a == 7 ? (b >= 3 ? -1 : 1) : (b == 7 ? (a >= 3 ? 1 : -1) : a - b)
                                                                            }).map(escid => {
                                                                                var escpool = task.users[fileno][escid]['pool'];
                                                                                var escusr = task.users[fileno][escid]['user'];
                                                                                return [Object.keys(escpool).map(poolid => {
                                                                                    let spenttime = '-';
                                                                                    if(escpool[poolid]['sent_tat'] !== null && escpool[poolid]['sent_tat'].length > 0){
                                                                                        let sec = escpool[poolid]['sent_tat'] - escpool[poolid]['received_tat'];
                                                                                        let hours = Math.floor(sec / 3600);
                                                                                        let mins = Math.floor((sec % 3600) / 60);
                                                                                        spenttime = hours +':'+ mins +':'+ (sec % 3600) % 60;

                                                                                    }
                                                                                    return (
                                                                                        <tr key={poolid}>
                                                                                            <td>{usersStats.pool[poolid]}</td>
                                                                                            <td>{'Pool'}</td>
                                                                                            <td>{new Date(escpool[poolid]['received_tat'] * 1000).toLocaleString()}</td>
                                                                                            <td>{escpool[poolid]['sent_tat'] !== null && escpool[poolid]['sent_tat'].length > 0 ? new Date(escpool[poolid]['sent_tat'] * 1000).toLocaleString() : '-'}</td>
                                                                                            <td>{spenttime}</td>
                                                                                        </tr>
                                                                                    )
                                                                                })
                                                                                ,
                                                                                Object.keys(escusr).map(usrid => {
                                                                                    let spenttime = '-';
                                                                                    if(escusr[usrid]['sent_tat'] !== null && escusr[usrid]['sent_tat'].length > 0){
                                                                                        let sec = escusr[usrid]['sent_tat'] - escusr[usrid]['received_tat'];
                                                                                        let hours = Math.floor(sec / 3600);
                                                                                        let mins = Math.floor((sec % 3600) / 60);
                                                                                        spenttime = hours +':'+ mins +':'+ (sec % 3600) % 60;

                                                                                    }
                                                                                    return (
                                                                                        <tr key={usrid}>
                                                                                            <td>{usersStats.pool[escid]}</td>
                                                                                            <td>{usrid > 0 ? usersStats.user[usrid] : '-'}</td>
                                                                                            <td>{new Date(escusr[usrid]['received_tat'] * 1000).toLocaleString()}</td>
                                                                                            <td>{escusr[usrid]['sent_tat'] !== null && escusr[usrid]['sent_tat'].length > 0 ? new Date(escusr[usrid]['sent_tat'] * 1000).toLocaleString() : '-'}</td>
                                                                                            <td>{spenttime}</td>
                                                                                        </tr>
                                                                                    )
                                                                                })]
                                                                            })
                                                                        }
                                                                        </tbody>
                                                                    </Table>
                                                                </React.Fragment>
                                                            )
                                                        })
                                                    }
                                                    <Table size="sm">
                                                        <tr>
                                                            <td><strong>Sent To Dictator</strong></td>
                                                            <td>{new Date(task.stdt * 1000).toLocaleString()}</td>
                                                            <td>{
                                                                Math.floor((task.stdt-task.open_time) / 3600) + ':' +
                                                                Math.floor(((task.stdt-task.open_time) % 3600) / 60) + ':' +
                                                                ((task.stdt-task.open_time) % 3600) % 60
                                                            }</td>
                                                        </tr>
                                                    </Table>
                                                </td>
                                            ) : null
                                        }
                                    </tr>
                                );
                            })
                        }
                    </tbody>
                </Table>
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            <Row>
                <Col>
                    <FilterForm 
                        afterSubmit={(statedata, formdt) => setNewFilter(formdt)} 
                        fields={{
                            datefrom:'', 
                            dateto: '',
                            dictators: 'Institute/getInstituteListWithDictator.php',
                        }}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <TATTable formdata={newFilter} />
                </Col>
            </Row>
        </React.Fragment>
    );
}

const DashboardPage = (props) => {
    const [newFilter, setNewFilter] = useState(new FormData());
    return (
        <Container fluid className={Classes.mainContainer}>
            <Row style={{marginBottom: '20px', borderBottom: '1px solid #82E0BF'}}>
                <Col>
                    <NavLink exact to={'/dashboard/home/dashboard/'} className={Classes.MenuItem} activeClassName={Classes.menuActive}>
                        Dashboard
                    </NavLink>
                    <NavLink exact to={'/dashboard/home/dashboard/performance/'} className={Classes.MenuItem} activeClassName={Classes.menuActive}>
                        Performance
                    </NavLink>
                    <NavLink exact to={'/dashboard/home/dashboard/tatreport/'} className={Classes.MenuItem} activeClassName={Classes.menuActive}>
                        TAT Report
                    </NavLink>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Switch>
                        <Route path={'/dashboard/home/dashboard/tatreport/'} component={TATReportComponent} />
                        <Route path={'/dashboard/home/dashboard/performance/'} component={PerformanceComponent} />
                        <Route path={'/dashboard/home/dashboard/'} render={(props) => (
                            <React.Fragment>
                                <Row>
                                    <Col lg={3}>
                                        <FileLineCount {...props} />
                                    </Col>
                                    <Col lg={9}>
                                        <FilterForm 
                                            afterSubmit={(statedata, formdt) => setNewFilter(formdt)} 
                                            fields={{
                                                datefrom:'', 
                                                dateto: '',
                                                dictators: 'Institute/getInstituteListWithDictator.php',
                                                siid: 'Industry/getIndustryWithServices.php',
                                                levels: 'Pool/getPoolICanAccess.php',
                                            }}
                                        />
                                    </Col>
                                </Row>
                                <PendingFiles formdata={newFilter} />
                            </React.Fragment>
                        )} />
                    </Switch>
                </Col>
            </Row>
        </Container>
    );
};

export default DashboardPage;