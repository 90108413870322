import React from 'react';
import ReactDOM from 'react-dom';
import CreatePart from 'modules/Tasks/Parts/Create';
import CreateSnapshot from 'modules/Tasks/Snapshot/Create';
import AssignTemplate from 'modules/Tasks/Template/AssignOrEdit/TaskWithDemo';
import IsComplete from 'modules/Tasks/AssignOrEdit/IsComplete';
import TaskToPool from 'modules/Tasks/AssignOrEdit/TaskToPool';
import TaskToUser from 'modules/Tasks/AssignOrEdit/TaskToUser';
import Form from 'components/Form/Form';
import Strings from 'Strings.js';
import Classes from './PartsList.module.css';
import axios from 'axios/axios.js';
import store from 'store.js';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import runningPage from 'global';

class TasksList extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            isOpen: 0,
            refreshPartList: 1,
            refreshEditor: 1,
            refreshDemo: 1,
            refreshInfo:1,
            partlist:[],
            openedPart: {},
            openedPartIndex: 0,
            isLastPart:1,
        }

        

        this.snapshotSaved = 0;
        this.refreshForward = 0;
        this.audioTime = 0;

        this.directPost = 0;
        this.blankCheck = 0;
        this.forward = 0;
        this.backward = 0;
        this.dictid = 0;
        this.demoOrEditor = React.createRef(0).current;

        this.editorInFocus = 1;

        this.grading = [];

        this.efaxes = [];
    }

    getAudioTime = () => {
        var audP = document.getElementById('audioPlayer');
        if(audP)
            this.audioTime = audP.currentTime;
    }

    

    getPartsList = (partnum, type) => {
        this.snapshotSaved = 0;
        axios.post('Tasks/partsList.php?tid='+this.props.tid, {}).then(response => {
            if(response.data.r === 1)
            {
                var max = 0;
                var item = {};
                var partIndex = 0;
                var isLastPart = 0;
                    
                response.data.d.partsList.forEach((ele,ind) => {
                   isLastPart = ind;
                    if(partnum == 'new'){
                        if(ele.num > max){
                            max = ele.num;
                            item = ele;
                            partIndex = ind;
                        }
                    }
                    else{
                        if(ele.num == partnum){
                            item = ele;
                            partIndex = ind;
                        }
                    }
                });

                console.log("Last Part Index: "+isLastPart, "Current Part Index: "+partIndex);
                this.getAudioTime();
               if(type==1){
                    document.getElementsByTagName('body')[0].style.overflow = 'hidden';
                    this.setState({
                        refreshEditor: 0,
                        refreshDemo: 0,
                        refreshPartList: 0,
                        refreshInfo: 0,
                    }, ()=>{
                        this.setState({
                            refreshEditor: 1,
                            refreshDemo: 1,
                            refreshPartList: 1,
                            refreshInfo: 1,
                            isOpen: 1,
                            partlist: response.data.d.partsList,
                            openedPart: item,
                            openedPartIndex: partIndex,
                            isLastPart: isLastPart == partIndex,
                        }, ()=>{
                            this.getInfoHtml(this.state.openedPart['template_id']);
                        })
                    });
               }
               else if(type==0){
                    this.setState({
                        refreshEditor: 0,
                        refreshDemo: 0,
                        refreshPartList: 0,
                        refreshInfo: 0,
                    }, ()=>{
                        this.setState({
                            refreshEditor: 1,
                            refreshDemo: 1,
                            refreshPartList: 1,
                            refreshInfo: 1,
                            partlist: response.data.d.partsList,
                            openedPart: item,
                            openedPartIndex: partIndex,
                            isLastPart: isLastPart == partIndex,
                        }, ()=>{
                            this.getInfoHtml(this.state.openedPart['template_id']);
                        })
                    });
               }
                
               
               
            }

        }).catch(error => {
            
        });
    }

    changePart = (e) => {
        this.setAppropriatePart(e.target.value);
    }

    getInfoHtml = (templateId) => {
        if(templateId > 0)
        {
            axios.post('TaskTemplate/getInfoSnapshotHtml.php?ttid='+templateId, {}).then(response => {
                if(response.data.r === 1)
                {
                    var editorMoreInfo = document.getElementById('editorMoreInfo');
                    if(editorMoreInfo)
                        editorMoreInfo.innerHTML = response.data.d;
                       
                }
            }).catch(error => {}); 
        }
        else
        {
            var editorMoreInfo = document.getElementById('editorMoreInfo');
            if(editorMoreInfo)
                editorMoreInfo.innerHTML = '';
        }
    }

    setAppropriatePart = (partnum) => {
        var max = 0;
        var item = {};
        var partIndex = 0;
        var isLastPart = 0;
        this.snapshotSaved = 0;    
        this.state.partlist.forEach((ele,ind) => {
            isLastPart = ind;
            if(partnum == 'new'){
                if(ele.num > max){
                    max = ele.num;
                    item = ele;
                    partIndex = ind;
                }
            }
            else{
                if(ele.num == partnum){
                    item = ele;
                    partIndex = ind;
                }
            }
        });

        console.log("Last Part Index: "+isLastPart, "Current Part Index: "+partIndex);
        this.getAudioTime();
        this.setState({
            refreshEditor: 0,
            refreshDemo: 0,
            refreshInfo: 0,
        }, ()=>{
            this.setState({
                refreshEditor: 1,
                refreshDemo: 1,
                refreshInfo: 1,
                openedPart: item,
                openedPartIndex: partIndex,
                isLastPart: isLastPart == partIndex,
            }, ()=>{
                this.getInfoHtml(this.state.openedPart['template_id']);
            })
        });
    }

    refreshEditor = () => {
        this.getAudioTime();
        this.setState({
            refreshEditor: 0,
        }, ()=>{
            this.setState({
                refreshEditor: 1,
            })
        });
    }

    openWorkbench = () => {
        axios.post('Tasks/checkIfTaskIsStillAssigned.php?tid='+this.props.tid, {}).then(response => {
            if(response.data.r === 1)
            {
                this.directPost = response.data.d.directPost;
                this.blankCheck = response.data.d.blankCheck;
                this.forward = response.data.d.forward;
                this.backward = response.data.d.backward;
                this.efaxes = response.data.d.efaxes;
                this.dictid = response.data.d.did;
                this.grading = response.data.d.grading;

                this.getPartsList(1, 1);
            //    this.assignDemo();
            }
            else if(response.data.e == 'TASK_ASSIGNED_SOMEONE_ELSE'){
                this.closeDemo();
                this.props.refreshList();
            }

        }).catch(error => {
            
        });  
    }

    snapshotSavedFunc = () => {
        console.log("Snapshot Saved Called");
        this.getAudioTime();
        this.snapshotSaved = 1;
        //this.refreshEditor();
    }

    buttonTrigger = (isDisable) => {
        
        this.refreshForward = isDisable && this.snapshotSaved;
        console.log("Button Trigger Called. Save is not "+this.refreshForward);
        var byt = document.getElementsByClassName('toRefreshForward')[0].getElementsByClassName('formSubmitButton');
        for(var i=0; i<byt.length; i++){
            if(this.refreshForward == 1)
                byt[i].removeAttribute('disabled');
            else
                byt[i].setAttribute('disabled','disabled');
        }
    }

    demoButtonTrigger = (isDisable) => {
        if(isDisable){
            document.getElementById('hideEditorBox').style.display= 'none';
            ReactDOM.unmountComponentAtNode (document.getElementById('patientListTable'));
            ReactDOM.unmountComponentAtNode (document.getElementById('associateListTable'));
            this.editorInFocus = 1;
        }
        else{
            document.getElementById('hideEditorBox').style.display= null;
            if(this.editorInFocus){
                this.snapshotRef.createFileRef.fieldRef.formRef.handleSubmit();
            }
            this.editorInFocus = 0;
        }
    }

    successTaskComplete = (data) => {
        this.closeDemo();
        this.state.partlist.map(item=>{
            localStorage.removeItem("snapshot:"+this.props.tid+":"+item.num); 
            return true;
        });
        
        this.props.refreshList();
    }

    failTaskComplete = (data) => {

    }

    confirmDelete = () => {
        
        var r = window.confirm("Are You Sure, You Want To Delete This Part?");
        if(r === true){
            if(this.state.partlist.length > 1) {
                axios.post('Tasks/deleteSinglePart.php?tid='+this.props.tid+'&part='+this.state.openedPart.num, {}).then(response => {
                    if(response.data.r === 1)
                    {
                        this.state.partlist.forEach((item, ind) => {
                            if(item.num > this.state.openedPart.num){
                                localStorage.setItem("snapshot:"+this.props.tid+":"+(item.num-1), localStorage.getItem("snapshot:"+this.props.tid+":"+item.num));
                            }
                        })
                        localStorage.removeItem("snapshot:"+this.props.tid+":"+this.state.partlist[this.state.partlist.length - 1].num); 
                        this.getPartsList(1, 1);
                    }
                    else if(response.data.e == 'TASK_ASSIGNED_SOMEONE_ELSE'){
                        this.closeDemo();
                        this.props.refreshList();
                    }
                    else if(response.data.e == 'NOT_COMPOSITE_FILE'){
                        alert("Single part can't be deleted. Only parts of Composite files could be deleted.");
                    }
        
                }).catch(error => {});
            }
            else{
                alert("Single part can't be deleted. Only parts of Composite files could be deleted.");
            }
        }
    }

    partsListEvent = (e) => {
        if(runningPage.page === 'taskopen'){
            if(e.altKey && e.ctrlKey){
                if(e.keyCode === 84){
                    var tableContainer = document.getElementById('tableContainer');
                    if(tableContainer.style.display === 'block'){
                        tableContainer.getElementsByClassName('public-DraftEditor-content')[0].focus();
                    }
                }
            }
            else if(e.altKey){
                if (e.keyCode == 192){
                    this.closeDemo();
                } else if (e.keyCode === 81){
                    if(!this.demoOrEditor){
                        this.refs['demoBoxRef'].createFileRef.fieldRef.formRef.mainFormRef.getElementsByTagName('select')[0].focus();
                        this.demoOrEditor = 1;
                    }
                    else{
                        this.snapshotRef.createFileRef.fieldRef.formRef.editorRef.setEditorFocus()
                        this.demoOrEditor = 0;
                    }
                } else if (e.keyCode === 83){
                    // alt + s
                    if(document.getElementById('hideEditorBox').style.display == 'none'){
                        this.refs['demoBoxRef'].createFileRef.fieldRef.formRef.mainFormRef.getElementsByClassName('searchButton')[0].click();
                    } else {
                        this.demoButtonTrigger(1);
                    }
                    
                } else if (e.keyCode === 78){
                    // alt + n
                    console.log(this.createPartRef.partCreate.fieldRef.formRef.mainFormRef.getElementsByClassName('autoFormSubmit')[0])
                    this.createPartRef.partCreate.fieldRef.formRef.mainFormRef.getElementsByClassName('autoFormSubmit')[0].click()
                } else if (e.keyCode === 80) {
                    // alt + p
                    this.selectPartListRef.focus();
                } else if (e.keyCode === 69) {
                    this.efaxListRef.focus();
                }
            } else {
                if (e.keyCode === 33 || e.keyCode === 34){
                    e.preventDefault();
                    if(document.getElementById('demoPreviewCloseButton')){
                        if(e.keyCode === 33)
                            document.getElementById('demoPreviewWindowId').scrollBy(0, -100)
                        else if(e.keyCode === 34)
                            document.getElementById('demoPreviewWindowId').scrollBy(0, 100)
                    }
                }
            }
        }
    }

    componentDidMount(){
        if(this.state.isOpen === 0){
            this.openWorkbench();
        }
        runningPage.page = 'taskopen';
        document.addEventListener('keydown', this.partsListEvent);
    }

    componentWillUnmount(){
        runningPage.page = 'home';
        document.removeEventListener('keydown', this.partsListEvent);
    }

    
    assignDemo = (e) => {
        if(e)
            e.preventDefault();
        document.getElementsByTagName('body')[0].style.overflow = 'hidden';
        this.setState({isOpen: 1});
    }

    closeDemo = () => {
        document.getElementsByTagName('body')[0].style.overflow = 'auto';
        this.setState({isOpen: 0});
        runningPage.page = 'home';
        document.removeEventListener('keydown', this.partsListEvent);
    }

    downloadEfax = (e) => {
        e.preventDefault();
        var fd = new FormData();
        fd.append('file', e.target.value);
        var fileval = e.target.value;
        axios.post('downloadEFaxDoc.php', fd, {responseType: 'arraybuffer'}).then(response => {
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(new Blob([response.data], {type: 'application/octet-stream'}));
            link.download = fileval;

            document.body.appendChild(link);

            link.click();
            setTimeout(function() {
                window.URL.revokeObjectURL(link);
            }, 200);
        }).catch(error => {})
    }

    download = (data) => {
        var fd = new FormData();
        fd.append('tid', this.props.tid);
        fd.append('part', this.state.openedPart.num);
        axios.post('downloadPrevDoc.php', fd, {responseType: 'arraybuffer'}).then(response => {
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(new Blob([response.data], {type: 'application/octet-stream'}));
            link.download = data.file;

            document.body.appendChild(link);

            link.click();
            setTimeout(function() {
                window.URL.revokeObjectURL(link);
            }, 200);
        }).catch(error => {})
    }

    checkBeforeSubmit = () => {
        if((this.props.to_be_graded == 1 && this.props.escalation_level_id == 3) || this.props.escalation_level_id == 5){
            if(this.grading.length == 0){
                store.dispatch({type: 'SHOW_ERROR', payload:{errorCode: 'GRADING_NOT_FILLED', type:0}});
                return false;
            }
        }
        return true;
    }

    checkIfWordsExists = (words, str) => {
        var exists = 1;
        if(words.length == 0){
            exists = 0;
        }
        else{
            words.forEach(el=>{
                if(str.toLowerCase().indexOf(el.toLowerCase()) == -1)
                    exists = 0;
            });
        }

        return exists;
    }

    openDemoPreviewWindow = (open, cb) => {
        if('demoBoxRef' in this.refs){
            var demoPrevWindow = document.getElementById('demoPreviewWindowId');
            if(!open){
                demoPrevWindow.style.display= 'none';
                ReactDOM.unmountComponentAtNode(demoPrevWindow);
            }
            else{
                const demoVal = this.refs.demoBoxRef.getDemoState();
                const patient = {};
                const refPhy = {};
                const cc = {};
                const encl = {};
                const everyElse = {};
                for(var pair of demoVal.val.entries()) {
                    if(pair[0].indexOf('demo:') === 0){
                        demoVal.fields.forEach(ite => {
                            if(ite.name === pair[0]){
                                if(ite.label.toLowerCase().indexOf('mrn') === 0 ||
                                ite.label.toLowerCase().indexOf('date') === 0 ||
                                ite.label.toLowerCase().indexOf('patient') === 0 )
                                    patient[ite.label] = pair[1];
                                else if(ite.label.toLowerCase().indexOf('ref') === 0)
                                    refPhy[ite.label] = pair[1];
                                else if(ite.label.toLowerCase().indexOf('cc') === 0)
                                    cc[ite.label] = pair[1];
                                else if(ite.label.toLowerCase().indexOf('encl') === 0)
                                    encl[ite.label] = pair[1];
                                else 
                                    everyElse[ite.label] = pair[1];
                            }
                        });
                    } 
                }
                ReactDOM.render(<Container fluid>
                                    <Row>
                                        <Col>
                                            <Button variant="primary" id={'demoPreviewCloseButton'} type="button" onClick={() => this.openDemoPreviewWindow(false, cb)}>CLOSE</Button>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={5}>
                                            <div style={{marginBottom: '40px'}}>
                                            {
                                                Object.keys(patient).map(ite => {
                                                    return (
                                                        <Row style={{borderBottom:'1px solid #faa'}}>
                                                            <Col sm={6}>
                                                                {ite}
                                                            </Col>
                                                            <Col sm={6}>
                                                                {patient[ite]}
                                                            </Col>
                                                        </Row>
                                                    );
                                                })
                                            }
                                            </div>
                                            <div style={{marginBottom: '40px'}}>
                                            {
                                                Object.keys(refPhy).map(ite => {
                                                    return (
                                                        <Row style={{borderBottom:'1px solid #faa'}}>
                                                            <Col sm={6}>
                                                                {ite}
                                                            </Col>
                                                            <Col sm={6}>
                                                                {refPhy[ite]}
                                                            </Col>
                                                        </Row>
                                                    );
                                                })
                                            }
                                            </div>
                                        </Col>
                                        <Col lg={2}></Col>
                                        <Col lg={5}>
                                            <div style={{marginBottom: '40px'}}>
                                            {
                                                Object.keys(cc).map(ite => {
                                                    return (
                                                        <Row style={{borderBottom:'1px solid #faa'}}>
                                                            <Col sm={6}>
                                                                {ite}
                                                            </Col>
                                                            <Col sm={6}>
                                                                {cc[ite]}
                                                            </Col>
                                                        </Row>
                                                    );
                                                })
                                            }
                                            </div>
                                            <div style={{marginBottom: '40px'}}>
                                            {
                                                Object.keys(encl).map(ite => {
                                                    return (
                                                        <Row style={{borderBottom:'1px solid #faa'}}>
                                                            <Col sm={6}>
                                                                {ite}
                                                            </Col>
                                                            <Col sm={6}>
                                                                {encl[ite]}
                                                            </Col>
                                                        </Row>
                                                    );
                                                })
                                            }
                                            </div>
                                            <div style={{marginBottom: '40px'}}>
                                            {
                                                Object.keys(everyElse).map(ite => {
                                                    return (
                                                        <Row style={{borderBottom:'1px solid #faa'}}>
                                                            <Col sm={6}>
                                                                {ite}
                                                            </Col>
                                                            <Col sm={6}>
                                                                {everyElse[ite]}
                                                            </Col>
                                                        </Row>
                                                    );
                                                })
                                            }
                                            </div>
                                        </Col>
                                    </Row>
                                    
                                </Container>, demoPrevWindow);
                demoPrevWindow.style.display= null;
                
            }
            if(cb)
                cb();
        }
    }

    
    render(){
        var partList = <React.Fragment>
                            <select ref={node => this.selectPartListRef = node} value={this.state.openedPart.num} onChange={this.changePart} style={{width:'100%', background:'#eee'}}>
                                {this.state.partlist.map((item, index) => {
                                    return(<option value={item.num} key={index}>{'Part - '}{item.num}{' | '}{item.assignedTaskTemplate}</option>)
                                })} 
                            </select>
                            <span style={{
                                fontSize: '10px',
                                display: 'block',
                            }}>{'( Alt+P )'}</span>
                        </React.Fragment>

        
        return(
            <div>
                {this.state.isOpen?(
                    <div style={{position:'fixed', width:'100vw', height:'100vh', top:0, left:0, zIndex:'5555', background:'#fff', boxSizing:'border-box', padding:'20px',overflow:'auto'}}>
                        <div id="hideEditorBox" style={{
                            background: 'rgba(255,255,255,1)',
                            position: 'fixed',
                            width: 'calc(100vw - 530px)',
                            height: 'calc(100vh - 170px)',
                            left:  '300px',
                            zIndex: '55555',
                            top: '134px',
                            display: 'none',
                            overflow: 'auto',
                        }}>
                            <div id="patientListTable"></div>
                            <div id="associateListTable"></div>
                        </div>

                        <div id={'demoPreviewWindowId'} style={{
                            position: 'fixed',
                            zIndex: 55555,
                            width: 'calc(100vw - 40px)',
                            height: 'calc(100vh - 40px)',
                            overflow: 'auto',
                            padding: '10px',
                            display: 'none',
                            background: '#fee',
                            boxShadow: '0 0 5px rgba(0,0,0,0.3)',
                        }}>

                        </div>

                        {this.state.refreshEditor?(
                            <div className={'transcriptionWorkbenchEditor'}>
                                <CreateSnapshot 
                                    audioTime={this.audioTime} 
                                    snapshotSaved={() => {this.snapshotSavedFunc()}} 
                                    buttonTrigger={(isDisable) => {this.buttonTrigger(isDisable)}} 
                                    buttonShortcut={'Ctrl+S'}
                                    containerCss={Classes.editorCss} 
                                    temp={this.state.openedPart.assignedTaskTemplate} 
                                    tabSize={this.state.openedPart.tabSize} 
                                    ttid={this.state.openedPart.template_id} 
                                    tid={this.props.tid} 
                                    partNum={this.state.openedPart.num} 
                                    audioUrl={this.props.file} 
                                    grading={this.grading}
                                    gradingChangeFunc={(data) => this.grading = data}
                                    ref={node => this.snapshotRef = node}
                                    to_be_graded={this.props.to_be_graded}
                                    passToInput={
                                                    {
                                                        post: () => {'postButtonRef' in this.refs ? this.refs.postButtonRef.submitFormButtonClick() : console.log('NO POST OPTION')},
                                                        forward: () => {'forwardButtonRef' in this.refs ? this.refs.forwardButtonRef.submitFormButtonClick() : console.log('NO FORWARD OPTION')},
                                                        preview: () => {'previewButtonRef' in this.refs ? this.refs.previewButtonRef.submitFormButtonClick() : console.log('NO PREVIEW OPTION')},
                                                        delete: () => {this.confirmDelete()},
                                                        demoPreview: (cb) => {'demoBoxRef' in this.refs ? this.openDemoPreviewWindow(true, cb) : console.log('NO DEMO BOX PRESENT')},
                                                        demoPreviewClose: (cb) => {'demoBoxRef' in this.refs ? this.openDemoPreviewWindow(false, cb) : console.log('NO DEMO BOX PRESENT')},
                                                        nextPart: () => {'nextPartButRef' in this.refs ? this.refs.nextPartButRef.submitFormButtonClick() : console.log('NO NEXT PART OPTION')},
                                                    }
                                                }
                                    did={this.dictid} />
                            </div>):null}
                        {this.state.refreshDemo?<div className={Classes.demoContainer}><AssignTemplate ref={'demoBoxRef'} refreshList={() => this.getPartsList(this.state.openedPart.num, 0)} tid={this.props.tid} partNum={this.state.openedPart.num} buttonTrigger={(isDisable) => {this.demoButtonTrigger(isDisable)}} /></div>:null} 
                        <div className={Classes.infoHtmlContainer} id={'editorMoreInfo'}></div>
                        <div className={Classes.topRight}>
                            <div style={{display:'inline-block'}}><CreatePart ref={node => this.createPartRef = node} tid={this.props.tid} partAdded={() => this.getPartsList('new', 0)}/></div>
                            <div style={{display:'inline-block', width:'200px', marginLeft:'10px'}}>{this.state.refreshPartList?partList:null}</div>
                            <div style={{display:'inline-block', width:'130px', marginLeft:'10px'}}>
                                <select ref={node => this.efaxListRef = node} value={'c'} onChange={this.downloadEfax} style={{width:'100%'}}>
                                    <option value={'c'}>Choose Efaxes</option>
                                    {
                                        this.efaxes.map(el => {
                                            return <option value={el}>{el}</option>;
                                        })
                                    }
                                </select>
                                <span style={{
                                    fontSize: '10px',
                                    display: 'block',
                                }}>{'( Alt+E )'}</span>
                            </div>
                            <div style={{display:'inline-block', marginLeft:'10px'}}>
                                <Form   
                                onSubmit={this.closeDemo}
                                buttonValue={Strings.text.button.closeButton}
                                buttonType='success'
                                buttonShortcut={'Alt+`'}
                                enableButton={true}
                                fields={[]} />
                            </div>
                        </div>
                        <div>
                            <div className={'toRefreshForward'}>
                                {this.directPost?(
                                    <div style={{display:'inline-block',marginRight:'10px'}}>
                                        {this.state.refreshDemo?(
                                        <Form 
                                        url={'Tasks/showWordFileDemo.php'}
                                        ref={'previewButtonRef'}
                                        buttonValue={Strings.text.button.preview}
                                        buttonType='info'
                                        buttonShortcut={'Ctrl+Q'}
                                        enableButton={true}
                                        successSubmit={(data) => this.download(data)}
                                        failSubmit={(data) => this.failTaskComplete(data)}
                                        fields={[
                                            {
                                                type: 'hidden',
                                                value: this.props.tid,
                                                name: 'tid',
                                                options: {
                                                            required: true,
                                                            noFormGroup: true,
                                                        },
                                            },
                                            {
                                                type: 'hidden',
                                                value: this.state.openedPart.num,
                                                name: 'part',
                                                options: {
                                                            required: true,
                                                            noFormGroup: true,
                                                        },
                                            },
                                        ]} /> 
                                        ):null}
                                    </div>
                                ):null}
                                {this.state.isLastPart?(
                                    <React.Fragment>
                                        {this.directPost?(
                                                <div style={{display:'inline-block',marginRight:'10px'}}>
                                                    <Form 
                                                    url={'Tasks/markTaskComplete.php'}
                                                    ref={'postButtonRef'}
                                                    buttonValue={Strings.text.button.markCompletePost}
                                                    buttonType='primary'
                                                    buttonShortcut={'Ctrl+P'}
                                                    enableButton={true}
                                                    successSubmit={(data) => this.successTaskComplete(data)}
                                                    failSubmit={(data) => this.failTaskComplete(data)}
                                                    checkBeforeSubmit={this.checkBeforeSubmit}
                                                    fields={[
                                                        {
                                                            type: 'hidden',
                                                            value: this.props.tid,
                                                            name: 'tid',
                                                            options: {
                                                                        required: true,
                                                                        noFormGroup: true,
                                                                    },
                                                        },
                                                        {
                                                            type: 'hidden',
                                                            value: 1,
                                                            name: 'is_complete',
                                                            options: {
                                                                        required: true,
                                                                        noFormGroup: true,
                                                                    },
                                                        },
                                                    ]} /> 
                                                
                                                </div>
                                            
                                        ):null}
                                    
                                    {this.backward?(
                                        <div style={{display:'inline-block',marginRight:'10px'}}>
                                            <div id="backPopUp" className={Classes.backPopUp}>
                                                Are you sure, you want to send this file back to last user?
                                                <div style={{marginTop: '10px',overflow:'hidden'}}>
                                                    <div style={{float:'left'}}>
                                                        <Form 
                                                            url={'Tasks/escalateTaskBackward.php'}
                                                            buttonValue={'YES'}
                                                            buttonType='warning'
                                                            enableButton={true}
                                                            successSubmit={(data) => this.successTaskComplete(data)}
                                                            failSubmit={(data) => this.failTaskComplete(data)}
                                                            fields={[{
                                                                type: 'hidden',
                                                                value: this.props.tid,
                                                                name: 'tid',
                                                                options: {
                                                                            required: true,
                                                                            noFormGroup: true,
                                                                        },
                                                            }]} />
                                                    </div>

                                                    <div style={{float:'right'}}>
                                                        <Form 
                                                            onSubmit={(data)=>{document.getElementById('backPopUp').style.display='none'}}
                                                            buttonValue={'NO'}
                                                            buttonType='warning'
                                                            enableButton={true}
                                                            fields={[]} />
                                                    </div>
                                                </div>
                                            </div>
                                            <Form 
                                                onSubmit={(data)=>{document.getElementById('backPopUp').style.display='block'}}
                                                buttonValue={Strings.text.button.backward}
                                                buttonType='primary'
                                                enableButton={true}
                                                successSubmit={(data) => {}}
                                                failSubmit={(data) => {}}
                                                fields={[]} /> 
                                        
                                        </div> 
                                    ):null}
                                    {this.forward?(
                                        <div style={{display:'inline-block',marginRight:'10px'}}>
                                            <Form 
                                            url={'Tasks/escalateTask.php'}
                                            ref={'forwardButtonRef'}
                                            buttonValue={Strings.text.button.forward}
                                            buttonType='primary'
                                            buttonShortcut={'Ctrl+Shift+F'}
                                            enableButton={true}
                                            successSubmit={(data) => this.successTaskComplete(data)}
                                            failSubmit={(data) => this.failTaskComplete(data)}
                                            checkBeforeSubmit={this.checkBeforeSubmit}
                                            fields={[
                                                {
                                                    type: 'hidden',
                                                    value: this.props.tid,
                                                    name: 'tid',
                                                    options: {
                                                                required: true,
                                                                noFormGroup: true,
                                                            },
                                                }
                                            ]} /> 
                                        
                                        </div> 
                                    ):null}
                                    {this.blankCheck?(
                                        <div style={{display:'inline-block',marginRight:'10px'}}>
                                            <Form 
                                            url={'Tasks/postToBlankCheck.php'}
                                            buttonValue={Strings.text.button.blankCheck}
                                            buttonType='primary'
                                            enableButton={true}
                                            successSubmit={(data) => this.successTaskComplete(data)}
                                            failSubmit={(data) => this.failTaskComplete(data)}
                                            checkBeforeSubmit={this.checkBeforeSubmit}
                                            fields={[
                                                {
                                                    type: 'hidden',
                                                    value: this.props.tid,
                                                    name: 'tid',
                                                    options: {
                                                                required: true,
                                                                noFormGroup: true,
                                                            },
                                                }
                                            ]} /> 
                                        
                                        </div> 
                                    ):null}
                                    </React.Fragment>
                                ):(
                                    <div style={{display:'inline-block',marginRight:'10px'}}>
                                        <Form   
                                        onSubmit={() => this.setAppropriatePart(parseInt(this.state.openedPart.num)+1)}
                                        ref={'nextPartButRef'}
                                        buttonValue={Strings.text.button.nextPart}
                                        buttonType='success'
                                        buttonShortcut={'Alt+→'}
                                        enableButton={true}
                                        fields={[]} />
                                    </div>
                                )}
                            </div>
                        </div>   
                    </div> 
                ):null}
            </div>
        )
    }
}



export default TasksList; 