import React from 'react';
import CreateFile from 'modules/Form/Create/Create';
import Strings from 'Strings.js';

class Create extends React.Component{

    onSuccess = (data) => {
        this.props.refresh();
    }

    onFailure = (data) => {

    }

    render(){
        return(
            <CreateFile
                createPermission = {11}
                createContainerCSS = {''}
                url = {'PermissionTemplate/createPermissionTemplate.php'}
                onSuccess = {(data) => this.onSuccess(data)}
                onFailure = {(data) => this.onFailure(data)}
                buttonValue = {Strings.text.button.createPermissionTemplate}
                fields = {[{
                        type: 'text',
                        value: '',
                        name: 'name',
                        placeholder: Strings.text.placeholder.permissionTemplateName,
                        options: {
                                    required: true,
                                },
                        label: Strings.text.label.permissionTemplateName,
                    },{
                        type: 'select',
                        value: '',
                        name: 'siid',
                        options: {
                                    required: true,
                                    showLoader: [true, 'black', 'Industry/serviceIndustryList.php'],
                                    options: [
                                        {value: '', name: '', selected: true},
                                    ]
                                 },
                        label: Strings.text.label.selectServiceIndustry,
                    },{
                        type: 'select',
                        value: '',
                        name: 'type',
                        options: {
                                    required: true,
                                    options: [
                                        {value: 'Workbench', name: 'workbench', selected: true},
                                        {value: 'Client App', name: 'client', selected: false},
                                    ]
                                 },
                        label: 'Permission Template Type',
                    }]}
            />
        );
    }
}

export default Create;