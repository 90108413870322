import React from 'react';
import List from 'modules/List/List';
import Form from 'components/Form/Form';
import axios from 'axios/axios';
import Strings from 'Strings.js';

class Single extends React.Component{

    onSuccess = (data) => {
        console.log(data);
        
        var fd = new FormData();
        fd.append('doc', data.file);
        axios.post('downloadTempFile.php', fd, {responseType: 'arraybuffer'}).then(response => {
            var link = document.createElement('a');
            link.href = URL.createObjectURL(new Blob([response.data], {type: 'application/octet-stream'}));
            link.download = data.file;

            document.body.appendChild(link);
            link.click();
            setTimeout(function() {
                window.URL.revokeObjectURL(link);
            }, 1000);
            document.body.removeChild(link);

        }).catch(error => {})
    }

    onFailure = (data) => {

    }

    editTemplate = () => {
        console.log("onsubmit reached");
        this.props.editTemplate();
    }

    download = (url) => {
        window.location.href = url;
    }

    render(){
        return(
            <React.Fragment>
                <td>{this.props.name}</td>
                <td>{this.props.associatedDictator}</td>
                <td>{this.props.createdBy}</td>
                <td>{this.props.templateHeader}</td>
                <td><Form   
                    onSubmit={this.editTemplate}
                    buttonValue={Strings.text.button.editTemplate}
                    buttonType='primary'
                    enableButton='false'
                    fields={[{
                        type: 'hidden',
                        value: this.props.id,
                        name: 'ttid',
                        options: {
                                    required: false,
                                },
                    }]} />
                </td>
                <td><Form   
                    successSubmit = {(data) => this.onSuccess(data)}
                    failSubmit = {(data) => this.onFailure(data)}
                    url = {'Tasks/testTaskTemplate.php'}
                    buttonValue={Strings.text.button.testTemplate}
                    buttonType='success'
                    enableButton='false'
                    fields={[{
                        type: 'hidden',
                        value: this.props.id,
                        name: 'ttid',
                        options: {
                                    required: true,
                                },
                    }]} />
                </td>
            </React.Fragment>
        );
    }
}

class _List extends React.Component{

    render(){
        return(
            <List 
                listPermission={78}
                list={this.props.list}
                activeClass={'listactive'}
                
                headings={{taskTemplateName:'name',
                            associatedDictator:'associatedDictator',
                            createdBy: 'createdBy',
                            templateHeader: 'header',
                        }}
                singleItem={(item) => (<Single id={item.id} name={item.name} 
                associatedDictator={item.associatedDictator} createdBy={item.createdBy}
                templateHeader={item.header} 
                editTemplate={() => this.props.editTemplate({ttid: item.id, name: item.name, did:item.did, thid:item.header, tabSize:item.tabSize})}
                />)}
                listContainerCSS={this.props.listContainerCSS}
            />
        );
    }
}

export {Single as TaskTemplateSingle, _List as TaskTemplateListList};

/*
    for FileSingle -
        name, associatedDictator, createdBy

    for FileList -
        onListClick, list, 
*/