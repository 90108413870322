const initialState = {
    isLogin : localStorage.getItem('awx') === null?0:1,
}

const reducer = (state = initialState, action) => {
    if(action.type === 'CHANGE_LOGIN'){
        return {
            ...state,
            isLogin: action.payload.isLogin
        }
    }

    return state;
}
export default reducer;
