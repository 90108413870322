import React from 'react';
import CreateFile from 'modules/Form/Create/Create';
import Strings from 'Strings.js';

class Create extends React.Component{

    onSuccess = (data) => {
        this.props.partAdded();
    }

    onFailure = (data) => {

    }

    render(){
        return(
            <CreateFile
                createPermission = {'ALL'}
                createContainerCSS = {''}
                url = {'Tasks/addPart.php'}
                onSuccess = {(data) => this.onSuccess(data)}
                onFailure = {(data) => this.onFailure(data)}
                buttonValue = {Strings.text.button.addPartButton}
                buttonShortcut={'Alt+n'}
                enableButton = {true}
                ref={node => this.partCreate = node}
                fields = {[{
                    type: 'hidden',
                    value: this.props.tid,
                    name: 'tid',
                    options: {
                                required: true,
                                noFormGroup: true,
                            },
                }]}
            />
        );
    }
}

export default Create;