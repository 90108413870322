import React from 'react';
import Strings from 'Strings.js';
import Classes from './PdfViewer.module.css';
import ProgressBar from 'react-bootstrap/ProgressBar';
import axios from 'axios/axios.js';
import pdfjs from 'pdfjs-dist';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
//import cmapUrl from 'pdfjs-dist/cmaps';

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

class PdfViewer extends React.Component{
    constructor(props){
        super(props);
        this.pdf = null;
        this.canvasRendering = 0;
    }

    state = {
        currentPage: 1,
        currentPageInput: 1,
        totalPages: 1,
        scale: 0,
        fileProgress: 0,
        pdfLoaded: 0,
    }

    loadPdfFromUrl = (data) => {
        var loadingTask = pdfjs.getDocument({
                                                url: data.url,
                                            //    cMapUrl: cmapUrl,
                                            //    cMapPacked: true,
                                            });
        loadingTask.promise.then((pdf) => {
            this.pdf = pdf
            console.log(pdf);
            this.setState({currentPage: data.page, totalPages: pdf.numPages, currentPageInput: data.page, pdfLoaded:1}, () => this.drawPageOnCanvas())
        });
    }

    loadPdfFromFile = (data) => {
        console.log(data)
        var loadingTask = pdfjs.getDocument({data: data.file});
        loadingTask.promise.then((pdf) => {
            this.pdf = pdf;
            console.log(pdf);
            this.setState({currentPage: data.page, totalPages: pdf.numPages, currentPageInput: data.page, pdfLoaded:1}, () => this.drawPageOnCanvas())
        });
    }

    drawPageOnCanvas = () => {
        if(this.canvasRendering == 0)
        {
            var startTime = Date.now();
            this.pdf.getPage(this.state.currentPage).then((page) => {
                console.log("Page Fetched", Date.now() - startTime);
                this.canvasRendering = 1;
                var scale = this.state.scale;
                if(scale == 0){
                    scale = (window.innerHeight - 130) / page.getViewport({ scale: 1}).height;
                    this.setState({scale: scale});
                }

                var viewport = page.getViewport({ scale: scale});
                var canvas = document.getElementById('docViewer');
                var context = canvas.getContext('2d');
                canvas.height = viewport.height;
                canvas.width = viewport.width;

                var renderContext = {
                    canvasContext: context,
                    viewport: viewport
                };
                console.log("Before Page Render", Date.now() - startTime);
                page.render(renderContext).promise.then(() => {console.log("After Page Render", Date.now() - startTime);this.canvasRendering = 0});
            });
        }
    }

    componentDidMount(){
        if(this.props.tid){
            var fd = new FormData();
            fd.append('tid', this.props.tid);
            axios.post(localStorage.getItem('appUrl')+'servePDF.php', fd, {
                responseType: 'arraybuffer',
                onDownloadProgress: ( progressEvent ) => {
                    this.setState({pdfLoaded: 0, fileProgress: Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 )})
                }
            }).then(response => {
                if(response.status == 200){
                    this.loadPdfFromFile({file: response.data, page: 1});
                    //this.loadPdfFromFile({file: response.data.d.pdf, page: 1});
                }
            }).catch(error => {});
            
        }
    }

    changePage = (num) => {
        if(typeof num == "number"){
            if((this.state.currentPage + num > 0) && (this.state.currentPage + num <= this.state.totalPages)){
                this.setState(prevState => ({currentPage: prevState.currentPage + num, currentPageInput: prevState.currentPage + num}), () => this.drawPageOnCanvas());  
            }
        }
    }

    updateInputValue = (e) => {
        e.preventDefault();
        var value = e.target.value;
        if(value){
            value = parseInt(value);
            this.setState({currentPageInput: value}, () => {
                if((value > 0) && (value <= this.state.totalPages)){
                    this.setState({currentPage: value}, () => this.drawPageOnCanvas())
                } 
            })
        }
        else
            this.setState({currentPageInput: ""})
    }

    zoom = (num) => {
        if(this.state.scale + (num * 0.125) > 0){
            this.setState(prevState => ({scale: prevState.scale + (num * 0.125)}), () => this.drawPageOnCanvas());
        }
    }

    render(){
        return(
            <React.Fragment>
                <div style={{textAlign:'center', border:'1px solid #ccc', overflow:'auto', height:'calc(100vh - 120px)',background:'#eee'}}>
                    {this.state.pdfLoaded == 1?(
                        <canvas id="docViewer" />
                    ):(
                        <div>
                            <p>Loading...</p>
                            <div><ProgressBar style={{width:'calc(50vw - 200px)', background:'#FFF', margin:'auto'}} now={this.state.fileProgress} label={`${this.state.fileProgress}%`} /></div>
                        </div>
                    )}
                    
                </div>
                <div style={{textAlign: 'center'}}>
                    <span className={[Classes.arrows, Classes.noselect].join(' ')} onClick={() => this.changePage(-1)}>←</span>
                    <span><input className={Classes.pageInput} value={this.state.currentPageInput} onChange={this.updateInputValue} type="number" autoComplete="off" /></span>
                    <span className={Classes.noselect}> / {this.state.totalPages}</span>
                    <span className={[Classes.arrows, Classes.noselect].join(' ')} onClick={() => this.changePage(1)}>→</span>
                    <span className={[Classes.separator, Classes.noselect].join(' ')}>|</span>
                    <span onClick={() => this.zoom(1)} className={[Classes.zoom, Classes.noselect].join(' ')} role={'img'} aria-label={'zoom in'}>+🔍</span>
                    <span className={Classes.noselect}>{(this.state.scale * 100).toFixed(1)}%</span>
                    <span onClick={() => this.zoom(-1)} className={[Classes.zoom, Classes.noselect].join(' ')} role={'img'} aria-label={'zoom out'}>🔎-</span>
                </div>
            </React.Fragment>
        )
    }
}

export default PdfViewer