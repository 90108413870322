import React from 'react';
import TasksList from 'pages/TasksList/TasksList';
import Classes from './AssignedTasks.module.css';
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import runningPage from 'global';

class AssignedTasks extends React.Component{

    constructor(props){
        super(props);
        this.dt = JSON.parse(localStorage.getItem('tab'));

        var makeState = {};
        this.refVar = {};
        var first = 1;
        Object.keys(this.dt).forEach(el=>{
            makeState['tab'+el] = first;
            if(first){
                makeState['active'] = 'tab'+el;
            }
            first = 0;
        })

        this.state = makeState;
    }

    setTab = (k) => {
        this.setState({
            [k] : 1,
            active: k,
        });
    }

    toggleWorkbenchHome = (e) => {
        if(runningPage.page === 'home'){
            if(e.altKey){
                if (e.keyCode == '1'.charCodeAt(0)) {
                    if('1' in this.dt)
                        this.setTab('tab1');
                } else if (e.keyCode == '2'.charCodeAt(0)) {
                    if('2' in this.dt)
                        this.setTab('tab2');
                } else if (e.keyCode == '3'.charCodeAt(0)) {
                    if('3' in this.dt)
                        this.setTab('tab3');
                } else if (e.keyCode == '4'.charCodeAt(0)) {
                    if('4' in this.dt)
                        this.setTab('tab4');
                } else if (e.keyCode == '5'.charCodeAt(0)) {
                    if('5' in this.dt)
                        this.setTab('tab5');
                } else if (e.keyCode == '6'.charCodeAt(0)) {
                    if('6' in this.dt)
                        this.setTab('tab6');
                } else if (e.keyCode == '7'.charCodeAt(0)) {
                    if('7' in this.dt)
                        this.setTab('tab7');
                } else if (e.keyCode == '8'.charCodeAt(0)) {
                    if('8' in this.dt)
                        this.setTab('tab8');
                }
            } else if (e.keyCode == 38) {
                this.refVar[this.state.active].setActive(-1);
            } else if (e.keyCode == 40) {
                this.refVar[this.state.active].setActive(1);
            } else if (e.keyCode == 13) {
                this.refVar[this.state.active].openActive();
            }
            
        }
    };

    componentDidMount(){
        runningPage.page = 'home';
        document.addEventListener('keydown', this.toggleWorkbenchHome);
    }

    componentWillUnmount(){
        document.removeEventListener('keydown', this.toggleWorkbenchHome);
    }

    render(){

        const tabs = Object.keys(this.dt).map(el=>{
            return (
                <Tab key={'tab'+el} eventKey={'tab'+el} title={[this.dt[el], <span style={{
                    fontSize: '10px',
                    display: 'block',
                }}>( Alt+{el} )</span>]}>
                    <div style={{marginRight:'20px',boxSizing:'border-box'}}>
                        {this.state['tab'+el]==1?<TasksList inputRef={ref => this.refVar['tab'+el] = ref} self={1} siid={el} />:<div>Loading....</div>}
                    </div>
                </Tab>
            );
        })


        return(
            <Tabs id="uncontrolled-tab-example" activeKey={this.state.active} onSelect={k => this.setTab(k)}>
                {tabs}
            </Tabs>
        )
    }
}



export default AssignedTasks; 