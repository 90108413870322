import { EditorState, RichUtils, KeyBindingUtil, Modifier } from 'draft-js';

const {hasCommandModifier} = KeyBindingUtil;

export default () => {
    return {
        customStyleMap: {
            'SUBSCRIPT': {
                verticalAlign:'sub', 
                fontSize:'smaller'
            }
        },
        keyBindingFn: e => {
            if (e.keyCode === 49 && e.altKey) {
                //1
                return "subscript";
            }
        },
        handleKeyCommand: (command, editorState, eventTimeStamp, { setEditorState }) => {
            if (command === "subscript") {
                const nextContentState = ['SUBSCRIPT','SUPERSCRIPT'].reduce((contentState, color) => {
                    return Modifier.removeInlineStyle(contentState, editorState.getSelection(), color)
                }, editorState.getCurrentContent());
        
                let nextEditorState = EditorState.push(
                    editorState,
                    nextContentState,
                    'change-inline-style'
                );
                setEditorState(RichUtils.toggleInlineStyle(nextEditorState, "SUBSCRIPT"));
                return true;
            }
        }
    };
  };